import { Skeleton } from '@mui/material';
import { Box } from '@mui/material'

export const PersonSkeleton = ({ ...rest }: any) => (
    <Box position="relative" width="100%" {...rest}>
        <Box marginY="7.5px" display="flex" alignItems="center">
            <Box >
                <Skeleton variant="circular" width={24} height={24} />
            </Box>

            <Box
                marginLeft="10px"
                minWidth='100px'
            >
                <Skeleton variant="text" />
            </Box>
        </Box>
    </Box>
)


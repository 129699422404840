import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() =>
    createStyles({
        attribute: ({ styles }) => ({
            marginTop: '-8px',
            ...styles?.attribute,
        }),
        attributeLabel: ({ styles }) => ({
            minWidth: '265px',
            ...styles?.attributeLabel,
        }),
        attributeValue: ({ styles }) => ({
            wordBreak: 'unset',
            ...styles?.attributeValue,
        }),
    }),
)

const AccessRequestPolicyDetails = ({
    policy,
    orientation = 'horizontal',
    showValuesIfUnrestricted = true,
    styles,
    noOfColumns = 1,
}) => {
    const classes = useStyles({ styles })
    const { t } = useTranslation()

    const getAttributeWidth = () => {
        switch (noOfColumns) {
            case 1:
                return 12
            case 2:
                return 6
            case 3:
                return 4
            case 4:
                return 3
            default:
                return 12
        }
    }
    return (
        <Grid container>
            <Grid item xs={getAttributeWidth()}>
                <Attribute
                    className={classes.attribute}
                    orientation={orientation}
                >
                    <AttributeLabel className={classes.attributeLabel}>
                        {t('AccessRequestPolicy')}
                    </AttributeLabel>
                    <AttributeValue className={classes.attributeValue}>
                        {policy && policy.name}
                    </AttributeValue>
                </Attribute>
            </Grid>

            <Grid item xs={getAttributeWidth()}>
                <Attribute
                    className={classes.attribute}
                    orientation={orientation}
                >
                    <AttributeLabel className={classes.attributeLabel}>
                        {t('RestrictsLengthOfAccess')}
                    </AttributeLabel>
                    <AttributeValue className={classes.attributeValue}>
                        {policy && policy.isTimeConstrained
                            ? t('Yes')
                            : t('No')}
                    </AttributeValue>
                </Attribute>
            </Grid>

            {(showValuesIfUnrestricted || policy.isTimeConstrained) && (
                <>
                    <Grid item xs={getAttributeWidth()}>
                        <Attribute
                            className={classes.attribute}
                            orientation={orientation}
                        >
                            <AttributeLabel className={classes.attributeLabel}>
                                {t('DefaultAccessDuration')}
                            </AttributeLabel>
                            <AttributeValue className={classes.attributeValue}>
                                {policy && policy.defaultValueInMinutes}
                            </AttributeValue>
                        </Attribute>
                    </Grid>

                    <Grid item xs={getAttributeWidth()}>
                        <Attribute
                            className={classes.attribute}
                            orientation={orientation}
                        >
                            <AttributeLabel className={classes.attributeLabel}>
                                {t('MaxAccessDuration')}
                            </AttributeLabel>
                            <AttributeValue className={classes.attributeValue}>
                                {policy && policy.maximumValueInMinutes}
                            </AttributeValue>
                        </Attribute>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

export default AccessRequestPolicyDetails

export const DEFAULT_LOOKUP_ATTRIBUTES = [
    {
        name: 'FriendlyName',
        value: 'FriendlyName',
        label: 'FriendlyName',
        sortable: true,
    },
    {
        name: 'ValueName',
        value: 'ValueName',
        label: 'Value',
        sortable: true,
    },
]

export const DEFAULT_PAGE_SIZE_DETAILS = 6

export const updateFieldTypeJson = (
    oldJson,
    keyToFind,
    valueToFind,
    updatedValue,
    parentKeyValue,
) => {
    try {
        if (oldJson instanceof Array) {
            for (let i = 0; i < oldJson.length; i++) {
                updateFieldTypeJson(
                    oldJson[i],
                    keyToFind,
                    valueToFind,
                    updatedValue,
                    parentKeyValue,
                )
            }
        } else if (typeof oldJson === 'object') {
            for (let prop in oldJson) {
                if (
                    prop === keyToFind &&
                    oldJson[prop] === valueToFind &&
                    oldJson?.parentRightId === parentKeyValue
                ) {
                    return Object.assign(oldJson, updatedValue)
                } else {
                    updateFieldTypeJson(
                        oldJson[prop],
                        keyToFind,
                        valueToFind,
                        updatedValue,
                        parentKeyValue,
                    )
                }
            }
        }
        return oldJson
    } catch (err) {}
    return oldJson
}

export const findChangedFieldTypesForAppRight = (
    apiData,
    updatedFieldTypes,
    fieldTypeKeysToExclude,
) => {
    let changedData = {}
    try {
        const newData = { ...updatedFieldTypes }
        const oldData = { ...apiData }
        const oldFieldTypes = oldData.FieldType || []
        const newFieldTypes = newData.FieldType || []
        const fieldTypeArray = newFieldTypes.map((item) => {
            const fieldType = getModifiedFieldTypeValue(oldFieldTypes, item)
            const filteredObject = Object.assign({}, fieldType)

            fieldTypeKeysToExclude.forEach((key) => {
                delete filteredObject[key]
            })
            return filteredObject
        })
        if (fieldTypeArray?.length) {
            changedData = {
                ...updatedFieldTypes,
                FieldType: fieldTypeArray,
            }
        } else {
            changedData = {
                ...updatedFieldTypes,
            }
        }
    } catch (err) {
        console.log('Err', err.message || err)
    }
    return changedData
}

export const findChangedFieldTypesForAppRole = (
    apiData,
    updatedAppRoles,
    fieldTypeKeysToExclude,
) => {
    let changedData = {}
    try {
        const newData = { ...updatedAppRoles }
        const oldData = { ...apiData }
        // Assuming the structure is consistent, iterate over the Rights array
        const oldRights = oldData.Rights || []
        const newRights = newData.Rights || []
        const updatedRights = newRights.map((item) => {
            const oldRight = oldRights.find(
                (x) => x.AzLocalRightID === item.AzLocalRightID,
            )
            const oldFieldTypes = oldRight?.FieldType || []
            const rightFieldTypes = item?.FieldType || []
            const fieldTypeArray = rightFieldTypes.map((item) => {
                const fieldType = getModifiedFieldTypeValue(oldFieldTypes, item)
                const filteredObject = Object.assign({}, fieldType)

                fieldTypeKeysToExclude.forEach((key) => {
                    delete filteredObject[key]
                })
                return filteredObject
            })
            return {
                ...item,
                FieldType: fieldTypeArray,
            }
        })
        if (updatedRights?.length) {
            changedData = {
                ...updatedAppRoles,
                Rights: updatedRights,
            }
        } else {
            changedData = {
                ...updatedAppRoles,
            }
        }
    } catch (err) {
        console.log('Err', err.message || err)
    }

    return changedData
}

export const getFieldTypeRequiredErrorMessage = (item) => {
    let errorMessage = ''
    try {
        if (item?.IsRequired && !item?.AssignedAllValuesRange) {
            const valueType =
                item?.AzFieldTypeSelectionRuleTypeID ===
                FIELD_TYPES_SELECTION_RULE.MULTISELECT
                    ? 'AssignedValues'
                    : 'AssignedValue'

            if (
                item?.SelectionRuleType === 'Range' &&
                (item?.SelectionRule ===
                    FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_RANGE ||
                    item?.SelectionRule ===
                        FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE)
            ) {
                if (!item?.AssignedValueFrom || !item?.AssignedValueTo) {
                    errorMessage = 'Common_SelectRangeForFieldType'
                }
            } else {
                if (typeof item?.[valueType] === 'string') {
                    if (!item[valueType]) {
                        errorMessage = 'Common_SelectFieldTypeValue'
                    }
                } else if (Array.isArray(item?.[valueType])) {
                    if (!item[valueType]?.length) {
                        errorMessage = 'Common_SelectItemFromListForFieldType'
                    }
                } else {
                    if (!item[valueType]?.FriendlyName) {
                        errorMessage = 'Common_SelectFieldTypeValue'
                    }
                }
            }
        }
    } catch (err) {}
    return errorMessage
}

export const checkIfAnyFieldTypeMissingValue = (appRight) => {
    let isRequiredFilled = true
    try {
        appRight?.FieldType?.forEach((item) => {
            if (!checkAllRequiredFieldTypeFilled(item)) {
                isRequiredFilled = false
            }
        })
    } catch (err) {}

    return isRequiredFilled
}

export const checkFieldTypeWithValidSourceValues = (item) => {
    let isValidFieldValue = true
    try {
        if (item?.IsRequired) {
            if (
                item?.SelectionRuleType !== 'Range' &&
                item?.SelectionRule !==
                    FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE &&
                item?.SelectionRule !==
                    FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_RANGE &&
                item?.SelectionRule !==
                    FIELD_TYPE_COMPONENTS_TYPES.FIXED_SINGLE_VALUE &&
                item?.SelectionRule !==
                    FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_SINGLE_VALUE &&
                item?.SelectionRule !== FIELD_TYPE_COMPONENTS_TYPES.FIXED_LIST
            ) {
                const sourceValue = item?.SourceValues
                if (!sourceValue) {
                    isValidFieldValue = false
                } else {
                    if (Array.isArray(sourceValue)) {
                        if (!sourceValue?.length) {
                            isValidFieldValue = false
                        }
                    } else {
                        if (!sourceValue?.ValueName) {
                            isValidFieldValue = false
                        }
                    }
                }
            } else {
                if (
                    item?.SelectionRule ===
                        FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE &&
                    (!item?.AssignedValueFrom || !item?.AssignedValueTo)
                ) {
                    isValidFieldValue = false
                }
            }
        }
    } catch (err) {}
    return isValidFieldValue
}

export const getModifiedFieldTypeValue = (orginalData, fieldType) => {
    let fieldTypeValue = { ...fieldType }
    try {
        const oldFieldType = orginalData.find(
            (x) => x.AzFieldTypeID === fieldType.AzFieldTypeID,
        )

        if (oldFieldType) {
            const valueType =
                oldFieldType?.AzFieldTypeSelectionRuleTypeID ===
                FIELD_TYPES_SELECTION_RULE.MULTISELECT
                    ? 'AssignedValues'
                    : 'AssignedValue'
            const oldValue = oldFieldType[valueType]
            const newValue = fieldType[valueType]

            const isAllSelectedChanged =
                Boolean(fieldType?.AssignedAllValuesRange) !==
                Boolean(oldFieldType?.AssignedAllValuesRange)

            // SingleSelectRadioButton
            if (
                fieldType?.SelectionRuleType === 'Range' &&
                (fieldType?.SelectionRule ===
                    FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_RANGE ||
                    fieldType?.SelectionRule ===
                        FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE)
            ) {
                // Case for range values (from)
                const oldAssignedVal = oldFieldType?.AssignedValueFrom
                const newAssignedVal = fieldType.AssignedValueFrom
                // Case for range values (to)
                const oldAssignedValTo = oldFieldType.AssignedValueTo
                const newAssignedValTo = fieldType.AssignedValueTo

                // Case for range change
                const oldAssignedRange = oldFieldType.AssignedAllValuesRange
                const newAssignedRange = fieldType.AssignedAllValuesRange
                if (
                    oldAssignedVal !== newAssignedVal ||
                    oldAssignedValTo !== newAssignedValTo ||
                    (oldAssignedRange !== newAssignedRange &&
                        (newAssignedVal || newAssignedValTo))
                ) {
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: true,
                        oldValue:
                            (oldAssignedVal && !newAssignedVal) ||
                            (oldAssignedValTo && !newAssignedValTo)
                                ? {
                                      type: 'Range',
                                      oldFrom: oldAssignedVal,
                                      oldTo: oldAssignedValTo,
                                  }
                                : null,
                        ActionType:
                            getActionType(
                                oldAssignedVal,
                                newAssignedVal,
                                fieldType,
                            ) ||
                            getActionType(
                                oldAssignedValTo,
                                newAssignedValTo,
                                fieldType,
                            ) ||
                            getActionType(
                                oldAssignedRange,
                                newAssignedRange,
                                fieldType,
                            ),
                    }
                } else {
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: false,
                        ActionType: getActionType(
                            oldAssignedVal,
                            newAssignedVal,
                            fieldType,
                        ),
                    }
                }
            } else if (typeof newValue === 'string') {
                if (newValue !== oldValue) {
                    fieldTypeValue = {
                        ...fieldType,
                        ActionType: getActionType(
                            oldValue,
                            newValue,
                            fieldType,
                        ),
                        isModified: true,
                        oldValue:
                            oldValue && !newValue
                                ? {
                                      type: 'Single',
                                      oldValue: oldValue,
                                  }
                                : null,
                    }
                } else {
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: false,
                        ActionType: getActionType(
                            oldValue,
                            newValue,
                            fieldType,
                        ),
                    }
                }
            } else if (Array.isArray(newValue) || isAllSelectedChanged) {
                if (
                    fieldType?.SelectionRule ===
                        FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUE ||
                    fieldType?.SelectionRule ===
                        FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUES
                ) {
                    const filteredNewVal = newValue.filter(
                        (i) => i?.FriendlyName || i?.ValueName,
                    )
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: filteredNewVal?.filter(
                            (x) => x.ActionType !== ACTION_TYPES.NO_CHANGE,
                        )?.length
                            ? true
                            : false,
                        [valueType]: filteredNewVal,
                        ActionType: ACTION_TYPES.UN_CHANGED,
                    }
                } else {
                    const changedArrayItems = findChangesInAssignedValues(
                        oldValue || [],
                        newValue || [],
                        'ValueName',
                    )

                    // Filter added items
                    const addedItems = filterAddedOrRemoved(
                        changedArrayItems,
                        ACTION_TYPES.ADDED,
                    )

                    // Filter removed items
                    const removedItems = filterAddedOrRemoved(
                        changedArrayItems,
                        ACTION_TYPES.DELETED,
                    )

                    // Filter un changed items
                    const Unchanged = filterAddedOrRemoved(
                        changedArrayItems,
                        ACTION_TYPES.NO_CHANGE,
                    )
                    if (
                        addedItems?.length ||
                        removedItems?.length ||
                        isAllSelectedChanged
                    ) {
                        fieldTypeValue = {
                            ...fieldType,
                            [valueType]: [
                                ...removedItems,
                                ...addedItems,
                                ...Unchanged,
                            ],
                            isModified: true,
                            ActionType: isAllSelectedChanged
                                ? ACTION_TYPES.ADDED
                                : ACTION_TYPES.UN_CHANGED,
                            oldValue: {
                                type: 'MultiValue',
                                removed: removedItems.map(
                                    (item) => item?.FriendlyName,
                                ),
                                added: addedItems.map(
                                    (item) => item?.FriendlyName,
                                ),
                            },
                        }
                    } else {
                        const arrayWithActionType = fieldType?.[valueType]?.map(
                            (x) => {
                                return {
                                    ...x,
                                    ActionType: getActionType(
                                        x?.ValueName,
                                        x?.ValueName,
                                        fieldType,
                                    ),
                                }
                            },
                        )
                        fieldTypeValue = {
                            ...fieldType,
                            isModified: false,
                            [valueType]: arrayWithActionType,
                            ActionType: arrayWithActionType.find(
                                (x) => x.ActionType === ACTION_TYPES.FIXED,
                            )
                                ? ACTION_TYPES.FIXED
                                : null,
                        }
                    }
                }
            } else {
                if (oldValue?.ValueName !== newValue?.ValueName) {
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: true,
                        ActionType:
                            getActionType(
                                oldValue?.ValueName,
                                newValue?.ValueName,
                                fieldType,
                            ) || getActionType(oldValue, newValue, fieldType),
                        oldValue:
                            (oldValue?.ValueName && !newValue?.ValueName) ||
                            (oldValue && !newValue)
                                ? {
                                      type: 'Single',
                                      oldValue: oldValue?.ValueName || oldValue,
                                  }
                                : null,
                    }
                } else {
                    fieldTypeValue = {
                        ...fieldType,
                        isModified: false,
                        ActionType: getActionType(
                            oldValue?.ValueName,
                            newValue?.ValueName,
                            fieldType,
                        ),
                    }
                }
            }
        }
        if (fieldTypeValue?.isModified) {
            const refinedObject = Object.assign({}, fieldTypeValue)
            delete refinedObject.SourceValues
            delete refinedObject.totalCount

            fieldTypeValue = {
                ...refinedObject,
                AssignedAllValuesRange:
                    fieldTypeValue.AssignedAllValuesRange || false,
            }
        } else {
            const refinedObject = Object.assign({}, fieldTypeValue)
            delete refinedObject.SourceValues
            if (refinedObject.ActionType !== ACTION_TYPES.FIXED) {
                delete refinedObject.ActionType
            }
            delete refinedObject.totalCount
            delete refinedObject.oldValue

            fieldTypeValue = {
                ...refinedObject,
                AssignedAllValuesRange:
                    fieldTypeValue.AssignedAllValuesRange || false,
            }
        }
    } catch (err) {}

    return fieldTypeValue
}

const findChangesInAssignedValues = (oldArray, newArray, keyToMatch) => {
    const changes = oldArray.map((oldItem) => {
        const newItem = newArray.find(
            (item) => item[keyToMatch] === oldItem[keyToMatch],
        )
        if (!newItem) {
            return { ...oldItem, ActionType: ACTION_TYPES.DELETED }
        } else if (JSON.stringify(oldItem) !== JSON.stringify(newItem)) {
            return { ...newItem, ActionType: ACTION_TYPES.ADDED }
        } else {
            return { ...newItem, ActionType: ACTION_TYPES.NO_CHANGE }
        }
    })

    newArray.forEach((newItem) => {
        const found = oldArray.find(
            (item) => item[keyToMatch] === newItem[keyToMatch],
        )
        if (!found) {
            changes.push({ ...newItem, ActionType: ACTION_TYPES.ADDED })
        }
    })

    return changes
}

const filterAddedOrRemoved = (combinedArray, type) => {
    return combinedArray.filter((obj) => obj.ActionType === type)
}

const getActionType = (oldVal, newVal, fieldType) => {
    try {
        let tOldVal = oldVal
        let tNewVal = newVal
        if (typeof newVal !== 'string') {
            tOldVal = oldVal?.ValueName
            tNewVal = newVal?.ValueName
        }
        if (!tOldVal && tNewVal) {
            return ACTION_TYPES.ADDED
        }
        if (tOldVal && !tNewVal) {
            return ACTION_TYPES.DELETED
        }
        if (tOldVal && tNewVal && tOldVal !== tNewVal) {
            return ACTION_TYPES.UPDATED
        }
        if (
            (fieldType?.SelectionRule ===
                FIELD_TYPE_COMPONENTS_TYPES.PERSON_RELATIVE ||
                fieldType.SelectionRule ===
                    FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE ||
                fieldType.SelectionRule ===
                    FIELD_TYPE_COMPONENTS_TYPES.FIXED_SINGLE_VALUE ||
                fieldType.SelectionRule ===
                    FIELD_TYPE_COMPONENTS_TYPES.FIXED_LIST) &&
            tOldVal
        ) {
            return 'Fixed'
        }
    } catch (err) {}
    return ''
}

export const checkAllRequiredFieldTypeFilled = (item) => {
    let isRequiredFilled = true
    try {
        const valueType =
            item?.AzFieldTypeSelectionRuleTypeID ===
            FIELD_TYPES_SELECTION_RULE.MULTISELECT
                ? 'AssignedValues'
                : 'AssignedValue'
        if (item?.IsRequired && !item?.AssignedAllValuesRange) {
            if (
                item?.SelectionRuleType === 'Range' &&
                (item?.SelectionRule ===
                    FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_RANGE ||
                    item?.SelectionRule ===
                        FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE)
            ) {
                if (!item?.AssignedValueFrom || !item?.AssignedValueTo) {
                    isRequiredFilled = false
                }
            } else {
                if (typeof item?.[valueType] === 'string') {
                    if (!item[valueType]) {
                        isRequiredFilled = false
                    }
                } else if (Array.isArray(item?.[valueType])) {
                    if (
                        item?.SelectionRule ===
                            FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUE ||
                        item?.SelectionRule ===
                            FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUES
                    ) {
                        if (item?.[valueType]?.length) {
                            const nonEmptyValues = item?.[valueType].filter(
                                (x) => x?.FriendlyName || x?.ValueName,
                            )
                            if (nonEmptyValues?.length) {
                                const fieldWithMisiingLabelValue =
                                    nonEmptyValues.filter(
                                        (x) =>
                                            !x?.FriendlyName || !x?.ValueName,
                                    )
                                if (fieldWithMisiingLabelValue?.length) {
                                    isRequiredFilled = false
                                }
                            }
                        }
                    } else if (!item[valueType]?.length) {
                        isRequiredFilled = false
                    }
                } else {
                    if (!item[valueType]?.ValueName) {
                        isRequiredFilled = false
                    }
                }
            }
        } else if (
            item?.SelectionRule ===
                FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUE ||
            item?.SelectionRule ===
                FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUES
        ) {
            if (item?.[valueType]?.length) {
                const nonEmptyValues = item?.[valueType].filter(
                    (x) => x?.FriendlyName || x?.ValueName,
                )
                if (nonEmptyValues?.length) {
                    const fieldWithMisiingLabelValue = nonEmptyValues.filter(
                        (x) => !x?.FriendlyName || !x?.ValueName,
                    )
                    if (fieldWithMisiingLabelValue?.length) {
                        isRequiredFilled = false
                    }
                }
            }
        }
    } catch (err) {}
    return isRequiredFilled
}

export const mergeCartAndApiJson = (cartJson, apiJson) => {
    let modifiedJson = { ...cartJson }
    try {
        if (modifiedJson?.Rights?.length) {
            const newData = modifiedJson?.Rights?.map((right) => {
                const apiRight = apiJson?.Rights?.find(
                    (x) => x.AzLocalRightID === right.AzLocalRightID,
                )
                let fieldType = []
                if (right?.FieldType?.length) {
                    fieldType = right.FieldType.map((i) => {
                        const apiField = apiRight?.FieldType?.find(
                            (x) => x.AzFieldTypeID === i.AzFieldTypeID,
                        )
                        const valueType =
                            i?.AzFieldTypeSelectionRuleTypeID ===
                            FIELD_TYPES_SELECTION_RULE.MULTISELECT
                                ? 'AssignedValues'
                                : 'AssignedValue'
                        const value = i[valueType]
                        return {
                            ...i,
                            SourceValues: apiField.SourceValues,
                            isModified: false,
                            [valueType]: Array.isArray(value)
                                ? value
                                      .filter(
                                          (x) =>
                                              x.ActionType !==
                                              ACTION_TYPES.DELETED,
                                      )
                                      .map((item) => {
                                          const refinedObject = Object.assign(
                                              {},
                                              item,
                                          )
                                          delete refinedObject.ActionType
                                          return refinedObject
                                      })
                                : value,
                        }
                    })
                }
                return { ...right, FieldType: fieldType }
            })
            return { ...modifiedJson, Rights: newData }
        } else {
            if (modifiedJson?.FieldType?.length) {
                const apiFieldType = apiJson.FieldType
                const fieldTypes = modifiedJson.FieldType.map((i) => {
                    const apiField = apiFieldType?.find(
                        (x) => x.AzFieldTypeID === i.AzFieldTypeID,
                    )
                    const valueType =
                        i?.AzFieldTypeSelectionRuleTypeID ===
                        FIELD_TYPES_SELECTION_RULE.MULTISELECT
                            ? 'AssignedValues'
                            : 'AssignedValue'
                    const value = i[valueType]
                    return {
                        ...i,
                        SourceValues: apiField.SourceValues,
                        isModified: false,
                        [valueType]: Array.isArray(value)
                            ? value
                                  .filter(
                                      (x) =>
                                          x.ActionType !== ACTION_TYPES.DELETED,
                                  )
                                  .map((item) => {
                                      const refinedObject = Object.assign(
                                          {},
                                          item,
                                      )
                                      delete refinedObject.ActionType
                                      return refinedObject
                                  })
                            : value,
                    }
                })
                return { ...modifiedJson, FieldType: fieldTypes }
            }
            return modifiedJson
        }
    } catch (err) {}
    return modifiedJson
}

export const updateJsonMappingWithApiFormat = (data, isApiFormat) => {
    try {
        if (data?.Rights?.length) {
            const newData = data?.Rights?.map((right) => {
                let fieldType = []
                if (right?.FieldType?.length) {
                    fieldType = right.FieldType.map((field) => {
                        const valueType =
                            field?.AzFieldTypeSelectionRuleTypeID ===
                            FIELD_TYPES_SELECTION_RULE.MULTISELECT
                                ? 'AssignedValues'
                                : 'AssignedValue'
                        let fieldValue = field[valueType]
                        let AzFieldTypeID = field?.AzFieldTypeID
                        let FieldTypeScope = `-${
                            field?.FieldTypeScope?.toLowerCase() || 'resource'
                        }`
                        if (
                            field?.AzFieldTypeSelectionRuleTypeID !==
                            FIELD_TYPES_SELECTION_RULE.MULTISELECT
                        ) {
                            if (isApiFormat && fieldValue) {
                                fieldValue = [fieldValue]
                            } else if (!isApiFormat && fieldValue?.length) {
                                fieldValue = fieldValue?.[0]
                            }
                        }

                        if (isApiFormat) {
                            AzFieldTypeID = AzFieldTypeID.replace(
                                FieldTypeScope,
                                '',
                            )
                            if (fieldValue?.length) {
                                fieldValue = fieldValue.map((item) => {
                                    const valueItem = { ...item }
                                    delete valueItem.isNewlyAdded
                                    return valueItem
                                })
                            }
                        } else {
                            AzFieldTypeID = `${AzFieldTypeID}${FieldTypeScope}`
                        }

                        return {
                            ...field,
                            [valueType]: fieldValue,
                            AzFieldTypeID: AzFieldTypeID,
                            parentRightId: right.AzLocalRightID,
                        }
                    })
                }
                return { ...right, FieldType: fieldType }
            })
            return { ...data, Rights: newData }
        } else {
            if (data?.FieldType?.length) {
                const fieldTypes = data.FieldType.map((field) => {
                    const valueType =
                        field?.AzFieldTypeSelectionRuleTypeID ===
                        FIELD_TYPES_SELECTION_RULE.MULTISELECT
                            ? 'AssignedValues'
                            : 'AssignedValue'
                    let fieldValue = field[valueType]
                    let AzFieldTypeID = field?.AzFieldTypeID
                    let FieldTypeScope = `-${
                        field?.FieldTypeScope?.toLowerCase() || 'resource'
                    }`
                    if (
                        field?.AzFieldTypeSelectionRuleTypeID !==
                        FIELD_TYPES_SELECTION_RULE.MULTISELECT
                    ) {
                        if (isApiFormat && fieldValue) {
                            fieldValue = [fieldValue]
                        } else if (!isApiFormat && fieldValue?.length) {
                            fieldValue = fieldValue?.[0]
                        }
                    }

                    if (isApiFormat) {
                        AzFieldTypeID = AzFieldTypeID.replace(
                            FieldTypeScope,
                            '',
                        )
                        if (fieldValue?.length) {
                            fieldValue = fieldValue.map((item) => {
                                const valueItem = { ...item }
                                delete valueItem.isNewlyAdded
                                return valueItem
                            })
                        }
                    } else {
                        AzFieldTypeID = `${AzFieldTypeID}${FieldTypeScope}`
                    }

                    return {
                        ...field,
                        [valueType]: fieldValue,
                        AzFieldTypeID: AzFieldTypeID,
                        parentRightId: data.AzLocalRightID,
                    }
                })
                return { ...data, FieldType: fieldTypes }
            }
            return data
        }
    } catch (err) {}
    return data
}

export const hasValidJson = (apiDataObject) => {
    let isValidJson = false
    try {
        const parsedObject = Array.isArray(apiDataObject)
            ? apiDataObject[0]
            : apiDataObject

        if (Object.keys(parsedObject).length > 0) {
            isValidJson = true
        }
        // Check to find if all fieldtypes contains value
        if (isValidJson) {
            const combinedFieldTypes = getCombinedFields(parsedObject)
            if (!combinedFieldTypes?.length) {
                return false
            }
            let isValidJsonWithFieldTypes = false
            combinedFieldTypes.forEach((item) => {
                item?.fieldTypes?.forEach((field) => {
                    const validField = getFieldTypeWithValue(field)
                    if (validField) {
                        isValidJsonWithFieldTypes = true
                    }
                })
            })
            return isValidJson && isValidJsonWithFieldTypes
        }
    } catch (err) {}
    return isValidJson
}

export const getCombinedFields = (dataObject) => {
    let updatedAppRights = []
    try {
        const appRights = dataObject?.Rights || []
        if (appRights?.length) {
            //To check if App Role
            appRights.forEach((element) => {
                const fieldTypes = element?.FieldType || []
                const modifiedField = {
                    displayName: element?.DisplayName,
                    isModified: fieldTypes.find((x) => x.isModified)
                        ? true
                        : false,
                    fieldTypes: fieldTypes,
                }
                if (fieldTypes?.length) {
                    updatedAppRights.push(modifiedField)
                }
            })
        } else {
            // Else is for app Right
            const appRightsFields = dataObject?.FieldType || []
            const modifiedField = {
                displayName: dataObject?.DisplayName,
                isModified: appRightsFields.find((x) => x.isModified)
                    ? true
                    : false,
                fieldTypes: appRightsFields,
            }
            if (appRightsFields?.length) {
                updatedAppRights.push(modifiedField)
            }
        }
    } catch (err) {}
    return updatedAppRights
}

export const getFieldTypeWithValue = (field) => {
    try {
        const valueType =
            field?.AzFieldTypeSelectionRuleTypeID ===
            FIELD_TYPES_SELECTION_RULE.MULTISELECT
                ? 'AssignedValues'
                : 'AssignedValue'
        const value = field[valueType]
        if (field?.isModified) {
            return field
        }
        if (
            field?.SelectionRuleType === 'Range' &&
            (field?.SelectionRule ===
                FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_RANGE ||
                field?.SelectionRule ===
                    FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE)
        ) {
            if (field.AssignedValueFrom || field.AssignedValueTo) {
                return field
            }
        } else if (typeof value === 'string') {
            if (value) {
                return field
            }
        } else if (Array.isArray(value)) {
            if (value?.length) {
                return field
            }
        } else {
            if (value?.ValueName) {
                return field
            } else if (value) {
                return field
            }
        }
    } catch (err) {}
    return null
}

export const paginate = (items, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize
    const end = startIndex + pageSize
    items = items.slice(startIndex, end)
    return items
}

export const FIELD_TYPE_COMPONENTS_TYPES = {
    FREE_TEXT_MULTI_VALUE: 'FreeTextMultiValue',
    FREE_TEXT_MULTI_VALUES: 'FreeTextMultiValues',
    SINGLE_SELECT_RADIO_BUTTON: 'SingleSelectRadioButton',
    SINGLE_SELECT_DROPDOWN_LIST: 'SingleSelectDropdownList',
    SINGLE_SELECT_LOOKUP_CONTROL: 'SingleSelectLookupControl',
    PERSON_RELATIVE: 'PersonRelative',
    FREE_TEXT_RANGE: 'FreeTextRange',
    FIXED_RANGE: 'FixedRange',
    FIXED_SINGLE_VALUE: 'FixedSingleValue',
    FREE_TEXT_SINGLE_VALUE: 'FreeTextSingleValue',
    MULTI_SELECT_CHECKBOX_LIST: 'MultiSelectCheckBoxList',
    MULTI_SELECT_LOOKUP_CONTROL: 'MultiSelectLookupControl',
    MULTI_SELECT_AUTOCOMPLETE: 'MultiSelectAutocomplete',
    FIXED_LIST: 'FixedList',
    SINGLE_SELECT_AUTO_COMPLETE: 'SingleSelectAutocomplete',
}

export const ACTION_TYPES = {
    UN_CHANGED: 'Unchanged',
    ADDED: 'Added',
    DELETED: 'Deleted',
    FIXED: 'Fixed',
    UPDATED: 'Updated',
    NO_CHANGE: 'NoChange',
}

export const checkIfValidFreeLablValues = (appRightsFieldTypes) => {
    const data = { ...appRightsFieldTypes }
    let isValidFreeTextLabel = true
    try {
        if (data?.Rights?.length) {
            data?.Rights?.forEach((right) => {
                if (right?.FieldType?.length) {
                    right?.FieldType?.forEach((item) => {
                        if (!checkIfSameLabelFreeTextValues(item)) {
                            isValidFreeTextLabel = false
                        }
                    })
                }
            })
        } else {
            if (data?.FieldType?.length) {
                data?.FieldType?.forEach((item) => {
                    if (!checkIfSameLabelFreeTextValues(item)) {
                        isValidFreeTextLabel = false
                    }
                })
            }
        }
    } catch (err) {}
    return isValidFreeTextLabel
}

const checkIfSameLabelFreeTextValues = (fieldType) => {
    let isValid = true
    try {
        if (
            fieldType?.SelectionRule ===
                FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUE ||
            fieldType?.SelectionRule ===
                FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUES
        ) {
            const labelMap = new Map()

            for (let item of fieldType?.AssignedValues.filter(
                (i) =>
                    (i?.FriendlyName || i?.ValueName) &&
                    i?.ActionType !== ACTION_TYPES.DELETED,
            )) {
                if (labelMap.has(item.FriendlyName)) {
                    return false
                } else {
                    labelMap.set(item.FriendlyName, item)
                }
            }
        }
    } catch (err) {}
    return isValid
}

export const checkIfAtleastOneAddedValue = (item) => {
    let isOneAddedValue = true
    try {
        const valueType =
            item?.AzFieldTypeSelectionRuleTypeID ===
            FIELD_TYPES_SELECTION_RULE.MULTISELECT
                ? 'AssignedValues'
                : 'AssignedValue'
        if (Array.isArray(item?.[valueType])) {
            isOneAddedValue =
                item?.[valueType]?.find(
                    (x) => x.ActionType === ACTION_TYPES.ADDED,
                ) || item?.ActionType === ACTION_TYPES.ADDED
                    ? true
                    : false
        } else {
            isOneAddedValue =
                item?.ActionType === ACTION_TYPES.ADDED ? true : false
        }
        if (item?.AssignedAllValuesRange) {
            isOneAddedValue = true
        }
    } catch (err) {}
    return isOneAddedValue
}

export const FIELD_TYPES_SELECTION_RULE = {
    SINGLE: 1,
    RANGE: 2,
    MULTISELECT: 3,
    RELATIVE: 4,
}

import React, { useEffect, useState } from 'react'
import { EidTree } from 'packages/eid-controls'

const TreeFilter = ({
    url,
    initialValue,
    value,
    onChange,
    selectedValueIndicatorProp,
    searchPlaceholder,
    ...rest
}) => {
    const [selectedItem, setSelectedItem] = useState(value)

    useEffect(() => {
        if (value !== selectedItem) {
            setSelectedItem(value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        onChange(selectedItem)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem])

    let basicUrl = `${url}`

    return (
        <EidTree
            url={basicUrl}
            initialValue={initialValue}
            onChange={(item) => setSelectedItem(item)}
            value={value}
            searchPlaceholder={searchPlaceholder}
            {...rest}
        />
    )
}

export default TreeFilter

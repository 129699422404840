import AccountDropdown from './AccountDropdown'
import Header from './Header'
import MobileHeader from './MobileHeader'
import IconLink from './IconLink'
import Item from './Item'
import RightSection from './RightSection'
import Search from './Search'
import Title from './Title'
import PopupMenu from './PopupMenu'
import SearchMenu from './SearchMenu'
import HelpMenu from './HelpMenu'

Header.SearchMenu = SearchMenu
Header.AccountDropdown = AccountDropdown
Header.PopupMenu = PopupMenu
Header.IconLink = IconLink
Header.Item = Item
Header.RightSection = RightSection
Header.Search = Search
Header.Title = Title
Header.Mobile = MobileHeader
Header.HelpMenu = HelpMenu

export default Header

import React from 'react'
import { Box } from '@mui/material';


import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles(
  createStyles({
       riskcollapsebtn: {
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      border: 'solid 1px #fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center !important',
      cursor: 'pointer',
      overflow: 'hidden',
      marginTop:'3px',
    }
  }),
)
export const ExpandIconPlusMinus = ({ expanded, onClick } :any) => {
  const classes = useStyles()
  return (
    <Box className={classes.riskcollapsebtn}>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        fontSize="12px"
        fontWeight="bold"
        color="#307fc1"
        minHeight="20px"
      >
        <Box
          component="span"
          display="flex"
          onClick={onClick}
        >
          <Box>
            {expanded ? '-' : '+'}
          </Box>
        </Box>
      </Box>
    </Box>

  )
}

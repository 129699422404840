import { Box } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import IconContainer from './IconContainer'
import IconLabel from './IconLabel'

const ErrorIcon = ({ label }) => {
    return (
        <Box display="flex" alignItems="center">
            <IconContainer bgcolor="#00a4d8">
                <Icon
                    name="ArrowLong"
                    color="#ffffff"
                    direction="right"
                    width="20px"
                />
            </IconContainer>
            <IconLabel style={{ color: '#00a4d8' }}>{label}</IconLabel>
        </Box>
    )
}

export default ErrorIcon

import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import { Skeleton } from '@mui/material';
import { Box } from '@mui/material'
import { isNilOrEmpty } from '../../../core'
import { AppMenuIcon, EmpowerIdAppLogoIcon, Icon } from '../../../eid-icons'

const MenuRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '45px',
    height: '100%',
    boxShadow: ' 3px 3px 5px 0 rgba(0, 0, 0, 0.04)',
    borderTopRightRadius: '20px',
    backgroundColor: '#307fc1',
})

const MenuHeader = styled('div')({
    backgroundColor: '#1c5f96',
    borderTopRightRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    width: '45px',
})

const AppIconLink = styled('a')({
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '32px',
    width: '32px',
    marginTop: '10px',
    marginBottom: '10px',
})

const CurrentAppIconLink = styled(AppIconLink)({
    borderRadius: 3.8,
    backgroundColor: '#78abd6',
    opacity: 0.6,
})

const Menu = ({ eid, apps, current, loading }) => {
    const isCurrentApp = (appName) => appName === current

    const renderLink = (app) => {
        const LinkComponent = isCurrentApp(app.name)
            ? CurrentAppIconLink
            : AppIconLink

        return (
            <LinkComponent
                title={app.displayName}
                href={app.url}
                key={app.name}
            >
                <Icon name={app.name} color="#fff" />
            </LinkComponent>
        )
    }

    return (
        <MenuRoot>
            <MenuHeader>
                <AppMenuIcon color="#78abd6" width="17px" height="17px" />
            </MenuHeader>

            {loading ? (
                <Box marginTop={2} padding={1} width="100%">
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={64}
                        style={{ borderRadius: '2px' }}
                    />
                </Box>
            ) : (
                <>
                    {!isNilOrEmpty(eid) && (
                        <AppIconLink title={eid.displayName} href={eid.url}>
                            <EmpowerIdAppLogoIcon color="#fff" />
                        </AppIconLink>
                    )}
                    {apps.map((app) => renderLink(app))}
                </>
            )}
        </MenuRoot>
    );
}

Menu.propTypes = {
    eid: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }),
    apps: PropTypes.array,
    current: PropTypes.string.isRequired,
}

export default Menu

import { useState } from 'react'
import { Box, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'
import {
    ClaimControl,
    DelegateMenuButton,
    DelegationsHistory,
    ItemDetails,
    ProcessSteps,
} from 'components'
import Overview from './Overview'
import Approvers from './Approvers'
import RequestItemComments from './RequestItemComments'
import useSubcomponents from 'useSubcomponents'
import {
    useApplicationSettings,
    useCancelRequestItem,
    useCurrentPerson,
    useRefreshRequestItemRisks,
    useRequestItemApprovalSteps,
    useRequestItemDelegationsHistory,
} from 'hooks'
import { isNilOrEmpty } from 'packages/core'
import { isValidJson, smallScreenWidth } from 'utils'
import { RiskIndicator } from 'packages/eid-ui'
import { CancelationButton } from 'components/Button/CancelationButton'
import { WorkflowDecisionModal } from 'components/WorkflowDecisionModal'
import { ItemApprovalDecisionPrompt } from './ItemApprovalDecisionPrompt'
import { RefreshButton } from 'components/Button/RefreshButton'
import { PendingToDoList } from './PendingToDoList'
import { RisksWithViolationsByFunctions } from 'components/V2/RiskViolationsByFunctions'
import FunctionalAccess from './FunctionalAcces'
import RequestDynamicFieldTypeValues from 'components/RequestDynamicFieldTypeValues'

const TabsContainerBox = styled(Box)({
    backgroundColor: '#fbfbfd',
    borderBottom: '1px solid #d8dadd',

    paddingRight: '30px',
    display: 'flex',
    justifyContent: 'space-between',

    '& .Tabs-root': {
        display: 'flex',
    },

    [`@media (max-width:${smallScreenWidth})`]: {
        flexDirection: 'column-reverse',
        paddingTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',

        '& .Tabs-root': {
            marginLeft: '-20px',
        },
    },
})

const OverflowContainer = styled(Box)({
    [`@media (max-width:${smallScreenWidth})`]: {
        overflowX: 'auto',
    },
})

const useStyles = makeStyles({
    subHeader: {
        position: 'sticky',
        top: '0px',
        zIndex: 200,
    },
    subHeaderContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '7px 20px',
        display: 'flex',
        alignItems: 'center',
    },
    riskIndicator: {
        fontWeight: 'bold',
    },
    dynamicFieldSection: {
        '& div[class*="-fieldHeaderTitle"]': {
            padding: '0 2.4rem 0 4rem',
            borderTop: '0.1rem solid #dfdfe5',
            borderBottom: '0 solid #dfdfe5',
        },
        '& div[class*="-additionalFieldWrapper"]': {
            padding: '0 2.4rem 0 4rem',
        },
        '& div[class*="-containerWrapper"]': {
            borderBottom: '0.1rem solid #dfdfe5',
        },
        borderBottom: '0.1rem solid #dfdfe5',
    },
})

const BusinessRequestItemDetails = ({ data, refetch }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const { data: appConfig } = useApplicationSettings()

    const { data: currentPerson } = useCurrentPerson()

    const [activeTab, setActiveTab] = useState('overview')

    const {
        hasAccessToDelegationsHistoryControl,
        hasAccessToRequestItemProcessStepsControl,
        hasAccessToRequestItemCurrentStepApproversControl,
        hasAccessToRequestItemClaimControl,
        hasAccessToRequestItemDelegateControl,
        hasAccessToAdminCancelRequestItemControl,
        hasAccessToCancelRequestItemControl,
        hasAccessToRefreshViolationsControl,
        canSeeRiskViolationsByRisks,
    } = useSubcomponents()

    const isClaimedOrAssigned = !isNilOrEmpty(data.claimedApproverId)

    const isClaimedOrClaimable =
        data.currentApprovalStepId && isNilOrEmpty(data.assignedByPersonId)

    const isItemNotActionable =
        data.businessRequestStatus === 'Expired' ||
        data.businessRequestStatus === 'Canceled' ||
        data.status === 'Canceled'

    const isDelegatable = data.currentApprovalStepId

    const [
        cancelRequestItem,
        { isLoading: isCanceling },
    ] = useCancelRequestItem(data.id)

    const [
        refreshRequestItemRisks,
        { isLoading: isRefreshing },
    ] = useRefreshRequestItemRisks(data.businessRequestId, data.id)

    return (
        <>
            <Box
                className={classes.subHeader}
                style={{
                    backgroundImage: appConfig.style.colors.primary?.gradient,
                    backgroundColor: appConfig.style.colors.primary?.header,
                }}
            >
                <Box className={classes.subHeaderContent}>
                    {hasAccessToRefreshViolationsControl && (
                        <Box
                            data-protectedsubcomponent={
                                hasAccessToRefreshViolationsControl
                            }
                        >
                            <RefreshButton
                                onClick={refreshRequestItemRisks}
                                isRefreshing={isRefreshing}
                            />
                        </Box>
                    )}

                    {data.riskLevel && (
                        <Box marginLeft={'15px'}>
                            <RiskIndicator
                                variant={data.riskLevel}
                                label={t(`${data.riskLevel}Risk`)}
                                className={classes.riskIndicator}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <TabsContainerBox>
                <Box marginLeft="-13px" className="Tabs-root">
                    <ItemDetails.Tabs
                        variant="scrollable"
                        scrollButtons
                        value={activeTab}
                        onChange={(_, value) => setActiveTab(value)}
                    >
                        <ItemDetails.Tabs.Tab
                            value="overview"
                            label={t('Overview')}
                        />
                        {hasAccessToRequestItemProcessStepsControl && (
                            <ItemDetails.Tabs.Tab
                                value="processSteps"
                                label={t('ProcessSteps')}
                                data-protectedsubcomponent={
                                    hasAccessToRequestItemProcessStepsControl
                                }
                            />
                        )}
                        {hasAccessToRequestItemCurrentStepApproversControl &&
                            !isClaimedOrAssigned &&
                            data.approverCount !== null &&
                            data.currentApprovalStepId && (
                                <ItemDetails.Tabs.Tab
                                    value="currentStepApprovers"
                                    label={t('CurrentStepApprovers')}
                                    data-protectedsubcomponent={
                                        hasAccessToRequestItemCurrentStepApproversControl
                                    }
                                />
                            )}
                        {hasAccessToDelegationsHistoryControl && (
                            <ItemDetails.Tabs.Tab
                                value="delegationsHistory"
                                label={t('Delegations')}
                                data-protectedsubcomponent={
                                    hasAccessToDelegationsHistoryControl
                                }
                            />
                        )}
                        {data.riskCount > 0 && canSeeRiskViolationsByRisks && (
                            <ItemDetails.Tabs.Tab
                                value={'violations'}
                                label={t('RiskViolations')}
                                data-protectedsubcomponent={
                                    canSeeRiskViolationsByRisks
                                }
                            />
                        )}
                    </ItemDetails.Tabs>
                </Box>
                <Box display="flex" alignItems="center">
                    {hasAccessToRequestItemClaimControl &&
                        isClaimedOrClaimable &&
                        data.allowAssignApprover && (
                            <Box
                                marginRight="8px"
                                data-protectedsubcomponent={
                                    hasAccessToRequestItemClaimControl
                                }
                            >
                                <ClaimControl
                                    disabled={isItemNotActionable}
                                    page="RequestItems"
                                    isApprover={data.isApprover}
                                    requestId={data.businessRequestId}
                                    requestItemId={data.id}
                                    currentApprovalStepId={
                                        data.currentApprovalStepId
                                    }
                                    claimedApproverId={data.claimedApproverId}
                                    claimedApproverFriendlyName={
                                        data.claimedApproverFriendlyName
                                    }
                                />
                            </Box>
                        )}
                    {hasAccessToRequestItemDelegateControl && isDelegatable && (
                        <DelegateMenuButton
                            page="RequestItems"
                            disabled={isItemNotActionable}
                            requestId={data.businessRequestId}
                            requestItemId={data.id}
                            isApprover={data.isApprover}
                            currentApprovalStepId={data.currentApprovalStepId}
                            assignedByPersonId={data.assignedByPersonId}
                            assignedByPersonFriendlyName={
                                data.assignedByPersonFriendlyName
                            }
                            claimedApproverId={data.claimedApproverId}
                            claimedApproverFriendlyName={
                                data.claimedApproverFriendlyName
                            }
                            allowAddApprover={data.allowAddApprover}
                            allowAssignApprover={data.allowAssignApprover}
                            protectSubcomponent={
                                hasAccessToRequestItemDelegateControl
                            }
                        />
                    )}
                </Box>
            </TabsContainerBox>

            {activeTab === 'overview' && (
                <Overview data={data} refetch={refetch} />
            )}
            {activeTab === 'processSteps' && (
                <OverflowContainer>
                    <ProcessSteps
                        useDataHook={useRequestItemApprovalSteps}
                        requestId={data.businessRequestId}
                        itemId={data.id}
                        isCanceled={data.businessRequestStatus === 'Canceled'}
                    />
                </OverflowContainer>
            )}
            {activeTab === 'currentStepApprovers' && (
                <Approvers id={data.id} stepId={data.currentApprovalStepId} />
            )}
            {activeTab === 'delegationsHistory' && (
                <OverflowContainer>
                    <DelegationsHistory
                        useDataHook={useRequestItemDelegationsHistory}
                        requestId={data.businessRequestId}
                        page="RequestItem"
                        itemId={data.id}
                    />
                </OverflowContainer>
            )}
            {activeTab === 'violations' && (
                <RisksWithViolationsByFunctions
                    showDecisions={false}
                    requestType="businessRequestitems"
                    page={'RequestItems'}
                    itemId={data.id}
                    requestId={data.businessRequestId}
                />
            )}
            {/* Business Request Dynamic Field Type Section Start */}
            {isValidJson(data?.fieldTypeValueJson) && (
                <Box className={classes.dynamicFieldSection}>
                    <RequestDynamicFieldTypeValues
                        fieldTypeJson={data?.fieldTypeValueJson}
                    />
                </Box>
            )}
            {/* Business Request Dynamic Field Type Section End */}
            <FunctionalAccess
                subjectId={data?.subjectId}
                assigneeId={data?.assigneeId}
            />

            <PendingToDoList
                itemId={data.id}
                afterwardsChildren={
                    data &&
                    !isItemNotActionable &&
                    data.isApprovable &&
                    (data.decisionWorkflowName ? (
                        <Box
                            marginY={'24px'}
                            marginX={'28px'}
                            display={'flex'}
                            justifyContent={'flex-end'}
                        >
                            <WorkflowDecisionModal
                                fullScreen={true}
                                iframeHeight="calc(100vh - 7rem)"
                                page={'RequestItems'}
                                requestId={data.businessRequestId}
                                itemId={data.id}
                                currentApprovalStepId={
                                    data.currentApprovalStepId
                                }
                                decisionWorkflowName={data.decisionWorkflowName}
                                maxWidth={'lg'}
                            />
                        </Box>
                    ) : (
                        <ItemApprovalDecisionPrompt
                            requestItem={data}
                            refetch={refetch}
                        />
                    ))
                }
            />

            <Box marginY="18px">
                <RequestItemComments
                    requestId={data.businessRequestId}
                    itemId={data.id}
                />
            </Box>

            {data.isCancellable &&
                (hasAccessToAdminCancelRequestItemControl ||
                    (hasAccessToCancelRequestItemControl &&
                        data.initiatorPersonId === currentPerson.id)) && (
                    <Box
                        marginY={'32px'}
                        marginX={'32px'}
                        data-protectedsubcomponent={`${hasAccessToAdminCancelRequestItemControl} OR ${hasAccessToCancelRequestItemControl}`}
                    >
                        <CancelationButton
                            confirmationMessage={t(
                                'RequestItemCancelationConfirmation',
                            )}
                            isCanceling={isCanceling}
                            onClick={cancelRequestItem}
                        >
                            {t('CancelRequestItem')}
                        </CancelationButton>
                    </Box>
                )}
        </>
    )
}

export default BusinessRequestItemDetails

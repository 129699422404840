import React, { useRef } from 'react'
import { Box, styled, Typography, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from 'packages/eid-icons'
import { Dropdown } from 'packages/eid-ui'
import Loader from './Loader'

const styles = {
    listTitle: {
        fontSize: '12px',
        color: '#307fc1',
        padding: '0px 14px 6px 14px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    divider: {
        backgroundColor: '#e8e8e8',
        margin: '6px 8px',
    },
    label: {
        color: '#5d6870',
        fontSize: '17px',
        fontWeight: 'bold',
        paddingLeft: '3px',
    },
    subLabel: { color: '#5d6870', fontSize: '14px' },
}

const StyledMenuItem = styled(MenuItem)({
    minWidth: '160px',
    color: '#000000',
    borderLeft: '1.5px solid transparent',
    '&.Mui-selected': {
        borderLeft: '1.5px solid #307fc1',
        backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
        '&:hover': {
            backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
        },
        '& svg': {
            color: '#000000',
        },
    },

    '& svg': {
        marginRight: '10px',
    },
    '&:hover': {
        color: '#307fc1',
        backgroundColor: 'inherit !important',
        borderLeft: '1.5px solid #307fc1',
        '& svg': {
            color: '#307fc1',
        },
    },
})

const menuStyles = {
    paddingRight: '0px',
}
const listStyles = {
    paddingTop: '0px',
    paddingBottom: '0px',
}

const Container = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    minWidth: '310px',
    width: '100%',
    position: 'relative',
    paddingBottom: '4px',
    height: '45px',
    '&:hover': {
        cursor: 'pointer',
        '& .Gradient-border': {
            backgroundImage: 'linear-gradient(91deg, #01ae8f, #307fc1)',
        },

        '& .Down-Arrow': {
            color: '#307fc1',
        },
    },
})

const Border = styled(Box)(({ styles, open }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderWidth: '2px',
    height: '3px',
    width: '100%',
    backgroundColor: !open && '#307fc1',
    backgroundImage: open && 'linear-gradient(91deg, #01ae8f, #307fc1)',
    marginTop: '10px',
    ...styles,
}))

const useStyles = makeStyles(() => ({
    dropdownAnchor: ({ anchorPosition }) => ({
        position: 'relative',
        top: '45px',
        ...anchorPosition,
    }),
    icon: {
        transform: 'rotate(90deg)',
    },
}))

const DropdownFilter = ({
    label,
    subLabel,
    style,
    anchorPosition,
    showCone,
    loading,
    open,
    setOpen,
    children,
}) => {
    const classes = useStyles({ anchorPosition })

    const anchorRef = useRef(null)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    return (
        <>
            <Container
                style={{ ...style }}
                onClick={() => setOpen((prev) => !prev)}
            >
                <span ref={anchorRef} className={classes.dropdownAnchor} />

                <Typography display="block" style={styles.label}>
                    {label}
                </Typography>

                <Icon
                    name="Arrow"
                    color="#D8D8DD"
                    className={classes.icon}
                    width="20px"
                    height="20px"
                />

                <Typography display="block" style={styles.subLabel}>
                    {subLabel}
                </Typography>

                <Box
                    marginLeft="6px"
                    position="absolute"
                    top="9px"
                    right="-4px"
                    height="24px"
                >
                    <Icon
                        className="Down-Arrow"
                        name="Arrow"
                        color={open ? '#307fc1' : '#D8D8DD'}
                    />
                </Box>

                <Border className="Gradient-border" open={open} />
            </Container>
            <Dropdown
                open={open}
                anchorEl={anchorRef.current}
                handleClose={handleClose}
                handleListKeyDown={handleListKeyDown}
                showCone={showCone}
                menuHeight="50vh"
                width="310px"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                rootStyles={menuStyles}
                listStyles={listStyles}
            >
                {loading && <Loader />}
                {!loading && children}
            </Dropdown>
        </>
    )
}

DropdownFilter.Item = StyledMenuItem
export default DropdownFilter

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete, Card } from 'packages/eid-ui'
import { GetBusinessRequestTypes } from 'hooks'
import { pipe, prop, map, uniqBy } from 'ramda'
import { useIsSmallScreen } from 'packages/core'
import { ArrowIcon } from 'packages/eid-icons'
import { Box } from '@mui/material'
import { usePageContext } from 'pageContext'
const fetchOptionsData = {
    skip: 0,
    take: 100,
}
const styles = {
    '& > div': {
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1) !important',
        borderColor: '#d4d4d8',
        padding: '1.5px !important',
        
        '& > div': {
            right: '0px !important',
            pointerEvents: 'none',
        },
        '& > input': {
            height: '35px',
            cursor: 'pointer',
            width: '100%',
        },
    },
}

const BusinessRequestTypeFilter = () => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const [callEndpoint, setCallEndpoint] = useState(false)
    const [activeType, setActiveType] = useState('')
    const [options, setOptions] = useState([])

    const { data } = GetBusinessRequestTypes(callEndpoint && fetchOptionsData)
    
    useEffect(() => {
        if (data) {
            setOptions(data)
        }
    }, [data])

    const fetchBusinessRequestTypes = pipe(
        uniqBy(prop('id')),
        map((x) => ({
            id: x.id,
            friendlyName: x.friendlyName,
        })),
    )
    const businessRequestTypeActive = activeType === 'businessRequestType'

    const [state, dispatch] = usePageContext()
    const businessRequestType = (
        <Autocomplete
            placeholder={t('BusinessRequestType')}
            options={fetchBusinessRequestTypes(options)}
            loading={callEndpoint && !data && businessRequestTypeActive}
            loadingText=""
            noOptionsText=""
            multiple={false}
            onInputChange={() => {}}
            value={state.businessRequestType}
            onChange={(_, selectedItem) =>
                dispatch({
                    type: 'SET_BUSINESS_REQUEST_TYPE',
                    payload: {
                        businessRequestType: selectedItem,
                    },
                })
            }
            getOptionLabel={(option) => option.friendlyName}
            onOpen={() => {
                setCallEndpoint(true)
                setActiveType('businessRequestType')
            }}
            onClose={() => {
                setCallEndpoint(false)
                setActiveType('')
            }}
            endAdornmentComponent={() =>
                !isSmallScreen && (
                    <ArrowIcon
                        width="12px"
                        height="12px"
                        direction={businessRequestTypeActive ? 'up' : 'down'}
                        color="#969798"
                    ></ArrowIcon>
                )
            }
            rootStyleProps={!isSmallScreen && styles}
            filterOptions={undefined}
        />
    )

    return (
        <Box>
            <Card color="#fff" cardTitle={t('BusinessRequestType')} collapsible expanded={Boolean(state.businessRequestType)}>
                <Box padding="20px 20px 0px 20px">{businessRequestType}</Box>
            </Card>
        </Box>
    )
}

export default BusinessRequestTypeFilter

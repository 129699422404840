import React, { useEffect, useState } from 'react'
import { Icon } from 'packages/eid-icons'
import { Box, Popover } from '@mui/material'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { SaveTimeConstraintForRequest } from 'hooks'
import { NativeDateTimePicker } from 'packages/eid-ui/DateTimePickers'

const styles = {
    timeconstaintmodal: {
        backgroundColor: '#ffffff',
        color: '#363636',
        boxshadow: '0 2px 16px 0 rgba(0, 0, 0, 0.15)',
        padding: '30px 24px',
        width: '572px',
        position: 'relative',
    },
    rootMenubox: {
        paddingRight: '0px',
        width: '552px',
        paddingBottom: '0',

        '&::before': {
            borderRadius: 'inherit',
            content: '""',
            position: 'absolute',
            height: '100px',
            width: '100px',
            backgroundColor: '#000',
            left: 0,
            top: 0,
        },
    },
    list: {
        padding: '0px',
    },
    timeconstaintleft: {
        width: '50%',
    },
    timeconstaintright: {
        width: '44%',
    },
    timeconstaintmain: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&::before': {
            borderRadius: 'inherit',
            content: '""',
            position: 'absolute',
            height: '100px',
            width: '100px',
            backgroundColor: '#000',
            left: 0,
            top: 0,
        },
    },
    timeconstainttop: {
        display: 'flex',
    },
    timeconstaintbottom: {
        display: 'flex',
    },
    timeconstaintmainsecond: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '30px',
    },
    timeconstainbtn: {
        marginTop: '30px',
    },
    errorMessage: {
        color: 'red',
        fontSize: '10px',
    },
}

const TimeConstraintDialog = ({
    anchorEl,
    expanded,
    handleExpand,
    data,
    id,
    requestPolicyData,
    refetch,
}) => {
    const { t } = useTranslation()
    const [modifiedDate, setModifiedDate] = useState(null)
    const [endDate, setEndDate] = useState(
        data.requestDataAssignmentEndDate
            ? moment
                  .utc(data.requestDataAssignmentEndDate)
                  .local()
                  .format('LLL')
            : null,
    )
    const [startDate, setStartDate] = useState(
        data.requestDataAssignmentStartDate
            ? moment
                  .utc(data.requestDataAssignmentStartDate)
                  .local()
                  .format('LLL')
            : moment(new Date()).format('LLL'),
    )

    const handleResetClick = () => {
        setStartDate(
            data.requestDataAssignmentStartDate
                ? moment
                      .utc(data.requestDataAssignmentStartDate)
                      .local()
                      .format('LLL')
                : null,
        )
        setEndDate(
            data.requestDataAssignmentEndDate
                ? moment
                      .utc(data.requestDataAssignmentEndDate)
                      .local()
                      .format('LLL')
                : null,
        )
        setErrorMessage('')
    }

    const maxEndTimeAllowed = moment(startDate).add(
        requestPolicyData.maximumValueInMinutes,
        'minutes',
    )

    const [errorMessage, setErrorMessage] = useState('')
    const [
        saveTimeData,
        { data: saveResponse, error, isLoading },
    ] = SaveTimeConstraintForRequest(modifiedDate, id, refetch)
    useEffect(() => {
        if (saveResponse) {
            handleExpand(false)
        } else if (error) {
            setErrorMessage(t('ApiErrorMessage'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveResponse, error])
    const handleSaveTimeConstraint = () => {
        if (endDate) {
            //Check for valid end date
            if (moment(endDate).isAfter(startDate)) {
                const minuteDifference = moment(endDate).diff(
                    moment(startDate),
                    'minutes',
                )

                if (
                    requestPolicyData.maximumValueInMinutes &&
                    minuteDifference > requestPolicyData.maximumValueInMinutes
                ) {
                    setErrorMessage(
                        `${t('EndDateGreaterError')} ${moment(
                            maxEndTimeAllowed,
                        ).format('LLL')}`,
                    )
                } else {
                    setModifiedDate({
                        itemId: data.id,
                        requestPolicyId: requestPolicyData.id,
                        startDateUtc: moment(startDate)
                            .utc()
                            .format('YYYY-MM-DDTHH:mm:ss'),
                        endDateUtc: endDate
                            ? moment(endDate)
                                  .utc()
                                  .format('YYYY-MM-DDTHH:mm:ss')
                            : null,
                    })
                    saveTimeData()
                }
            } else {
                setErrorMessage(t('EndDateGreaterThanStartError'))
            }
        } else {
            setModifiedDate({
                itemId: data.id,
                requestPolicyId: requestPolicyData.id,
                startDateUtc: moment
                    .utc(startDate)
                    .format('YYYY-MM-DDTHH:mm:ss'),
                endDateUtc: endDate
                    ? moment.utc(endDate).format('YYYY-MM-DDTHH:mm:ss')
                    : null,
            })
            saveTimeData()
        }
    }
    return (
        <Box>
            <Box>
                <Box>
                    <Popover
                        anchorReference={anchorEl}
                        open={expanded}
                        rootStyles={styles.rootMenubox}
                        listStyles={styles.list}
                        anchorEl={anchorEl}
                        onClose={() => {
                            handleExpand(false)
                            setErrorMessage('')
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div style={styles.timeconstaintmodal}>
                            <div style={styles.timeconstaintmain}>
                                <div style={styles.timeconstaintleft}>
                                    <div style={styles.timeconstainttop}>
                                        <span
                                            style={{
                                                color: '#b4b4b4',
                                                fontSize: '10px',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {t('StartDate')}
                                        </span>

                                        <Icon
                                            name={'StartTime'}
                                            style={{
                                                marginLeft: '20px',
                                            }}
                                        />
                                    </div>
                                    <div style={styles.timeconstaintbottom}>
                                        <h3
                                            style={{
                                                fontSize: '16px',
                                                margin: '0',
                                                color: '#5d6870',
                                                fontWeight: '400',
                                            }}
                                        >
                                            {moment(startDate).format('LLL')}
                                        </h3>

                                        <div
                                            style={{
                                                position: 'relative',
                                            }}
                                        >
                                            <NativeDateTimePicker
                                                value={moment(startDate).format(
                                                    'YYYY-MM-DDTHH:mm',
                                                )}
                                                handleChange={(e) => {
                                                    setStartDate(e)
                                                    if (errorMessage)
                                                        setErrorMessage('')
                                                }}
                                                minDate={moment(
                                                    new Date(),
                                                ).format('YYYY-MM-DDTHH:mm')}
                                                disabled={
                                                    !requestPolicyData.isStartDateSelectable
                                                }
                                            >
                                                <Icon
                                                    name={'EditFile'}
                                                    style={{
                                                        marginLeft: '20px',
                                                    }}
                                                />
                                            </NativeDateTimePicker>
                                        </div>
                                    </div>
                                </div>
                                <div style={styles.timeconstaintright}>
                                    <div style={styles.timeconstaintrighttop}>
                                        <p
                                            style={{
                                                margin: '0',
                                                color: '#b4b4b4',
                                                fontSize: '10px',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {t('AccessRequestPolicy')}
                                        </p>
                                        <h3
                                            style={{
                                                fontSize: '16px',
                                                margin: '0',
                                                color: '#5d6870',
                                                fontWeight: '400',
                                            }}
                                        >
                                            {requestPolicyData.friendlyName}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div style={styles.timeconstaintmainsecond}>
                                <div style={styles.timeconstaintleft}>
                                    <div style={styles.timeconstainttop}>
                                        <span
                                            style={{
                                                color: '#b4b4b4',
                                                fontSize: '10px',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {t('EndDate')}
                                        </span>
                                        <Icon
                                            name={'EndTime'}
                                            style={{
                                                marginLeft: '20px',
                                            }}
                                        />
                                    </div>
                                    <div style={styles.timeconstaintbottom}>
                                        <h3
                                            style={{
                                                fontSize: '16px',
                                                margin: '0',
                                                color: '#5d6870',
                                                fontWeight: '400',
                                            }}
                                        >
                                            {endDate
                                                ? moment(endDate).format('LLL')
                                                : '---- -- --'}
                                        </h3>
                                        <div
                                            style={{
                                                position: 'relative',
                                            }}
                                        >
                                            <NativeDateTimePicker
                                                value={moment(endDate).format(
                                                    'YYYY-MM-DDTHH:mm',
                                                )}
                                                handleChange={(e) => {
                                                    setEndDate(e)
                                                    if (errorMessage)
                                                        setErrorMessage('')
                                                }}
                                                minDate={moment(
                                                    startDate,
                                                ).format('YYYY-MM-DDTHH:mm')}
                                                maxDate={moment(
                                                    maxEndTimeAllowed,
                                                ).format('YYYY-MM-DDTHH:mm')}
                                                disabled={
                                                    !requestPolicyData.isEndDateSelectable
                                                }
                                            >
                                                <Icon
                                                    name={'EditFile'}
                                                    style={{
                                                        marginLeft: '20px',
                                                    }}
                                                />
                                            </NativeDateTimePicker>
                                        </div>
                                    </div>
                                </div>
                                <div style={styles.timeconstaintright}>
                                    <div style={styles.timeconstaintrighttop}>
                                        <p
                                            style={{
                                                margin: '0',
                                                color: '#b4b4b4',
                                                fontSize: '10px',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {t(
                                                'RestrictsLengthOfAccess',
                                            )}
                                        </p>
                                        <h3
                                            style={{
                                                fontSize: '16px',
                                                margin: '0',
                                                color: '#5d6870',
                                                fontWeight: '400',
                                            }}
                                        >
                                            {requestPolicyData.isTimeConstrained
                                                ? t('Yes')
                                                : t('No')}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            {errorMessage && (
                                <div>
                                    <p style={styles.errorMessage}>
                                        {errorMessage}
                                    </p>
                                </div>
                            )}
                            <div style={styles.timeconstainbtn}>
                                <Button
                                    style={{
                                        width: '100px',
                                        minHeight: '42px',
                                        backgroundColor: '#307fc1',
                                        borderRadius: '5px',
                                        color: '#fff',
                                        marginLeft: '5px',
                                        '&:hover': {
                                            backgroundColor:
                                                '#6298c5 !important',
                                        },
                                    }}
                                    onClick={() => handleSaveTimeConstraint()}
                                    loading={isLoading}
                                >
                                    <Icon
                                        name="Save"
                                        style={{
                                            marginRight: '10px',
                                        }}
                                    />
                                    {t('Save')}
                                </Button>
                                <Button
                                    style={{
                                        width: '210px',
                                        minHeight: '42px',
                                        border: '1px solid #307fc1',
                                        borderRadius: '5px',
                                        color: '#307fc1',
                                        marginLeft: '25px',
                                    }}
                                    onClick={() => handleResetClick()}
                                >
                                    <Icon
                                        name="RotateBack"
                                        style={{
                                            marginRight: '10px',
                                        }}
                                    />
                                    {t('UndoDateChanges')}
                                </Button>
                            </div>
                        </div>
                    </Popover>
                </Box>
            </Box>
        </Box>
    )
}

export default TimeConstraintDialog

import { forwardRef } from 'react'
import { Box, Typography, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import PersonDetails from 'components/ProcessSteps/PersonDetails'
import { Icon } from 'packages/eid-icons'
import StatusIcon from 'components/ProcessSteps/Icons/Icon'
import moment from 'moment'
import { getFullImageUrl } from 'utils'
import { Divider, EidWorkflow } from 'packages/eid-ui'
import { ArrowIcon } from 'components/ProcessSteps/Icons'
import config from 'config'
import { useTranslation } from 'react-i18next'

const Date = styled(Typography)({
    paddingLeft: '16px',
    fontSize: '18px',
    display: 'flex',
    color: '#1b1f1f',
})

const Time = styled(Typography)({
    fontSize: '18px',
    display: 'flex',
    color: '#5d6870',
})

const useContainerStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            '& *': {
                zIndex: 2,
            },
        },
    }),
)

const styles = {
    preApprovedStatus: {
        color: '#01ae8f',
        fontSize: '14px',
    },
    timeDivider: {
        backgroundColor: '#5d6870',
        width: '20px',
        height: '1px',
        margin: '12px 0px',
    },
    decisionText: {
        marginLeft: '30px',
        fontSize: '14px',
    },
    verticalLine: {
        width: '1px',
        backgroundColor: '#d8d8dd',
        position: 'absolute' as const,
        top: '-10px',
        height: '130%',
    },
}

const Decision = forwardRef<
    HTMLDivElement,
    {
        personName: string
        personTitle: string
        personImageUrl: string
        decisionName: string
        status: string
        statusDescription: string
        date: string
        showVerticalLine?: boolean
        approvalFormUrl?: string
    }
>((props, ref) => {
    const {
        personName,
        personTitle,
        personImageUrl,
        decisionName,
        status,
        statusDescription,
        date,
        showVerticalLine = true,
        approvalFormUrl,
    } = props
    const classes = useContainerStyles()
    const { t } = useTranslation()

    const ApprovalFormButton = ({ maxWidth }: any) => {
        return (
            <>
                {approvalFormUrl && (
                    <Box display="flex" alignItems="center" marginTop="3px">
                        <Icon name="Regulations" color="#5d6870" />
                        <Typography>
                            <EidWorkflow
                                maxWidth={maxWidth}
                                baseEidUrl={config.BASE_EID_URL}
                                title={t('ApprovalForm')}
                                workflowUrl={`${config.BASE_EID_URL}/ui?hideHeader=true${approvalFormUrl}`}
                            >
                                <div
                                    style={{
                                        textDecoration: 'none',
                                        fontSize: '13px',
                                        marginLeft: '10px',
                                        color: '#307fc1',
                                    }}
                                >
                                    {t('ApprovalForm')}
                                </div>
                            </EidWorkflow>
                        </Typography>
                    </Box>
                )}
            </>
        )
    }

    return (
        <div className={classes.root} ref={ref}>
            <Box marginLeft="16px" marginRight="32px" width="100%">
                <Box display="flex" alignItems="center">
                    <Box>
                        {date && (
                            <Box display="flex">
                                <Icon
                                    height="24px"
                                    width="24px"
                                    name="Clock"
                                    color="#5d6870"
                                />

                                <Date noWrap>
                                    {moment.utc(date).local().format('ll')}
                                </Date>
                                <Divider vertical style={styles.timeDivider} />
                                <Time noWrap>
                                    {moment.utc(date).local().format('LTS')}
                                </Time>
                            </Box>
                        )}
                    </Box>

                    <ArrowIcon
                        variant={status}
                        style={{ margin: '0px 20px' }}
                        maxWidth="none"
                    />

                    <Box maxWidth="100%" minWidth="35%">
                        <PersonDetails
                            name={personName}
                            imageUrl={getFullImageUrl(personImageUrl)}
                            statusStyles={{
                                color: '#9230c5',
                                textAlign: 'justify',
                            }}
                            personTitle={personTitle}
                            moreInfo={<ApprovalFormButton maxWidth="lg" />}
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    position="relative"
                    minHeight="60px"
                    paddingBottom="20px"
                >
                    <Box display="flex" justifyContent="center" width="24px">
                        {showVerticalLine && (
                            <div style={styles.verticalLine} />
                        )}
                    </Box>
                    <Box marginLeft="16px">
                        <StatusIcon icon={status} label={statusDescription} />
                    </Box>
                    <Box style={styles.decisionText}>{decisionName}</Box>
                </Box>
            </Box>
        </div>
    )
})

export { Decision }

import { IItemRisk } from 'types'
import { FC, Fragment, useState } from 'react'
import { Box, Collapse } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiskViolation } from '../../RiskViolation'
import { ApproveRisk } from '../ApproveRisk'
import RevokeButton from '../../Button/RevokeButton'
import { mobileScreenWidth } from 'utils'
import { useSubmitRiskItemDecision } from 'hooks'
import { useTranslation } from 'react-i18next'
import { ArrowIcon } from 'packages/eid-icons'
import { FunctionsGranted } from './FunctionsGranted'

export interface IRiskProps {
    page?: 'Requests' | 'RequestItems'
    risk: IItemRisk
    showFunctionsGranted?: boolean
    requestId: string
    itemId: string
    allowDecision?: boolean
}

const useStyles = makeStyles(
    createStyles({
        functionsAndDetails: {
            position: 'relative',
            backgroundColor: '#efeff1',
            margin: '0px -16px',
            borderTop: '1px solid #efeff1',
            marginTop: '32px',
        },
        showMoreButton: {
            position: 'absolute',
            bottom: '-14px',
            left: 'calc(50% - 58px)',
            zIndex: 2,
            '& > button': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                border: 'none',
                height: '32px',
                minWidth: '115px',
                color: '#307fc1',
                fontSize: '11px',
                textTransform: 'uppercase',
                borderRadius: '15px',
                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                cursor: 'pointer',
                paddingLeft: '12px',
                paddingRight: '6px',
                '& svg': {
                    color: '#307fc1',
                    height: '20px',
                },
            },
        },
        actionArea: {
            backgroundColor: '#ffffff',
            width: '100%',
            minHeight: '40px',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            paddingTop: '32px',
            paddingBottom: '8px',
            '& > button': {
                margin: '16px 16px 0 16px',
            },
            [`@media (max-width:${mobileScreenWidth})`]: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                '& > button': {
                    width: '100%',
                    maxWidth: '420px',
                },
            },
        },
    }),
)

export const Risk: FC<IRiskProps> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const { risk } = props

    const [
        submitRiskDecision,
        { isLoading: isSubmitting },
    ] = useSubmitRiskItemDecision(
        props.page as 'Requests' | 'RequestItems',
        props.requestId,
        props.itemId,
    )

    const [checked, setChecked] = useState(false)

    const handleChange = () => {
        setChecked((prev) => !prev)
    }

    const actionable =
        props.allowDecision && risk.decisions && risk.currentApprovalStepId

    return (
        <Fragment key={risk.localRiskId}>
            <Box>
                <RiskViolation
                    requestId={props.requestId}
                    risk={risk}
                    showReason={true}
                />
            </Box>
            {props.showFunctionsGranted && (
                <Box className={classes.functionsAndDetails}>
                    <Box className={classes.showMoreButton}>
                        <button onClick={handleChange}>
                            {checked
                                ? t('Hide')
                                : t('ShowMore')}
                            <ArrowIcon direction={checked ? 'up' : 'down'} />
                        </button>
                    </Box>

                    <Collapse in={checked} collapsedSize={0}>
                        <FunctionsGranted risk={risk} fetch={checked} />
                    </Collapse>
                </Box>
            )}
            {actionable && (
                <Box className={classes.actionArea}>
                    {risk.decisions?.map((d) => (
                        <Fragment key={d.decisionId}>
                            {d.decisionAction === 'Continue' ? (
                                <ApproveRisk
                                    globalRiskId={risk.globalRiskId}
                                    onSubmit={(value) => {
                                        const approveReq = {
                                            ...value,
                                            riskId: risk.id,
                                            stepId: risk.currentApprovalStepId,
                                            decisionId: d.decisionId,
                                        }
                                        return submitRiskDecision(approveReq)
                                    }}
                                    decisionFriendlyName={
                                        d.decisionFriendlyName
                                    }
                                    isLoading={isSubmitting}
                                />
                            ) : (
                                <RevokeButton
                                    size={'small'}
                                    onClick={() => {
                                        const revokeReq = {
                                            riskId: risk.id,
                                            stepId: risk.currentApprovalStepId,
                                            decisionId: d.decisionId,
                                        }
                                        submitRiskDecision(revokeReq)
                                    }}
                                    loading={isSubmitting}
                                >
                                    {d.decisionFriendlyName}
                                </RevokeButton>
                            )}
                        </Fragment>
                    ))}
                </Box>
            )}
        </Fragment>
    );
}

import React, { FC } from 'react'
import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(
    createStyles({
        riskgap: {
            // marginTop: '16px',
        },
    }),
)
export type RiskOverViewProps = {
    risk: any
}

const RiskOverView: FC<RiskOverViewProps> = ({ risk }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    return (
        <>
            <Box className={classes.riskgap} style={{ marginBottom: '13px' }}>
                <h2>{t('RiskName')}</h2>
                <p>{risk.localRiskName}</p>
            </Box>
            <Box className={classes.riskgap}>
                <h2>{t('RiskDescriptions')}</h2>
                <p>{risk.localRiskDescription}</p>
            </Box>
        </>

    )
}

export default RiskOverView
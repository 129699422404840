import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAuthState } from '../context'
import { resetAuthState } from '../actions'
import useUserManager from '../useUserManager'

function Login({ location, render }) {
    const [, dispatch] = useAuthState()
    const userManager = useUserManager()

    useEffect(() => {
        userManager.clearStaleState()
        dispatch(resetAuthState())
        userManager.signinRedirect({ state: location.state })
    }, [dispatch, location])

    return render()
}

Login.propTypes = {
    location: PropTypes.object.isRequired,
    render: PropTypes.func.isRequired,
}

export default Login

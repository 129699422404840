import { Box, styled } from '@mui/material'
import React, { FC } from 'react'
import { ISelectWithLabel, Select } from '..'

const SelectWithLabelBox = styled(Box)({
    background: '#fff',
    padding: '7px 12px',
    borderRadius: '4px',
    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #ebebed',
    display: 'flex',
    alignItems: 'center',
    gap: '20px',

    '& label': {
        fontSize: '10px',
        width: '76px',
        color: ' #b4b4b4',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',


    },
    '& div': {
        border: 'none',
        margin: '0 !important',
        padding: '0',
        boxShadow: 'none !important'
    },
    '& div:nth-child(1)': {
        top: '0',
        left: '0',
        minWidth: '68px',
    },
})

const SelectWithLabel: FC<ISelectWithLabel> = ({
    options = [],
    value,
    handleChange,
    getOptionLabel,
    placeHolder,
    label
}) => {
    return (
        <SelectWithLabelBox>
            <label>{label}</label>
            <Select
                options={options}
                onChange={(data: any) => {
                    handleChange(data)
                }}
                value={value}
                getOptionLabel={getOptionLabel}
                placeholder={placeHolder}
            />
        </SelectWithLabelBox>
    )
}

export default SelectWithLabel

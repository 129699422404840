import { FC } from 'react'
import { Box, Typography, Dialog, SxProps, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Icon } from 'packages/eid-icons'
import { IconButton } from 'packages/eid-ui'

const mobileScreenWidth = '767px'

const useStyles = makeStyles((theme) => ({
    body: () => ({
        backgroundColor: theme?.palette?.background?.default,
        padding: '24px 40px',
        '&:focus': {
            outline: 'none',
        },

        [`@media (max-width:${mobileScreenWidth})`]: {},
    }),
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        maxHeight: '12%',
        padding: '28px 12px 16px 28px',
        backgroundColor: theme?.palette?.background?.paper,
    },
    closeIcon: {
        display: 'flex',
        height: '13px',
        marginTop: '-13px',
    },

    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#3b454d',
    },
    footer: {
        backgroundColor: theme?.palette?.background?.paper,
    },
}))

const useDialogStyles = makeStyles(() => ({
    paper: {
        borderRadius: 10,
        maxWidth: '800px',
        [`@media (max-width:${mobileScreenWidth})`]: {
            margin: '8px ',
            width: 'calc(100% - 16px) !important',
            maxWidth: 'calc(100% - 16px) !important ',
        },
    },
}))

export interface ModalProps {
    open?: any
    onClose?: any
    title?: any
    footerContent?: any
    headerContent?: any
    disableClose?: boolean
    customDialogClass?: SxProps<Theme>
}

const Modal: FC<ModalProps> = (props) => {
    const classes = useStyles()

    const {
        open,
        onClose,
        title,
        children,
        headerContent,
        footerContent,
        disableClose,
        customDialogClass,
    } = props

    const dialogClasses = useDialogStyles()

    return (
        <Dialog
            scroll={'body'}
            open={open}
            onClose={disableClose ? null : onClose}
            aria-labelledby={title}
            aria-describedby={title}
            sx={customDialogClass}
            classes={dialogClasses}
        >
            <Box className={classes.modalHeader} id="dialog-header">
                {!headerContent ? (
                    <Typography className={classes.title}>{title}</Typography>
                ) : (
                    headerContent
                )}
                {!disableClose && (
                    <Box className={classes.closeIcon}>
                        <IconButton onClick={onClose} size="large">
                            <Icon
                                style={{ height: 13, width: 13 }}
                                name="Close"
                                color="#727272"
                            />
                        </IconButton>
                    </Box>
                )}
            </Box>

            {children && (
                <Box id="dialog-body" className={classes.body}>
                    {children}
                </Box>
            )}

            {footerContent && (
                <Box id="dialog-footer" className={classes.footer}>
                    {footerContent}
                </Box>
            )}
        </Dialog>
    )
}

export default Modal

import useSubcomponents from 'useSubcomponents'
import config from 'config'

export const useRouteOptions = () => {
    const {
        hasAccessToRequestViewMyRequestsPage,
        hasAccessToItemViewMyRequestsPage,
        hasAccessToWorkflowsMyRequestsPage,
        hasAccessToRequestViewMyTasksPage,
        hasAccessToItemViewMyTasksPage,
        hasAccessToWorkflowsMyTasksPage,
        hasAccessToRequestViewAllRequestsPage,
        hasAccessToItemViewAllRequestsPage,
        hasAccessToWorkflowsAllRequestsPage,
    } = useSubcomponents()

    const routeOptions = []
    const myRequestPageOptions = {
        name: 'MyRequests',
        title: 'MyRequests',
        icon: 'MTMyRequests',
        route: `${config.APP_SUBPATH}/myRequests`,

        children: [
            {
                title: 'BusinessRequests',
                icon: 'BusinessRolesSmall',
                route: `${config.APP_SUBPATH}/myRequests/businessRequests`,
                view: 'businessRequests',
                hasAccess: hasAccessToRequestViewMyRequestsPage,
            },
            {
                title: 'RequestedItems',
                icon: 'ManagementRolesSmall',
                route: `${config.APP_SUBPATH}/myRequests/businessRequestItems`,
                view: 'businessRequestItems',
                hasAccess: hasAccessToItemViewMyRequestsPage,
            },
            {
                title: 'CollaborationTasks',
                icon: 'ManagementRolesSmall',
                route: `${config.APP_SUBPATH}/myRequests/collaborationTasks`,
                view: 'collaborationTasks',
                hasAccess: hasAccessToWorkflowsMyRequestsPage,
            },
        ],
    }

    const myTasksPageOptions = {
        name: 'MyTasks',
        title: 'ToDo',
        icon: 'MTTodo',
        route: `${config.APP_SUBPATH}/myTasks`,

        children: [
            {
                title: 'BusinessRequests',
                icon: 'BusinessRolesSmall',
                route: `${config.APP_SUBPATH}/myTasks/businessRequests`,
                view: 'businessRequests',
                hasAccess: hasAccessToRequestViewMyTasksPage,
            },
            {
                title: 'RequestedItems',
                icon: 'ManagementRolesSmall',
                route: `${config.APP_SUBPATH}/myTasks/businessRequestItems`,
                view: 'businessRequestItems',
                hasAccess: hasAccessToItemViewMyTasksPage,
            },
            {
                title: 'CollaborationTasks',
                icon: 'ManagementRolesSmall',
                route: `${config.APP_SUBPATH}/myTasks/collaborationTasks`,
                view: 'collaborationTasks',
                hasAccess: hasAccessToWorkflowsMyTasksPage,
            },
        ],
    }

    const allRequestPageOptions = {
        name: 'AllRequests',
        title: 'All',
        icon: 'MTAll',
        route: `${config.APP_SUBPATH}/all`,

        children: [
            {
                title: 'BusinessRequests',
                icon: 'BusinessRolesSmall',
                route: `${config.APP_SUBPATH}/all/businessRequests`,
                view: 'businessRequests',
                hasAccess: hasAccessToRequestViewAllRequestsPage,
            },
            {
                title: 'RequestedItems',
                icon: 'ManagementRolesSmall',
                route: `${config.APP_SUBPATH}/all/businessRequestItems`,
                view: 'businessRequestItems',
                hasAccess: hasAccessToItemViewAllRequestsPage,
            },
            {
                title: 'CollaborationTasks',
                icon: 'ManagementRolesSmall',
                route: `${config.APP_SUBPATH}/all/collaborationTasks`,
                view: 'collaborationTasks',
                hasAccess: hasAccessToWorkflowsAllRequestsPage,
            },
        ],
    }

    const showMyRequestPage =
        hasAccessToRequestViewMyRequestsPage ||
        hasAccessToItemViewMyRequestsPage ||
        hasAccessToWorkflowsMyRequestsPage
    const myTasksPage =
        hasAccessToRequestViewMyTasksPage ||
        hasAccessToItemViewMyTasksPage ||
        hasAccessToWorkflowsMyTasksPage
    const showAllRequestPage =
        hasAccessToRequestViewAllRequestsPage ||
        hasAccessToItemViewAllRequestsPage ||
        hasAccessToWorkflowsAllRequestsPage

    if (myTasksPage) {
        routeOptions.push(myTasksPageOptions)
    }

    if (showMyRequestPage) {
        routeOptions.push(myRequestPageOptions)
    }

    if (showAllRequestPage) {
        routeOptions.push(allRequestPageOptions)
    }

    const hasAccessToRequestView = (name) => {
        if (name.toLowerCase() === 'myrequests')
            return hasAccessToRequestViewMyRequestsPage
        else if (name.toLowerCase() === 'mytasks')
            return hasAccessToRequestViewMyTasksPage
        else return hasAccessToRequestViewAllRequestsPage
    }
    const hasAccessToItemView = (name) => {
        if (name.toLowerCase() === 'myrequests')
            return hasAccessToItemViewMyRequestsPage
        else if (name.toLowerCase() === 'mytasks')
            return hasAccessToItemViewMyTasksPage
        else return hasAccessToItemViewAllRequestsPage
    }
    const hasAccessWorkflowsView = (name) => {
        if (name.toLowerCase() === 'myrequests')
            return hasAccessToWorkflowsMyRequestsPage
        else if (name.toLowerCase() === 'mytasks')
            return hasAccessToWorkflowsMyTasksPage
        else return hasAccessToWorkflowsAllRequestsPage
    }

    return {
        routeOptions,
        hasAccessToRequestView,
        hasAccessToItemView,
        hasAccessWorkflowsView,
    }
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { useClaim, useUnclaim, useCurrentPerson } from 'hooks'
import { Button, Tag } from 'components'

const ClaimControl = ({
    disabled,
    page,
    isApprover,
    requestId,
    requestItemId,
    currentApprovalStepId,
    claimedApproverId,
    claimedApproverFriendlyName,
}) => {
    const { t } = useTranslation()

    const [claim, { isLoading: isClaiming }] = useClaim(
        page,
        requestId,
        requestItemId,
    )

    const [unclaim, { isLoading: isUnclaiming }] = useUnclaim(
        page,
        requestId,
        requestItemId,
    )

    const { data: currentPerson } = useCurrentPerson()

    const isClaimedByMe = currentPerson.id === claimedApproverId
    const isClaimedByNobody = isNilOrEmpty(claimedApproverId)

    if (isClaimedByMe) {
        return (
            <Button.Unclaim
                disabled={disabled}
                onClick={() => unclaim(currentApprovalStepId)}
                loading={isUnclaiming}
            >
                {t('Unclaim')}
            </Button.Unclaim>
        )
    } else if (isClaimedByNobody) {
        if (isApprover && !disabled) {
            return (
                <Button.Claim
                    onClick={() => claim(currentApprovalStepId)}
                    loading={isClaiming}
                >
                    {t('Claim')}
                </Button.Claim>
            )
        } else return <></>
    } else {
        return (
            <Tag
                label={t('ClaimedBy')}
                value={claimedApproverFriendlyName}
                icon={
                    <Icon
                        name="Approve"
                        color="#01ae8f"
                        width="16px"
                        height="16px"
                    />
                }
            />
        )
    }
}

export default ClaimControl

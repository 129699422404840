import { useRef } from 'react'
import { Registry } from './Registry'
import { SubjectTypesNamespace } from './RegistryNamespaces'
import { GenericSubjectType, PersonSubjectType } from './subjectTypes'

/**
 * React hook for using a registry. Only initializes
 * Registry once and on subsequent usages, it returns
 * the same initialized instance.
 */
export const useRegistry: () => Registry = () => {
    const registryRef = useRef<Registry>()

    if (registryRef.current === undefined) {
        const registry = new Registry()

        registry.registerNamespace(SubjectTypesNamespace)

        const genericSubjectType = new GenericSubjectType()
        const personSubjectType = new PersonSubjectType()

        registry.register(SubjectTypesNamespace, genericSubjectType)
        registry.register(SubjectTypesNamespace, personSubjectType)

        registryRef.current = registry
    }

    return registryRef.current
}

import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '../IconButton'
import { Tooltip } from '../../eid-ui'
import { CloseIcon } from '../../eid-icons'

const WithCloseButton = React.forwardRef(
    (
        {
            onClose,
            iconColor,
            children,
            style,
            iconPosition,
            renderCloseButton = true,
            closeTooltip,
            ...rest
        },
        ref,
    ) => {
        let wrappedCloseButton

        const closeButton = (
            <IconButton onClick={onClose} disabled={!Boolean(onClose)} size="large">
                <CloseIcon color={iconColor} />
            </IconButton>
        )
        if (closeTooltip) {
            wrappedCloseButton = (
                <Tooltip title={closeTooltip}>
                    <div>{closeButton}</div>
                </Tooltip>
            )
        } else {
            wrappedCloseButton = closeButton
        }
        return (
            <div ref={ref} style={{ position: 'relative', ...style }} {...rest}>
                {children}
                <div
                    style={{
                        position: 'absolute',
                        right: iconPosition.right,
                        top: iconPosition.top,
                        transform: 'translateY(-50%)',
                        zIndex: 3,
                    }}
                >
                    {renderCloseButton && wrappedCloseButton}
                </div>
            </div>
        )
    },
)

WithCloseButton.propTypes = {
    onClose: PropTypes.func,
    iconColor: PropTypes.string,
    style: PropTypes.object,
    iconPosition: PropTypes.object,
}

WithCloseButton.defaultProps = {
    iconColor: '#fff',
    iconPosition: {
        right: 0,
        top: '50%',
    },
}

export default WithCloseButton

import { FC, Fragment, useEffect, useState } from 'react'
import RiskViolations from './RiskViolation'
import { Box } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    createStyles({
        root: {
            width: '100%',
        },
        RiskViolations: {},
        riskDivider: {
            height: '40px',
        },
    }),
)

export type RiskViolationToDoListProps = {
    props: any
    showDecisions: boolean
    pendingRiskItems?: any
    itemId?: any
    page?: 'Requests' | 'RequestItems'
    requestType: string
}

const RiskViolationToDoList: FC<RiskViolationToDoListProps> = ({
    props,
    itemId,
    page,
    showDecisions,
    pendingRiskItems,
    requestType,
}) => {
    const classes = useStyles()
    const { data, requestId } = props
    const [riskFunctionData, setRiskFunctionData] = useState<any>([])

    useEffect(() => {
        if (data?.length && showDecisions) {
            const matchedRiskData = pendingRiskItems.map((item: any) => {
                const isRiskMatched = data.find((i: any) => {
                    if (i.riskViolationIds.includes(item.id)) {
                        return i
                    }
                })
                return {
                    ...isRiskMatched,
                    pendingRiskItems: item,
                }
            })
            setRiskFunctionData(matchedRiskData)
        } else if (data?.length && !showDecisions) {
            setRiskFunctionData(data)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, pendingRiskItems])

    return (
        <Box className={classes.root}>
            <Box className={classes.RiskViolations}>
                {riskFunctionData.map((risk: any, index: number) => (
                    <Fragment key={`risk-violations-${index}`}>
                        <RiskViolations
                            page={page}
                            requestId={requestId}
                            itemId={itemId}
                            risk={risk}
                            showDecisions={showDecisions}
                            requestType={requestType}
                            pendingRiskItems={risk?.pendingRiskItems || {}}
                        />
                        {index < data.length - 1 && (
                            <Box className={classes.riskDivider} />
                        )}
                    </Fragment>
                ))}
            </Box>
        </Box>
    )
}

export default RiskViolationToDoList

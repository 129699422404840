import { useRef } from 'react'
import { UserManager } from 'oidc-client'
import createUserManager from './createUserManager'

const useUserManager = () => {
    const userManagerRef = useRef<UserManager>()

    if (userManagerRef.current === undefined) {
        userManagerRef.current = createUserManager()
    }

    const userManager: UserManager = userManagerRef.current

    return userManager
}

export default useUserManager

import React, { FC, Fragment, useState } from 'react'
import { Box, Collapse, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import RiskOwners from './Components/RiskOwners'
import RiskViolationLeftAndRight from './Components/RiskViolationLeftAndRight'
import RiskOverView from './Components/RiskOverView'
import { ExpandIconPlusMinus } from './Components/ExpandIcon'
import { useTranslation } from 'react-i18next'
import { RiskIndicator } from 'packages/eid-ui'
import classNames from 'classnames'

import MitigationDetails from './MitigationDetails'
import { ApproveRisk } from 'components/PendingRiskItem/ApproveRisk'
import { useSubmitRiskItemDecision } from 'hooks'
import RevokeButton from 'components/Button/RevokeButton'

const statusColors: any = {
    NotMitigated: 'linear-gradient(to right, #eda300 0%, #d66a00 100%)',
    PreMitigated: 'linear-gradient(to right, #01ae8f 0%, #30c17c 100%)',
    Mitigated: 'linear-gradient(to right, #01ae8f 0%, #30c17c 100%)',
    Rejected: 'linear-gradient(to right, #ae0142 0%, #c14830 100%)',
}

const useStyles = makeStyles(
    createStyles({
        riskowner: {
            backgroundColor: '#307fc1',
            borderTopRightRadius: '5px',
            borderTopLeftRadius: '5px',
            padding: '24px 16px 16px',
        },
        riskownerExpanded: {
            background:
                'linear-gradient(108deg, #019cae, #307fc1 100%) !important',
        },
        riskownerheaderRight: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '-40px',
        },
        riskownerleftheader: {
            background: '#fff',
            border: 'solid 1px #aab0b4',
            fontSize: '12px',
            fontWeight: 600,
            textTransform: 'uppercase',
            padding: '4px 8px',
            borderRadius: '5px',
            fontFamily: 'Rubik',
        },

        riskownerheaderLeft: {
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            marginTop: '-40px',
        },
        riskownerrightheader: {
            background: ' ',
            padding: '4px 10px 4px 8px',
            fontSize: '12px',
            fontWeight: 500,
            textTransform: 'uppercase',
            color: '#fff',
            borderRadius: '5px',
        },
        riskownerbody: {
            marginTop: '18px',
            '& h3': {
                fontSize: '18px',
                fontWeight: 600,
                color: '#fff',
                fontFamily: 'Rubik',
            },
        },
        riskownerbtn: {
            display: 'flex',
            marginTop: '12px',
            gap: '26px',
            fontFamily: 'Rubik',
            '& button': {
                background: '#d0021b',
                border: ' solid 1px #d0021b',
                borderRadius: '5px',
                fontSize: '11px',
                padding: '4px 8px 4px 4px',
                height: '24px',
                color: '#fff',
                '& hover': {
                    background: '#d0021b !important',
                },
            },
            '& h4': {
                fontSize: '14px',
                fontWeight: 600,
                color: '#fdcc02',
            },
        },
        risksegregatedsection: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: '30px',
            padding: '16px',
        },
        risksection: {
            marginTop: '16px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        risksectionleft: {
            maxWidth: '50%',
            width: '100%',
            '& p': {
                fontSize: '12px',
                fontWeight: 'normal',
                color: '#fff',
                marginBottom: '8px',
                marginTop: '0px',
            },
            '& h2': {
                fontSize: '12px',
                fontWeight: 600,
                color: '#fff',
                marginBottom: '8px',
                marginTop: '0px',
                textTransform: 'uppercase',
            },
        },
        risksectionright: {
            maxWidth: '50%',
            width: '100%',
            '& p': {
                fontSize: '12px',
                fontWeight: 500,
                color: '#fff',
                marginBottom: '8px',
                marginTop: '0px',
            },
        },
        risksectionavtar: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '15px',
            marginBottom: '10px',
            '& p': {
                fontSize: '12px',
                fontWeight: 500,
                color: '#fff',
                marginBottom: '0',
                marginLeft: '10px',
            },
        },
        riskowners: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        risksectionavtaricon: {
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            background: 'red',
            marginTop: '0 !important',
        },
        riskfunction: {
            marginTop: '16px',
            '& h2': {
                fontSize: '16px',
                fontWeight: 600,
                color: '#3b454d',
                marginBottom: '0',
                textTransform: 'uppercase',
                fontFamily: 'Rubik',
            },
            '& p': {
                fontSize: '16px',
                fontWeight: 400,
                color: '#000',
                marginBottom: '0',
            },
        },

        riskcollapse: {
            '& h4': {
                fontSize: '13px',
                fontWeight: 500,
                color: '#fff',
            },
            '& p': {
                fontSize: '13px',
                fontWeight: 500,
                color: '#fff',
                marginTop: '8px',
            },
        },
        riskownerdetails: {
            boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.08)',
            padding: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        riskbtnsection: {
            display: 'flex',
        },
        riskbtndetails: {
            display: 'flex',
            alignItems: 'center',
            '& p': {
                fontSize: '14px',
                fontWeight: 500,
                color: '#307fc1',
                marginTop: '0',
                marginRight: '8px',
            },
            '& div': {
                border: '1px solid #307fc1 ',
                '& div': {
                    border: 'none',
                },
            },
        },
        riskapprovebtn: {
            '& button': {
                cursor: 'pointer',
                background: '#01ae8f',
                borderRadius: '5px',
                padding: '10px 24px',
                color: '#fff',
                fontSize: '16px',
                marginRight: '20px',
                textTransform: 'capitalize',
                '&:hover': {
                    background: '#01ae8fcf',
                },
            },
        },
        riskrejectbtn: {
            '& button': {
                cursor: 'pointer',
                borderRadius: '5px',
                padding: '10px 24px',
                color: '#d0021b',
                fontSize: '16px',
                border: '1px solid #d0021b',
                background: 'transparent',
                textTransform: 'capitalize',
                '& svg': {
                    color: '#d0021b',
                },
            },
        },

        riskIndicator: {
            opacity: 1,
            fontWeight: 'bold',
        },
        riskBtnDetailHideApprover: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',

            width: '100%',
            '& p': {
                marginTop: '4px',
            },
        },
        expandedRisk: {
            border: 'solid 1px #307fc1 !important',
            borderRadius: '8px',
            boxShadow: '0 4px 16px 0 rgba(049, 126, 193, 0.25) !important',
        },
        root: {
            borderImageSource:
                ' linear-gradient(to right, rgba(208, 2, 27, 0), #d0021b 8%, #d00238 91%, rgba(208, 2, 69, 0))',
            borderImageSlice: '1',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            boxShadow: '0 2px 16px 0 rgba(228, 150, 107, 0.25)',
            borderRadius: '8px',
        },
        riskcollapseinnersite: {
            // '& div:nth-child(2)': {
            //   justifyContent: 'flex-end',
            // }
        },
        Mitigationbutton: {
            '& button': {
                background: '#307fc1',
                border: 'solid 1px #307fc1',
                color: '#fff',
                fontSize: '14px',
                '&:hover': {
                    background: '#307fc1',
                },
            },
        },
    }),
)

export type RiskViolationsProps = {
    page?: 'Requests' | 'RequestItems'
    requestId: string
    itemId: string
    risk: any
    showDecisions?: boolean
    pendingRiskItems?: any
    requestType: string
}

const RiskViolations: FC<RiskViolationsProps> = ({
    page,
    itemId,
    requestId,
    risk,
    showDecisions,
    pendingRiskItems,
    requestType,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [expanded, setExpended] = useState(false)
    const showSegregationOfduties = risk?.riskType === 'SegregationOfDuties'
    const riskFunctionsGrouped = risk.riskFunctionsGrouped

    const [submitRiskDecision, { isLoading: isSubmitting }] =
        useSubmitRiskItemDecision(
            page as 'Requests' | 'RequestItems',
            requestId,
            itemId,
        )

    const getMitigatedStatusColor = (
        status: string,
        defaultColor = 'linear-gradient(to right, #eda300 0%, #d66a00 100%',
    ) => {
        return Object.keys(statusColors).includes(status)
            ? statusColors[status]
            : defaultColor
    }

    const bindRiskViolationLeftAndRightSOD = () => {
        return riskFunctionsGrouped.map((item: any, index: number) => (
            <Box
                key={`sod-risk-functions-${index}`}
                className={classes.risksegregatedsection}
            >
                <Box className={classes.risksectionleft}>
                    <Box className={classes.riskfunction}>
                        {index === 0 && <h2>{t('RiskFunctions')}</h2>}
                        <RiskViolationLeftAndRight
                            itemId={itemId}
                            requestType={requestType}
                            isSOD={true}
                            requestId={requestId}
                            risk={risk}
                            riskFunction={item.riskFunction}
                        />
                    </Box>
                </Box>
                <Box className={classes.risksectionright}>
                    <Box className={classes.riskfunction}>
                        {index === 0 && <h2>{t('RiskSegregatedFunctions')}</h2>}
                        <RiskViolationLeftAndRight
                            itemId={itemId}
                            requestType={requestType}
                            isSOD={true}
                            requestId={requestId}
                            risk={risk}
                            riskFunction={item.segregatedRiskFunction}
                        />
                    </Box>
                </Box>
            </Box>
        ))
    }

    const bindRiskViolationLeftAndRightCriticalAccess = () => {
        return riskFunctionsGrouped.map((item: any, index: number) => (
            <Box
                key={`critical-risk-function-${index}`}
                style={{
                    width: '100%',
                }}
            >
                <Box className={classes.riskcollapseinnersite}>
                    <Box className={classes.riskfunction}>
                        {index === 0 && <h2>{t('RiskFunctions')}</h2>}
                        <RiskViolationLeftAndRight
                            itemId={itemId}
                            requestType={requestType}
                            isSOD={false}
                            requestId={requestId}
                            risk={risk}
                            riskFunction={item.riskFunction}
                        />
                    </Box>
                </Box>
            </Box>
        ))
    }

    return (
        <Box
            className={classNames(classes.root, {
                [classes.expandedRisk]: expanded,
            })}
        >
            <Box
                className={classNames(classes.riskowner, {
                    [classes.riskownerExpanded]: expanded,
                })}
            >
                {showDecisions && (
                    <Box className={classes.riskownerheaderRight}>
                        <Box className={classes.riskownerleftheader}>
                            {t('RiskOwner')}
                        </Box>
                    </Box>
                )}
                {!showDecisions && (
                    <Box className={classes.riskownerheaderLeft}>
                        <Box
                            style={{
                                background: getMitigatedStatusColor(
                                    risk.mitigationStatus,
                                ),
                            }}
                            className={classes.riskownerrightheader}
                        >
                            {t(`${risk.mitigationStatus}`)}
                        </Box>
                    </Box>
                )}
                <Box className={classes.riskownerbody}>
                    <Typography variant="h3" component="h3">
                        {risk.localRiskFriendlyName}
                    </Typography>
                    <Box className={classes.riskownerbtn}>
                        <RiskIndicator
                            variant={risk.riskLevel}
                            label={t(`${risk.riskLevel}Risk`)}
                            className={
                                risk &&
                                risk.riskLevel !== 'Critical' &&
                                classes.riskIndicator
                            }
                        />
                        <Typography variant="h4" component="h4">
                            {t(`${risk.riskType}`)}
                        </Typography>
                    </Box>
                    <Collapse in={expanded}>
                        <Box className={classes.risksection}>
                            <Box className={classes.risksectionleft}>
                                {expanded && <RiskOverView risk={risk} />}
                            </Box>
                            <Box className={classes.risksectionright}>
                                {expanded && (
                                    <RiskOwners
                                        enableApi={expanded}
                                        localRiskId={risk?.localRiskId}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Collapse>
                </Box>
            </Box>

            <Box className={classes.riskownerdetails}>
                {showDecisions ? (
                    <Box className={classes.riskbtnsection}>
                        {pendingRiskItems?.decisions?.map(
                            (d: any, index: number) => (
                                <Fragment key={`risk-approval-btn-${index}`}>
                                    {d.decisionAction === 'Continue' ? (
                                        <Box className={classes.riskapprovebtn}>
                                            <ApproveRisk
                                                globalRiskId={
                                                    pendingRiskItems.globalRiskId
                                                }
                                                onSubmit={(value) => {
                                                    const approveReq = {
                                                        ...value,
                                                        riskId: pendingRiskItems.id,
                                                        stepId: pendingRiskItems.currentApprovalStepId,
                                                        decisionId:
                                                            d.decisionId,
                                                    }
                                                    return submitRiskDecision(
                                                        approveReq,
                                                    )
                                                }}
                                                decisionFriendlyName={
                                                    d.decisionFriendlyName
                                                }
                                                isLoading={isSubmitting}
                                                assignmentStartDate={
                                                    pendingRiskItems.requestDataAssignmentStartDate
                                                }
                                                assignmentEndDate={
                                                    pendingRiskItems.requestDataAssignmentEndDate
                                                }
                                                showTimeConstrainedControl={
                                                    d.isTimeConstraintEditable
                                                }
                                                timeConstraintRequired={
                                                    d.isTimeConstraintRequired
                                                }
                                                requestPolicyId={
                                                    pendingRiskItems.requestPolicyId
                                                }
                                            />
                                        </Box>
                                    ) : (
                                        <Box className={classes.riskrejectbtn}>
                                            <RevokeButton
                                                size={'small'}
                                                onClick={() => {
                                                    const revokeReq = {
                                                        riskId: pendingRiskItems.id,
                                                        stepId: pendingRiskItems.currentApprovalStepId,
                                                        decisionId:
                                                            d.decisionId,
                                                    }
                                                    submitRiskDecision(
                                                        revokeReq,
                                                    )
                                                }}
                                                loading={isSubmitting}
                                            >
                                                {d.decisionFriendlyName}
                                            </RevokeButton>
                                        </Box>
                                    )}
                                </Fragment>
                            ),
                        )}
                    </Box>
                ) : (
                    <Box
                        className={classes.Mitigationbutton}
                        style={{
                            width: '100%',
                        }}
                    >
                        {risk?.riskViolatorMitigationId &&
                            (risk.mitigationStatus === 'Mitigated' ||
                                risk.mitigationStatus === 'Rejected') &&
                            !showDecisions && (
                                <MitigationDetails
                                    riskViolatorMitigationId={
                                        risk?.riskViolatorMitigationId
                                    }
                                />
                            )}
                    </Box>
                )}
                <Box
                    className={classNames(classes.riskbtndetails, {
                        [classes.riskBtnDetailHideApprover]: !showDecisions,
                    })}
                >
                    <Typography>
                        {expanded ? t('LessDetails') : t('MoreDetails')}
                    </Typography>
                    <ExpandIconPlusMinus
                        expanded={expanded}
                        onClick={() => {
                            setExpended(!expanded)
                        }}
                    />
                </Box>
            </Box>
            <Collapse in={expanded}>
                <>
                    {expanded && (
                        <>
                            {showSegregationOfduties ? (
                                bindRiskViolationLeftAndRightSOD()
                            ) : (
                                <Box className={classes.risksegregatedsection}>
                                    {bindRiskViolationLeftAndRightCriticalAccess()}
                                </Box>
                            )}
                        </>
                    )}
                </>
            </Collapse>
        </Box>
    )
}

export default RiskViolations

import React from 'react'
import { Box } from '@mui/material'
import Spinner from '../Spinner'

const Loader = (props) => {
    const { label, color, size, ...rest } = props

    return (
        <Box
            height="2in"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            {...rest}
        >
            <Spinner color={color} size={size} />
            {label && (
                <Box marginTop="5px" color={color}>
                    {label}
                </Box>
            )}
        </Box>
    )
}

export default Loader

import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FC } from 'react'
import { Icon } from 'packages/eid-icons'

interface InfoBoxMessageProps {
    message: string
}
const useStyles = makeStyles({
    root: {
        border: 'solid 1px #ebebed',
        boxShadow: ' 0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        display: 'inline-flex',
        alignItems: 'center',
        height: '48px',
        background: '#fff',
        overflow: 'hidden',
    },
    infoIcon: {
        background: '#8b909a',
        width: '48px',
        height: '48px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            width: '24px !important',
            height: '24px !important',
        },
    },
    infoMsg: {
        padding: '16px',

        color: '#5d6870',
    },
})

const InfoBoxMessage: FC<InfoBoxMessageProps> = ({ message }) => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Box className={classes.infoIcon}>
                <Icon name="InfoNew" />
            </Box>
            <Box className={classes.infoMsg}>{message}</Box>
        </Box>
    )
}

export default InfoBoxMessage

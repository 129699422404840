import React from 'react'
import { Box, styled, InputBase, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { smallScreenWidth } from 'utils'

export const COMMENT_MAX_LENGTH = 500
export const SHOW_REPLIES = 'SHOW_REPLIES'
export const SHOW_REPLIES_AND_INPUT_FIELD = 'SHOW_REPLIES_AND_INPUT_FIELD'
export const SHOW_EDIT_INPUT_FIELD = 'SHOW_EDIT_INPUT_FIELD'

export const StyledInput = withStyles(() => ({
    root: {
        width: '100% !important',
        borderRadius: '5px',
        border: 'solid 1px #d8d8dd',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        '&:hover': {
            border: 'solid 1px #307fc1',
        },
        '&:focus-within': {
            boxShadow: '0 4px 8px 0 rgba(48, 127, 193, 0.33)',
            border: 'solid 1px #307fc1 !important',
        },
    },
    input: {
        position: 'relative',
        fontSize: 15,
        lineHeight: 1.33,
        color: '#8b909a',
        padding: '3px 15px',
    },
    error: {
        border: 'solid 1px #f44336 !important',
        '&:focus-within': {
            border: 'solid 1px #f44336 !important',
        },
    },
}))(InputBase)

export const TextButtonContainer = styled(Box)({
    color: '#307fc1',
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 10,
    '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
})

export const ListItemSkeleton = styled(Skeleton)({
    width: '100%',
    margin: '8px',
    minWidth: '36px',
    height: 6,
})

export const IconContainer = styled(Icon)({
    display: 'flex',
    alignItems: 'center',
    width: '11px',
    height: '11px',
    color: '#ffffff',
    marginLeft: '11px',

    '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: '#e1e1e1',
    },
})

export const Conatiner = styled(Box)({
    display: 'flex',
    alignItems: 'flex-end',
    margin: '10px 0px',
})

export const RepliesConatiner = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #ebebed',
    padding: '8px 15px',
    margin: '0px -15px',
    marginBottom: '-5px',
    position: 'relative',
    backgroundColor: '#ffffff',
})

export const InputContainer = styled(Box)({
    borderRadius: '10px',
    minWidth: '250px',
    padding: '5px 15px',
    width: 'calc(100% - 55px)',
    boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.11)',
    position: 'relative',
})

export const Label = styled(Typography)({
    fontSize: '11px',
    lineHeight: 1.45,
})

export const Value = styled(Typography)({
    fontSize: '14px',
    lineHeight: 1.45,
})

export const InfoContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',

    [`@media (max-width:${smallScreenWidth})`]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
})

export const InfoLabelsContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',

    [`@media (max-width:${smallScreenWidth})`]: {
        width: '100%',
        flexDirection: 'row-reverse',

        justifyContent: 'space-between',
        '& >p': {
            paddingLeft: '0px',
        },
    },
})

export const DisplayNamesContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',

    [`@media (max-width:${smallScreenWidth})`]: {
        width: '100%',
        justifyContent: 'space-between',
    },
})

export const decisionUiData = {
    Approved: {
        icon: 'CheckedRound',
        color: '#029435',
    },
    Rejected: {
        icon: 'CloseRound',
        color: '#ED5858',
    },
}

export const getDecisionUiData = (participantDecisionFriendlyName) => {
    if (
        participantDecisionFriendlyName &&
        Object.keys(decisionUiData).includes(participantDecisionFriendlyName)
    ) {
        return decisionUiData[participantDecisionFriendlyName]
    }
    return null
}

export const useMyCommentStyles = makeStyles(() => ({
    container: {
        width: '90%',
        flexDirection: 'row ',
        justifyContent: 'flex-start ',
    },
    input: {
        backgroundColor: '#5d6870',
        borderBottomLeftRadius: '10px !important',
        borderBottomRightRadius: '0px !important',

        //marginLeft: shouldAllowReplies && !myComment ? '55px !important' : '',

        //marginRight: shouldAllowReplies && myComment ? '55px !important' : '',
        //marginLeft: '',
        marginRight: '55px !important',
    },
    label: {
        color: '#efeff1',
    },
    typeLabel: {
        color: '#c6b8ff',
        marginLeft: '10px',
    },

    decisionLabel: ({ participantDecisionFriendlyName }) => ({
        color:
            getDecisionUiData(participantDecisionFriendlyName)?.color ??
            'initial',
        fontWeight: 'bold',
    }),

    stepLabel: {
        fontSize: '12px',
        color: '#000000',
        margin: '0px 4px',
    },
    stepValue: {
        //color: !myComment || shouldAllowReplies ? '#5d6870 ' : '#ffffff',
        color: '#ffffff',

        fontSize: '12px',
    },

    date: {
        color: '#efeff1',
        paddingLeft: '20px',
    },
    value: {
        color: '#ffffff',
    },
    valueContainer: {
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        overflow: 'auto',
    },
    actionRowContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        paddingBottom: '5px',
    },
    actionRowLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    avatarContainer: {
        position: 'absolute',
        top: '0px',
        right: '-55px',
    },
}))

export const useOtherCommentStyles = makeStyles(() => ({
    container: {
        width: '90%',
        flexDirection: ' row-reverse ',
        justifyContent: 'flex-end ',
    },
    input: {
        backgroundColor: '#ffffff ',
        borderBottomLeftRadius: '0px !important',
        borderBottomRightRadius: '10px !important',
        //marginLeft: shouldAllowReplies && '55px !important',

        // marginRight: shouldAllowReplies && myComment ? '55px !important' : '',
        marginLeft: '55px !important',
    },
    label: {
        color: '#8b909a ',
    },
    typeLabel: {
        color: '#5634da',
        marginLeft: '10px',
    },

    decisionLabel: ({ participantDecisionFriendlyName }) => ({
        color:
            getDecisionUiData(participantDecisionFriendlyName)?.color ??
            'initial',
        fontWeight: 'bold',
    }),

    stepLabel: {
        fontSize: '12px',
        color: '#000000',
        margin: '0px 4px',
    },
    stepValue: {
        //color: !myComment || shouldAllowReplies ? '#5d6870 ' : '#ffffff',
        color: '#5d6870 ',

        fontSize: '12px',
    },

    date: {
        color: '#8b909a ',
        paddingLeft: '20px',
    },
    value: {
        color: '#5d6870 ',
    },
    valueContainer: {
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        overflow: 'auto',
    },
    actionRowContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        paddingBottom: '5px',
    },
    actionRowLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    avatarContainer: {
        position: 'absolute',
        top: '0px',
        left: '-55px',
    },
}))

export const useCommentStyles = (mine, participantDecisionFriendlyName) => {
    const myCommentStyles = useMyCommentStyles({
        participantDecisionFriendlyName,
    })
    const otherCommentStyles = useOtherCommentStyles({
        participantDecisionFriendlyName,
    })
    return mine ? myCommentStyles : otherCommentStyles
}

export const ShowHideRepliesButton = ({ collapse, setCollapse, count }) => {
    const { t } = useTranslation()

    return (
        <TextButtonContainer
            onClick={() => {
                if (
                    collapse === SHOW_REPLIES ||
                    collapse === SHOW_REPLIES_AND_INPUT_FIELD
                ) {
                    setCollapse(false)
                } else {
                    setCollapse(SHOW_REPLIES)
                }
            }}
        >
            {collapse === SHOW_REPLIES ||
            collapse === SHOW_REPLIES_AND_INPUT_FIELD
                ? t('HideReplies')
                : t('ShowReplies', {
                      count: count,
                  })}
            <Icon
                name="ArrowSmall"
                color="#aab0b4"
                fill="#aab0b4"
                style={{
                    transform: collapse && 'rotate(180deg)',
                    marginLeft: '10px',
                }}
            />
        </TextButtonContainer>
    )
}

export const ShowReplyFieldButton = ({ setCollapse }) => {
    const { t } = useTranslation()
    return (
        <TextButtonContainer
            onClick={() => {
                setCollapse(SHOW_REPLIES_AND_INPUT_FIELD)
            }}
        >
            <Icon name="ReplyArrow" style={{ marginRight: '8px' }} />
            {t('Reply')}
        </TextButtonContainer>
    )
}

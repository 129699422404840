import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';

const NumberedLabel = withStyles(() => ({
    root: {
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: '2px 7px',
        marginRight: 8,
        marginLeft: -7,
    },
}))((props) => (
    <>
        <span className={props.classes.root}>{props.number}</span>
        {props.label}
    </>
))

NumberedLabel.propTypes = {
    label: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
}

export default NumberedLabel

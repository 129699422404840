import React from 'react'
import { CheckIcon } from '../../eid-icons'
import { Box } from '@mui/material'

const Option = ({
    option,
    optionState,
    getOptionLabel,
    getOptionSubLabel,
    showOptionSubLabel,
}) => (
    <>
        <span>
            <Box style={{ wordBreak: 'break-all' }}>
                {getOptionLabel(option)}
            </Box>
            {showOptionSubLabel && (
                <Box style={{ wordBreak: 'break-all', fontSize: '12px' }}>
                    {getOptionSubLabel(option)}
                </Box>
            )}
        </span>
        {optionState.selected && (
            <CheckIcon
                color="#919193"
                style={{ marginLeft: '1rem', overflow: 'visible' }}
            />
        )}
    </>
)

export default Option

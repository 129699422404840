import { Box, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { EidAutocomplete } from 'packages/eid-controls'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectAutocomplete from '../Components/AutoComplete/AutoComplete'
import FieldItemWrapper from '../Components/FieldItemWrapper'
import RequiredFieldErrorMessage from '../Components/FieldItemWrapper/RequiredFieldErrorMessage'
import classNames from 'classnames'
import { getFieldTypeRequiredErrorMessage } from 'packages/core'
import InfoMessageForWrongJson from '../Components/InfoMessageForWrongJson'

const useStyles = makeStyles((theme) => ({
    multiSelectAutoComplete: {
        display: 'flex',
        maxWidth: '360px',
    },
    errorField: {
        outline: '1px solid #d0021b',
        borderRadius: '4px',
    },
    disabledField: {
        opacity: '.7',
        pointerEvents: 'none',
        '& div': {
            '&:hover': {
                borderColor: `${theme?.palette?.disabled?.main} !important`,
            },
        },

        '& input': {
            cursor: 'not-allowed',
            '&:hover': {
                borderColor: `${theme?.palette?.disabled?.main} !important`,
            },
        },
        '& svg': {
            opacity: '.8',
            cursor: 'not-allowed',
            pointerEvents: 'none',
        },
        '& .MuiAutocomplete-tag': {
            opacity: '.8',
            cursor: 'not-allowed',
            border: 'none',
            '&:hover': {
                borderColor: `${theme?.palette?.disabled?.main} !important`,
            },
        },
    },
}))

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'person',
    disableImage: true,
    labelProp: 'FriendlyName',
})

const multiSelectStyles = {
    width: 'auto',
}

const InputWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    border: '1px solid #ebebed',
    backgroundColor: theme?.palette?.background?.paper,
    borderRadius: '4px',
    padding: '1px',
    display: 'flex',
    flexWrap: 'wrap',
    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
    minWidth: '360px',
    '& .MuiAutocomplete-tag': {
        height: '30px',
    },
    '& input': {
        fontSize: '15px',
        height: '35px',
        lineHeight: 'normal',
        padding: '8px',
        flexGrow: 1,
        border: 0,
        outline: 0,
        '&::placeholder': {
            color: '#b4b4b4',
        },

        '&:-ms-input-placeholder': {
            opacity: `${1} !important`,
            color: '#b4b4b4 !important',
        },
    },
    '&:hover': {
        borderColor: theme?.palette?.primary?.main,
    },
}))

const MultiSelectAutocomplete = ({
    fieldType,
    isFormValidated,
    valueType,
    isApiSupportedSource,
    useDataHook,
    handleUpdate,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [sourceValues, setSourceValues] = useState([])
    const [showFieldValues, setShowFieldValues] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [sourceUpdated, setSourceUpdated] = useState(false)

    useEffect(() => {
        if (fieldType?.SourceValues && sourceUpdated) {
            setShowFieldValues(fieldType?.SourceValues?.length ? true : false)
            setSourceValues(fieldType?.SourceValues || [])
        }
    }, [fieldType?.SourceValues, sourceUpdated])

    useEffect(() => {
        if (isFormValidated && !fieldType?.[valueType]) {
            setErrorMessage(getFieldTypeRequiredErrorMessage(fieldType))
        } else {
            setErrorMessage('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated, fieldType?.[valueType]])

    const { data: sourceData, isFetching } = useDataHook(fieldType)

    useEffect(() => {
        if (isApiSupportedSource) {
            if (!isFetching) {
                updateSourceValues()
                setSourceUpdated(true)
            }
        } else {
            setShowFieldValues(fieldType?.SourceValues?.length ? true : false)
            setSourceValues(fieldType?.SourceValues || [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isApiSupportedSource, isFetching])

    const updateSourceValues = () => {
        const valueToUpdate = {
            ...fieldType,
            SourceValues: sourceData?.data || [],
        }

        handleUpdate(valueToUpdate)
    }

    const handleChange = (value) => {
        try {
            const valueToUpdate = {
                ...fieldType,
                [valueType]: value,
            }

            handleUpdate(valueToUpdate)
        } catch (err) {}
    }

    const getOptionLabel = (option) =>
        option ? option.FriendlyName || option.ValueName : ''

    return (
        <FieldItemWrapper
            title={fieldType?.Name}
            isRequired={fieldType?.IsRequired}
            fieldTypeScope={fieldType?.FieldTypeScope}
        >
            {/* Types List */}
            <Box
                className={classNames(classes.multiSelectAutoComplete, {
                    [classes.errorField]: Boolean(errorMessage),
                    [classes.disabledField]: fieldType?.IsReadOnly,
                })}
            >
                {showFieldValues ? (
                    <SelectAutocomplete
                        placeholder={
                            fieldType?.[valueType]?.length > 0
                                ? ''
                                : t('Select')
                        }
                        optionComponent={OptionComponent}
                        value={fieldType?.[valueType] || []}
                        options={sourceValues}
                        getOptionLabel={getOptionLabel}
                        onChange={(_, value) => handleChange(value)}
                        labelProps="FriendlyName"
                        inputWrapperComponent={InputWrapper}
                        multiselect
                        inputWrapperStyleProps={multiSelectStyles}
                        loading={isFetching}
                        inputStyleProps={{ width: '0' }}
                        disabled={fieldType?.IsReadOnly}
                        showInfoTooltip={true}
                    />
                ) : (
                    <InfoMessageForWrongJson />
                )}
            </Box>
            <Box>
                {Boolean(errorMessage) && (
                    <RequiredFieldErrorMessage errorMsgKey={errorMessage} />
                )}
            </Box>
        </FieldItemWrapper>
    )
}

export default MultiSelectAutocomplete

import React from 'react'
import {
    Typography,
    Accordion as MuiExpansionPanel,
    AccordionSummary as MuiExpansionPanelSummary,
    AccordionDetails as MuiExpansionPanelDetails,
    Collapse,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ArrowIcon } from 'packages/eid-icons'

const StyledPanel = withStyles({
    root: {
        width: '100%',
        border: 'none !important',
        '&:not(:last-child)': {
            borderBottom: '0px !important',
        },
        '&:before': {
            display: 'none',
        },
    },
    expanded: {
        margin: '0 !important',
    },
    disabled: {
        backgroundColor: '#ffffff !important',
        pointerEvents: 'none',
        opacity: 0.66,
    },
})(MuiExpansionPanel)

const StyledPanelSummary = withStyles({
    root: {
        minHeight: '0 !important',
        margin: '0 !important',
        borderBottom: '1px solid #e8e8ec !important',
    },
    expanded: {
        minHeight: '0 !important',
        marginTop: '0 !important',
        marginBottom: '0 !important',
        '& p': { fontWeight: 'bold !important' },
    },
    content: {},
})(MuiExpansionPanelSummary)

const StyledPanelDetails = withStyles({
    root: {
        display: 'block',
        padding: 0,
        backgroundColor: '#fbfbfd',
    },
})(MuiExpansionPanelDetails)

const Accordion = (props) => {
    const { expanded, children, title, disabled, ...rest } = props
    const [_expanded, setExpanded] = React.useState(expanded)

    return (
        <StyledPanel
            expanded={_expanded}
            onChange={() => setExpanded(!_expanded)}
            disabled={disabled}
            {...rest}
        >
            <StyledPanelSummary
                expandIcon={<ArrowIcon direction="down" color="#919193" />}
                disabled={disabled}
            >
                <Typography>{title}</Typography>
            </StyledPanelSummary>
            <Collapse in={_expanded} timeout={400} unmountOnExit>
                <StyledPanelDetails disabled={disabled}>
                    {children}
                </StyledPanelDetails>
            </Collapse>
        </StyledPanel>
    )
}

export default Accordion

import React from 'react'
import { Icon } from 'packages/eid-icons'
import IconContainer from './IconContainer'

export const SkippedStatusIcon = (props) => {
    return (
        <IconContainer bgcolor={'#5d6870'}>
            <Icon name="SkippedStatus" color="#ffffff" width={16} height={16} />
        </IconContainer>
    )
}

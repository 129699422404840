import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { Skeleton } from '@mui/material';
import TreeList from './Tree'

export default function Tree({
    data,
    handleSelect,
    loading,
    selectedItem,
    idProp = 'id',
    labelProp = 'friendlyName',
    searchTerm,
}) {
    return loading ? (
        <>
            <Box margin="4px 8px">
                <Skeleton animation="wave" variant="rectangular" height={20} />
            </Box>
            <Box margin="4px 8px">
                <Skeleton animation="wave" variant="rectangular" height={20} />
            </Box>
        </>
    ) : (
        data && (
            <div
                style={{
                    maxHeight: 300,
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
            >
                <TreeList
                    data={data}
                    labelProp={labelProp}
                    idProp={idProp}
                    selected={selectedItem}
                    onClick={handleSelect}
                    searchTerm={searchTerm}
                />
            </div>
        )
    );
}

Tree.propTypes = {
    data: PropTypes.array,
    handleSelect: PropTypes.func.isRequired,
}

Tree.defaultProps = {}

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    AttributeContainer,
    AttributeValue,
    getLocationString,
    VerticalAlignedLabel,
    styles,
} from 'components/DetailsComponents'
import { Avatar as MuiAvatar, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getFullImageUrl, smallScreenWidth } from 'utils'
import useSubcomponents from 'useSubcomponents'
import { getURLTarget } from 'packages/core'

const useStyles = makeStyles({
    avatarContainer: {
        position: 'absolute',
        right: '15px',
    },
    avatar: {
        width: '100px',
        height: '100px',
        boxShadow: 'rgb(110 110 111 / 10%) 0px 20px 15px 3px',
    },
    viewProfileAnchor: {
        textDecoration: 'none',
        color: 'rgb(48, 127, 193)',
        '&:hover,&:active,&:visted': {
            color: 'rgb(48, 127, 193)',
        },
    },
})

export const InitiatorPersonDetails = ({ person }: any) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const { canSeeEIDProfileLink } = useSubcomponents()

    const { BASE_EID_URL } = (window as any).config

    return (
        <>
            <Box
                className={classes.avatarContainer}
                marginBottom="24px !important"
            >
                <MuiAvatar
                    src={getFullImageUrl(person?.imageUrl)}
                    className={classes.avatar}
                />
            </Box>

            <AttributeContainer style={{ display: 'block' }}>
                <VerticalAlignedLabel>
                    {t('Initiator')}
                </VerticalAlignedLabel>

                <AttributeValue
                    style={styles.roleReceiverValue}
                    value={person?.friendlyName || person?.fallbackName || '-'}
                />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('JobFunction')}
                </VerticalAlignedLabel>
                <AttributeValue
                    value={person?.jobTitle}
                    style={styles.nextToAvatar}
                    noWrap
                />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('Location')}
                </VerticalAlignedLabel>

                <AttributeValue
                    value={getLocationString(person?.city, person?.country)}
                    style={styles.nextToAvatar}
                    noWrap
                />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('Manager')}
                </VerticalAlignedLabel>
                <AttributeValue value={person?.managerFriendlyName} noWrap />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('Email')}
                </VerticalAlignedLabel>
                <AttributeValue value={person?.email} noWrap />
            </AttributeContainer>

            <AttributeContainer>
                <VerticalAlignedLabel>
                    {t('Phone')}
                </VerticalAlignedLabel>
                <AttributeValue value={person?.telephone} noWrap />
            </AttributeContainer>

            {canSeeEIDProfileLink && person?.eidPersonUrl && (
                <AttributeContainer
                    style={{ marginTop: 15 }}
                    data-protectedsubcomponent={canSeeEIDProfileLink}
                >
                    <a
                        className={classes.viewProfileAnchor}
                        target={getURLTarget()}
                        rel="noreferrer"
                        href={BASE_EID_URL + person?.eidPersonUrl}
                    >
                        {t('ViewUserProfile')}
                    </a>
                </AttributeContainer>
            )}
        </>
    )
}

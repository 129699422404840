import { RegistryBase } from 'packages/core'
import { SubjectTypesNamespace } from './RegistryNamespaces'
import { ISubjectType } from './subjectTypes'

/**
 * Contains all instances registered in the system. Instances
 * are separated out in different namespaces for better organization.
 */
export class Registry extends RegistryBase {
    /**
     * Gets the subject type instance.
     * Assumes at least one subject type (Generic Subject Type)
     * instance is already registered.
     * @param name Subject Type name (optional).
     */
    getSubjectType(name: string): ISubjectType {
        const subjectTypes = this.getAll(SubjectTypesNamespace)
        if (Object.keys(subjectTypes).includes(name)) {
            return subjectTypes[name]
        }
        return subjectTypes[Object.keys(subjectTypes)[0]]
    }
}

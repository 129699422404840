import React from 'react'
import * as Icons from './'

const Icon = ({ name, ...rest }) => {
    const IconComponent = Icons[`${name}Icon`]
    if (IconComponent === undefined) {
        console.warn(`Icon with name: ${name} not found.`)
        return <></>
    }
    return <IconComponent {...rest} />
}

export const CheckIfIconExists = (iconName) => {
    const IconComponent = Icons[`${iconName}Icon`]
    return IconComponent === undefined ? false : true
}
export default Icon

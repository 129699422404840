import React from 'react'
import Tabs from './Tabs'
import Tab from './Tab'

const VerticalTabs = ({ children, value }) => (
    <Tabs orientation="vertical" value={value}>
        {children}
    </Tabs>
)

VerticalTabs.Tab = Tab

export default VerticalTabs

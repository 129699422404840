import React from 'react'
import { Box, Divider } from '@mui/material'
import { StatusIndicator } from 'packages/eid-ui'
import { OverviewSectionSkeleton, Person, ElapsedTime } from 'components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { usePerson } from 'hooks'
import {
    AttributeContainer,
    AttributeLabel,
    AttributeValue,
    Container,
    styles,
    useStyles,
    VerticalAlignedLabel,
} from 'components/DetailsComponents'
import { getFullImageUrl } from 'utils'
import { InitiatorPersonDetails } from './InitiatorPersonDetails'

const statusStyles = {
    color: '#9b9b9b',
    maxWidth: '270px',
}

const Overview = ({ data }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const { data: initiatorPerson } = usePerson(data?.initiatorPersonId)
    const { data: executorPerson } = usePerson(data?.executorPersonId)
    const { data: onBehalfOfPerson } = usePerson(data?.onBehalfOfPersonId)

    const itemStatus = data?.preApproved ? 'PreApproved' : data?.status

    if (!data) {
        return (
            <Container>
                <OverviewSectionSkeleton />
            </Container>
        )
    }

    return (
        <>
            <Container style={{ display: 'block' }}>
                <div style={{ display: 'flex' }}>
                    <Box className={classes.profileContainer}>
                        <InitiatorPersonDetails
                            person={{
                                ...initiatorPerson,
                                fallbackName: data?.initiatorPersonFriendlyName,
                            }}
                        />
                    </Box>

                    <Divider
                        orientation="vertical"
                        flexItem
                        style={styles.divider}
                    />

                    <Box className={classes.detailsContainer}>
                        {data?.currentApprovalStepName && (
                            <AttributeContainer>
                                <AttributeLabel>{t('Step')}</AttributeLabel>
                                <AttributeValue
                                    style={{
                                        fontWeight: 700,
                                    }}
                                    value={data?.currentApprovalStepName}
                                />
                            </AttributeContainer>
                        )}
                        <AttributeContainer
                            className={classes.progressAttribute}
                        >
                            <StatusIndicator
                                size="large"
                                variant={itemStatus}
                                label={t(`${itemStatus}`)}
                                style={{
                                    minWidth: 'fit-content',
                                }}
                            />
                        </AttributeContainer>

                        {data.endDate && (
                            <AttributeContainer>
                                <AttributeLabel>
                                    {t('ApprovedOnDate')}
                                </AttributeLabel>

                                <AttributeValue
                                    value={moment
                                        .utc(data.endDate)
                                        .local()
                                        .format('LLL')}
                                />
                            </AttributeContainer>
                        )}

                        {executorPerson && executorPerson.friendlyName && (
                            <AttributeContainer>
                                <VerticalAlignedLabel>
                                    {t('Approver')}
                                </VerticalAlignedLabel>
                                <Box className={classes.person}>
                                    <Person.Details
                                        imgSrc={getFullImageUrl(
                                            executorPerson?.imageThumbUrl,
                                        )}
                                        size="large"
                                        name={
                                            executorPerson
                                                ? executorPerson?.friendlyName
                                                : data?.executorPersonFriendlyName
                                        }
                                        status={executorPerson?.login}
                                        statusStyles={statusStyles}
                                        showTooltip={true}
                                    />
                                </Box>
                            </AttributeContainer>
                        )}

                        {onBehalfOfPerson && onBehalfOfPerson.friendlyName && (
                            <AttributeContainer>
                                <VerticalAlignedLabel>
                                    {t('OnBehalfOf')}
                                </VerticalAlignedLabel>
                                <Box className={classes.person}>
                                    {
                                        <Person.Details
                                            imgSrc={getFullImageUrl(
                                                onBehalfOfPerson?.imageThumbUrl,
                                            )}
                                            size="large"
                                            name={
                                                onBehalfOfPerson
                                                    ? onBehalfOfPerson?.friendlyName
                                                    : data?.onBehalfOfPersonFriendlyName
                                            }
                                            status={onBehalfOfPerson?.login}
                                            statusStyles={statusStyles}
                                            showTooltip={true}
                                        />
                                    }
                                </Box>
                            </AttributeContainer>
                        )}

                        <AttributeContainer>
                            <AttributeLabel>{t('RequestID')}</AttributeLabel>
                            <AttributeValue value={data?.id} />
                        </AttributeContainer>
                        <AttributeContainer>
                            <AttributeLabel>
                                {t('WorkflowDisplayName')}
                            </AttributeLabel>
                            <AttributeValue
                                value={data?.requestWorkflowFriendlyName}
                            />
                        </AttributeContainer>

                        <AttributeContainer>
                            <AttributeLabel>{t('ElapsedTime')}</AttributeLabel>

                            <ElapsedTime startTime={data.startDate} />
                        </AttributeContainer>
                        <AttributeContainer>
                            <AttributeLabel>{t('Started')}</AttributeLabel>

                            <AttributeValue
                                value={
                                    !data.startDate
                                        ? '-'
                                        : moment
                                              .utc(data.startDate)
                                              .local()
                                              .format('LLL')
                                }
                            />
                        </AttributeContainer>

                        <AttributeContainer>
                            <AttributeLabel>{t('Expires')}</AttributeLabel>

                            <AttributeValue
                                value={
                                    !data.expirationDate
                                        ? ''
                                        : moment
                                              .utc(data.expirationDate)
                                              .local()
                                              .format('LLL')
                                }
                            />
                        </AttributeContainer>
                    </Box>
                </div>
            </Container>
        </>
    )
}

export default Overview

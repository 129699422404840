import useSwr from 'swr'
import useAxios from './useAxios'

/**
 * Used to fetch data. It provides options like caching, revalidation etc.
 * @param {key} key Url of the endpoint to get the data from. It's used as a cache key.
 * One of the most important things while using this is to keep the key unique per resource.
 * @param {config} config SWR configuration.
 */
const useSwrApiGet = (key, config) => {
    const callApi = useAxios()
    const fetcher = (url) => callApi({ url, method: 'GET' })
    return useSwr(key, fetcher, {
        revalidateOnFocus: false,
        ...config,
    })
}

export default useSwrApiGet

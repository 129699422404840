import React from 'react'
import PropTypes from 'prop-types'
import './index.css'
import { styled } from '@mui/material'

const Spinner = styled((props) => (
    <div {...props}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
    </div>
))((props) => ({
    width: '24px',
    height: '24px',
    zoom: props.size,
    // '@keyframes $circularAnimation': () => ({
    //   '0%': {
    //     opacity: 1,
    //   },
    //   '100%': {
    //     opacity: 0,
    //   },
    // }),
    '& div': {
        transformOrigin: '12px 12px',
        animation: 'circularAnimation 0.8s linear infinite',
    },
    '& div:after': {
        content: '" "',
        display: 'block',
        position: 'absolute',
        top: '1.125px',
        left: '10.875px',
        width: '2.5px',
        height: '6px',
        borderRadius: '20%',
        backgroundColor:
            props.color ?? props.theme.palette.primary?.main ?? '#307fc1',
    },

    '& div:nth-child(1)': {
        transform: 'rotate(0deg)',
        animationDelay: '-0.7s',
    },
    '& div:nth-child(2)': {
        transform: 'rotate(45deg)',
        animationDelay: '-0.6s',
    },
    '& div:nth-child(3)': {
        transform: 'rotate(90deg)',
        animationDelay: '-0.5s',
    },
    '& div:nth-child(4)': {
        transform: 'rotate(135deg)',
        animationDelay: '-0.4s',
    },
    '& div:nth-child(5)': {
        transform: 'rotate(180deg)',
        animationDelay: '-0.3s',
    },
    '& div:nth-child(6)': {
        transform: 'rotate(225deg)',
        animationDelay: '-0.2s',
    },
    '& div:nth-child(7)': {
        transform: 'rotate(270deg)',
        animationDelay: '-0.1s',
    },
    '& div:nth-child(8)': {
        transform: 'rotate(315deg)',
        animationDelay: '0s',
    },
}))

Spinner.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOf([0.5, 0.6, 0.7, 0.8, 0.9, 1]),
}

Spinner.defaultProps = {
    size: 1,
}

export default Spinner

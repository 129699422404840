import { Box, Typography, useTheme } from '@mui/material'
import React, { Fragment } from 'react'
import { Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'

const InfoTooltip = ({ friendlyName, description = '', style = {} }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const infoMessage = () => {
        return (
            <Box
                padding="1rem"
                style={{
                    minWidth: '40rem',
                }}
            >
                <Box
                    display="flex"
                    style={{
                        gap: '1rem',
                    }}
                >
                    <Typography
                        variant="label"
                        style={{
                            fontSize: '1.2rem',
                            textTransform: 'uppercase',
                            color: '#9b9b9b',
                            fontWeight: '600',
                            maxWidth: '11rem',
                            width: '100%',
                            marginTop: '.1rem',
                        }}
                    >
                        {t('Common_FriendlyName')}
                    </Typography>
                    <Typography
                        style={{
                            fontSize: '1.4rem',
                            fontWeight: '600',
                        }}
                    >
                        {friendlyName || '-'}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    style={{
                        gap: '1rem',
                        marginTop: '1.5rem',
                    }}
                >
                    <Typography
                        variant="label"
                        style={{
                            fontSize: '1.2rem',
                            textTransform: 'uppercase',
                            color: '#9b9b9b',
                            fontWeight: '600',
                            maxWidth: '11rem',
                            width: '100%',
                            marginTop: '.1rem',
                        }}
                    >
                        {t('Common_Description')}
                    </Typography>
                    <Typography
                        style={{
                            fontSize: '1.4rem',
                            fontWeight: '600',
                        }}
                    >
                        {description || '-'}
                    </Typography>
                </Box>
            </Box>
        )
    }
    return (
        <>
            {friendlyName && (
                <Box sx={{ ml: '0.8rem', ...style }}>
                    <Tooltip
                        title={infoMessage()}
                        color={theme?.palette?.common?.white}
                        fontColor={theme?.palette?.common?.black}
                        tooltipStyles={{
                            maxWidth: '65rem',
                            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
                            width: '100%',
                        }}
                        arrow={true}
                    >
                        <Box
                            sx={{
                                height: '1.6rem',
                                '&:hover': {
                                    '& path': {
                                        fill: '#307fc1',
                                    },
                                },
                            }}
                        >
                            <Icon name="InfoNew" />
                        </Box>
                    </Tooltip>
                </Box>
            )}
        </>
    )
}

export default InfoTooltip

import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material';
import React, { FC } from 'react'
import { Container } from 'components/DetailsComponents'
import RiskViolationToDoList from 'pages/Requests/RiskViolationToDoList'
import { useRequestRisksWithFunctions } from 'hooks'
import { PendingRiskItems } from './PendingRiskItems'

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '8px',
            width: '100%',
        },
        riskContainer: {
            margin: '20px 20px',
            width: '100%',
            paddingLeft: '24px',
            paddingRight: '24px',
        },
        riskDivider: {
            borderImageSource:
                'linear-gradient(to right, rgba(208, 2, 27, 0), #d0021b 8%, #d00238 91%, rgba(208, 2, 69, 0))',
            borderImageSlice: 1,
            borderBottom: '1px solid',
            opacity: '0.25',
        },
    }),
)

export type RisksWithViolationsProps = {
    requestId: string
    showDecisions: boolean
    pendingRiskItems?: any
    itemId?: any
    page?: any
    requestType: string
}

export const RisksWithViolationsByFunctions: FC<RisksWithViolationsProps> = (
    props,
) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const {
        requestId,
        showDecisions,
        pendingRiskItems,
        itemId,
        page,
        requestType,
    } = props

    const {
        data: dataWithFunctions,
        isLoading,
    }: { data: any; isLoading: boolean } = useRequestRisksWithFunctions(
        requestType === 'businessRequests' ? requestId : itemId,
        requestType,
    )

    return <>
        {isLoading || !dataWithFunctions || !dataWithFunctions?.data ? (
            <Box marginX={4} marginY={4}>
                <Skeleton animation="wave" variant="rectangular" height={24} />
                <Box marginY={1} />
                <Skeleton animation="wave" variant="rectangular" height={24} />
            </Box>
        ) : dataWithFunctions?.data?.length === 0 ? (
            <Container>
                <Box
                    marginX={4}
                    marginY={4}
                    display={'flex'}
                    justifyContent={'center'}
                    textAlign={'center'}
                    width="100%"
                >
                    {t('NoDataFound')}
                </Box>
            </Container>
        ) : dataWithFunctions?.data?.length ? (
            <Container
                style={{
                    overflow: 'inherit',
                }}
            >
                <div className={classes.root}>
                    <RiskViolationToDoList
                        props={{ ...dataWithFunctions, requestId }}
                        requestType={requestType}
                        itemId={itemId}
                        page={page}
                        pendingRiskItems={pendingRiskItems}
                        showDecisions={showDecisions}
                    />
                </div>
            </Container>
        ) : (
            <></>
        )}
    </>;
}

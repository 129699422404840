import React from 'react'
import { styled } from '@mui/material'
import { CloseIcon } from '../../eid-icons'

const Tag = styled(({ label, onDelete, ...props }) => (
    <div {...props}>
        <span>{label}</span>
        <CloseIcon color="#fff" onClick={onDelete} />
    </div>
))({
    display: 'flex',
    alignItems: 'center',
    height: '34px',
    margin: '2px',
    lineHeight: '22px',
    backgroundColor: '#535b62',
    border: '1px solid #535b62',
    borderRadius: '5px',
    boxSizing: 'content-box',
    padding: '0 4px 0 10px',
    outline: 0,
    overflow: 'hidden',
    color: '#fff',
    pointerEvents: 'all !important',
    '&:focus': {
        border: '1px solid #307fc1',
    },
    '& span': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    '& svg': {
        fontSize: '12px',
        cursor: 'pointer',
        padding: '14px 10px 14px 10px',
    },
})

export default Tag

import React, { FC, Fragment } from 'react'
import Xarrow from 'react-xarrows'
import { IArrow } from 'types'

export const DiagramArrows: FC<{
    arrows: IArrow[]
}> = (props) => {
    return (
        <Fragment>
            {props.arrows.map((a, index) => (
                <Xarrow
                    key={index}
                    path={'smooth'}
                    strokeWidth={2}
                    curveness={0.9}
                    start={a.start}
                    end={a.end}
                    startAnchor={a.startAnchor}
                    endAnchor={a.endAnchor}
                    color={a.color}
                />
            ))}
        </Fragment>
    )
}

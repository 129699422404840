import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { HorizontalTabs } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { DropdownMenu } from 'components/DropdownMenu'
import { useRouteOptions } from 'hooks'
import { SubPathLevel } from 'appConfigurations'
import appConfig from 'config'
import { useIsSmallScreen } from 'packages/core'

const SubHeaderTabs = styled(HorizontalTabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: theme.mixins.navBar.minHeight,
        marginTop: '8px',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
        height: '4px',
        padding: '0 6px',
        boxSizing: 'content-box',
        marginLeft: '-6px',
        marginBottom: '0px',
        zIndex: 1,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
        letterSpacing: '0.022em',
    },
    '& .MuiTab-root': {
        fontSize: '14px',
        letterSpacing: '0.3px',
        margin: '-2px 0px 0 8px',
        transition: 'color 0.15s ease-in-out',

        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}))

const useStyles = makeStyles(
    createStyles({
        navBarContainer: {
            height: '80px',
            marginBottom: '-4px',
            position: 'relative',
            whiteSpace: 'nowrap',

            '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                left: 0,
                top: '-4px',
                width: '100%',
                height: 'calc(100% - 2px)',
                borderBottom: '1px solid #d8d8dd',
            },

            '&::after': {
                content: '""',
                display: 'block',
                width: '200%',
                // transform: 'translateX(-50%)',
                height: '16px',
                backgroundImage:
                    'linear-gradient(to top, rgba(238, 240, 244, 0), rgba(238, 240, 244, 0.9))',
                position: 'absolute',
                left: '-50%',
                bottom: '-10px',
            },
        },
        cartMenu: {
            display: 'flex',
            '& >div:first-child': {
                minWidth: '20px !important',
                left: '-4px',
                '& hr': {
                    height: '30px !important',
                },
            },
        },
        mobileCartMenu: {
            display: 'flex',
            position: 'absolute',
            top: '25px',
            right: '8px',
            '& >div:first-child': {
                minWidth: '20px !important',
                left: '-4px',
                '& hr': {
                    height: '30px !important',
                },
            },
        },
    }),
)

const SubHeader = ({
    showNavTabs = true,
    subHeaderButton,
    viewSwitcher,
    totalCount,
}) => {
    const {
        routeOptions,
        hasAccessToRequestView,
        hasAccessToItemView,
        hasAccessWorkflowsView,
    } = useRouteOptions()

    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const classes = useStyles()

    const history = useHistory()
    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]

    let selectedRoute
    let selectedParentRoute

    for (let i = 0; i < routeOptions.length; i++) {
        const foundRoute = routeOptions[i].children.find((c) => {
            return c.route.toLowerCase() === pathname.toLowerCase()
        })

        if (foundRoute) {
            selectedRoute = foundRoute
            selectedParentRoute = routeOptions[i]
            break
        }
    }
    const selectedTab = pathname.split('/')[2 + SubPathLevel]

    const getTabLabelWithCount = (tabLabel) => {
        if (totalCount)
            return (
                <>
                    {tabLabel} ({totalCount})
                </>
            )
        return tabLabel
    }

    return (
        <Box
            height={isSmallScreen ? '128px' : '80px'}
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            alignItems={isSmallScreen ? 'start' : 'center'}
            justifyContent="space-between"
            width="100%"
            className={isSmallScreen ? '' : [classes.navBarContainer]}
        >
            <Box
                display="flex"
                paddingLeft={isSmallScreen ? '8px' : '0px'}
                justifyContent="space-between"
            >
                <DropdownMenu
                    routeOptions={routeOptions}
                    selectedRoute={selectedRoute}
                    selectedParentRoute={selectedParentRoute}
                />
            </Box>
            {subHeaderButton}

            {showNavTabs && (
                <SubHeaderTabs
                    variant="standard"
                    value={selectedTab}
                    onChange={(_, value) => {
                        history.push(
                            `${appConfig.APP_SUBPATH}/${areaName}/${value}`,
                        )
                    }}
                >
                    {hasAccessToRequestView(areaName) && (
                        <HorizontalTabs.Tab
                            key={1}
                            value="businessRequests"
                            label={
                                selectedTab === 'businessRequests'
                                    ? getTabLabelWithCount(
                                          t('RequestView'),
                                      )
                                    : t('RequestView')
                            }
                        />
                    )}
                    {hasAccessToItemView(areaName) && (
                        <HorizontalTabs.Tab
                            key={2}
                            value="businessRequestItems"
                            label={
                                selectedTab === 'businessRequestItems'
                                    ? getTabLabelWithCount(
                                          t('ItemView'),
                                      )
                                    : t('ItemView')
                            }
                        />
                    )}
                    {hasAccessWorkflowsView(areaName) && (
                        <HorizontalTabs.Tab
                            key={3}
                            value="collaborationTasks"
                            label={
                                selectedTab === 'collaborationTasks'
                                    ? getTabLabelWithCount(
                                          t('CollaborationTasks'),
                                      )
                                    : t('CollaborationTasks')
                            }
                        />
                    )}
                </SubHeaderTabs>
            )}

            <Box
                display="flex"
                marginRight="0"
                minWidth="130px"
                justifyContent="end"
                marginTop="-5px"
            >
                {viewSwitcher}
            </Box>
        </Box>
    )
}

export default SubHeader

import { Box, Radio, styled, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import config from 'config'
import { Icon } from 'packages/eid-icons'
import React, { useEffect, useState } from 'react'
import { ReactComponent as PlusSquareIconSvg } from '../Resources/collapse.svg'
import { ReactComponent as MinusquareIconSvg } from '../Resources/constrain.svg'
import TreeSubItem from './TreeSubItem'
import { isNilOrEmpty } from 'packages/core'

const getFullImageUrl = (imagePath) => `${config.BASE_EID_URL}${imagePath}`
const useStyles = makeStyles({
    listItem: {
        listStyle: 'none',
        paddingLeft: '28px',
        marginTop: '5px',
        marginBottom: '5px',
        width: 290,
        '&  li ul li p': {
            fontWeight: '400 !important',
        },
    },
    listContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    listRadioSection: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        width: 'fit-content',
    },
    listCollapse: {
        width: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '5px',
    },
    searchHighlight: {
        color: '#01745f',
        fontWeight: 600,
        textDecoration: 'underline',
    },
})

const StyledRadio = styled(Radio)({
    padding: '2px',
    maxHeight: '20px',
    '&:hover': {
        backgroundColor: '#d2d2d9',
    },
})

const TreeListItem = React.memo(
    ({ node, onClick, selected, labelProp, idProp, searchTerm }) => {
        const classes = useStyles()
        const theme = useTheme()
        const [isOpen, setIsOpen] = useState(
            !isNilOrEmpty(searchTerm) ? true : false,
        )

        const handleClick = () => {
            setIsOpen(!isOpen)
        }

        useEffect(() => {
            if (!isNilOrEmpty(searchTerm)) {
                setIsOpen(true)
            }
        }, [searchTerm])

        const bindName = (fieldName) => {
            const substring = searchTerm

            // Create a regular expression to match the substring
            const regex = new RegExp(`(${substring})`, 'gi')

            // Replace the substring with a span element containing the highlighted style
            const highlightedText = fieldName.replace(
                regex,
                `<span class=${classes.searchHighlight}>$1</span>`,
            )

            return highlightedText
        }

        return (
            <div>
                <div>
                    <Box className={classes.listContainer}>
                        {/* Check if we  have children then only render the expand icon */}
                        <Box
                            onClick={() => handleClick()}
                            className={classes.listCollapse}
                        >
                            {node.children.length ? (
                                isOpen ? (
                                    <MinusquareIconSvg />
                                ) : (
                                    <PlusSquareIconSvg />
                                )
                            ) : null}
                        </Box>
                        {/* Radio Selection Button */}
                        <Box
                            onClick={() => onClick(node)}
                            className={classes.listRadioSection}
                        >
                            <StyledRadio
                                checkedIcon={
                                    <Icon
                                        name="RadioFilled"
                                        color={theme?.palette?.primary?.main}
                                    />
                                }
                                icon={<Icon name="Radio" />}
                                checked={
                                    selected?.[idProp] === node[idProp] ?? false
                                }
                                onClick={() => onClick(node)}
                            />

                            {/* Icon if exist in node */}
                            {node?.iconUrl && (
                                <img
                                    style={{ maxHeight: '16px' }}
                                    src={getFullImageUrl(node.iconUrl)}
                                    alt={'icon'}
                                />
                            )}
                            {/* Label */}
                            <Typography
                                variant="body2"
                                style={{
                                    fontWeight: 'bold',
                                    wordBreak: 'break-all',
                                    paddingLeft: '5px',
                                    color: '#767676',
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: bindName(node[labelProp]),
                                }}
                            ></Typography>
                            {/* Count */}
                            {node?.children?.length ? (
                                <Typography
                                    variant="body2"
                                    style={{
                                        marginLeft: '5px',
                                        color: '#919193',
                                    }}
                                >
                                    ({node.children.length})
                                </Typography>
                            ) : null}
                        </Box>
                    </Box>
                </div>
                {isOpen && (
                    <ul className={classes.listItem}>
                        {node.children.map((childNode) => (
                            <li key={childNode[idProp]}>
                                <TreeSubItem
                                    node={childNode}
                                    labelProp={labelProp}
                                    onClick={onClick}
                                    selected={selected}
                                    idProp={idProp}
                                    searchTerm={searchTerm}
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        )
    },
)

export default TreeListItem

import React from 'react'
import { AppBar, Container, Toolbar } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useHeaderStyles = makeStyles(() => ({
    appBar: ({ bgColor }) => ({
        backgroundColor: bgColor,
    }),
    xlContainer: {
        padding: 0,
        display: 'flex', // for IE
    },
    toolbar: ({ height }) => ({
        alignItems: 'stretch',
        width: '100%',
        minHeight: height,
    }),
}))

const Header = ({ children, bgColor, height, borderBottom = {} }) => {
    const headerStyles = useHeaderStyles({ bgColor, height })
    return (
        <AppBar className={headerStyles.appBar}>
            <Container maxWidth="100%" className={headerStyles.xlContainer}>
                <Toolbar
                    style={{ ...borderBottom }}
                    disableGutters
                    className={headerStyles.toolbar}
                >
                    {children}
                </Toolbar>
            </Container>
        </AppBar>
    )
}

Header.defaultProps = {
    bgColor: '#fff',
}

export default Header

import { Box, Dialog, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from '../../eid-icons'
import IconButton from '../IconButton'
import { FC } from 'react'

const useStyles = makeStyles((theme) => ({
    modal: {
        backgroundColor: '#f7f8fa',
        borderRadius: '8px',
        boxShadow: '0 0 13px 0 rgba(0, 0, 0, 0.15)',
        padding: 0,
        '&:focus': {
            outline: 'none',
        },
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        maxHeight: '12%',
        padding: '16px 0px',
    },
    closeIcon: {
        display: 'flex',
        marginRight: '-16px',
        marginTop: '-16px',
        height: '10px',
    },

    title: {
        fontSize: '24px',
        fontWeight: 'normal',
        color: '#282828',
    },
}))

export const Modal: FC<any> = (props) => {
    const {
        open,
        onClose,
        title,
        header,
        children,
        maxWidth = 'md',
        fullScreen = false,
        fullWidth = true,
    } = props
    const classes = useStyles()

    return (
        <Dialog
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            scroll={'body'}
            open={open}
            onClose={onClose}
            aria-labelledby={title}
            aria-describedby={title}
        >
            <Box className={classes.modal}>
                {header ? (
                    header
                ) : (
                    <Box className={classes.modalHeader}>
                        <Typography className={classes.title}>
                            {title}
                        </Typography>
                        <Box className={classes.closeIcon}>
                            <IconButton onClick={onClose} size="large">
                                <Icon name="Close" color="#959598" />
                            </IconButton>
                        </Box>
                    </Box>
                )}

                {children}
            </Box>
        </Dialog>
    );
}

import {
    RESET_AUTH_STATE,
    USER_LOGGED_IN,
    SET_IS_WORKFLOW_RENDERABLE,
    SET_IS_COOKIE_ENABLED,
} from './constants'

export function resetAuthState() {
    return {
        type: RESET_AUTH_STATE,
    }
}
export function userLoggedIn(payload) {
    return {
        type: USER_LOGGED_IN,
        payload,
    }
}
export function setIsWorkflowRenderable(payload) {
    return {
        type: SET_IS_WORKFLOW_RENDERABLE,
        payload,
    }
}

export function setIsCookieEnabled(payload) {
    return {
        type: SET_IS_COOKIE_ENABLED,
        payload,
    }
}

const actions = {
    resetAuthState,
    userLoggedIn,
    setIsWorkflowRenderable,
    setIsCookieEnabled,
}
export default actions

import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { EidWorkflow } from '../packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import config from 'config'

export interface IWorkflowApprovalFormModalProps {
    approvalFormUrl: string
    title: string
    maxWidth?: any
}

export const WorkflowApprovalFormModal = (
    props: IWorkflowApprovalFormModalProps,
) => {
    const { t } = useTranslation()

    return (
        <EidWorkflow
            maxWidth={props?.maxWidth}
            baseEidUrl={config.BASE_EID_URL}
            title={props.title}
            workflowUrl={`${config.BASE_EID_URL}/ui?hideHeader=true&wfMsContext=true&wfMsUrl=${window.location.origin}${props.approvalFormUrl}`}
        >
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <ItemDetails.ActionButton
                    fontColor="#ffffff"
                    bgColor={'#307fc1'}
                    width="100%"
                    loading={false}
                    onClick={() => {}}
                >
                    <Box display="flex" alignItems="center" paddingLeft="10px">
                        <Icon name="Clipboard" color="#ffffff" />
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        marginLeft="14px"
                        marginRight="10px"
                        fontSize="16px"
                    >
                        {t('ViewApprovalForm')}
                    </Box>
                </ItemDetails.ActionButton>
            </Box>
        </EidWorkflow>
    )
}

import { FC, useState } from 'react'
import { Box, styled, TextField, Typography, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
    getDecisionCommentValidationMessage,
    isDateInPast,
    mobileScreenWidth,
} from 'utils'
import { isNilOrEmpty } from 'packages/core'
import MitigationControlList from './MitigationControlList'
import Button from '../../Button'
import { Icon } from 'packages/eid-icons'
import {
    Divider,
    Spinner,
    ToggleSwitch,
    ValidationMessage,
    ValidationMessages,
} from 'packages/eid-ui'
import DecisionComment from '../../DecisionComment'
import { NativeDatePicker } from 'packages/eid-ui/DateTimePickers'
import { useTranslation } from 'react-i18next'
import { IMitigatingControl } from 'types'
import useSubcomponents from 'useSubcomponents'
import { Moment } from 'moment'
import moment from 'moment'
import { useAccessRequestPolicy } from 'hooks'
import { StartDateTimePicker } from 'components/TimeConstrainedControls/StartDateTimePicker'
import { EndDateTimePicker } from 'components/TimeConstrainedControls/EndDateTimePicker'
import AccessRequestPolicyDetails from 'components/AccessRequestPolicyDetails'

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            paddingTop: '12px',
            paddingBottom: '12px',

            fontSize: '14px',
            fontWeight: 'bold',
            color: '#000000',
        },
        submitButton: {
            '& >button': {
                backgroundColor: '#307fc1',
                '&:hover': {
                    backgroundColor: '#307fc1',
                    opacity: 0.95,
                },
                '& svg': {
                    color: '#ffffff',
                },
            },
        },
        cancelButton: {
            '& >button': {
                backgroundColor: '#d9d9d9',
                '&:hover': {
                    backgroundColor: '#d9d9d9',
                    opacity: 0.95,
                },

                '& svg': {
                    color: '#ffffff',
                    width: '14px',
                    height: '14px',
                },
            },
        },
        endDateContainer: {
            display: 'flex',
            width: '100%',
            alignItems: 'flex-end',
            '& svg': {
                color: '#307fc1',
                margin: '4px 12px 4px 0px',
            },
        },
        datePicker: {
            width: '100%',
            borderBottom: '1px solid transparent',
            '&:hover': {
                borderBottom: '1px solid black',
            },
        },

        warningLabel: {
            fontSize: '1.4rem',
            fontWeight: 'bold',
            color: theme?.palette?.warning?.main,
            margin: '0 0.5rem',
        },

        warningMessage: {
            fontSize: '1.4rem',
        },
    }),
)

const PopoverContentContainer = styled(Box)({
    borderRadius: '5px',
    padding: '12px 24px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.15)',
    [`@media (min-width:${mobileScreenWidth})`]: {
        minWidth: '420px',
    },
    [`@media (max-width:${mobileScreenWidth})`]: {
        width: '100%',
    },
})

const StyledLabel = styled(Box)({
    margin: '2.4rem 0',
    fontSize: '1.2rem',
    lineHeight: 1.33,
    color: '#9b9b9b',
    textTransform: 'uppercase',
    minWidth: '20.4rem',
    display: 'block',
    wordBreak: 'break-word',
    fontWeight: 'bold',
})

export interface MitigationPopoverProps {
    globalRiskId: string
    decisionFriendlyName: string
    onSubmit: (value: any) => Promise<any>
    isLoading?: boolean
    handleClose?: () => void
    assignmentStartDate?: any
    assignmentEndDate?: any
    showTimeConstrainedControl?: boolean
    timeConstraintRequired?: boolean
    requestPolicyId?: string
}

export const MitigateRisk: FC<MitigationPopoverProps> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()
    const theme = useTheme()

    const [decisionComment, setDecisionComment] = useState('')

    const [showErrors, setShowErrors] = useState(false)

    const [
        selectedMitigatingControls,
        setSelectedMitigatingControls,
    ] = useState<IMitigatingControl[]>([])
    const [
        mitigationControlEndDate,
        setMitigationControlEndDate,
    ] = useState<Moment | null>(null)

    const { hasAccessToRiskMitigationEndDateControl } = useSubcomponents()

    const noMitigationControlSelected = selectedMitigatingControls.length === 0

    const validationMessage = getDecisionCommentValidationMessage(
        decisionComment,
    )
    const invalidComment = !isNilOrEmpty(validationMessage)

    const [startDate, setStartDate] = useState(props.assignmentStartDate)
    const [endDate, setEndDate] = useState(props.assignmentEndDate)

    const policy = useAccessRequestPolicy(
        props.requestPolicyId ? props.requestPolicyId : '',
    )
    const invalidDate =
        ((policy?.isTimeConstrained || props.timeConstraintRequired) &&
            !endDate) ||
        isDateInPast(endDate) ||
        moment(startDate).isAfter(moment(endDate))

    const notSubmittable =
        noMitigationControlSelected || invalidComment || invalidDate

    const [toggleValue, setToggle] = useState(
        Boolean(endDate) ||
            policy?.isTimeConstrained ||
            props.timeConstraintRequired,
    )

    const handleToggle = () => {
        if (toggleValue) {
            setEndDate('')
        }
        setToggle((prev) => !prev)
    }

    return (
        <PopoverContentContainer>
            <Box className={classes.title}>{props.decisionFriendlyName}</Box>

            <MitigationControlList
                onChange={setSelectedMitigatingControls}
                globalRiskId={props.globalRiskId}
            />

            {hasAccessToRiskMitigationEndDateControl && (
                <Box
                    className={classes.endDateContainer}
                    data-protectedsubcomponent={
                        hasAccessToRiskMitigationEndDateControl
                    }
                >
                    <Icon name="EndTime" />
                    <Box className={classes.datePicker}>
                        <NativeDatePicker
                            minDate={moment(new Date()).format('YYYY-MM-DD')}
                            value={
                                mitigationControlEndDate
                                    ? mitigationControlEndDate
                                          .clone()
                                          .local()
                                          .format('YYYY-MM-DD')
                                    : null
                            }
                            handleChange={(e: any) => {
                                const date = moment(e)
                                const dateToSet = date
                                    .clone()
                                    .endOf('day')
                                    .subtract(1, 'minutes')
                                    .set('milliseconds', 0)
                                    .utc()
                                setMitigationControlEndDate(dateToSet)
                            }}
                            pickerStyles={{ top: '22px' }}
                        >
                            <TextField
                                fullWidth
                                type="text"
                                value={
                                    mitigationControlEndDate
                                        ? mitigationControlEndDate
                                              .clone()
                                              .local()
                                              .format('D MMMM YYYY')
                                        : '-- -- ----'
                                }
                                label={t('EndDate')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </NativeDatePicker>
                    </Box>
                </Box>
            )}

            {props.showTimeConstrainedControl && (
                <>
                    <Box margin="2.4rem 0">
                        <Divider />
                    </Box>
                    <Box maxWidth={'43rem'}>
                        <StyledLabel>{t('SelectTimeConstraint')}</StyledLabel>
                        <Box minWidth="14rem">
                            <ToggleSwitch
                                value={toggleValue}
                                label={t('SetDuration')}
                                labelColor="#5d6870"
                                onChange={handleToggle}
                                disabled={
                                    policy?.isTimeConstrained ||
                                    props.timeConstraintRequired
                                }
                            />
                        </Box>
                        {toggleValue && (
                            <>
                                <Box display={'flex'} minWidth={'20rem'}>
                                    <Box
                                        padding={'0 0.4rem'}
                                        display="flex"
                                        minWidth={'21.5rem'}
                                    >
                                        <Box
                                            style={{
                                                alignSelf: 'end',
                                                marginRight: '0.8rem',
                                            }}
                                        >
                                            <Icon name={'StartTime'} />
                                        </Box>
                                        <StartDateTimePicker
                                            selectedStartDate={startDate}
                                            setSelectedStartDate={setStartDate}
                                            showWarningColor={invalidDate}
                                        />
                                    </Box>

                                    <Box
                                        padding={'0 0.4rem'}
                                        display="flex"
                                    >
                                        <Box
                                            style={{
                                                alignSelf: 'end',
                                                marginRight: '0.8rem',
                                            }}
                                        >
                                            <Icon name={'EndTime'} />
                                        </Box>
                                        <EndDateTimePicker
                                            selectedEndDate={endDate}
                                            setSelectedEndDate={setEndDate}
                                            minEndDate={moment(new Date())}
                                            maxEndDate={
                                                policy?.isTimeConstrained
                                                    ? moment(startDate).add(
                                                          policy?.maximumValueInMinutes,
                                                          'minute',
                                                      )
                                                    : null
                                            }
                                            showWarningColor={invalidDate}
                                        />
                                    </Box>
                                </Box>

                                {isDateInPast(endDate) && (
                                    <Box
                                        marginTop={'1.6rem'}
                                        paddingLeft={'0.4rem'}
                                    >
                                        <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            marginLeft={'1.3rem'}
                                        >
                                            <Icon
                                                name="Warning"
                                                color={
                                                    theme?.palette?.warning
                                                        ?.main
                                                }
                                                height="1rem"
                                                width="1rem"
                                            />
                                            <Typography
                                                className={classes.warningLabel}
                                            >
                                                {`${t('Warning')}:`}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                className={
                                                    classes.warningMessage
                                                }
                                            >
                                                {t(
                                                    'TimeConstraintExceededMessage',
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}

                                <Box marginTop={'2.4rem'}>
                                    <AccessRequestPolicyDetails
                                        policy={policy}
                                        orientation="vertical"
                                        noOfColumns={2}
                                        styles={null}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>

                    <Box margin="2.4rem 0">
                        <Divider />
                    </Box>
                </>
            )}

            <Box my={3}>
                <DecisionComment
                    initialValue={decisionComment}
                    onChange={(comment: any) => setDecisionComment(comment)}
                />
            </Box>

            {showErrors && notSubmittable && (
                <ValidationMessages>
                    {selectedMitigatingControls.length === 0 && (
                        <ValidationMessage
                            message={t('MitigationControlRequired')}
                        />
                    )}
                    {invalidComment && (
                        <ValidationMessage message={validationMessage} />
                    )}
                    {invalidDate && (
                        <ValidationMessage
                            message={t('EndDateGreaterThanStartError')}
                        />
                    )}
                </ValidationMessages>
            )}

            <Box my={2} display="flex">
                <Box className={classes.cancelButton}>
                    <Button size="medium" onClick={props.handleClose}>
                        <Icon name="Close" />
                    </Button>
                </Box>
                <Box mx={2} className={classes.submitButton}>
                    <Button
                        size="medium"
                        onClick={() => {
                            if (notSubmittable) {
                                setShowErrors(true)
                                return
                            }

                            let itemToSubmit: any = {
                                mitigatingControlIds: selectedMitigatingControls.map(
                                    (m) => m.id,
                                ),
                                comment: decisionComment,
                                requestDataAssignmentStartDate: startDate,
                                requestDataAssignmentEndDate: endDate,
                            }
                            if (hasAccessToRiskMitigationEndDateControl) {
                                itemToSubmit.endDateUtc = mitigationControlEndDate
                            }

                            props.onSubmit(itemToSubmit)
                        }}
                    >
                        {props.isLoading ? (
                            <Spinner size={0.6} color="#ffffff" />
                        ) : (
                            <Icon name="Approve" />
                        )}
                    </Button>
                </Box>
            </Box>
        </PopoverContentContainer>
    )
}

const attributes = [
    {
        placement: 'TagRow',
        component: {
            componentName: 'StatusIndicator',
            props: {
                size: 'small',
            },
            name: 'status',
        },
    },

    {
        placement: 'TagRow',
        component: {
            componentName: 'FulfillmentStatusIndicator',
            props: {
                size: 'small',
            },
            name: 'fulfillmentStatusFriendlyName',
        },
    },

    {
        placement: 'TagRow',
        renderBrStatus: true,
    },

    {
        isHeader: true,
        name: 'friendlyName',
        label: 'Name',
        sortBy: 'FriendlyName',
        sortable: true,
        tableStyles: {
            headerCellStyles: {
                width: '20%',
                minWidth: '150px',
            },
            styles: {
                position: 'relative',
                overflow: 'visible',
                color: '#01ae8f',
                fontWeight: 500,
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
        },
        link: false,
    },
    {
        name: 'resourceRequested',
        label: 'TargetResource',
        sortBy: 'ResourceRequested',
        sortable: true,
        tableStyles: {
            headerCellStyles: {
                width: '12%',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
        },
    },
    {
        name: 'initiatorPersonFriendlyName',
        label: 'RequestedBy',
        sortBy: 'InitiatorPersonFriendlyName',
        hideValue: true,
        sortable: true,
        avatar: true,
        imgProp: 'initiatorPersonThumbnailUrl',
        tableStyles: {
            headerCellStyles: {
                maxWidth: '150px',
            },
            styles: {
                maxWidth: '150px',
            },
            textStyles: {
                color: '#282828',
                fontSize: '13px',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
            },
        },
    },
    {
        name: 'targetPersonFriendlyName',
        label: 'RequestedFor',
        sortBy: 'SubjectFriendlyName',
        hideValue: true,
        sortable: true,
        avatar: false,
        tableStyles: {
            headerCellStyles: {
                maxWidth: '150px',
            },
            styles: {
                maxWidth: '150px',
            },
        },
        component: {
            componentName: 'SubjectType',
            props: {
                size: 'small',
                style: {
                    color: '#282828',
                    fontSize: '13px',
                },
            },
        },
    },
    {
        name: 'businessRequestName',
        label: 'BusinessRequestName',
        sortBy: 'BusinessRequestName',
        sortable: true,
        tableStyles: {
            headerCellStyles: {
                width: '12%',
            },
            styles: {
                fontSize: '13px',
            },
        },
        cardStyles: {
            color: '#262fa1 !important',
            fontWeight: 'bold ',
        },
    },
    {
        name: 'businessRequestNumber',
        label: 'BusinessRequestNumber',
        sorBy: 'BusinessRequestNumber',
        sortable: true,

        tableStyles: {
            headerCellStyles: {
                width: '11%',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
        },
    },
    {
        name: 'createdDateUtc',
        hideValue: true,
        label: 'CreatedDate',
        sortable: true,
        sortBy: 'CreatedDateUtc',
        tableStyles: {
            headerCellStyles: {
                width: '10%',
            },
        },
        component: { componentName: 'CellDateTime' },
    },
    {
        name: 'Risk',
        hideValue: true,
        label: 'RiskLevel',
        sortable: true,
        sortBy: 'RiskLevel',
        tableStyles: {
            headerCellStyles: {},
        },
        component: { componentName: 'RiskIndicator', name: 'riskLevel' },
    },
]

export default attributes

import React, { useEffect, useReducer, useState } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PeopleSearch from '../Filters/PeopleSearch'
import Button from '../Button'
import { isNilOrEmpty, isDevelopmentEnv, isTestEnv } from 'packages/core'
import { mobileScreenWidth } from 'utils'
import {
    useCurrentPerson,
    useEnvironment,
    useHasAccessToAddApprovers,
} from 'hooks'

const styles = {
    peopleSearch: {
        '& > div': {
            border: 'solid 1px #ebebed',
            '&:hover': {
                borderColor: '#307fc1',
            },
            padding: '1.5px !important',
            '& > div': {
                right: '0px !important',
                pointerEvents: 'none',
            },
            '& > input': {
                height: '35px',
                cursor: 'pointer',
                width: 'auto !important',
            },
        },
    },
    cancelButton: {
        backgroundColor: '#ffffff !important',
        color: '#307fc1',
        boxShadow: 'none',
        border: '1px solid #307fc1',
        arrowDirection: 'right',
    },
}

const ItemContainer = styled(Box)({
    padding: '15px 0px',
    [`@media (max-width:${mobileScreenWidth})`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
})

const reducers = (state, { type, payload }) => {
    switch (type) {
        case 'SET_PERSON':
            return { ...state, person: payload }

        case 'SET_DELEGATE_FOREVER':
            return { ...state, delegateForever: payload }

        case 'CLEAR_STATE':
            return {
                ...payload,
            }
        default:
            return state
    }
}

const AdditionalApproversForm = ({ onSave, isSaving, onCancel }) => {
    const [state, dispatch] = useReducer(reducers, {
        person: [],
    })

    const { t } = useTranslation()
    const { data: currentPerson } = useCurrentPerson()
    const { data: env } = useEnvironment()
    const [personToCheck, setPersonToCheck] = useState(null)
    const [personsAccess, setPersonsAccess] = useState([])
    const isDevOrTest = isDevelopmentEnv(env) || isTestEnv(env)
    const [disableButton, setDisableButton] = useState(true)
    const { data: access, isLoading: checkingAccess } =
        useHasAccessToAddApprovers(personToCheck?.id)
    useEffect(() => {
        let alreadyCheckedPersons = personsAccess.map((p) => p.id)
        if (state.person.length > personsAccess.length) {
            let newPerson = state.person.find(
                (p) => !alreadyCheckedPersons.includes(p.id),
            )
            setPersonToCheck(newPerson)
        } else if (state.person.length < personsAccess.length) {
            let stateIds = state.person.map((i) => i.id)
            let deletedPerson = alreadyCheckedPersons.find(
                (x) => !stateIds.some((y) => y === x),
            )

            const updatedAccessPersons = personsAccess.filter(
                (i) => i.id !== deletedPerson,
            )
            setPersonsAccess(updatedAccessPersons)
        }
    }, [state.person])
    useEffect(() => {
        if (access) {
            setPersonsAccess((pre) => [
                ...pre,
                { ...personToCheck, hasAccess: access?.hasAccess },
            ])
        }
    }, [access])

    useEffect(() => {
        setDisableButton(!personsAccess.every((obj) => obj.hasAccess))
    }, [personsAccess])

    return (
        <>
            <ItemContainer>
                <PeopleSearch
                    endAdornmentComponent={() => <></>}
                    label={t('PersonName')}
                    placeholder={t('SelectPerson')}
                    onChange={(_, value) =>
                        dispatch({
                            type: 'SET_PERSON',
                            payload: value,
                        })
                    }
                    url="/api/people/approvers"
                    excludeOptions={isDevOrTest ? [] : [currentPerson.id]}
                    rootStyleProps={styles.peopleSearch}
                    value={state.person}
                    multiple
                />
                {disableButton && (
                    <>
                        <Typography style={{ color: 'red', fontSize: '10px' }}>
                            {`*${t('AccessDeniedMessage')}`}
                        </Typography>
                        <ul style={{ marginTop: '0px', marginBottom: '0px' }}>
                            {personsAccess
                                .filter((p) => !p.hasAccess)
                                .map((item) => {
                                    return (
                                        <li>
                                            <Typography
                                                style={{
                                                    color: 'red',
                                                    fontSize: '10px',
                                                }}
                                            >
                                                {item.friendlyName}
                                            </Typography>
                                        </li>
                                    )
                                })}
                        </ul>
                    </>
                )}
            </ItemContainer>

            <ItemContainer
                display="flex"
                marginLeft="-8px"
                flexDirection="row !important"
                flexWrap="wrap"
            >
                <Button.Rounded
                    disabled={
                        isNilOrEmpty(state.person) ||
                        isSaving ||
                        disableButton ||
                        checkingAccess
                    }
                    isLoading={isSaving}
                    onClick={() => {
                        onSave(state)
                    }}
                    styles={{ arrowDirection: 'right' }}
                >
                    {t('AddAdditionalApprovers')}
                </Button.Rounded>

                <Button.Rounded onClick={onCancel} styles={styles.cancelButton}>
                    {t('Cancel')}
                </Button.Rounded>
            </ItemContainer>
        </>
    )
}

export default AdditionalApproversForm

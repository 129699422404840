import React from 'react'
import { Icon } from 'packages/eid-icons'
import IconContainer from './IconContainer'

export const RiskIcon = (props) => {
    const { active } = props
    return (
        <IconContainer bgcolor={active ? '#dc3f52' : '#d8d8dd'}>
            <Icon name="CriticalRisk" color="#ffffff" width={16} height={16} />
        </IconContainer>
    )
}

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import {
    useWorkflowClaim,
    useWorkflowUnclaim,
    useCanUnClaimWorkflow,
    useCurrentPerson,
} from 'hooks'
import { Button, Tag } from 'components'
import { Spinner } from 'packages/eid-ui'

const WorkflowClaimControl = ({
    disabled,
    isApprover,
    workflowTaskId,
    claimedByPersonId,
    claimByPersonFriendlyName,
}) => {
    const { t } = useTranslation()

    const [showClaimButton, setShowClaimButton] = useState(false)

    const [
        canUnClaim,
        { isLoading: isCheckingUnClaimAccess },
    ] = useCanUnClaimWorkflow(workflowTaskId)

    const [claim, { isLoading: isClaiming }] = useWorkflowClaim(workflowTaskId)

    const [unclaim, { isLoading: isUnclaiming }] = useWorkflowUnclaim(
        workflowTaskId,
    )

    const { data: currentPerson } = useCurrentPerson()

    const isClaimedByNobody = isNilOrEmpty(claimedByPersonId)
    const isClaimedByMe = currentPerson.id === claimedByPersonId

    useEffect(() => {
        async function checkIfUserCanUnclaim() {
            if (claimedByPersonId) {
                var res = await canUnClaim(claimedByPersonId)

                setShowClaimButton(res?.data.hasAccess || false)
            }
        }
        checkIfUserCanUnclaim()
    }, [claimedByPersonId, canUnClaim])

    if (isCheckingUnClaimAccess) {
        return <Spinner />
    }
    if (isClaimedByMe && showClaimButton) {
        return (
            <Button.Unclaim
                disabled={disabled}
                onClick={() => unclaim(workflowTaskId)}
                loading={isUnclaiming}
            >
                {t('Unclaim')}
            </Button.Unclaim>
        )
    }
    if (isClaimedByNobody) {
        if (isApprover && !disabled) {
            return (
                <Button.Claim onClick={() => claim()} loading={isClaiming}>
                    {t('Claim')}
                </Button.Claim>
            )
        } else return <></>
    } else {
        return (
            <span style={{ display: 'flex', flexDirection: 'row' }}>
                <Tag
                    label={t('ClaimedBy')}
                    value={claimByPersonFriendlyName}
                    icon={
                        <Icon
                            name="Approve"
                            color="#01ae8f"
                            width="16px"
                            height="16px"
                        />
                    }
                />
            </span>
        )
    }
}

export default WorkflowClaimControl

import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { InputBase, MenuItem, Select, styled } from '@mui/material'
import { Icon } from '../../eid-icons'
import ArrowIcon from './ArrowIcon'

const CenteredDiv = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})
const StyledMenuItem = styled(MenuItem)({
    minWidth: '260px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
    color: '#919193',
    fontSize: '16px',
    borderRadius: '4px',
    padding: '8px 12px',
    '&:hover': {
        backgroundColor: '#f5f6f8 !important',
    },
})

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiSelect-iconOpen': {
            transform: 'rotate(180deg) !important',
        },
    },

    iconOpen: {
        transform: 'rotate(180deg) !important',
    },

    input: ({ inputStyles }) => ({
        display: 'flex',
        alignItems: 'center',
        fontSize: 13,
        height: '34px',
        padding: '0px 10px',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            borderColor: `${theme.palette.primary.main} !important`,
        },
        '&:focus': {
            borderColor: theme.palette.primary.main,
            boxShadow: `0 2px 4px 0 ${theme.palette.primary.light}`,
        },

        ...inputStyles,
    }),

    iconStyles: {
        position: 'absolute',
        top: '0',
        right: '0',
        display: 'inline-block',
        color: '#c0c0c0',
        width: '24px',
        height: '24px',
        pointerEvents: 'none',
    },
}))

const useMenuStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '4px',
        padding: '0px 6px',
    },

    root: {
        '& .Mui-selected': {
            color: '#000000 !important',
            backgroundColor: '#f5f6f8 !important',
        },
    },
}))

const SelectMenu = ({ options, inputStyles, handleSelect }) => {
    const classes = useStyles({ inputStyles })
    const menuClasses = useMenuStyles()

    const menuProps = {
        PopoverClasses: menuClasses,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    }

    const [value, setValue] = React.useState({
        route: '',
    })

    useEffect(() => {
        const value = options.find((i) => i.selected === true)
        if (value) {
            setValue(value)
        }
    }, [options])

    return (
        <Select
            variant="standard"
            value={value.route}
            renderValue={(value) => (
                <span>{options.find((o) => o.route === value)?.title}</span>
            )}
            input={
                <InputBase
                    classes={{
                        input: classes.input,
                    }}
                />
            }
            IconComponent={(props) => (
                <ArrowIcon
                    {...props}
                    className={`material-icons ${props.className}`}
                    style={{ top: '15px', right: '12px' }}
                    color="#919193"
                />
            )}
            MenuProps={{
                ...menuProps,
            }}>
            {options.map((option) => (
                <StyledMenuItem
                    onClick={() => {
                        setValue(option)
                        handleSelect(option)
                    }}
                    key={option.route}
                    value={option.route}
                    selected={option.selected}
                >
                    <CenteredDiv>
                        <Icon
                            name={option.icon.small}
                            id={`${option.icon.small}-header`}
                            color="#919193"
                        />

                        <span style={{ margin: '0px 16px' }}>
                            {option.title}
                        </span>
                    </CenteredDiv>

                    {option.selected && (
                        <CenteredDiv>
                            <Icon
                                name="Check"
                                width="10px"
                                height="7px"
                                color="#7d7c7c"
                            />
                        </CenteredDiv>
                    )}
                </StyledMenuItem>
            ))}
        </Select>
    );
}

export default SelectMenu

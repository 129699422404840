import { FC, Fragment, useState } from 'react'
import { Button } from 'components'
import { Spinner } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { Box, Popover } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { mobileScreenWidth } from 'utils'

const useStyles = makeStyles(
    createStyles({
        popoverContentContainer: {
            [`@media (min-width:${mobileScreenWidth})`]: {
                maxWidth: '320px',
            },
        },
        message: {
            marginTop: '32px',
            marginLeft: '24px',
            marginRight: '24px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
        },
        actions: {
            margin: '16px 24px',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > div:not(:last-child)': {
                marginRight: '16px',
            },
        },
    }),
)

export interface ICancelationButtonProps {
    confirmationMessage: string
    isCanceling: boolean
    onClick: () => Promise<any>
}

export const CancelationButton: FC<ICancelationButtonProps> = (props) => {
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    const showConfirmation = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Fragment>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                disableScrollLock={true}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box className={classes.popoverContentContainer}>
                    <Box className={classes.message}>
                        {props.confirmationMessage}
                    </Box>

                    <Box className={classes.actions}>
                        <Box>
                            <Button
                                size="medium"
                                onClick={handleClose}
                                style={{
                                    backgroundColor: '#d9d9d9',
                                    '&:hover': {
                                        backgroundColor: '#d9d9d9',
                                        opacity: 0.95,
                                    },
                                }}
                            >
                                <Icon
                                    name="Close"
                                    color="#ffffff"
                                    width="14px"
                                    height="14px"
                                />
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                size="medium"
                                disabled={props.isCanceling}
                                onClick={() =>
                                    props.onClick().then(() => handleClose())
                                }
                                style={{
                                    backgroundColor: '#307fc1',
                                    '&:hover': {
                                        backgroundColor: '#307fc1',
                                        opacity: 0.95,
                                    },
                                }}
                            >
                                {props.isCanceling ? (
                                    <Spinner size={0.6} color="#ffffff" />
                                ) : (
                                    <Icon name="Approve" color="#ffffff" />
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Popover>
            <Button.Revoke size="small" onClick={showConfirmation}>
                {props.children}
            </Button.Revoke>
        </Fragment>
    )
}

import React from 'react'
import { Divider as MuiDivider } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
    root: (props) => ({
        backgroundColor: props.color,
        borderColor: props.color,
        width: props.width,
        height: props.height,
        transform: props.vertical ? 'rotate(90deg)' : '',
    }),
})

const Divider = (props) => {
    const { color, width, height, vertical, ...rest } = props
    const classes = useStyles({
        color,
        width,
        height,
        vertical,
    })
    return <MuiDivider classes={classes} {...rest} />
}

Divider.defaultProps = {
    color: '#e8e8e8',
    height: 1,
}

export default Divider

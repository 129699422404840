import React, { useEffect } from 'react'
import { useAuthState } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { isNilOrEmpty, useQuery } from 'packages/core'
import {
    useCurrentPerson,
    useAccessSubcomponents,
    useAllApplications,
    useTranslations,
} from 'hooks'
import moment from 'moment'
import i18n from 'i18n'

const useLoadCurrentPerson = () => {
    const { data } = useCurrentPerson()

    return !isNilOrEmpty(data)
}

const useLoadAccessSubcomponents = () => {
    const [{ access }, dispatchAuthState] = useAuthState()
    const { data } = useAccessSubcomponents()

    useEffect(() => {
        if (data) {
            dispatchAuthState({
                type: 'SET_ACCESS_SUBCOMPONENTS',
                payload: data,
            })
        }
    }, [data, dispatchAuthState])

    return access !== null
}

const useLoadAllApplications = () => {
    const { data: applications } = useAllApplications()

    return !isNilOrEmpty(applications)
}

const useLoadTranslations = () => {
    const RESOURCE_SET_NAME = 'MyTasks'

    const query = useQuery()
    const debugLocalization = query.get('debugLocalization') === 'true'

    const { data } = useTranslations()
    const { data: currentPerson } = useCurrentPerson()

    let locale = 'en'
    if (currentPerson?.languageCode) {
        locale = currentPerson?.languageCode
    }

    useEffect(() => {
        i18n.changeLanguage(locale)
        moment.locale(locale)
    }, [locale])

    useEffect(() => {
        if (data) {
            const localeData = data.reduce((current, r) => {
                let keyToUse = r.key
                const parts = r.key.split('_')
                if (parts[0] === RESOURCE_SET_NAME) {
                    keyToUse = r.key.substring(RESOURCE_SET_NAME.length + 1)
                }

                if (debugLocalization) {
                    current[keyToUse] = r.key
                } else {
                    current[keyToUse] = r.translatedValue
                }

                return current
            }, {})

            i18n.addResourceBundle(locale, 'translation', localeData)
        }
    }, [data, locale, debugLocalization])

    return Boolean(data)
}

const withRequiredData = (ChildComponent) => (props) => {
    const currentPersonLoaded = useLoadCurrentPerson()
    const accessSubcomponentsLoaded = useLoadAccessSubcomponents()
    const allApplicationsLoaded = useLoadAllApplications()
    const translationsLoaded = useLoadTranslations()

    if (
        !currentPersonLoaded ||
        !accessSubcomponentsLoaded ||
        !allApplicationsLoaded ||
        !translationsLoaded
    )
        return <Loader />

    return <ChildComponent {...props} />
}

export default withRequiredData

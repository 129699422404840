import React, { Fragment } from 'react'
import { Box, Table, TableCell, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { Skeleton } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'packages/core'
import { StatusIndicator, Avatar } from 'packages/eid-ui'
import { getFullImageUrl } from 'utils'

const useStyles = makeStyles({
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: '#ffffff',
        paddingLeft: '50px',
    },
    tableHeaderRow: {
        border: ' solid 1px #efeff1',
    },
    tableRow: {
        border: ' solid 1px rgba(214, 106, 0, 0.33)',
    },

    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        fontWeight: 'bold',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: '#307fc1 !important',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '12px',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
    sectionTitle: {
        padding: '24px 31px',
        lineHeight: '15px',
        color: '#000000',
        fontSize: '16px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        border: 'none',
        borderTop: '1px solid #d8dadd',
        borderBottom: '1px solid #d8dadd',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
    },
    countStyles: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '8px',
        minWidth: '28px',
        height: '25px',
        fontSize: '14px',
        color: '#ffffff',
        fontWeight: 'normal',
        background: '#307fc1',
        borderRadius: '8px',
    },
})

const WorkflowRelatedTasksListing = ({ data, isLoading }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const attributes = [
        {
            label: t('Decision'),
            name: 'desicion',
            style: {
                width: '10%',
            },
            resolve: (item) => (
                <Box display="flex">
                    <StatusIndicator
                        size="medium"
                        variant={item.responseType}
                        label={t(
                            item.responseType
                                ? `${item.responseType}`
                                : '-',
                        )}
                    />
                </Box>
            ),
        },
        {
            label: t('Initiator'),
            name: 'resourceFriendlyName',
            style: {
                color: '#5d6870',
                width: '20%',
                fontWeight: 'bold',
            },
            resolve: (item) => (
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Avatar
                        src={getFullImageUrl(item.initiatorPersonImageUrl)}
                        style={{
                            width: '30px',
                            height: '30px',
                            boxShadow: '0 0px 8px 0 #6e6e6f',
                            marginRight: '10px',
                        }}
                    />
                    {item.initiatorPersonFriendlyName}
                </Box>
            ),
        },
        {
            label: t('Description'),
            name: 'taskFriendlyName',
            style: {
                width: '50%',
                color: '#5d6870',
            },
        },
        {
            label: t('Approver'),
            name: 'operationFriendlyName',
            style: {
                width: '20%',
                fontWeight: 'bold',
            },
            resolve: (item) => (
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Avatar
                        src={getFullImageUrl(item.executorPersonImageUrl)}
                        style={{
                            width: '30px',
                            height: '30px',
                            boxShadow: '0 0px 8px 0 #6e6e6f',
                            marginRight: '10px',
                        }}
                    />
                    <Box display="flex" flexDirection="column">
                        {item.executorPersonFriendlyName}
                        <br />
                        {item.onBehalfPersonFriendlyName && (
                            <Fragment>
                                <span
                                    style={{
                                        color: '#5d6870',
                                        fontWeight: 'normal',
                                        fontSize: '11px',
                                    }}
                                >
                                    {t('OnBehalfOf')}
                                </span>
                                <span
                                    style={{
                                        fontWeight: 'normal',
                                    }}
                                >
                                    {item.onBehalfPersonFriendlyName}
                                </span>
                            </Fragment>
                        )}
                    </Box>
                </Box>
            ),
        },
    ]

    const headings = attributes.map((a) => a.label)

    return (
        <Box marginTop="30px">
            <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                className={classes.sectionTitle}
            >
                <Box style={{ paddingTop: '5px' }}>
                    {t('CollaborationTasksDecisionsOverview')}
                </Box>
                <Box className={classes.countStyles}>{data?.length || 0}</Box>
            </Box>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        {headings.map((heading, index) => (
                            <TableCell
                                key={index}
                                className={clsx(
                                    classes.tableHeaderCell,
                                    classes.tableCell,
                                    classes.headGrey,
                                )}
                                align={heading.align}
                            >
                                {heading}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {isLoading ? (
                    <TableRow className={classes.tableRow}>
                        <TableCell colSpan={headings.length + 2}>
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                height={64}
                            />
                        </TableCell>
                    </TableRow>
                ) : !data || data.length === 0 ? (
                    <TableRow className={classes.tableRow} hover={false}>
                        <TableCell
                            className={classes.tableCell}
                            colSpan={headings.length + 2}
                            style={{ textAlign: 'center' }}
                        >
                            <Trans i18nKey="NoDataFound" />
                        </TableCell>
                    </TableRow>
                ) : (
                    <>
                        {data.map((item) => (
                            <React.Fragment key={item.id}>
                                <TableRow className={classes.tableRow}>
                                    {attributes.map((a, index) => (
                                        <TableCell
                                            key={`${item.id}${index}`}
                                            className={classes.tableCell}
                                            style={a.style}
                                        >
                                            {a.resolve
                                                ? a.resolve(item)
                                                : !isNilOrEmpty(item[a.name])
                                                ? item[a.name]
                                                : '-'}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </>
                )}
            </Table>
        </Box>
    );
}
export default WorkflowRelatedTasksListing

import React from 'react'
import PropTypes from 'prop-types'
import { TextField as MuiTextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { alertIcon } from './resources'

const styles = (theme) => ({
    placeholder: {
        color: theme.palette.text.placeholder,
    },
    textField: {
        //IE vertical scroll fix
        '& textarea': {
            'overflow-y': 'auto',
        },
    },
    wrapper: {
        width:'100%',
        position: 'relative',
        '& img': {
            position: 'absolute',
            right: '6px',
            bottom: '6px',
        },
    },
})

const TextField = withStyles(styles)(({ classes, alert, ...rest }) => {
    return (
        <div className={classes.wrapper}>
            <MuiTextField
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                    classes: {
                        root: classes.placeholder,
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.textField,
                    },
                }}
                {...rest}
            />
            {rest.error && alert && <img src={alertIcon} alt="" />}
        </div>
    )
})

TextField.propTypes = {
    ...MuiTextField.propTypes,
    alert: PropTypes.bool,
    classes: PropTypes.object,
}

TextField.defaultProps = {
    ...MuiTextField.defaultProps,
}

export default TextField

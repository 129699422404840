import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { useAuthState } from '../context'
import { resetAuthState, userLoggedIn } from '../actions'
import jwtDecode from 'jwt-decode'
import useUserManager from '../useUserManager'
import { setOidcUser } from '../refreshAccessToken'

function LoginCallback({ history, render }) {
    const [{ extractAuthData }, dispatch] = useAuthState()
    const userManager = useUserManager()

    useEffect(() => {
        const signedIn = async (user) => {
            const decodedAccessToken = jwtDecode(user.access_token)

            const userAuthData = await extractAuthData(
                user.access_token,
                decodedAccessToken,
            )

            const newExpiresAt = decodedAccessToken.exp
                ? decodedAccessToken.exp
                : user.expires_at

            setOidcUser(userManager, {
                ...user,
                expires_at: newExpiresAt,
            })

            dispatch(
                userLoggedIn({
                    accessToken: user.access_token,
                    idToken: user.id_token,
                    ...userAuthData,
                }),
            )

            if (user.state) {
                history.push(user.state.from)
            } else {
                history.push('/')
            }
        }

        const func = async () => {
            dispatch(resetAuthState())

            try {
                const user = await userManager.signinRedirectCallback()
                if (user) await signedIn(user)
            } catch {
                const existingUser = await userManager.getUser()
                if (existingUser) {
                    await signedIn(existingUser)
                } else {
                    history.push('/signin')
                }
            }
        }

        func()
    }, [dispatch, history])

    return render()
}

LoginCallback.propTypes = {
    render: PropTypes.func.isRequired,
}

export default withRouter(LoginCallback)

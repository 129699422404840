import { FC, forwardRef, useState } from 'react'
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Icon } from '../../eid-icons'
import { Modal } from './Modal'

import classNames from 'classnames'

const useStyles = makeStyles(
    createStyles({
        button: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        modalHeader: {
            padding: '20px',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            backgroundColor: '#307fc1',
            backgroundImage: 'linear-gradient(105deg, #682a94, #05afe0)',
        },
        content: {
            color: '#000000',
            marginTop: '64px',
        },
        title: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#ffffff',
        },
        closeIcon: {
            position: 'absolute',
            right: 20,
            top: 16,
            height: '10px',
            cursor: 'pointer',
        },
    }),
)

const getIframeStyles = (height?: string) => ({
    border: 'none',
    width: '100%',
    height: height ? height : '78vh',
    margin: 0,
    padding: 0,
})

export type EidIFrameProps = {
    url: string
    title: string
    className?: string
    height?: string
}

export const EidIFrame: FC<EidIFrameProps> = forwardRef((props, ref) => {
    const classes = useStyles()

    const [showModal, setShowModal] = useState(false)

    const header = (
        <Box className={classes.modalHeader}>
            <Typography className={classes.title}>{props.title}</Typography>
            <Box className={classes.closeIcon}>
                <Icon
                    name="Close"
                    color="#ffffff"
                    onClick={() => setShowModal(false)}
                />
            </Box>
        </Box>
    )

    return (
        // @ts-ignore
        <div ref={ref}>
            <Modal open={showModal} header={header}>
                <Box className={classes.content}>
                    {showModal && (
                        <iframe
                            title={props.title}
                            style={getIframeStyles(props.height)}
                            src={props.url}
                        />
                    )}
                </Box>
            </Modal>
            <Box
                onClick={() => setShowModal(true)}
                className={classNames(classes.button, props.className)}
            >
                {props.children}
            </Box>
        </div>
    )
})

import React from 'react'
import { Box, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Avatar, Tooltip } from 'packages/eid-ui'
import { getFullImageUrl } from 'utils'
import { Icon } from 'packages/eid-icons'

const PaddedDiv = styled(Box)({
    padding: '0 10px',
})

const useStyles = makeStyles({
    maincontainer: {
        borderRadius: '8px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        background: '#fff',
        marginTop: '16px',
        padding: '16px',
        width: '300px',
    },

    personName: {
        fontSize: '14px',
        fontWeight: 600,
        color: '#307fc1',
        marginTop: '0px',
        marginBottom: '0px',
    },
    personJobRole: {
        color: '#9b9b9b',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
    },
    avatar: {
        width: '40px',
        height: '40px',
        boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px',
    },
})

export const ApproverPersonCardItem = ({ person }: any) => {
    const classes = useStyles()

    return (
        <PaddedDiv>
            <Box className={classes.maincontainer}>
                <Box display="flex" flexDirection="row" marginBottom="20px">
                    <Avatar
                        size="small"
                        src={getFullImageUrl(person?.imageUrl)}
                        className={classes.avatar}
                    />
                    <Box paddingLeft="20px">
                        <Box component="h2" className={classes.personName}>
                            {person.friendlyName}
                        </Box>
                        <Box className={classes.personJobRole}>
                            {person.title}
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" flexDirection="row" marginLeft="15px">
                    <Icon name="EmailRound" style={{ marginRight: '30px' }} />
                    <Tooltip
                        title={person.email}
                        disableInteractive={!person.email}
                    >
                        <Box
                            style={{ color: '#307fc1' }}
                            textOverflow="ellipsis"
                            width="200px"
                            maxWidth="200px"
                            overflow="hidden"
                        >
                            {person.email || '-'}
                        </Box>
                    </Tooltip>
                </Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    marginLeft="15px"
                    marginTop="10px"
                >
                    <Icon name="PhoneRound" />
                    <Box style={{ color: '#307fc1' }} marginLeft="30px">
                        {person?.telephone || '-'}
                    </Box>
                </Box>
            </Box>
        </PaddedDiv>
    )
}

export default ApproverPersonCardItem

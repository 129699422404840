import React, { FC, Fragment } from 'react'
import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import { PillTabs } from 'components/PillTabs'
import { Tab } from 'components/PillTabs/Tab'
import { TabLabel } from 'components/PillTabs/TabLabel'
import { useTranslation } from 'react-i18next'
import { mobileScreenWidth } from 'utils'
import { useItemPendingRiskItems } from 'hooks'
import { RisksWithViolationsByFunctions } from 'components/V2/RiskViolationsByFunctions'

const useStyles = makeStyles(
    createStyles({
        root: {
            marginTop: '1px',
        },
        tabsContainer: {
            display: 'flex',
            [`@media (max-width:${mobileScreenWidth}) `]: {
                display: 'block',
            },
        },
        tabsSearchContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: '#FAFAFC',
            borderBottom: 'solid 1px #d8d8dd',
            alignItems: 'flex-end',
            paddingBottom: '8px',
            '& > *': {
                marginRight: '26px',
                width: '309px',
                display: 'flex',
                alignItems: 'center',
                [`@media (max-width:${mobileScreenWidth}) `]: {
                    width: '100%',
                    marginLeft: '26px',
                    marginTop: '8px',
                },
            },
        },
    }),
)

export interface ItemPendingToDoListProps {
    itemId: string
    afterwardsChildren: any
    requestId?:any
}

export const PendingToDoList: FC<ItemPendingToDoListProps> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const {
        data: pendingRiskItems,
        totalCount,
        isLoading,
    } = useItemPendingRiskItems(props.itemId)

    if (!pendingRiskItems || isLoading) {
        return (
            <Box marginX={4} marginY={4}>
                <Skeleton animation="wave" variant="rectangular" height={24} />
                <Box marginY={1} />
                <Skeleton animation="wave" variant="rectangular" height={24} />
            </Box>
        );
    }

    if (totalCount === 0) {
        return (
            <Fragment>{pendingRiskItems && props.afterwardsChildren}</Fragment>
        )
    }

    return (
        <Fragment>
            <Box className={classes.root}>
                <Box className={classes.tabsContainer}>
                    <PillTabs value={'pendingRiskItems'} onChange={() => {}}>
                        <Tab
                            label={
                                <TabLabel
                                    label={t('RiskViolationsToDoList')}
                                    count={totalCount}
                                />
                            }
                            value={'pendingRiskItems'}
                        />
                    </PillTabs>
                    <Box className={classes.tabsSearchContainer}>
                        {/*<Search*/}
                        {/*    outlined*/}
                        {/*    placeholder={t('Search')}*/}
                        {/*    value={searchKey}*/}
                        {/*    handleClear={() => setSearchKey('')}*/}
                        {/*    handleSearch={(value: any) => {*/}
                        {/*        setPage(1)*/}
                        {/*        setSearchKey(value)*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </Box>
                </Box>
                <Box marginY={'16px'}>
                    <RisksWithViolationsByFunctions requestType="businessRequestitems" pendingRiskItems={pendingRiskItems} page={'RequestItems'} showDecisions={true} itemId={props.itemId} requestId={props.itemId} />
                </Box>
            </Box>
            {pendingRiskItems && totalCount === 0 && props.afterwardsChildren}
        </Fragment>
    )
}

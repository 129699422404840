import React, { useState } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { Avatar } from 'packages/eid-ui'
import config from 'config'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import EditComment from './EditComment'
import { smallScreenWidth } from 'utils'

const IconContainer = styled(Icon)({
    display: 'flex',
    alignItems: 'center',
    color: '#307fc1',
    width: '11px',
    height: '11px',
    marginLeft: '13px',

    '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
})

const Reply = (props) => {
    const {
        id,
        value,
        commentorPersonFriendlyName,
        participantTypeFriendlyName,
        createdDateUtc,
        lastEditDateUtc,
        pathToImage,
        handleDelete,
        handleEdit,
    } = props

    const [editComment, setEditComment] = useState(false)

    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const displayNames = (
        <DisplayNames>
            {commentorPersonFriendlyName}
            {participantTypeFriendlyName && (
                <Box component="span" marginLeft="10px">
                    ({participantTypeFriendlyName})
                </Box>
            )}
        </DisplayNames>
    )

    const dateTime = (
        <Box display="flex" alignItems="center">
            <DateTime>
                {moment
                    .utc(createdDateUtc)
                    .local()
                    .format('MMMM Do YYYY, h:mm a')}
            </DateTime>

            {lastEditDateUtc && (
                <Box
                    component="span"
                    marginLeft="4px"
                    color="#5d6870"
                    fontSize="11px"
                >
                    ({t('Edited')})
                </Box>
            )}
        </Box>
    )

    const editable = handleEdit && !editComment
    const deletable = handleDelete

    const editIcon = editable && (
        <IconContainer
            name="Edit"
            onClick={() => setEditComment(true)}
            height="10px "
            width="10px"
        />
    )

    const deleteIcon = deletable && (
        <IconContainer
            name="Delete"
            onClick={() => handleDelete(id)}
            height="10px "
            width="10px"
        />
    )

    return (
        <Box marginY="12.5px" display="flex" alignItems="center">
            <Box minWidth="40px">
                <Avatar
                    size={isSmallScreen ? 'medium' : 'large'}
                    src={`${config.BASE_EID_URL}${pathToImage}`}
                />
            </Box>

            <Box width={`calc(100% - 40px)`} marginLeft="10px">
                <Box display="flex" alignItems="center">
                    <InfoContainer>
                        {displayNames}
                        {dateTime}
                    </InfoContainer>

                    {editIcon}

                    {deleteIcon}
                </Box>

                {editComment ? (
                    <EditComment
                        id={id}
                        initialValue={value}
                        editComment={editComment}
                        setEditComment={setEditComment}
                        handleEditComment={handleEdit}
                        variant="dark"
                    />
                ) : (
                    <Value>{value}</Value>
                )}
            </Box>
        </Box>
    )
}

export default Reply

const InfoContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',

    [`@media (max-width:${smallScreenWidth})`]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
})

const Value = styled(Typography)({
    fontSize: '14px',
    color: '#5d6870',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
})

const DateTime = styled(Typography)({
    fontSize: '11px',
    color: '#5d6870',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
})

const DisplayNames = styled(Typography)({
    fontSize: '11px',
    color: '#5d6870',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    marginRight: '16px',
})

import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as RoundCloseSvgIcon } from './Resources/round-close-button.svg'
import { FilterChip } from 'packages/eid-ui'
const useStyles = makeStyles({
    root: {
        backgroundColor: '#ffffff',
        maxWidth: '485px',
        borderRadius: '5px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '5px 0px 5px 10px',
    },
    filter: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: ' #e9f4fb',
        borderRadius: '10px',
        minWidth: '80px',
        maxHeight: '20px',
        color: '#307fc1',
        // marginLeft: '10px',
        margin: '5px 15px 5px 0px',
        padding: '0 10px',
    },
    icon: {
        marginLeft: '10px',
    },
})

const FilterBar = ({ list, fontSize }) => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            {list.map((item, index) => (
                <FilterChip
                    //bgColor=""
                    label={item.label}
                    fontSize={fontSize}
                    key={index}
                    height="14px"
                    //border="solid 1px #453fbb"
                    CloseIcon={RoundCloseSvgIcon}
                    borderRadius="10px"
                    onClick={() => {}}
                ></FilterChip>
            ))}
        </Box>
    )
}

FilterBar.propTypes = {
    list: PropTypes.array.isRequired,

    fontSize: PropTypes.string,
    // fontColor: PropTypes.string,
}

FilterBar.defaultProps = {}

export default FilterBar

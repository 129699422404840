import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { Avatar, Divider } from 'packages/eid-ui'

const styles = {
    name: {
        fontSize: '14px',
        color: '#5d6870',
        fontWeight: 'bold' as const,
    },
    position: {
        textTransform: 'uppercase' as const,
        color: '#9b9b9b',
        fontSize: '11px',
        fontWeight: 'bold' as const,
    },
    status: {
        color: '#01ae8f',
        fontSize: '14px',
    },
    divider: {
        backgroundColor: '#5d6870',
        width: '16px',
        height: '1px',
    },
}

const PersonDetails: FC<any> = (props) => {
    const {
        avatarId,
        imageUrl,
        name,
        moreInfo,
        requestedBy,
        size = 'large',
        statusStyles,
        personTitle = '',
    } = props
    return (
        <Box position="relative" width="100%">
            <Box marginY="7.5px" display="flex" alignItems="center">
                <Box
                    id={avatarId}
                    minWidth={size}
                    marginRight="10px"
                    style={{
                        boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px',
                        borderRadius: '50%',
                    }}
                >
                    <Avatar size={size} src={imageUrl} />
                </Box>
                <Box>
                    <Box display='flex' alignItems='center'>
                    <Typography style={styles.name}>{name}</Typography>
                    {personTitle && (
                            <>
                                <Divider vertical style={styles.divider} />
                                <Typography
                                    style={{
                                        ...styles.position,
                                    }}
                                >
                                    {personTitle}
                                </Typography>
                            </>
                        )}
                        </Box>


                    <Box display="flex" alignItems="center">
                        <Typography
                            style={{ ...styles.status, ...statusStyles }}
                        >
                            {requestedBy}
                        </Typography>

                        {moreInfo && moreInfo}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default PersonDetails

import React from 'react'
import PropTypes from 'prop-types'
import { Chip as MuiChip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import NumberedLabel from './NumberedLabel'

const Chip = withStyles(() => ({
    root: {
        backgroundColor: (props) =>
            props.tone === 'dark'
                ? 'rgba(205, 205, 205, 1)'
                : 'rgba(223, 223, 223, 1)',
        '&:hover': {
            backgroundColor: (props) =>
                props.tone === 'dark'
                    ? 'rgba(223, 223, 223, 1)'
                    : 'rgba(205, 205, 205, 1)',
        },
    },
}))((props) => (
    <MuiChip
        {...props}
        label={
            props.number ? (
                <NumberedLabel label={props.label} number={props.number} />
            ) : (
                props.label
            )
        }
    />
))

Chip.propTypes = {
    ...MuiChip.propTypes,
    tone: PropTypes.oneOf(['light', 'dark']).isRequired,
    number: PropTypes.number,
}

Chip.defaultProps = {
    ...MuiChip.defaultProps,
    tone: 'light',
}

export default Chip

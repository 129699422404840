import React, { useReducer, createContext, useContext } from 'react'
import reducer from './reducer'
export const AuthContext = createContext({})

export const initialState = {
    isAuth: null,
    idToken: '',
    accessToken: '',
    tokenExpiresAt: 0,
    access: null,
    isWorkflowIRenderable: false,
    isCookieEnabled: true,
}

export const AuthContextProvider = ({ children, config }) => (
    <AuthContext.Provider
        value={useReducer(reducer, {
            ...initialState,
            ...config.initialValue,
            extractAuthData: config.extractAuthData,
        })}
    >
        {children}
    </AuthContext.Provider>
)

export const useAuthState = () => useContext(AuthContext)

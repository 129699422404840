import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { TextInput } from 'packages/eid-ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FieldItemWrapper from '../Components/FieldItemWrapper'
import RequiredFieldErrorMessage from '../Components/FieldItemWrapper/RequiredFieldErrorMessage'
import { FIELD_TYPE_COMPONENTS_TYPES, getFieldTypeRequiredErrorMessage } from 'packages/core'

const useStyles = makeStyles({
    freesingleValue: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        '& input': {
            maxWidth: '360px',
            color: '#3b454d',
        },
        '& .MuiBox-root': {
            maxWidth: '360px',
            width: '100%',
        },
        '& .MuiFormControl-root': {
            marginTop: '0 !important',
            maxWidth: '360px',
            borderRadius: '4px',
        },

        '& .MuiInputBase-formControl': {
            minWidth: '360px',
        },
    },
    errorField: {
        '& input': {
            outline: '1px solid #d0021b',
        },
    },
    disabledStyle: {
        pointerEvents: 'none',
        opacity: '.6',
        cursor: 'not-allowed',
    },
})

const FixedSingleValue = ({
    fieldType,
    isFormValidated,
    valueType,
    handleUpdate,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (isFormValidated && !fieldType?.[valueType]) {
            setErrorMessage(getFieldTypeRequiredErrorMessage(fieldType))
        } else {
            setErrorMessage('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated, fieldType?.[valueType]])

    const handleChange = (value) => {
        try {
            const valueToUpdate = {
                ...fieldType,
                [valueType]: { ValueName: value },
            }
            handleUpdate(valueToUpdate)
        } catch (err) {}
    }
    const isReadOnly = () => {
        return (
            fieldType?.SelectionRule === FIELD_TYPE_COMPONENTS_TYPES.FIXED_SINGLE_VALUE ||
            fieldType.IsReadOnly
        )
    }
    return (
        <FieldItemWrapper
            title={fieldType?.Name}
            isRequired={fieldType?.IsRequired}
            fieldTypeScope={fieldType?.FieldTypeScope}
        >
            <Box
                className={classNames(classes.freesingleValue, {
                    [classes.errorField]: Boolean(errorMessage),
                    [classes.disabledStyle]: isReadOnly(),
                })}
            >
                <TextInput
                    handleChange={(e) => handleChange(e.target.value)}
                    value={fieldType?.[valueType]?.ValueName || ''}
                    placeholder={t('Common_EnterValue')}
                    disabled={isReadOnly()}
                />
            </Box>

            <Box>
                {Boolean(errorMessage) && (
                    <RequiredFieldErrorMessage errorMsgKey={errorMessage} />
                )}
            </Box>
        </FieldItemWrapper>
    )
}

export default FixedSingleValue

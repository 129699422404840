import { FC, Fragment, useState } from 'react'
import { Box, Collapse, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiskIndicator, Tooltip } from 'packages/eid-ui'
import classNames from 'classnames'
import { mobileScreenWidth } from 'utils'
import { useTranslation } from 'react-i18next'
import { RiskViolationLeftRight } from './RiskViolationLeftRight'
import { useSubmitRiskItemDecision } from 'hooks'
import { ApproveRisk } from '../PendingRiskItem/ApproveRisk'
import RevokeButton from '../Button/RevokeButton'
import { Button } from 'components'
import { ArrowIcon } from 'packages/eid-icons'


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            background: '#fff',
            borderRadius: '5px',
            '& >div': {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        },
        header: {
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            paddingTop: '16px',
            paddingBottom: '16px',
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            backgroundColor: '#307fc1',
            [`@media (max-width:${mobileScreenWidth})`]: {
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            },
        },
        titleSection: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        headerTitle: {
            color: '#ffffff',
            fontSize: '18px',
            fontWeight: 'bold',
        },
        headerSubTitle: {
            color: '#fdcc02',
            fontSize: '14px',
            display: 'flex',
            marginTop: '8px',
            justifyContent: 'space-between',
            [`@media (max-width:${mobileScreenWidth})`]: {
                lineHeight: 1.7,
                flexWrap: 'wrap',
            },
        },
        actionArea: {
            minHeight: '40px',
            position: 'absolute',
            bottom: '-16px',
            right: 0,
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            '& > button': {
                margin: '16px 16px 0 16px',
            },
        },
        riskType: {
            minWidth: '150px',
            marginRight: '12px',
            [`@media (max-width:${mobileScreenWidth})`]: {
                display: 'flex',
                flexGrow: 1,
            },
        },
        itemFriendlyName: {
            color: '#efeff1',
            display: 'flex',
            justifyContent: 'space-between',
        },
        riskIndicatorBox: {
            display: 'flex',
            alignItems: 'center',
            [`@media (max-width:${mobileScreenWidth})`]: {
                display: 'none',
            },
        },
        riskIndicatorMobileContainer: {
            display: 'none',
            [`@media (max-width:${mobileScreenWidth})`]: {
                display: 'flex',
                alignItems: 'center',
            },
        },

        riskIndicator: {
            fontWeight: 'bold',
        },

        contentClass: {
            backgroundColor: '#ffffff',
        },
        riskContainer: {
            paddingTop: '24px',
        },
        sectionDivider: {
            height: '16px',
            padding: '0 0 15px',
            backgroundImage:
                'linear-gradient(to bottom, #efeff1, rgba(0, 0, 0, 0))',
        },
        removePadding: {
            marginLeft: '-16px',
            marginRight: '-16px',
        },
        showLessAndMore: {
            color: '#fff',
            border: '1px solid #fff',
            borderRadius: '15px',
            padding: '5px 15px 5px 16px',
            textTransform: 'uppercase',
            fontSize: '11px',
            height: '32px',
            
          
        },
    }),
)

export type RiskWithViolationsProps = {
    page: string
    requestId?: string
    itemId?: string
    risk: any
    showDecisions?: boolean
}

export const RiskWithViolations: FC<RiskWithViolationsProps> = (props) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [showMore, setShowMore] = useState(false)
    const { risk } = props
    const [
        submitRiskDecision,
        { isLoading: isSubmitting },
    ] = useSubmitRiskItemDecision(
        props.page,
        props.requestId as string,
        props.itemId,
    )

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Box className={classes.titleSection}>
                    <Typography className={classes.headerTitle}>
                        {risk.localRiskFriendlyName}
                    </Typography>
                    <Box className={classes.headerSubTitle}>
                        <Box className={classes.riskType}>
                            {t(`${risk.riskType}`)}
                        </Box>
                        <Box className={classes.riskIndicatorMobileContainer}>
                            <RiskIndicator
                                variant={risk.riskLevel}
                                label={t(`${risk.riskLevel}Risk`)}
                                className={classes.riskIndicator}
                            />
                        </Box>

                        <Box className={classes.itemFriendlyName}>
                            <Box>
                                <Box style={{ width: '90%' }}>
                                    {risk.itemFriendlyName}
                                </Box>
                            </Box>
                            <Box>
                                <Tooltip
                                    title={(showMore ? t('LessDetails')  : t('MoreDetails')) || '' }
                                    placement="bottom"
                                    enterDelay={400}
                                    enterNextDelay={400}
                                >
                                <Button
                                    onClick={() => setShowMore(!showMore)}
                                    className={classes.showLessAndMore}
                                    style={{ width: '150px' }}
                                >
                                    <label style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        minWidth: '35px',
                                        maxWidth: '100px',
                                    }}>
                                    {showMore
                                        ? t('LessDetails')
                                        : t('MoreDetails')}
                                    </label>
                                    <ArrowIcon
                                        style={{ marginLeft: '8px' }}
                                        color="#fff"
                                        direction={showMore ? 'up' : 'down'}
                                    />
                                </Button>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.riskIndicatorBox}>
                    <RiskIndicator
                        variant={risk.riskLevel}
                        label={t(`${risk.riskLevel}Risk`)}
                        className={classes.riskIndicator}
                    />
                </Box>

                {props.showDecisions && risk.decisions?.length > 0 && (
                    <Box className={classes.actionArea}>
                        {risk.decisions?.map((d: any) => (
                            <Fragment key={d.decisionId}>
                                {d.decisionAction === 'Continue' ? (
                                    <ApproveRisk
                                        globalRiskId={risk.globalRiskId}
                                        onSubmit={(value) => {
                                            const approveReq = {
                                                ...value,
                                                riskId: risk.id,
                                                stepId:
                                                    risk.currentApprovalStepId,
                                                decisionId: d.decisionId,
                                            }
                                            return submitRiskDecision(
                                                approveReq,
                                            )
                                        }}
                                        decisionFriendlyName={
                                            d.decisionFriendlyName
                                        }
                                        isLoading={isSubmitting}
                                    />
                                ) : (
                                    <RevokeButton
                                        size={'small'}
                                        onClick={() => {
                                            const revokeReq = {
                                                riskId: risk.id,
                                                stepId:
                                                    risk.currentApprovalStepId,
                                                decisionId: d.decisionId,
                                            }
                                            submitRiskDecision(revokeReq)
                                        }}
                                        loading={isSubmitting}
                                    >
                                        {d.decisionFriendlyName}
                                    </RevokeButton>
                                )}
                            </Fragment>
                        ))}
                    </Box>
                )}
            </Box>
            <Collapse in={showMore} collapsedSize={0}>
                <Box className={classes.contentClass}>
                    {risk.violations.map((violation: any, index: number) => (
                        <Fragment key={violation.id}>
                            {index !== 0 && (
                                <Box
                                    className={classNames(
                                        classes.sectionDivider,
                                        classes.removePadding,
                                    )}
                                />
                            )}
                            <Box className={classes.riskContainer}>
                                <RiskViolationLeftRight
                                    page={props.page}
                                    requestId={props.requestId}
                                    itemId={props.itemId}
                                    violation={violation}
                                />
                            </Box>
                        </Fragment>
                    ))}
                </Box>
            </Collapse>
        </Box>
    );
}

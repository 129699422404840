import { useState } from 'react'
import { Box, Collapse, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ShowHideButton from './Button/ShowHideButton'

const useStyles = makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#282828',
        fontSize: '16px',
        fontWeight: 'bold',
        paddingLeft: '32px',
        paddingRight: '24px',
        height: '60px',
        textTransform: 'uppercase',
        '& .MuiChip-root': {
            height: '20px',
            color: '#ffffff',
            fontWeight: 'normal',
            fontSize: '14px',
            backgroundColor: '#8b909a',
            marginLeft: '8px',
        },
    },
})

const CollapseableSectionContainer = ({ children, label, count }) => {
    const classes = useStyles()
    const [expanded, setExpanded] = useState(false)

    return (
        <Box>
            <Box className={classes.header}>
                <Box>
                    {label}
                    <Chip size="small" label={count} />
                </Box>

                <ShowHideButton
                    expanded={expanded}
                    onClick={() => setExpanded((prev) => !prev)}
                />
            </Box>

            <Collapse in={expanded} timeout={300} unmountOnExit>
                <Box
                    borderBottom="solid 1px #d8dadd"
                    paddingRight="18px"
                    paddingTop="10px"
                >
                    {children}
                </Box>
            </Collapse>
        </Box>
    )
}

export default CollapseableSectionContainer

import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography, ListItem, Checkbox, useTheme } from '@mui/material'
import React, { Fragment } from 'react'
import { CheckBoxIcon, CheckedBlueBoxIcon, Icon } from 'packages/eid-icons'
import { Tooltip } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import InfoTooltip from '../InfoTooltip'

const listItemStyles = makeStyles(() => ({
    root: () => ({
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
        borderLeft: '3px solid transparent',

        '&:hover': {
            backgroundColor: 'inherit',
        },
        '& span': {
            paddingTop: '0',
            paddingBottom: '0',
        },
        '& p': {
            width: '100%',
            padding: '0 0.5rem',

            borderRadius: '0.5rem',
            '&:hover': {
                backgroundColor: '#f1f1f4',
            },
        },
        '& .search-highlight2': {
            color: '#950167',
            fontWeight: 600,
            textDecoration: 'underline',
        },
    }),
}))
const useStyles = makeStyles(() => ({
    infoIcon: {
        height: '1.6rem',
        '&:hover': {
            '& path': {
                fill: '#307fc1',
            },
        },
    },
    activeListItem: {
        background: '#fafcff',
        borderLeft: '0.3rem solid #307fc1',
        color: '#000 !important',
        '& .MuiBox-root': {
            '& p': {
                background: '#fff !important',
                color: '#000 !important',
            },
        },
    },
}))
const checkboxStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
})

const highlight = (text, searchKey) => {
    searchKey = new RegExp('(' + searchKey + ')', 'gi')
    return text.split(searchKey).map((o, i) =>
        i % 2 === 1 ? (
            <span className="search-highlight2" key={i}>
                {o}
            </span>
        ) : (
            o
        ),
    )
}

const CheckBoxListItem = ({
    item,
    highlightSelected,
    handleToggle,
    checked,
    labelId,
    itemContainerHeight,
    highlightText,
    showInfoTooltip,
}) => {
    const itemClasses = listItemStyles({ itemContainerHeight })
    const checkboxClasses = checkboxStyles()
    const infoClasses = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()
    const isChecked = (item) => {
        return !item ? false : Boolean(checked.find((c) => c.id === item.id))
    }

    return (
        <ListItem
            role={undefined}
            button
            disabled={item?.IsReadOnly}
            onClick={() => handleToggle(item)}
            classes={itemClasses}
            className={
                highlightSelected && isChecked(item)
                    ? infoClasses.activeListItem
                    : ''
            }
        >
            <Box display="flex" alignItems="center">
                <Box>
                    <Checkbox
                        edge="start"
                        color="primary"
                        classes={checkboxClasses}
                        icon={
                            <Box display="flex" alignItems="center">
                                <CheckBoxIcon />
                            </Box>
                        }
                        checkedIcon={
                            <Box display="flex" alignItems="center">
                                <CheckedBlueBoxIcon />
                            </Box>
                        }
                        checked={isChecked(item)}
                        tabIndex={-1}
                        disableRipple
                        disabled={item?.IsReadOnly}
                        disableFocusRipple
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="body2"
                        style={{
                            fontSize: '1.3rem',
                            wordBreak: 'break-word',
                            wordWrap: 'break-word',
                            overflow: 'auto',
                            fontWeight: '700',
                        }}
                    >
                        {highlightText && highlightText.length
                            ? highlight(item.friendlyName, highlightText)
                            : item.friendlyName}
                    </Typography>
                    {showInfoTooltip && (
                        <InfoTooltip
                            friendlyName={item?.friendlyName}
                            description={item?.description}
                        />
                    )}
                </Box>
            </Box>
        </ListItem>
    )
}

export default CheckBoxListItem

import { Box } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles(() => {
    const theme = useTheme()
    return {
        expandIcon: {
            width: '1.6rem',
            height: '1.6rem',
            marginLeft: '0.8rem',
            marginTop: '-0.1rem',
            color: theme?.palette?.primary?.main,
        },
        expandButton: {
            textTransform: 'uppercase',
            alignItems: 'center',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    }
})

const ShowHideButton = (props) => {
    const {
        expanded,
        onClick,
        withLabel = true,
        showLabel = 'Show',
        hideLabel = 'Hide',
    } = props
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            fontSize="12px"
            fontWeight="bold"
            color="#307fc1"
        >
            <Box
                component="span"
                display="flex"
                className={classes.expandButton}
                onClick={onClick}
            >
                {withLabel && (expanded ? t(hideLabel) : t(showLabel))}
                <Box className={classes.expandIcon}>
                    {expanded ? (
                        <Icon
                            name="MinusCircle"
                            width="1.6rem"
                            height="1.6rem"
                        />
                    ) : (
                        <Icon
                            name="PlusCircle"
                            width="1.6rem"
                            height="1.6rem"
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default ShowHideButton

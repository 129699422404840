import { Box, Button, Popover } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import { useRiskMitigation } from 'hooks'
import moment from 'moment'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles(
    createStyles({
        mitigationbuttonbox: {
            position: 'relative',
        },
        customtooltip: {
            boxShadow: ' 0 2px 16px 0 rgba(0, 0, 0, 0.15)',
            background: '#fff',
            padding: '24px 24px',
            width: '300px',
            zIndex: 99,
            borderRadius: '4px',
        },
        customtooltippopover: {
            marginTop: '30px',
            marginLeft: '35px',
            boxShadow: 'none !important',
            background: 'transparent !important',
            '& .MuiPopover-paper': {
                background: 'red !important',
                maxHeight: '100%',
                overflow: 'inherit !important',
            },
        },

        customtooltipArrow: {
            position: 'relative',
            '&:before': {
                content: "''",
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: '10px solid #fff',
                position: 'absolute',
                top: '-34px',
                left: '30%',
            },
        },
        customtooltipinner: {
            marginBottom: '16px',
            '& span': {
                fontSize: '12px',
                color: '#9b9b9b',
                textTransform: 'uppercase',
            },
            '& p': {
                fontSize: '14px',
                color: '#000',
                marginTop: '5px',
            },
        },
    }),
)

export default function MitigationDetails({ riskViolatorMitigationId }: any) {
    const classes = useStyles()
    const { t } = useTranslation()

    const [anchorEl, setAnchorEl] = React.useState(null)

    const open = Boolean(anchorEl)

    const { data: mitigationData, isLoading } = useRiskMitigation(
        riskViolatorMitigationId,
        open,
    )

    const handleClose = () => {
        setAnchorEl(null)
    }

    const showMitigations = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <Box className={classes.mitigationbuttonbox}>
            <Button onClick={(e) => showMitigations(e)}>
                {t('MitigationDetails')}{' '}
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className={classes.customtooltippopover}
                disableScrollLock={true}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box className={classes.customtooltip}>
                    <Box className={classes.customtooltipArrow}>
                        {isLoading ? (
                            <Box>
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    height={8}
                                    style={{ marginTop: 2, marginBottom: 2 }}
                                />
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    height={8}
                                    style={{ marginTop: 4, marginBottom: 4 }}
                                />
                            </Box>
                        ) : mitigationData ? (
                            <>
                                <Box className={classes.customtooltipinner}>
                                    <span>{t('Mitigation')}</span>
                                    <p>{mitigationData?.name || '---'}</p>
                                </Box>
                                <Box className={classes.customtooltipinner}>
                                    <span>{t('StartDate')}</span>
                                    <p>
                                        {mitigationData?.validFrom
                                            ? moment
                                                  .utc(mitigationData.validFrom)
                                                  .local()
                                                  .format('LLL')
                                            : '---'}
                                    </p>
                                </Box>
                                <Box className={classes.customtooltipinner}>
                                    <span>{t('EndDate')}</span>
                                    <p>
                                        {mitigationData?.validUntil
                                            ? moment
                                                  .utc(
                                                      mitigationData.validUntil,
                                                  )
                                                  .local()
                                                  .format('LLL')
                                            : '---'}
                                    </p>
                                </Box>
                                <Box className={classes.customtooltipinner}>
                                    <span>{t('Comments')}</span>
                                    <p>{mitigationData?.comments || '---'}</p>
                                </Box>
                            </>
                        ) : (
                            <Box>{t('NoDataFound')}</Box>
                        )}
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
}

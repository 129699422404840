import ImageSrc from './session-expired-image.png'
import { styled, Typography, Link } from '@mui/material'
import config from 'config'

const Container = styled('div')({
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '8rem 0',
    overflow: 'auto',
})

const TextContainer = styled('div')({
    margin: 'auto',
    textAlign: 'center',
    marginBottom: '6.4rem',
})

const HeadingText = styled(Typography)({
    color: '#3f3767',
    lineHeight: 1,
    fontSize: '7.2rem',
    marginBottom: '32px',
    '@media (max-width:960px)': {
        fontSize: '4rem',
    },
})

const StyledImg = styled('img')({
    margin: 'auto',
    height: '50vh',
    '@media (max-width:960px)': {
        height: '30vh !important',
    },
})

const SessionExpiredPage = (props) => {
    const { title, description, linkText } = props
    return (
        <Container>
            <TextContainer>
                <HeadingText variant="h1">{title}</HeadingText>
                <Typography
                    style={{
                        color: '#000000',
                        fontSize: '2.4rem',
                        lineHeight: 1.33,
                    }}
                >
                    {description}
                    <Link
                        component="a"
                        underline="none"
                        href="/signin"
                        style={{
                            color: '#453fbb',
                            fontSize: '1.5vw',
                            lineHeight: '1.5vw',
                            fontWeight: 300,
                            marginLeft: '4px',
                        }}
                    >
                        {linkText}
                    </Link>
                </Typography>
            </TextContainer>

            <StyledImg
                src={`${config.APP_SUBPATH}${ImageSrc}`}
                alt={title}
            ></StyledImg>
        </Container>
    )
}

export default SessionExpiredPage

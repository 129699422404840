import React from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallbackRender()
        }

        return this.props.children
    }
}

ErrorBoundary.propTypes = {
    fallbackRender: PropTypes.func.isRequired,
}

export default ErrorBoundary

import React from 'react'
import { Icon, EidLogoIcon } from 'packages/eid-icons'
import { Header } from 'packages/eid-ui'
import { styled, Typography, Box, Link } from '@mui/material'
import LanguageMenu from './LanguageMenu'
import AccountDropdown from './AccountDropdown'
import appConfig from 'config'
import { useEnvironment } from 'hooks'

const itemStyles = {
    minWidth: '30px',
    padding: '0px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const styles = {
    environmentText: {
        fontSize: '8px',
        padding: '0px 1.5px',
        maxWidth: '58px',
    },
    dummyText: {
        fontSize: '8px',
        padding: '0px 1.5px',
        maxWidth: '58px',
        opacity: 0,
    },
}
const StyledChip = styled('div')({
    position: 'absolute',
    top: '0px',
    left: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff6100 !important',
    color: '#ffffff',
    height: '13px',
    maxWidth: '58px',
    minWidth: '24px',
    transform: 'skew(-20deg)',
    borderRadius: '2px',
})

const AppMobileHeader = ({ config }) => {
    const height = config.mobile?.height
    const { data: env } = useEnvironment()
    const envName = env?.environmentName
    return (
        <Header.Mobile bgColor={config.bgColor} height={height}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                }}
            >
                <Link
                    href={`${appConfig.APP_SUBPATH}/`}
                    underline="none"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Header.Item style={{ ...itemStyles, padding: 0 }}>
                        {config.appLogo.type === 'base64' ? (
                            <img
                                style={config.appLogo.style}
                                src={config.appLogo.value}
                                alt={config.appLogo.name}
                            />
                        ) : config.showEidLogo ? (
                            <EidLogoIcon />
                        ) : (
                            <Icon
                                style={config.appLogo.style}
                                name={config.appLogo.value}
                            />
                        )}
                    </Header.Item>
                </Link>

                {config.showEnvironment && envName && (
                    <Box position="relative" paddingX="8px" title={envName}>
                        <Box component="span" opacity={0}>
                            <Typography noWrap style={styles.dummyText}>
                                {envName}
                            </Typography>
                        </Box>

                        <StyledChip>
                            <Typography noWrap style={styles.environmentText}>
                                {envName}
                            </Typography>
                        </StyledChip>
                    </Box>
                )}

                <Header.RightSection>
                    <Header.Item style={itemStyles}>
                        <LanguageMenu
                            showCone={false}
                            anchorPosition={{
                                right: '-32px',
                                top: '54px',
                            }}
                        />
                    </Header.Item>
                    <Header.Item style={itemStyles}>
                        <AccountDropdown />
                    </Header.Item>
                </Header.RightSection>
            </div>
        </Header.Mobile>
    )
}
export default AppMobileHeader

import { useQuery } from 'react-query'
import useAxios from './useAxios'

const useQueryApiGet = (keysArray = [], url, queryConfig = {}) => {
    const callApi = useAxios()
    return useQuery(
        keysArray,
        () =>
            callApi({
                method: 'GET',
                url: url,
            }).then((data) => data.data),
        queryConfig,
    )
}

export default useQueryApiGet

import { FC } from 'react'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'packages/eid-ui'
import Button from './index'

const getStyles = (loading: any) => {
    return {
        minWidth: '130px',
        minHeight: '24px',
        maxHeight: '24px !important',
        fontSize: '11px',
        backgroundColor: 'transparent',
        padding: '2px 8px 2px 8px',
        color: '#ffffff',
        border: '1px solid #ffffff',
        '&:hover': {
            boxShadow: '0 2px 8px 0 rgba(48, 127, 193, 0.33)',
            backgroundColor: 'transparent',
            color: '#ffffff',
            '& svg': {
                color: '#ffffff',
            },
        },
        '&:active': {
            backgroundColor: '#307fc1',
            color: '#ffffff',
            '& svg': {
                color: '#ffffff',
            },
            boxShadow:
                '0 2px 8px 0 rgba(48, 127, 193, 0.33), inset 2px 2px 4px 0 rgba(0, 0, 0, 0.11)',
        },
    }
}

const styles = {
    disabled: {
        color: '#aab04 !important',
        border: 'solid 1px #aab0b4 !important',
    },
}

export interface IRefreshButtonProps {
    isRefreshing: boolean
    loading?: boolean
    disabled?: boolean
    onClick: () => Promise<any>
}

export const RefreshButton: FC<IRefreshButtonProps> = (props) => {
    const { t } = useTranslation()

    const { loading, disabled, isRefreshing, ...rest } = props
    return (
        <Button
            size="small"
            loading={loading}
            disabled={isRefreshing}
            style={getStyles(loading)}
            disabledStyles={styles.disabled}
            startIcon={!isRefreshing && <Icon name="Refresh" color="#ffffff" />}
            {...rest}
        >
            {isRefreshing ? (
                <Spinner size={0.6} color="#ffffff" />
            ) : (
                t('RefreshViolations')
            )}
        </Button>
    )
}

export const initialState = {
    appConfig: null,
    devFeaturesEnabled: false,
    itemsView: 'table',
}
const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_MULTIPLE_PROPS':
            return {
                ...state,
                ...payload,
            }

        case 'SET_PROP':
            return {
                ...state,
                [payload.key]: payload.value,
            }
        case 'SET_APP_CONFIG':
            return {
                ...state,
                appConfig: payload,
            }
        case 'ENABLE_DEV_FEATURES':
            return {
                ...state,
                devFeaturesEnabled: true,
            }
        case 'SET_ITEMS_VIEW':
                return {
                    ...state,
                    itemsView: payload,
                }
        default:
            return state
    }
}

export default reducer

import React, { Fragment, useState } from 'react'
import { Box, Collapse, Link, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandIconPlusMinus } from './ExpandIcon'
import { Icon } from 'packages/eid-icons'
import { ArrowIcon } from 'components/ProcessSteps/Icons'
import { useTranslation } from 'react-i18next'
import appConfig from 'config'
import classNames from 'classnames'
import {
    useAllApplications,
    useMembershipDirectAssigned,
    useRequest,
    useRequestItemAccessRecipient,
} from 'hooks'
import { Skeleton } from '@mui/material';
import { useUrlGenerator } from 'packages/core'

const useStyles = makeStyles(
    createStyles({
        businessrole: {
            '& h4': {
                fontSize: '12px',
                fontWeight: '600',
                marginBottom: '5px',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
            },
            '& p': {
                fontSize: '11px !important',
                fontWeight: '600 !important',
                color: '#ff6100  !important',
                textTransform: 'capitalize',
                marginTop: '4px',
            },
        },
        businessroleheader: {
            borderBottom: '1px solid #d8d8d8',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxSizing: 'border-box',
            padding: '16px 15px',
        },
        expandbtn: {
            marginLeft: '10px',
            '& div': {
                borderColor: '#307fc1',
                color: '#307fc1',
                boxShadow: '0 2px 8px rgba{0, 0, 0, 0.15 }',
            },
        },
        businessroleheaderleft: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '190px',
            width: '100%',
        },
        businessroleheaderfullleft: {},
        businessroleheaderright: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '75%',
            marginTop: '8px',
        },
        businessRequestTypeMain: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '77%',
        },
        businessRequestTypeFullMain: {
            display: 'flex',
            justifyContent: 'space-between',
            width: 'auto !important',
        },
        businessRequestType: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: 'fit-content',
            color: '#000',
            border: 'solid 1px #aab0b4',
            padding: '4px 8px',
            fontSize: '12px',
            fontWeight: 'bold',
            borderRadius: '5px',
            textTransform: 'uppercase',
            backgroundColor: '#ffffff',
            fontFamily: 'Rubik',
        },
        businessroleinnercustom: {
            maxWidth: '190px',
            width: '100%',
        },
        businessroleinnercollapse: {},
        businessroleinner: {
            padding: '16px 16px 16px 24px',
            background: ' rgba(239, 239, 241, 0.4)',

            '& p': {
                color: '#3eaa05 !important',
            },
            '& button': {
                '& span': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                },
            },
        },
        businessroledivider: {
            height: '1px',
            background: '#d8d8d8',
        },

        riskIndicator: {
            backgroundColor: '#fdf1f3',
            opacity: 1,
            fontWeight: 'bold',
        },
        arrowbox: {
            width: '34px',
            marginTop: '6px',
        },
        businessroleheadericon: {
            display: 'inline',
            '& svg': {
                width: '16px',
                height: '16px',
                position: 'relative',
                top: '2px',
            },
        },
        businessrolebtn: {
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            '& a': {
                color: '#9230c5',
                fontSize: '12px',
                fontWeight: '600',
            },
            '& p': {
                color: '#3eaa05',
                fontSize: '12px !important',
            },
        },
        businessRequestLabel: {},
        memberLink: {
            background: '#307fc1',
            borderRadius: '5px',
            fontSize: '12px',
            color: '#fff',
            fontWeight: 600,
            textDecoration: 'none',
            padding: '2px 10px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            textTransform: 'uppercase',
        },
        businessroleheadermember: {
            maxWidth: '225px',
        },
        fullWidthRisk: {
            maxWidth: '100%',
            width: '100%',
        },
        textGreen: {
            '& p': {
                color: '#3eaa05 !important',
            },
        },
        businessroleheaderfullwidth: {
            marginTop: '4px !important',
        },

        requestText: {
            color: '#626263 !important',
            fontSize: '11px !important',
            textTransform: 'capitalize',
            fontWeight: 600,
        },
        purpleText: {
            color: '#9230c5 !important',
        },

        orangeText: {
            color: '#ff6100 !important',
        },
        greenText: {
            color: '#3eaa05 !important',
        },
    }),
)
const RiskFunctions = ({
    isMembership,
    requestId,
    riskFunction,
    isSOD,
    requestType,
    itemId,
}: any) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [expanded, setExpended] = useState(false)
    const { data: targetPerson } = useRequest(
        requestType === 'businessRequests' ? requestId : '',
    )
    const { data: taregtPersonItems } = useRequestItemAccessRecipient(
        requestType === 'businessRequestitems' ? itemId : '',
        requestId,
    )
    const businessRequestRiskAssigneeDetails =
        riskFunction.businessRequestRiskAssigneeDetails
    const { getItShopUrl } = useUrlGenerator(useAllApplications)

    const { data: memberShipData, isLoading } = useMembershipDirectAssigned(
        expanded && isMembership ? riskFunction.riskFunctionAssigneeId : '',
        targetPerson?.subjectId || taregtPersonItems?.id,
    )

    const renderRequestNumber = (risk: any) => {
        const isCurrentRequest = requestId === risk.requestId
        if (isCurrentRequest) {
            return (
                <Box className={classes.businessrolebtn}>
                    <Box className={classes.businessRequestType}>
                        {t('ThisRequest')}
                    </Box>
                    <Typography>#{risk.requestNumber}</Typography>
                </Box>
            )
        } else {
            return (
                <Box className={classes.businessrolebtn}>
                    <Box className={classes.businessRequestType}>
                        {t('OtherRequest')}
                    </Box>
                    <Link
                        color={'inherit'}
                        underline={'always'}
                        target={'_blank'}
                        href={`${appConfig.APP_SUBPATH}/myRequests/businessRequests?id=${risk.requestId}`}
                    >
                        {risk.requestNumber
                            ? `#${risk.requestNumber}`
                            : t('GoToRequest')}
                    </Link>
                </Box>
            )
        }
    }

    const bindBusinessRequestItems = () => {
        return (
            <>
                {businessRequestRiskAssigneeDetails?.length
                    ? bindBusinessRiskItems()
                    : null}
            </>
        )
    }

    const bindBusinessRiskItems = () => {
        return businessRequestRiskAssigneeDetails.map(
            (item: any, index: number) => (
                <Fragment key={`business-risk-items-${index}`}>
                    <Box className={classes.businessroleinner}>
                        <Box display="flex" style={{ width: '100%' }}>
                            <Box
                                className={classNames(
                                    classes.businessroleinnercustom,
                                    {
                                        [classes.fullWidthRisk]: !isSOD,
                                    },
                                )}
                            >
                                <Typography variant="h4" component="h4">
                                    <Box>
                                        <Icon
                                            name={item.resourceTypeRequested}
                                        />
                                    </Box>
                                    {t(`${item.resourceTypeRequested}`)}
                                </Typography>
                                <span
                                    className={classNames(classes.requestText, {
                                        [classes.purpleText]:
                                            requestId !== item.requestId,
                                        [classes.greenText]:
                                            requestId === item.requestId,
                                    })}
                                >
                                    {item.resourceRequested}
                                </span>
                            </Box>
                            <Box
                                className={classNames(
                                    classes.businessroleheaderright,
                                    {
                                        [classes.businessroleheaderfullwidth]: !isSOD,
                                    },
                                )}
                            >
                                <Box
                                    marginRight="6px"
                                    className={classes.arrowbox}
                                >
                                    <ArrowIcon variant={'Approved'} />
                                </Box>
                                <Box>{renderRequestNumber(item)}</Box>
                            </Box>
                        </Box>
                    </Box>

                    {index < businessRequestRiskAssigneeDetails.length && (
                        <Box
                            style={{
                                padding: '0 16px',
                            }}
                        >
                            <Box className={classes.businessroledivider}></Box>
                        </Box>
                    )}
                </Fragment>
            ),
        )
    }

    const bindMembershipItems = () => {
        return <>
            {isLoading ? (
                <Box
                    width={'100%'}
                    style={{
                        padding: '0 16px',
                    }}
                >
                    <Box my={2}>
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={24}
                        />
                    </Box>
                    <Box my={2}>
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={24}
                        />
                    </Box>
                </Box>
            ) : memberShipData?.length && !isLoading ? (
                <Box>{bindMembershipRiskItems()}</Box>
            ) : null}
        </>;
    }

    const showMemberShipButton = (resourceType: any) => {
        try {
            if (!resourceType) {
                return false
            }

            return resourceType === 'ApplicationRole' ||
                resourceType === 'BusinessRole' ||
                resourceType === 'ManagementRole'
                ? true
                : false
        } catch (err: any) {
            return false
        }
    }

    const bindMembershipRiskItems = () => {
        return memberShipData.map((item: any, index: number) => (
            <Fragment key={`membership-risk-${index}`}>
                <Box className={classes.businessroleinner}>
                    <Box display="flex" style={{ width: '100%' }}>
                        <Box
                            className={classNames(
                                classes.businessroleinnercustom,
                                {
                                    [classes.fullWidthRisk]: !isSOD,
                                },
                            )}
                        >
                            <Typography variant="h4" component="h4">
                                <Box>
                                    <Icon name={item.resourceType} />
                                </Box>
                                {t(`${item.resourceType}`)}
                            </Typography>
                            <span
                                className={classNames(
                                    classes.requestText,
                                    classes.orangeText,
                                )}
                            >
                                {item.previewName}
                            </span>
                        </Box>
                        <Box
                            className={classNames(
                                classes.businessroleheaderright,
                                {
                                    [classes.businessroleheaderfullwidth]: !isSOD,
                                },
                            )}
                        >
                            {showMemberShipButton(item.resourceType) ? (
                                <>
                                    <Box
                                        marginRight="6px"
                                        className={classes.arrowbox}
                                    >
                                        <ArrowIcon variant={'Approved'} />
                                    </Box>
                                    <a
                                        href={`${getItShopUrl()}/${
                                            item.resourceType
                                        }s/manageAccess?selected=${item.id}`}
                                        target="blank"
                                        className={classes.memberLink}
                                    >
                                        {t('Membership')}
                                        <Icon
                                            name="EditFileWhite"
                                            color="#fff"
                                            width="16px"
                                            height="16px"
                                        />
                                    </a>
                                </>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
                {index < memberShipData?.length && (
                    <Box>
                        <Box className={classes.businessroledivider}></Box>
                    </Box>
                )}
            </Fragment>
        ))
    }

    return (
        <>
            <Box className={classes.businessrole}>
                <Box className={classes.businessroleheader}>
                    <Box
                        display="flex"
                        style={{
                            width: '100%',
                            alignItems: 'flex-start',
                            gap: '10px',
                        }}
                    >
                        <Box
                            className={classNames(
                                classes.businessroleheaderleft,
                                {
                                    [classes.businessroleheadermember]: isMembership,
                                    [classes.fullWidthRisk]: !isSOD,
                                    [classes.textGreen]: !isMembership,
                                },
                            )}
                        >
                            <Box alignItems="flex-start">
                                <Typography variant="h4" component="h4">
                                    <Box
                                        className={classNames(
                                            classes.businessroleheadericon,
                                        )}
                                    >
                                        <Icon
                                            name={riskFunction.resourceType}
                                        />
                                    </Box>
                                    {t(`${riskFunction.resourceType}`)}
                                </Typography>
                                <span
                                    className={classNames(classes.requestText, {
                                        [classes.orangeText]: isMembership,
                                    })}
                                >
                                    {riskFunction.riskFunctionAssignee}
                                </span>
                            </Box>
                        </Box>
                        <Box className={classes.businessroleheaderright}>
                            <Box marginRight="6px" className={classes.arrowbox}>
                                <ArrowIcon variant={'Approved'} />
                            </Box>
                            <Box
                                className={classNames(
                                    classes.businessRequestTypeMain,
                                    {
                                        [classes.businessRequestTypeFullMain]: !isSOD,
                                        [classes.businessRequestLabel]: !isMembership,
                                    },
                                )}
                            >
                                <Box className={classes.businessRequestType}>
                                    {/* Will enable once we have clarity on this one */}
                                    {/* {t(isMembership ? 'Membership' : (checkIfThisRequest() ? 'ThisRequest' : 'OtherRequest'))} */}
                                    {t(
                                        isMembership
                                            ? 'Membership'
                                            : 'BusinessRequest',
                                    )}
                                </Box>
                                <Box className={classes.expandbtn}>
                                    <ExpandIconPlusMinus
                                        expanded={expanded}
                                        onClick={() => {
                                            setExpended(!expanded)
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Collapse
                    in={expanded}
                    className={classes.businessroleinnercollapse}
                >
                    {expanded ? (
                        isMembership ? (
                            bindMembershipItems()
                        ) : (
                            bindBusinessRequestItems()
                        )
                    ) : (
                        <></>
                    )}
                </Collapse>
            </Box>
        </>
    )
}

export default RiskFunctions

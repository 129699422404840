import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { Select } from 'packages/eid-ui'
import { usePredefinedComment } from 'hooks'

const PredefinedComments = ({
    selectedJustification,
    setSelectedJustification,
    setDecisionComment,
}) => {
    const { t } = useTranslation()
    const [options, setOptions] = useState([])
    const { isLoading: loadingPredefined, data: predefinedComments } =
        usePredefinedComment()

    const DEFAULT_PREDEFINED_COMMENT_OPTION = {
        id: 'other',
        name: 'Other',
        value: t('Common_Other'),
    }

    useEffect(() => {
        if (predefinedComments?.length) {
            const allOptions = [
                ...predefinedComments,
                DEFAULT_PREDEFINED_COMMENT_OPTION,
            ]
            setOptions(allOptions)
        } else {
            setOptions([DEFAULT_PREDEFINED_COMMENT_OPTION])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [predefinedComments])

    const getOptionLabel = (option) =>
        option?.localizedName ? option?.localizedName : option?.value

    return (
        <Box
            style={{
                padding: '0 24px',
            }}
        >
            <Select
                placeholder={t('Common_PleaseSelectJustification')}
                options={options}
                loading={loadingPredefined}
                onChange={(selectedItem) => {
                    setSelectedJustification(selectedItem)
                    if (selectedItem?.name === 'Other') {
                        setDecisionComment('')
                    } else {
                        setDecisionComment(selectedItem?.value)
                    }
                }}
                value={selectedJustification}
                getOptionLabel={getOptionLabel}
            />
        </Box>
    )
}

export default PredefinedComments

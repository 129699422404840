import { ReactComponent as EmailRound } from './icon.svg'
import PropTypes from 'prop-types'
export default EmailRound

EmailRound.defaultProps = {
    color: '#E45887',
}

EmailRound.propTypes = {
    color: PropTypes.string,
}

import { queryCache } from 'react-query'
import { REQUESTS_KEY } from './requestHooks'
import { REQUEST_ITEMS_KEY } from './requestItemHooks'
import { WORKFLOW_ITEMS_KEY } from './workflowHooks'
import * as R from 'ramda'
import {
    IApprovalStep,
    IItemWithApprovalSteps,
    IItemWithRisks,
    IndentationLevel,
    IRequestWithApprovalSteps,
    RiskReason,
} from 'types'
import { roundOffNumber } from 'utils'
import { getStatusColor } from '../components/ProcessSteps/utils'

export const refreshRequest = (requestId: string) => {
    queryCache.invalidateQueries(
        (q) =>
            q.queryKey.includes(REQUESTS_KEY) && q.queryKey.includes(requestId),
    )
}

export const refreshWorkflow = () => {
    queryCache.invalidateQueries(
        (q) =>
            q.queryKey.includes(WORKFLOW_ITEMS_KEY) &&
            q.queryKey.includes('GET_SINGLE'),
    )
}

export const refreshRequestItemsByItemType = (
    requestId: string,
    itemTypeId: string,
) => {
    queryCache.invalidateQueries(
        (q) =>
            q.queryKey.includes(REQUESTS_KEY) &&
            q.queryKey.includes(requestId) &&
            q.queryKey.includes(itemTypeId) &&
            q.queryKey.includes('REQUEST_ITEMS_BY_ITEM_TYPE'),
    )
}
export const refreshRequestItem = (requestItemId: string) => {
    queryCache.invalidateQueries(
        (q) =>
            q.queryKey.includes(REQUEST_ITEMS_KEY) &&
            q.queryKey.includes(requestItemId),
    )
}

export const refreshRequestOrRequestItem = (
    page: string,
    requestId: string,
    requestItemId?: string,
) => {
    if (page === 'Requests') {
        refreshRequest(requestId)
    } else {
        refreshRequestItem(requestItemId as string)
    }
}

export const refreshComments = (
    page: string,
    tokenExpiresAt: string,
    requestId: string,
    requestItemId?: string,
) => {
    if (page === 'Requests') {
        queryCache.invalidateQueries(
            (q) =>
                q.queryKey.includes(REQUESTS_KEY) &&
                q.queryKey.includes(requestId) &&
                q.queryKey.includes(tokenExpiresAt) &&
                (q.queryKey.includes('REQUEST_COMMENTS') ||
                    q.queryKey.includes('REQUEST_View_ITEM_COMMENTS')),
        )
    } else {
        queryCache.invalidateQueries(
            (q) =>
                q.queryKey.includes(REQUEST_ITEMS_KEY) &&
                q.queryKey.includes(requestItemId) &&
                q.queryKey.includes(tokenExpiresAt) &&
                q.queryKey.includes('REQUEST_ITEM_COMMENTS'),
        )
    }
}

const toItemWithRisks: (requestId: string, input: any) => IItemWithRisks = (
    requestId,
    item,
) => ({
    requestId: requestId,
    id: item.id,
    inverted: item.riskDetailsInversed,
    left: item.riskDetails,
    right: item.segregatedRiskDetails,
    risks: [item],
})

export const groupByItems: (
    requestId: string,
    input: any,
) => IItemWithRisks[] = (requestId, input) => {
    return R.pipe(
        // @ts-ignore
        R.groupBy((x: any) => {
            if (x.riskDetailsInversed) return x.segregatedRiskDetails.itemId
            else return x.itemId
        }),
        R.map((x) => {
            return x.reduce((items: any, item: any) => {
                if (items === undefined) {
                    return { ...toItemWithRisks(requestId, item) }
                } else {
                    items.risks.push(item)
                    return items
                }
            }, undefined)
        }),
        R.values,
        R.flatten,
    )(input) as IItemWithRisks[]
}

export const groupByRisks: (input: any) => any = (input) => {
    const response = R.pipe(
        //@ts-ignore
        R.groupBy((x) => x.localRiskId),
        R.map((x: any) => {
            return x.reduce((obj: any, current: any) => {
                if (obj === undefined) {
                    return {
                        ...current,
                        violations: [current],
                    }
                } else {
                    obj.violations.push(current)
                    return obj
                }
            }, undefined)
        }),
        R.values,
        R.flatten,
    )(input)

    return response
}

export const augmentRisksWithDecisions: any = (items: any, risks: any) => {
    const risksGrouped: any = R.mergeAll(
        risks.map((r: any) => ({
            [r.id]: r,
        })),
    )

    const risksWithDecisions = []
    for (let i = 0; i < items.length; i++) {
        const currentItem = items[i]

        if (!currentItem.riskId) continue

        const relevantRisk = risksGrouped[currentItem.riskId]

        if (!relevantRisk || relevantRisk.mitigationStatus !== 'NotMitigated')
            continue

        risksWithDecisions.push({
            ...relevantRisk,
            itemId: currentItem.id,
            currentApprovalStepId: currentItem.currentApprovalStepId,
            decisions: currentItem.decisions,
            requestDataAssignmentStartDate:
                currentItem.requestDataAssignmentStartDate,
            requestDataAssignmentEndDate:
                currentItem.requestDataAssignmentEndDate,
            requestPolicyId: currentItem.requestPolicyId,
        })
    }

    return risksWithDecisions
}

const convertToSource: (input: string) => RiskReason = (riskFunctionSource) => {
    switch (riskFunctionSource) {
        case 'THIS REQUEST':
        case 'BUSINESS REQUEST':
            return 'BusinessRequest'
        case 'MEMBERSHIP':
            return 'Membership'
        default:
            return 'Unknown'
    }
}

const transformStep: (
    item: any,
    data: any,
    aggregatedRisks: any,
    indentationLevel: IndentationLevel,
) => IApprovalStep = (item, data, aggregatedRisks, indentationLevel) => {
    let statusToUse = data.status

    if (item && item.status === 'Canceled' && data.status === 'Open') {
        statusToUse = item.status
    }

    const step: IApprovalStep = {
        stepNumber: data.stepNumber,
        showMore:
            data.riskTypeID &&
            aggregatedRisks &&
            aggregatedRisks.violations.length > 0,
        parentStepNumber: data.parentStepNumber,
        friendlyName: data.friendlyName,
        autoApprove: data.autoApprove,
        approvedOnUtc: data.approverResponseDate,
        approverType: data.approverType,
        approvalFlowPolicyFriendlyName: item?.approvalFlowPolicyFriendlyName,
        status: statusToUse,
        indentationLevel: indentationLevel,
        stepId:
            data.stepID === '00000000-0000-0000-0000-000000000000'
                ? null
                : data.stepID,
        lastApprovalCount: data.lastApprovalCount,
    }
    if (data.approverPersonId) {
        step.approver = {
            id: data.approverPersonId,
            friendlyName: data.approverPreviewName,
            imageThumbUrl: data.approverPersonImageThumbUrl,
        }
    }
    if (data.riskTypeID) {
        const riskData = []
        if (aggregatedRisks.violations.length > 0) {
            for (
                let index = 0;
                index < aggregatedRisks.violations.length;
                index++
            ) {
                const element = aggregatedRisks.violations[index].step
                riskData.push({
                    riskFriendlyName: element.riskFriendlyName,
                    riskDescription: element.riskDescription,
                    riskViolator: element.riskViolator,
                    riskTypeID: element.riskTypeID,
                    riskLevelID: element.riskLevelID,
                    riskBusinessRequestID: element.riskBusinessRequestID,
                    riskRequestNumber: element.riskRequestNumber,
                    riskFunctionSource: convertToSource(
                        element.riskFunctionSource,
                    ),
                    riskFunctionAssignee: element.riskFunctionAssignee,

                    segregatedRiskBusinessRequestID:
                        element.segregatedRiskBusinessRequestID,
                    segregatedRiskRequestNumber:
                        element.segregatedRiskRequestNumber,
                    riskSegregatedFunctionSource: convertToSource(
                        element.riskSegregatedFunctionSource,
                    ),
                    riskSegregatedFunctionAssignee:
                        element.riskSegregatedFunctionAssignee,
                    riskReason_BusinessRequestID:
                        element.riskReason_BusinessRequestID,
                    riskReason_ResourceFriendlyName:
                        element.riskReason_ResourceFriendlyName,
                    riskApproversAndStatus: {
                        approverFriendlyName: element.approverPreviewName,
                        status: element.status,
                        autoApprove: element.autoApprove,
                    },
                })
            }
        }
        step.risk = riskData
    }

    return step
}

export const groupByLocalRisks: (input: any) => any = (input) => {
    const normalSteps: any = []
    const riskSteps: any = []
    input.forEach((element: any) => {
        if (element.step.localRiskID) {
            riskSteps.push(element)
        } else {
            normalSteps.push(element)
        }
    })
    const response: any = R.pipe(
        //@ts-ignore
        R.groupBy((x) => x.step.localRiskID && x.step.localRiskID),
        R.map((x: any) => {
            return x.reduce((obj: any, current: any) => {
                if (obj === undefined) {
                    return {
                        ...current,
                        violations: [current],
                    }
                } else {
                    obj.violations.push(current)
                    return obj
                }
            }, undefined)
        }),
        R.values,
        R.flatten,
    )(riskSteps)

    const refinedArray = [...normalSteps, ...response]

    return refinedArray
}

const transformItem: (
    data: any,
    indentationLevel: IndentationLevel,
) => IItemWithApprovalSteps = (data, indentationLevel) => {
    const itemWithApprovalSteps: IItemWithApprovalSteps = {
        id: data.businessRequestItemId,
        resourceRequested: data.resourceRequested,
        resourceType: data.resourceType,
        itemTypeActionFriendlyName: data.itemTypeActionFriendlyName,
        preApproved: data.preApproved,
        status: data.status,
        globalApprovalSkipped: data.globalApprovalSkipped,
        indentationLevel: indentationLevel,
        title: data.title,
        friendlyName: data.friendlyName,
        openStartDate: data.openStartDate,
        delayInHours: data.delayInHours,
        processTimeUtc: data.processTimeUtc,
        processStatus: data.processStatus,
        approvedOnUtc: data.approvedOnUtc,
        arrows: [],
        approvalSteps: groupByLocalRisks(data.itemApprovalSteps)
            .sort(
                (a: IApprovalStep, b: IApprovalStep) =>
                    parseFloat(a.stepNumber) - parseFloat(b.stepNumber),
            )
            .map((s: any) =>
                transformStep(
                    data,
                    s.step,
                    s,
                    (indentationLevel + 1) as IndentationLevel,
                ),
            ),
    }
    if (data.claimedApproverId) {
        itemWithApprovalSteps.claimedBy = {
            id: data.claimedApproverId,
            friendlyName: data.claimedApproverFriendlyName,
            imageThumbUrl: '',
        }
    }

    return itemWithApprovalSteps
}

export const transformApprovalStepsResponse: (
    request: any,
    approvalStepsData: any,
    item?: any,
) => IRequestWithApprovalSteps = (request, data, item = undefined) => {
    const percentageToUse = item
        ? item.percentageCompleted
        : request.percentageCompleted

    const requestWithApprovalSteps: IRequestWithApprovalSteps = {
        id: request.id,
        initiator: {
            id: request.initiatorPersonId,
            friendlyName: request.initiatorPersonFriendlyName,
            imageThumbUrl: request.initiatorPersonThumbnailUrl,
        },
        createdDateUtc: request.createdDateUtc,
        percentageCompleted: roundOffNumber(percentageToUse * 100),
        preApproved: data.preApproved,
        status: request.status,
        itemTypeCounts: data.itemTypeCount,
        globalApprovalSteps: data.approvalSteps
            .sort(
                (a: IApprovalStep, b: IApprovalStep) =>
                    parseFloat(a.stepNumber) - parseFloat(b.stepNumber),
            )
            .map((gs: any) =>
                transformStep(
                    {
                        status: request.status,
                    },
                    gs.step,
                    null,
                    0,
                ),
            ),
        items: data.items.map((i: any) => transformItem(i, 1)),
        arrows: [],
    }
    generateArrows(requestWithApprovalSteps)

    return requestWithApprovalSteps
}

const generateArrows: (request: IRequestWithApprovalSteps) => void = (
    request,
) => {
    request.arrows = []

    for (let i = request.globalApprovalSteps.length - 1; i >= 0; i--) {
        const current = request.globalApprovalSteps[i]

        if (i === 0) {
            request.arrows.push({
                start: 'requestedByNode',
                end: `global_${current.stepNumber}`,
                startAnchor: { position: 'bottom', offset: { y: 8 } },
                endAnchor: { position: 'top', offset: { y: -8 } },
                color: getStatusColor(current.status),
            })
        } else {
            const previous = request.globalApprovalSteps[i - 1]

            request.arrows.push({
                start: `global_${previous.stepNumber}`,
                end: `global_${current.stepNumber}`,
                startAnchor: { position: 'bottom', offset: { y: 8 } },
                endAnchor: { position: 'top', offset: { y: -8 } },
                color: getStatusColor(current.status),
            })
        }
    }

    const getLastGlobalApprovalStepStepNumber = () => {
        return request.globalApprovalSteps[
            request.globalApprovalSteps.length - 1
        ].stepNumber
    }

    for (let i = request.items.length - 1; i >= 0; i--) {
        const firstNodeIsGlobal = request.globalApprovalSteps.length > 0

        const currentItem = request.items[i]

        currentItem.arrows.push({
            start: firstNodeIsGlobal
                ? `global_${getLastGlobalApprovalStepStepNumber()}`
                : `requestedByNode`,
            end: `item_${currentItem.id}`,
            startAnchor: { position: 'bottom', offset: { y: 8 } },
            endAnchor: { position: 'left', offset: { x: -7 } },
            color: getStatusColor(currentItem.status),
        })

        for (let j = currentItem.approvalSteps.length - 1; j >= 0; j--) {
            const currentItemStep = currentItem.approvalSteps[j]

            currentItem.arrows.push({
                start: currentItemStep.parentStepNumber
                    ? `item_${currentItem.id}_${currentItemStep.parentStepNumber}`
                    : `item_${currentItem.id}`,
                end: currentItemStep.parentStepNumber
                    ? `item_${currentItem.id}_${currentItemStep.stepNumber}`
                    : `item_${currentItem.id}_${currentItemStep.stepNumber}`,
                startAnchor: { position: 'bottom', offset: { y: 8 } },
                endAnchor: currentItemStep.parentStepNumber
                    ? { position: 'top', offset: { y: -8 } }
                    : { position: 'left', offset: { x: -7 } },
                color: getStatusColor(currentItemStep.status),
            })
        }
    }
}

import {
    Box,
    Checkbox as MuiCheckbox,
    CheckboxProps,
    useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { CheckBoxIcon } from '../../eid-icons'
import { CheckedBoxIcon } from '../../eid-icons'
import React, { forwardRef } from 'react'

const useStyles = makeStyles({
    root: {
        justifyContent: 'start',
        maxWidth: 'fit-content',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
})

const Checkbox = forwardRef<any, CheckboxProps>((props, ref) => {
    const classes = useStyles()
    const theme = useTheme()

    return (
        <MuiCheckbox
            edge="start"
            color="primary"
            classes={classes}
            icon={
                <Box display="flex" alignItems="center">
                    <CheckBoxIcon />
                </Box>
            }
            checkedIcon={
                <Box display="flex" alignItems="center">
                    <CheckedBoxIcon color={theme.palette.primary.main} />
                </Box>
            }
            disableRipple
            disableFocusRipple
            ref={ref}
            {...props}
        />
    )
})

export default Checkbox

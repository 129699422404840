import React from 'react'
import Button from './index'
import { Icon } from 'packages/eid-icons'
import { ReactComponent as DoNothingIcon } from './do-nothing.svg'

const styles = {
    normal: {
        minWidth: '145px',
        backgroundColor: '#7593a0',
        color: '#ffffff',
        '&:hover': {
            boxShadow: '0 2px 8px 0 rgba(117, 147, 160, 0.33), inset 2px 2px 4px 0 rgba(0, 0, 0, 0.11)',
            backgroundColor: '#627a85',
            color: '#ffffff',
            '& svg': {
                color: '#ffffff',
            },
        },
        '&:active': {
            boxShadow: '0 2px 8px 0 rgba(117, 147, 160, 0.33), inset 2px 2px 4px 0 rgba(0, 0, 0, 0.11)',
            backgroundColor: '#627a85',
            color: '#ffffff',
            '& svg': {
                color: '#ffffff',
            },
        },
    },
    disabled: {
        backgroundColor: '#BEC3BE !important',
        color: '#ffffff !important',
    },
}

const DoNothingButton = (props) => {
    const { endIcon, ...rest } = props

    return (
        <Button
            size="large"
            style={styles.normal}
            disabledStyles={styles.disabled}
            startIcon={!endIcon && <DoNothingIcon color="#ffffff" />}
            endIcon={
                endIcon && (
                    <Icon
                        name="Close"
                        color="#ffffff"
                        width="14px"
                        height="14px"
                    />
                )
            }
            {...rest}
        />
    )
}

export default DoNothingButton

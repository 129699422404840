import React from 'react'
import PropTypes from 'prop-types'
import { Avatar as MuiAvatar, useTheme } from '@mui/material'

const spacing = {
    small: 3,
    medium: 4,
    large: 5,
}

const Avatar = ({ size, src, ...rest }) => {
    const theme = useTheme()

    return (
        <MuiAvatar
            sx={{
                position: 'relative',
                isolation: 'isolate',
                width: theme.spacing(spacing[size]),
                height: theme.spacing(spacing[size]),
                overflow: 'visible',
                borderRadius: '100vw',
                boxShadow: 'none !important',
                '& > img, & > svg': {
                    position: 'relative',
                    zIndex: 0,
                    borderRadius: '100vw',
                    boxShadow: 'none !important',
                },
                '&:not(:empty)::after': {
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    zIndex: '-1',
                    inset: 0,
                    transform: 'translate(2.5%, 6%)',
                    borderRadius: '100vw',
                    boxShadow: 'none !important',
                    pointerEvents: 'none',
                    filter: 'blur(5px)',
                    background: theme.palette.grey[400],
                    backgroundImage: `url(${src})`,
                    backgroundSize: 'cover',
                },
            }}
            src={src}
            {...rest}
        />
    )
}

Avatar.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
}

Avatar.defaultProps = {
    size: 'small',
}

export default Avatar

import { FC, Fragment, useState } from 'react'
import { Popover } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { mobileScreenWidth } from 'utils'
import ApproveButton from 'components/Button/ApproveButton'
import { MitigateRisk, MitigationPopoverProps } from './MitigateRisk'
import { Tooltip } from 'packages/eid-ui'

const usePopoverStyles = makeStyles(
    createStyles({
        paper: {
            [`@media (max-width:${mobileScreenWidth})`]: {
                width: '100%',
            },
        },
    }),
)

export const ApproveRisk: FC<MitigationPopoverProps> = (props) => {
    const {
        decisionFriendlyName,
        currentApprovalStepId,
        onSubmit,
        isLoading,
        ...rest
    } = props

    const popoverClasses = usePopoverStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    const showConfirmation = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Fragment>
            <Tooltip title={decisionFriendlyName}>
                <div>
                    <ApproveButton size={'small'} onClick={showConfirmation} />
                </div>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                disableScrollLock={true}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={popoverClasses}
            >
                {open && (
                    <MitigateRisk
                        currentApprovalStepId={currentApprovalStepId}
                        decisionFriendlyName={decisionFriendlyName}
                        onSubmit={(value) =>
                            onSubmit(value).then(() => handleClose())
                        }
                        handleClose={handleClose}
                        isLoading={isLoading}
                        {...rest}
                    />
                )}
            </Popover>
        </Fragment>
    )
}

import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import RequiredLabel from './RequiredLabel'

const useStyles = makeStyles({
    root: {
        background: '#fbfbfd',
        margin: '0',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    header: {
        gap: '1.5rem',
        display: 'flex',
        position: 'relative',
        padding: '1.25rem 3rem 1.5rem',
        alignItems: 'center',
        background: '#fbfbfd',

        '&::after': {
            content: '""',
            position: 'absolute',
            inset: 'auto 0 -1.6rem 0',
            zIndex: 1,
            height: '1.6rem',
            background:
                'linear-gradient(180deg, rgba(0,0,0,0.05), rgba(0,0,0,0))',
        },

        '& h3': {
            fontSize: '1.6rem',
            fontWeight: '600',
            width: '23.2rem',
            color: '#1b1f1f',
        },
    },
})

const FieldItemWrapper = ({
    title,
    fieldTypeScope,
    headerStyle,
    isRequired,
    childStyle,
    children,
}) => {
    const classes = useStyles()
    return (
        <Box className={classes.root}>
            <Box>
                <Box style={headerStyle} className={classes.header}>
                    <Box>
                        <Typography variant="h3">
                            {title}{' '}
                            {fieldTypeScope && (
                                <span>({fieldTypeScope || '-'})</span>
                            )}
                        </Typography>
                        {isRequired && <RequiredLabel />}
                    </Box>
                    <Box sx={childStyle}>{children}</Box>
                </Box>
            </Box>
        </Box>
    )
}

export default FieldItemWrapper

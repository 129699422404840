import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FieldItemWrapper from '../Components/FieldItemWrapper'
import RequiredFieldErrorMessage from '../Components/FieldItemWrapper/RequiredFieldErrorMessage'
import InputWithInnerLabel from '../Components/InputWithInnerLabel'
import { getFieldTypeRequiredErrorMessage } from 'packages/core'
import InfoMessageForWrongJson from '../Components/InfoMessageForWrongJson'
import InfoTooltip from '../Components/InfoTooltip'

const useStyles = makeStyles({
    fieldListWraper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        '& input': {
            boxShadow: 'none',
            fontSize: '15px',
            textTransform: 'capitalize',
            color: '#3b454d',
        },
        '& .MuiFormControl-root': {
            marginTop: '0 !important',
        },
    },
})

const FixedList = ({ fieldType, isFormValidated, valueType }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [errorMessage, setErrorMessage] = useState('')
    useEffect(() => {
        if (isFormValidated) {
            setErrorMessage(getFieldTypeRequiredErrorMessage(fieldType))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated, fieldType])

    return (
        <FieldItemWrapper
            headerStyle={{ alignItems: 'baseline' }}
            title={fieldType?.Name}
            isRequired={fieldType?.IsRequired}
            fieldTypeScope={fieldType?.FieldTypeScope}
        >
            <Box className={classes.fieldListWraper}>
                {fieldType?.[valueType]?.length ? (
                    fieldType?.[valueType]?.map((item, index) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <InputWithInnerLabel
                                key={`field-type-${index}`}
                                value={item?.ValueName}
                                placeholder={t('Common_To')}
                                disabled={true}
                                label={`${item?.FriendlyName}: `}
                            />
                            <InfoTooltip
                                friendlyName={item?.FriendlyName}
                                description={item?.Description}
                            />
                        </Box>
                    ))
                ) : (
                    <InfoMessageForWrongJson />
                )}
                <Box paddingLeft={'.2rem'}>
                    {Boolean(errorMessage) && (
                        <RequiredFieldErrorMessage errorMsgKey={errorMessage} />
                    )}
                </Box>
            </Box>
        </FieldItemWrapper>
    )
}

export default FixedList

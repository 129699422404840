import {
    Box,
    Collapse,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import {
    ApproversList,
    BusinessRequestItemComments,
    Button,
    DecisionComment,
    DecisionMenu,
    DelegateMenuIconButton,
    ShowHideButton,
} from 'components'
import {
    useAccessRequestPolicies,
    useRequestItemApprovers,
    useRequestItemRisks,
    useSaveRequestToDoItemDecision,
} from 'hooks'
import { isNilOrEmpty } from 'packages/core'
import React, { Fragment, useEffect, useReducer, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import {
    getDecisionCommentValidationMessage,
    isDateInPast,
    isValidJson,
    smallScreenWidth,
} from 'utils'
import { WorkflowDecisionModal } from 'components/WorkflowDecisionModal'
import config from 'config'
import { RisksWithViolations } from 'components/V2/RisksWithViolations'
import { ViolatingItemDecision } from './ViolatingItemDecision'
import FunctionalAccessItem from 'components/FunctionalAccessItem'
import PendingItemsDetails from './PendingItemDetails'
import {
    Divider,
    Tooltip,
    ValidationMessage,
    ValidationMessages,
} from 'packages/eid-ui'
import DecisionTimeConstraintControl from './DecisionTimeConstraintControl'
import { Icon } from 'packages/eid-icons'
import moment from 'moment'
import RequestDynamicFieldTypeValues from 'components/RequestDynamicFieldTypeValues'

const reducers = (state, { type, payload }) => {
    switch (type) {
        case 'SET_DECISION':
            const newState = [...state]
            const itemIndex = newState.findIndex(
                (i) =>
                    i.id === payload.item.id &&
                    i.stepId === payload.item.currentApprovalStepId,
            )

            let newItem

            let isRejected = payload.decisionId
                ? payload.item.decisions?.find(
                      (d) => d.decisionAction !== 'Continue',
                  )?.decisionId === payload.decisionId
                : null

            if (itemIndex >= 0) {
                newItem = { ...newState[itemIndex] }
                newState.splice(itemIndex, 1)
                newItem.decisionId = payload.decisionId
                newItem.stepId = payload.item.currentApprovalStepId
                newItem.isRejected = isRejected
            } else {
                newItem = {
                    id: payload.item.id,
                    stepId: payload.item.currentApprovalStepId,
                    decisionId: payload.decisionId,
                    isRejected: isRejected,
                }
            }

            return [...newState, newItem]

        case 'SET_MULTIPLE_DECISIONS':
            const newDecisions = [...state]
            for (let i = 0; i < payload.items.length; i++) {
                const currentItem = payload.items[i]
                const existingItemIndex = newDecisions.findIndex(
                    (i) =>
                        i.id === currentItem.id &&
                        i.stepId === currentItem.currentApprovalStepId,
                )

                let isRejected =
                    currentItem.decisions?.find(
                        (d) => d.decisionAction !== 'Continue',
                    )?.decisionId === payload.decisionId

                if (existingItemIndex >= 0) {
                    const existingItemCopy = {
                        ...newDecisions[existingItemIndex],
                    }
                    newDecisions.splice(existingItemIndex, 1)
                    existingItemCopy.decisionId = payload.decisionId
                    existingItemCopy.stepId = currentItem.currentApprovalStepId
                    existingItemCopy.isRejected = isRejected
                    newDecisions.push(existingItemCopy)
                } else {
                    newDecisions.push({
                        id: currentItem.id,
                        stepId: currentItem.currentApprovalStepId,
                        decisionId: payload.decisionId,
                        isRejected: isRejected,
                    })
                }
            }
            return newDecisions

        case 'SET_COMMENT':
            const commentsNewState = [...state]
            const commentItemIndex = commentsNewState.findIndex(
                (i) =>
                    i.id === payload.item.id &&
                    i.stepId === payload.item.currentApprovalStepId,
            )
            let commentNewItem
            if (commentItemIndex >= 0) {
                commentNewItem = { ...commentsNewState[commentItemIndex] }
                commentsNewState.splice(commentItemIndex, 1)
                commentNewItem.comment = payload.comment
            }
            return [...commentsNewState, commentNewItem]

        case 'SET_START_DATE':
            const startDateNewState = [...state]
            const startDateItemIndex = startDateNewState.findIndex(
                (i) =>
                    i.id === payload.item.id &&
                    i.stepId === payload.item.currentApprovalStepId,
            )
            let startDateNewItem
            if (startDateItemIndex >= 0) {
                startDateNewItem = { ...startDateNewState[startDateItemIndex] }
                startDateNewState.splice(startDateItemIndex, 1)
                startDateNewItem.requestDataAssignmentStartDate =
                    payload.requestDataAssignmentStartDate
            } else {
                startDateNewItem = {
                    id: payload.item.id,
                    stepId: payload.item.currentApprovalStepId,
                    requestDataAssignmentStartDate:
                        payload.requestDataAssignmentStartDate,
                }
            }
            return [...startDateNewState, startDateNewItem]

        case 'SET_END_DATE':
            const endDateNewState = [...state]
            const endDateItemIndex = endDateNewState.findIndex(
                (i) =>
                    i.id === payload.item.id &&
                    i.stepId === payload.item.currentApprovalStepId,
            )
            let endDateNewItem
            if (endDateItemIndex >= 0) {
                endDateNewItem = { ...endDateNewState[endDateItemIndex] }
                endDateNewState.splice(endDateItemIndex, 1)
                endDateNewItem.requestDataAssignmentEndDate =
                    payload.requestDataAssignmentEndDate
            } else {
                endDateNewItem = {
                    id: payload.item.id,
                    stepId: payload.item.currentApprovalStepId,
                    requestDataAssignmentEndDate:
                        payload.requestDataAssignmentEndDate,
                }
            }
            return [...endDateNewState, endDateNewItem]

        case 'SET_TOGGLE_VALUE':
            const newItemState = [...state]
            const indexItem = newItemState.findIndex(
                (i) =>
                    i.id === payload.item.id &&
                    i.stepId === payload.item.currentApprovalStepId,
            )
            let newToggleValue
            if (indexItem >= 0) {
                newToggleValue = { ...newItemState[indexItem] }
                newItemState.splice(indexItem, 1)
                newToggleValue.toggleValue = payload.toggleValue
            } else {
                newToggleValue = {
                    id: payload.item.id,
                    stepId: payload.item.currentApprovalStepId,
                    toggleValue: payload.toggleValue,
                }
            }
            return [...newItemState, newToggleValue]

        case 'SET_SHOW_WARNING':
            const showWarningNewState = [...state]
            const showWarningItemIndex = showWarningNewState.findIndex(
                (i) =>
                    i.id === payload.item.id &&
                    i.stepId === payload.item.currentApprovalStepId,
            )
            let showWarningNewItem
            if (showWarningItemIndex >= 0) {
                showWarningNewItem = {
                    ...showWarningNewState[showWarningItemIndex],
                }
                showWarningNewState.splice(showWarningItemIndex, 1)
                showWarningNewItem.showWarning = payload.showWarning
            } else {
                showWarningNewItem = {
                    id: payload.item.id,
                    stepId: payload.item.currentApprovalStepId,
                    showWarning: payload.showWarning,
                }
            }
            return [...showWarningNewState, showWarningNewItem]

        case 'CLEAR':
            return []

        default:
            return []
    }
}

const BusinessRequestItemApprovers = ({ requestId, itemId, totalCount }) => {
    const take = 9
    const [searchTerm, setSearchTerm] = useState('')
    const [page, setPage] = React.useState(1)
    const [perPage, setPerPage] = useState(take)

    const handlePageChange = (value) => {
        setPage(value)
    }

    const handlePageSelection = (value) => {
        setPage(value)
    }

    const handleItemsPerPageChange = (value) => {
        setPage(1)
        setPerPage(value)
    }

    const { latestData } = useRequestItemApprovers(
        requestId,
        itemId,
        (page - 1) * perPage,
        perPage,
        searchTerm,
    )

    const isLoading = !Boolean(latestData)

    const approvers = latestData ? latestData.data : undefined

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPage)
        : 0

    return (
        <ApproversList
            totalCount={totalCount}
            visibleCount={latestData && latestData.totalCount}
            isLoading={isLoading}
            data={approvers}
            page={page}
            handlePageChange={handlePageChange}
            numberOfPages={numberOfPages}
            searchTerm={searchTerm}
            handleSearch={setSearchTerm}
            perPage={perPage}
            handlePageSelection={handlePageSelection}
            handleItemsPerPageChange={handleItemsPerPageChange}
        />
    )
}

const useStyles = makeStyles((theme) => ({
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: '#fbfbfd',
        '& tr:hover': {},
        borderCollapse: 'separate',
        padding: '0px 15px',
    },
    tableRowExpanded: {
        border: ' solid 1px #efeff1',
        borderBottom: 'none',
    },

    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
    },
    expansionCell: {
        padding: '0px',
        border: 'none',
    },

    expansionCellActive: {
        padding: '1.4rem 0rem',
        border: 'none',
        backgroundColor: '#efeff1',
        borderBottomLeftRadius: '0rem',
        borderBottomRightRadius: '0rem',
    },
    expansionRow: {
        border: 'none',
    },
    expansionRowActive: {
        borderBottom: 'none',
        borderTop: 'none',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    tableHeaderCell: {
        position: 'relative',
        zIndex: '5',
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: '#307fc1 !important',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '12px',
        '& svg': {
            margin: '0px !important',
        },
    },

    headerBackground: {
        position: 'absolute',
        top: '0px',
        width: '100%',
        height: '54px',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        border: 'solid 1px #dfdfe5',
    },

    approversContainer: {
        maxWidth: '1000px',

        [`@media (max-width:${smallScreenWidth})`]: {
            width: '100vw',
        },
    },

    timeconstraintbtn: {
        position: 'relative',
        display: 'flex',
        textAlign: 'center',
        cursor: 'pointer',
    },
    violationbtn: {
        position: 'relative',
        display: 'flex',
        height: '33px',
        textAlign: 'center',
        cursor: 'pointer',
        '& button': {
            marginTop: '-8px',
        },
    },
    functionalaccessbtn: {
        position: 'relative',
        display: 'flex',
        height: '33px',
        textAlign: 'center',
        '& svg': {
            marginTop: '-8px',
        },
        '& button': {
            minWidth: 'auto',
        },
    },
    approverbtn: {
        position: 'relative',
        display: 'flex',
        height: '33px',
        textAlign: 'center',
        '& svg': {
            marginTop: '-8px',
        },
        '& button': {
            minWidth: 'auto',
        },
    },
    commentsbtn: {
        position: 'relative',
        display: 'flex',
        height: '33px',
        textAlign: 'center',
        '& svg': {
            marginTop: '-8px',
        },
        '& button': {
            minWidth: 'auto',
        },
    },
    requestsbtn: {
        position: 'relative',
        display: 'flex',
        height: '33px',
        textAlign: 'center',
        '& svg': {
            marginTop: '-10px',
        },
    },
    actionbutton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',
    },

    warningLabel: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
        color: theme?.palette?.warning?.main,
        margin: '0 0.5rem',
    },

    warningMessage: {
        fontSize: '1.4rem',
    },
    dynamicFieldSection: {
        width: '100%',
        '& div[class*="-fieldHeaderTitle"]': {
            padding: '0 1.3rem',
            borderBottom: '0 solid #dfdfe5',
        },
        '& div[class*="-additionalFieldWrapper"]': {
            padding: '0 1.3rem',
        },
        '& div[class*="-containerWrapper"]': {
            borderBottom: '0.1rem solid #dfdfe5',
        },
        borderBottom: '0.1rem solid #dfdfe5',
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderImageSlice: 1,
    },
}))

const useRowStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        backgroundColor: theme?.palette?.common?.white,
        borderTopLeftRadius: '0.8rem !important',
        borderTopRightRadius: '0.8rem !important',
        boxShadow: '0 0.6rem 0.8rem 0 rgba(0, 0, 0, 0.05)',

        '& >td:first-child': {
            borderBottomLeftRadius: '0rem !important',
            borderTopLeftRadius: '0.8rem !important',
            paddingLeft: '1.5rem',
        },
        '& >td:last-child': {
            borderBottomRightRadius: '0rem !important',
            borderTopRightRadius: '0.8rem !important',
            paddingRight: '2rem',
        },

        '&:hover': {
            '& .Details-button': {
                backgroundColor: theme?.palette?.primary?.main,
                color: theme?.palette?.common?.white,
                cursor: 'pointer',
            },
        },
    },
}))

const useCommentRowStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        backgroundColor: theme?.palette?.common?.white,
        borderTopLeftRadius: '0rem !important',
        borderTopRightRadius: '0rem !important',
        boxShadow: '0 0.6rem 0.8rem 0 rgba(0, 0, 0, 0.05)',

        '& >td:first-child': {
            borderBottomLeftRadius: '0.8rem !important',
            borderTopLeftRadius: '0rem !important',
            paddingLeft: '1.5rem',
        },
        '& >td:last-child': {
            borderBottomRightRadius: '0.8rem !important',
            borderTopRightRadius: '0rem !important',
            paddingRight: '2rem',
        },

        '&:hover': {
            '& .Details-button': {
                backgroundColor: theme?.palette?.primary?.main,
                color: theme?.palette?.common?.white,
                cursor: 'pointer',
            },
        },
    },
}))

const useTimeConstraintRowStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        backgroundColor: theme?.palette?.common?.white,
        borderTopLeftRadius: '0rem !important',
        borderTopRightRadius: '0rem !important',
        boxShadow: '0 0.6rem 0.8rem 0 rgba(0, 0, 0, 0.05)',

        '& >td:first-child': {
            borderBottomLeftRadius: '0rem !important',
            borderTopLeftRadius: '0rem !important',
            paddingLeft: '1.5rem',
        },
        '& >td:last-child': {
            borderBottomRightRadius: '0rem !important',
            borderTopRightRadius: '0rem !important',
            paddingRight: '2rem',
        },

        '&:hover': {
            '& .Details-button': {
                backgroundColor: theme?.palette?.primary?.main,
                color: theme?.palette?.common?.white,
                cursor: 'pointer',
            },
        },
    },
}))

const useButtonsRowStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme?.palette?.common?.white,
        minHeight: '4rem',
        '& >td:first-child': {
            borderTopLeftRadius: '0rem !important',
            paddingLeft: '1.5rem',
        },
        '& >td:last-child': {
            borderTopRightRadius: '0rem !important',
            paddingRight: '2rem',
        },

        '&:hover': {
            '& .Details-button': {
                backgroundColor: theme?.palette?.primary?.main,
                color: theme?.palette?.common?.white,
                cursor: 'pointer',
            },
        },
    },
    hoverableAttribute: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
}))

const ButtonsRowCell = styled(TableCell)({
    padding: '1rem 1rem',
    overflowWrap: 'break-word',
    wordBreak: 'normal',
    overflow: 'hidden',
    color: '#7d7c7c',
    borderBottomLeftRadius: '0rem !important',
    borderBottomRightRadius: '0rem !important',
})

const MainRow = TableRow

const ExpansionRow = TableRow

const ButtonsRow = TableRow

const EmptyRow = styled(TableRow)({
    border: 'none !important',
    backgroundColor: '#fbfbfd',
})
const EmptyCell = styled(TableCell)({
    border: 'none !important',
    padding: '9px',
})

const DynamicFieldCell = styled(TableCell)({
    border: 'none !important',
    padding: '0',
    borderBottomLeftRadius: '8px !important',
    borderBottomRightRadius: '8px !important',
})

export const PendingItems = ({
    businessRequest,
    id,
    data,
    isLoading,
    callApiOnEveryChange,
    totalCount,
    onSuccess,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    const rowClasses = useRowStyles()
    const commentRowClasses = useCommentRowStyles()
    const timeConstraintRowClasses = useTimeConstraintRowStyles()
    const buttonsRowClasses = useButtonsRowStyles()
    const { hasAccessToControl } = useSubcomponents()
    const [
        saveBusinessRequestItemsDecision,
        { isLoading: isSavingBusinessRequestItemsDecision },
    ] = useSaveRequestToDoItemDecision(id)

    const { data: requestAccessPolicies } = useAccessRequestPolicies()

    const {
        hasAccessToRequestItemDelegateControl,
        canSeeRiskViolationsByRisks,
    } = useSubcomponents()

    const [state, dispatch] = useReducer(reducers, [])

    const [expanded, setExpanded] = useState([])

    const isExpanded = (item) =>
        expanded.find(
            (i) =>
                i.id === item.id &&
                i.currentApprovalStepId === item.currentApprovalStepId,
        )

    const handleExpand = (item, section) => {
        const expandedIndex = expanded.findIndex(
            (i) =>
                i.id === item.id &&
                i.currentApprovalStepId === item.currentApprovalStepId,
        )

        const expandedItem = expanded.find(
            (i) =>
                i.id === item.id &&
                i.currentApprovalStepId === item.currentApprovalStepId,
        )

        let newExpanded = []

        if (!expandedItem) {
            newExpanded = newExpanded.concat(expanded, {
                section: section,
                ...item,
            })
        } else if (expandedItem && expandedItem.section !== section) {
            let filteredArray = expanded.filter((i) => i.id !== item.id)
            newExpanded = newExpanded.concat(filteredArray, {
                section: section,
                ...item,
            })
        } else if (expandedItem && expandedItem.section === section) {
            newExpanded = [...expanded]
            newExpanded.splice(expandedIndex, 1)
        }

        setExpanded(newExpanded)
    }

    const renderItemTypeActionFriendlyName = (item) => {
        if (item.assignee) {
            return (
                <Fragment>
                    {item.itemTypeActionFriendlyName} ({item.assignee})
                </Fragment>
            )
        }
        return item.itemTypeActionFriendlyName
    }

    useEffect(() => {
        if (data) {
            data.map((item) => {
                dispatch({
                    type: 'SET_START_DATE',
                    payload: {
                        item,
                        requestDataAssignmentStartDate:
                            item.requestDataAssignmentStartDate,
                    },
                })
                dispatch({
                    type: 'SET_END_DATE',
                    payload: {
                        item,
                        requestDataAssignmentEndDate:
                            item.requestDataAssignmentEndDate,
                    },
                })
            })
        }
    }, [data])

    const handleToggleValue = (item, toggleValue) => {
        dispatch({
            type: 'SET_TOGGLE_VALUE',
            payload: {
                item,
                toggleValue,
            },
        })
    }

    const handleStartDate = (item, startDateToSet) => {
        dispatch({
            type: 'SET_START_DATE',
            payload: {
                item,
                requestDataAssignmentStartDate: startDateToSet,
            },
        })
    }

    const handleEndDate = (item, endDateToSet) => {
        dispatch({
            type: 'SET_END_DATE',
            payload: {
                item,
                requestDataAssignmentEndDate: endDateToSet,
            },
        })

        if (!isDateInPast(endDateToSet)) {
            handleShowDateWarning(item, false)
        }
    }

    const handleShowDateWarning = (item, showWarning) => {
        dispatch({
            type: 'SET_SHOW_WARNING',
            payload: {
                item,
                showWarning: showWarning,
            },
        })
    }

    const unFilteredAttributes = [
        {
            style: {
                minWidth: '200px',
            },
            label: t('Name'),
            resolve: (item) => (
                <Link
                    to={`${config.APP_SUBPATH}/myTasks/businessRequestItems?id=${item.id}`}
                    style={{
                        color: '#01ae8f',
                        fontWeight: 'bold',
                    }}
                >
                    {renderItemTypeActionFriendlyName(item)}
                </Link>
            ),
        },
        {
            name: 'resourceRequested',
            label: t('TargetResource'),
            style: {
                color: '#5d6870',
                minWidth: '120px',
            },
            requireAccess: {
                control: 'MyTasks-RequestDetails-ToDo-Resource-Column',
            },
        },
        {
            name: 'assignee',
            label: t('TargetAssignee'),
            style: {
                color: '#5d6870',
                minWidth: '120px',
            },
            requireAccess: {
                control: 'MyTasks-RequestDetails-ToDo-Assignee-Column',
            },
        },
        {
            name: 'currentApprovalStepName',
            label: t('CurrentStep'),
            style: {
                color: '#5d6870',
                minWidth: '120px',
            },
            requireAccess: {
                control: 'MyTasks-RequestDetails-ToDo-CurrentStep-Column',
            },
        },
    ]
    const attributes = unFilteredAttributes.filter((a) =>
        hasAccessToControl(a.requireAccess?.control),
    )
    const headings = attributes.map((a) => a.label)

    const renderCollapsedContent = (item) => {
        const expandedItem = isExpanded(item)

        let expandedSection
        if (expandedItem && expandedItem.section === 'approvers') {
            expandedSection = (
                <Box classes={{ root: classes.approversContainer }}>
                    <BusinessRequestItemApprovers
                        requestId={id}
                        itemId={item.id}
                        totalCount={item.approverCount}
                    />
                </Box>
            )
        } else if (expandedItem && expandedItem.section === 'comments') {
            expandedSection = (
                <BusinessRequestItemComments requestId={id} itemId={item.id} />
            )
        } else if (
            expandedItem &&
            expandedItem.section === 'functionalaccess'
        ) {
            expandedSection = (
                <Box marginTop={'-14px'}>
                    <FunctionalAccessItem assigneeId={item.subjectId} />
                </Box>
            )
        } else if (expandedItem && expandedItem.section === 'violations') {
            expandedSection = (
                <RisksWithViolations
                    page={'Requests'}
                    requestId={id}
                    requestIdOrItemId={item.id}
                    useDataHook={useRequestItemRisks}
                />
            )
        } else if (expandedItem && expandedItem.section === 'details') {
            expandedSection = (
                <PendingItemsDetails
                    itemId={item?.id}
                    additionalResourceName={
                        item?.additionalResourceFriendlyName
                    }
                    handleShowDateWarning={(showWarning) =>
                        handleShowDateWarning(item, showWarning)
                    }
                />
            )
        }

        return <>{expandedItem && expandedSection}</>
    }

    const distinctDecisions = []
    if (data && data.length > 0) {
        data.map((d) => d.decisions)
            .reduce((current, next) => current.concat(next))
            .forEach((d) => {
                var decisionAlreadyInArray = distinctDecisions.find(
                    (a) => a.decisionId === d.decisionId,
                )
                if (!decisionAlreadyInArray) {
                    d.count = 1
                    distinctDecisions.push(d)
                } else {
                    decisionAlreadyInArray.count++
                }
            })
    }

    const handleDecideAll = (decisionMade) => {
        const decisionId = decisionMade ? decisionMade.decisionId : null

        const itemsRequiringManualDecisions = config.ENABLE_APPROVAL_WORKFLOWS
            ? data.filter((i) => !i.decisionWorkflowName)
            : data

        let items
        if (decisionId) {
            items = itemsRequiringManualDecisions.filter((i) =>
                i.decisions.find((d) => d.decisionId === decisionId),
            )
        } else {
            items = itemsRequiringManualDecisions
        }

        if (callApiOnEveryChange) {
            const saveDecisionRequest = items
                .filter((i) => i.decisionTakenId !== decisionId)
                .map((i) => ({
                    decisionId: decisionId,
                    id: i.id,
                    stepId: i.currentApprovalStepId,
                    requestDataAssignmentStartDate:
                        i.requestDataAssignmentStartDate,
                    requestDataAssignmentEndDate:
                        i.requestDataAssignmentEndDate,
                }))

            if (saveDecisionRequest.length > 0) {
                saveBusinessRequestItemsDecision(saveDecisionRequest)
            }
        } else {
            dispatch({
                type: 'SET_MULTIPLE_DECISIONS',
                payload: {
                    items,
                    decisionId: decisionId,
                },
            })
        }
    }

    const handleDecideOne = (item, decisionMade) => {
        const decisionId = decisionMade ? decisionMade.decisionId : null
        if (callApiOnEveryChange) {
            const saveDecisionRequest = {
                decisionId: decisionId,
                id: item.id,
                stepId: item.currentApprovalStepId,
                requestDataAssignmentStartDate:
                    item.requestDataAssignmentStartDate,
                requestDataAssignmentEndDate: item.requestDataAssignmentEndDate,
            }

            if (decisionId !== item.decisionTakenId) {
                saveBusinessRequestItemsDecision([saveDecisionRequest])
            }
        } else {
            dispatch({
                type: 'SET_DECISION',
                payload: {
                    item,
                    decisionId: decisionId,
                },
            })
        }
    }

    const handleDecideWithComment = (item, comment) => {
        if (callApiOnEveryChange) {
            const saveDecisionRequest = {
                id: item.id,
                decisionId: item.decisionTakenId,
                stepId: item.currentApprovalStepId,
                comment: comment,
                requestDataAssignmentStartDate:
                    item.requestDataAssignmentStartDate,
                requestDataAssignmentEndDate: item.requestDataAssignmentEndDate,
            }

            saveBusinessRequestItemsDecision([saveDecisionRequest])
        } else {
            dispatch({
                type: 'SET_COMMENT',
                payload: {
                    item,
                    comment: comment,
                },
            })
        }
    }

    const isInvalidDate = (itemInState) => {
        let isTimeConstraintEditable = false
        let isTimeConstraintRequired = false
        const item = data.find((s) => s.id === itemInState.id)

        const policy = requestAccessPolicies[item.requestPolicyId]

        if (itemInState.decisionId) {
            const decision = item.decisions.find(
                (x) => x.decisionId === itemInState.decisionId,
            )
            isTimeConstraintRequired =
                decision?.isTimeConstraintRequired || policy?.isTimeConstrained

            isTimeConstraintEditable = decision?.isTimeConstraintEditable
        }

        return (
            isTimeConstraintEditable &&
            (moment(itemInState.requestDataAssignmentStartDate).isAfter(
                moment(itemInState.requestDataAssignmentEndDate),
            ) ||
                (isTimeConstraintRequired &&
                    !itemInState.requestDataAssignmentEndDate) ||
                isDateInPast(itemInState.requestDataAssignmentEndDate))
        )
    }

    const commentValidationError = state.filter((item) => {
        return !isNilOrEmpty(
            getDecisionCommentValidationMessage(item.comment, item?.isRejected),
        )
    })
    const decidedItems = state.filter((item) => item.decisionId)

    const notSubmittable = decidedItems.filter((item) => {
        return (
            !isNilOrEmpty(
                getDecisionCommentValidationMessage(
                    item.comment,
                    item?.isRejected,
                ),
            ) || isInvalidDate(item)
        )
    })

    const invalidDateError = state.filter((item) => {
        return isInvalidDate(item)
    })

    const bindActionItems = (item) => {
        const expandedItem = isExpanded(item)
        return (
            <Box className={classes.actionbutton}>
                <Box className={classes.functionalaccessbtn}>
                    <Button.Expand
                        type="RiskFunction"
                        handleExpand={() =>
                            handleExpand(item, 'functionalaccess')
                        }
                        expanded={
                            expandedItem &&
                            expandedItem.section === 'functionalaccess'
                        }
                        extraShadeHeight="23"
                    />
                </Box>

                <>
                    {businessRequest.status !== 'Open_PendingApproval' && (
                        <Box className={classes.approverbtn}>
                            <Button.Expand
                                type="Approvers"
                                handleExpand={() =>
                                    handleExpand(item, 'approvers')
                                }
                                expanded={
                                    expandedItem &&
                                    expandedItem.section === 'approvers'
                                }
                                extraShadeHeight="23"
                            />
                        </Box>
                    )}
                </>
                <>
                    <Box className={classes.commentsbtn}>
                        <Button.Expand
                            type="Comments"
                            handleExpand={() => handleExpand(item, 'comments')}
                            expanded={
                                expandedItem &&
                                expandedItem.section === 'comments'
                            }
                            extraShadeHeight="23"
                        />
                    </Box>
                </>

                <>
                    {!hasAccessToRequestItemDelegateControl ||
                    (!item.allowAddApprover && !item.allowAssignApprover) ? (
                        <></>
                    ) : (
                        <Box className={classes.requestsbtn}>
                            <DelegateMenuIconButton
                                page="Requests"
                                isApprover={item.isApprover}
                                requestId={businessRequest.id}
                                requestItemId={item.id}
                                currentApprovalStepId={
                                    item.currentApprovalStepId
                                }
                                assignedByPersonId={item.assignedByPersonId}
                                assignedByPersonFriendlyName={
                                    item.assignedByPersonFriendlyName
                                }
                                claimedApproverId={item.claimedApproverId}
                                claimedApproverFriendlyName={
                                    item.claimedApproverFriendlyName
                                }
                                allowAddApprover={item.allowAddApprover}
                                allowAssignApprover={item.allowAssignApprover}
                                protectSubcomponent={
                                    hasAccessToRequestItemDelegateControl
                                }
                            />
                        </Box>
                    )}
                </>

                <>
                    {item.riskCount <= 0 || !canSeeRiskViolationsByRisks ? (
                        <></>
                    ) : (
                        <Box
                            data-protectedsubcomponent={
                                canSeeRiskViolationsByRisks
                            }
                            className={classes.violationbtn}
                        >
                            <Button.Expand
                                type={'Violations'}
                                handleExpand={() =>
                                    handleExpand(item, 'violations')
                                }
                                expanded={
                                    expandedItem &&
                                    expandedItem.section === 'violations'
                                }
                            />
                        </Box>
                    )}
                </>
            </Box>
        )
    }

    return (
        <Box style={{ position: 'relative' }}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                            style={{ minWidth: '160px' }}
                            padding="checkbox"
                        >
                            {!config.ENABLE_APPROVAL_WORKFLOWS ||
                            (data &&
                                !data.some(
                                    (i) =>
                                        i.decisionWorkflowName ||
                                        i.isViolatingItem,
                                )) ? (
                                <DecisionMenu
                                    value={'DecideAll'}
                                    options={distinctDecisions}
                                    onChange={handleDecideAll}
                                    numberOfDecisions={data.length}
                                />
                            ) : (
                                <Fragment />
                            )}
                        </TableCell>

                        {headings.map((heading, index) => (
                            <TableCell
                                key={index}
                                className={clsx(
                                    classes.tableHeaderCell,
                                    classes.tableCell,
                                    classes.headGrey,
                                )}
                                align={heading.align}
                            >
                                {heading}
                            </TableCell>
                        ))}
                    </TableRow>

                    <EmptyRow>
                        <EmptyCell colSpan={headings.length + 1} />
                    </EmptyRow>
                </TableHead>

                <TableBody>
                    {isLoading ? (
                        <TableRow>
                            <TableCell colSpan={headings.length + 1}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    height={64}
                                />
                            </TableCell>
                        </TableRow>
                    ) : data.length === 0 ? (
                        <TableRow classes={rowClasses} hover={false}>
                            <TableCell
                                className={classes.tableCell}
                                colSpan={headings.length + 1}
                                style={{ textAlign: 'center' }}
                            >
                                <Trans i18nKey="NoDataFound" />
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {data.map((item) => {
                                const itemInState = state.find(
                                    (s) =>
                                        s.id === item.id &&
                                        s.stepId === item.currentApprovalStepId,
                                )

                                const clientSideDecision =
                                    item.decisions?.find(
                                        (i) =>
                                            i.decisionId ===
                                            itemInState?.decisionId,
                                    ) ||
                                    item.decisions?.find(
                                        (i) =>
                                            i.decisionId ===
                                            item.decisionTakenId,
                                    )

                                const clientSideComment =
                                    itemInState?.comment || item.decisionComment

                                return (
                                    <React.Fragment
                                        key={`${item.id}-${item.itemApprovalStepId}`}
                                    >
                                        <MainRow classes={rowClasses}>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                {config.ENABLE_APPROVAL_WORKFLOWS?.toLowerCase() ===
                                                    'true' &&
                                                item.decisionWorkflowName ? (
                                                    <WorkflowDecisionModal
                                                        fullScreen={true}
                                                        iframeHeight="calc(100vh - 7rem)"
                                                        page={'Requests'}
                                                        requestId={
                                                            businessRequest.id
                                                        }
                                                        itemId={item.id}
                                                        currentApprovalStepId={
                                                            item.currentApprovalStepId
                                                        }
                                                        decisionWorkflowName={
                                                            item.decisionWorkflowName
                                                        }
                                                        maxWidth={'lg'}
                                                    />
                                                ) : item.isViolatingItem ? (
                                                    <ViolatingItemDecision
                                                        item={item}
                                                    />
                                                ) : (
                                                    <DecisionMenu
                                                        value={
                                                            itemInState?.decisionId ||
                                                            item.decisionTakenId
                                                        }
                                                        options={item.decisions}
                                                        onChange={(
                                                            decisionMade,
                                                        ) =>
                                                            handleDecideOne(
                                                                item,
                                                                decisionMade,
                                                            )
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                            {attributes.map((a, index) => (
                                                <TableCell
                                                    key={`${item.id}${index}`}
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    style={a.style}
                                                >
                                                    {a.resolve
                                                        ? a.resolve(item)
                                                        : !isNilOrEmpty(
                                                              item[a.name],
                                                          )
                                                        ? item[a.name]
                                                        : '-'}
                                                </TableCell>
                                            ))}
                                        </MainRow>

                                        {clientSideDecision && (
                                            <>
                                                {clientSideDecision.isTimeConstraintEditable && (
                                                    <TableRow
                                                        classes={
                                                            timeConstraintRowClasses
                                                        }
                                                    >
                                                        <TableCell
                                                            colspan={5}
                                                            className={
                                                                classes.tableCell
                                                            }
                                                            style={{
                                                                paddingLeft:
                                                                    '2rem',
                                                            }}
                                                        >
                                                            <Divider
                                                                style={{
                                                                    margin: '0 0 1.5rem 0rem',
                                                                }}
                                                            />

                                                            <DecisionTimeConstraintControl
                                                                requestPolicyId={
                                                                    item.requestPolicyId
                                                                }
                                                                timeConstraintRequired={
                                                                    clientSideDecision.isTimeConstraintRequired
                                                                }
                                                                selectedStartDate={
                                                                    itemInState
                                                                        ? itemInState.requestDataAssignmentStartDate
                                                                        : item.requestDataAssignmentStartDate
                                                                }
                                                                selectedEndDate={
                                                                    itemInState
                                                                        ? itemInState.requestDataAssignmentEndDate
                                                                        : item.requestDataAssignmentEndDate
                                                                }
                                                                handleToggleValue={(
                                                                    toggleValue,
                                                                ) =>
                                                                    handleToggleValue(
                                                                        item,
                                                                        toggleValue,
                                                                    )
                                                                }
                                                                handleStartDate={(
                                                                    startDate,
                                                                ) =>
                                                                    handleStartDate(
                                                                        item,
                                                                        startDate,
                                                                    )
                                                                }
                                                                handleEndDate={(
                                                                    endDate,
                                                                ) =>
                                                                    handleEndDate(
                                                                        item,
                                                                        endDate,
                                                                    )
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                                <TableRow
                                                    classes={commentRowClasses}
                                                >
                                                    <TableCell
                                                        colspan={5}
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        style={{
                                                            paddingTop: '0px',
                                                        }}
                                                    >
                                                        <Box>
                                                            <Divider
                                                                style={{
                                                                    margin: '0rem 0rem 0.6rem 0rem',
                                                                }}
                                                            />
                                                            <Box
                                                                width="50%"
                                                                minWidth="fit-content"
                                                            >
                                                                <DecisionComment
                                                                    initialValue={
                                                                        clientSideComment ??
                                                                        ''
                                                                    }
                                                                    onBlur={(
                                                                        e,
                                                                    ) => {
                                                                        let decisionComment =
                                                                            e
                                                                                .target
                                                                                .value
                                                                        handleDecideWithComment(
                                                                            item,
                                                                            decisionComment,
                                                                        )
                                                                    }}
                                                                    onChange={(
                                                                        comment,
                                                                    ) => {
                                                                        handleDecideWithComment(
                                                                            item,
                                                                            comment,
                                                                        )
                                                                    }}
                                                                    isRejected={
                                                                        itemInState?.isRejected
                                                                    }
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )}

                                        <ButtonsRow
                                            className={buttonsRowClasses.root}
                                        >
                                            <ButtonsRowCell
                                                colSpan={headings.length + 1}
                                            >
                                                <Box
                                                    component="span"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Box display={'flex'}>
                                                        <Box
                                                            display="flex"
                                                            minWidth={'14rem'}
                                                        >
                                                            <ShowHideButton
                                                                onClick={() =>
                                                                    handleExpand(
                                                                        item,
                                                                        'details',
                                                                    )
                                                                }
                                                                expanded={
                                                                    isExpanded(
                                                                        item,
                                                                    ) &&
                                                                    isExpanded(
                                                                        item,
                                                                    )
                                                                        ?.section ===
                                                                        'details'
                                                                }
                                                                showLabel="ShowDetails"
                                                                hideLabel="HideDetails"
                                                            />
                                                        </Box>
                                                        {(itemInState?.showWarning ||
                                                            (clientSideDecision &&
                                                                clientSideDecision.isTimeConstraintEditable &&
                                                                isDateInPast(
                                                                    itemInState
                                                                        ? itemInState.requestDataAssignmentEndDate
                                                                        : item.requestDataAssignmentEndDate,
                                                                ))) && (
                                                            <Box
                                                                display={'flex'}
                                                                alignItems={
                                                                    'center'
                                                                }
                                                                marginLeft={
                                                                    '2.4rem'
                                                                }
                                                            >
                                                                <Icon
                                                                    name="Warning"
                                                                    color={
                                                                        theme
                                                                            ?.palette
                                                                            ?.warning
                                                                            ?.main
                                                                    }
                                                                    height="1rem"
                                                                    width="1rem"
                                                                />
                                                                <Typography
                                                                    className={
                                                                        classes.warningLabel
                                                                    }
                                                                >
                                                                    {`${t(
                                                                        'Warning',
                                                                    )}:`}
                                                                </Typography>
                                                                <Typography
                                                                    className={
                                                                        classes.warningMessage
                                                                    }
                                                                >
                                                                    {t(
                                                                        'TimeConstraintExceededMessage',
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Box>

                                                    <Box>
                                                        {bindActionItems(item)}
                                                    </Box>
                                                </Box>
                                            </ButtonsRowCell>
                                        </ButtonsRow>

                                        <ExpansionRow
                                            hover={false}
                                            className={
                                                Boolean(isExpanded(item))
                                                    ? classes.expansionRowActive
                                                    : classes.expansionRow
                                            }
                                        >
                                            <TableCell
                                                colSpan={headings.length + 1}
                                                className={
                                                    Boolean(isExpanded(item))
                                                        ? classes.expansionCellActive
                                                        : classes.expansionCell
                                                }
                                            >
                                                <Collapse
                                                    in={
                                                        Boolean(
                                                            isExpanded(item),
                                                        ) ||
                                                        Boolean(
                                                            itemInState
                                                                ? itemInState.decisionId
                                                                : item.decisionTakenId,
                                                        )
                                                    }
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    {renderCollapsedContent(
                                                        item,
                                                    )}
                                                </Collapse>
                                            </TableCell>
                                        </ExpansionRow>

                                        {/* Business Request Dynamic Field Type Section Start */}
                                        {isValidJson(
                                            item?.fieldTypeValueJson,
                                        ) && (
                                            <EmptyRow>
                                                <DynamicFieldCell
                                                    colSpan={
                                                        headings.length + 1
                                                    }
                                                >
                                                    <Box
                                                        className={
                                                            classes.dynamicFieldSection
                                                        }
                                                    >
                                                        <RequestDynamicFieldTypeValues
                                                            fieldTypeJson={
                                                                item?.fieldTypeValueJson
                                                            }
                                                        />
                                                    </Box>
                                                </DynamicFieldCell>
                                            </EmptyRow>
                                        )}

                                        {/* Business Request Dynamic Field Type Section End */}

                                        <EmptyRow>
                                            <EmptyCell
                                                colSpan={headings.length + 1}
                                            />
                                        </EmptyRow>
                                    </React.Fragment>
                                )
                            })}
                        </>
                    )}
                </TableBody>
            </Table>
            <Box className={classes.headerBackground}></Box>
            {(!isNilOrEmpty(commentValidationError) ||
                !isNilOrEmpty(invalidDateError)) && (
                <Box marginX="30px" marginY="5px">
                    <ValidationErrors
                        isJustificationCommentMissing={
                            !isNilOrEmpty(commentValidationError)
                        }
                        isInvalidDate={!isNilOrEmpty(invalidDateError)}
                    />
                </Box>
            )}

            <Box display="flex">
                {data &&
                    data.length > 0 &&
                    !callApiOnEveryChange &&
                    decidedItems.length > 0 && (
                        <Tooltip
                            title={
                                !isNilOrEmpty(notSubmittable)
                                    ? t('CompleteMandatoryFields')
                                    : ''
                            }
                        >
                            <Box marginX="30px" marginY="15px" maxWidth="140px">
                                <Button.Submit
                                    disabled={!isNilOrEmpty(notSubmittable)}
                                    loading={
                                        isSavingBusinessRequestItemsDecision
                                    }
                                    onClick={() => {
                                        if (!isNilOrEmpty(notSubmittable)) {
                                            return
                                        }
                                        const updatedItems = decidedItems.map(
                                            (item) => {
                                                const {
                                                    toggleValue,
                                                    ...remainingItem
                                                } = item
                                                if (!toggleValue) {
                                                    return {
                                                        ...remainingItem,
                                                        requestDataAssignmentStartDate:
                                                        remainingItem.requestDataAssignmentStartDate || null,
                                                        requestDataAssignmentEndDate:
                                                        remainingItem.requestDataAssignmentEndDate || null,
                                                    }
                                                }
                                                return remainingItem
                                            },
                                        )
                                        saveBusinessRequestItemsDecision(
                                            updatedItems,
                                        ).then(() => {
                                            dispatch({
                                                type: 'CLEAR',
                                            })
                                            onSuccess()
                                        })
                                    }}
                                >
                                    {t('Submit')}
                                    {totalCount && (
                                        <Box component="span" marginLeft="8px">
                                            ({decidedItems.length}/{totalCount})
                                        </Box>
                                    )}
                                </Button.Submit>
                            </Box>
                        </Tooltip>
                    )}
            </Box>
        </Box>
    )
}

const validationMessages = {
    justificationComments: 'JustificationRequiredOnItems',
    invalidDateComments: 'InvalidDateOnItems',
}

const ValidationErrors = ({ isJustificationCommentMissing, isInvalidDate }) => {
    const { t } = useTranslation()

    return (
        <ValidationMessages>
            {isJustificationCommentMissing && (
                <ValidationMessage
                    message={t(
                        `${validationMessages['justificationComments']}`,
                    )}
                />
            )}
            {isInvalidDate && (
                <ValidationMessage
                    message={t(`${validationMessages['invalidDateComments']}`)}
                />
            )}
        </ValidationMessages>
    )
}

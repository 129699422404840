import React, { Fragment, useState } from 'react'
import { useRequestItemTypes } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Box, Collapse } from '@mui/material'
import { Skeleton } from '@mui/material'
import ItemTypeRequestItems from './ItemTypeRequestItems'
import { Trans } from 'react-i18next'
import { HorizontalTabs } from 'packages/eid-ui'
import { getStatusColor } from 'utils'
import { useTableStyles, Tabs, RoleCount, ExpandIcon } from './utils'
import { FulfillmentStatusDropDown } from './FulfillmentStatusDropDown'
import { isNilOrEmpty } from 'packages/core'

const RequestItemsSummary = ({ businessRequest, id }) => {
    const classes = useTableStyles()

    const [currentTabs, setCurrentTabs] = useState({})
    const { t } = useTranslation()
    const { data: requestItemTypes, isLoading } = useRequestItemTypes(id)

    const [selectedFulfillmentStatuses, setSelectedFulfillmentStatuses] =
        useState({})

    const [expanded, setExpanded] = useState([])

    const ITEM_STATUS_TABS = [
        {
            value: 'All',
            prop: 'total',
            label: t('All'),
        },
        {
            value: 'Approved',
            prop: 'approved',
            label: t('Approved'),
            showFullfilmentStatusDropdown: true,
        },
        {
            value: 'Rejected',
            prop: 'rejected',
            label: t('Rejected'),
            showFullfilmentStatusDropdown: true,
        },
        {
            value: 'AutoClosed',
            prop: 'autoClosed',
            label: t('AutoClosed'),
            showFullfilmentStatusDropdown: true,
        },
        {
            value: 'Open',
            prop: 'open',
            label: t('Open'),
        },

        {
            value: 'Canceled',
            prop: 'canceled',
            label: t('Canceled'),
        },

        {
            value: 'Error',
            prop: 'error',
            label: t('Error'),
        },
        {
            value: 'NotReady',
            prop: 'notReady',
            label: t('NotReady'),
        },
        {
            value: 'Completed',
            prop: 'completed',
            label: t('Completed'),
        },
    ]

    const isExpanded = (item) =>
        expanded.find((i) => i.itemTypeId === item.itemTypeId)

    const handleExpand = (item) => {
        const expandedIndex = expanded.findIndex(
            (i) => i.itemTypeId === item.itemTypeId,
        )
        const section = 'details'
        const expandedItem = expanded.find(
            (i) => i.itemTypeId === item.itemTypeId,
        )

        let newExpanded = []

        if (!expandedItem) {
            newExpanded = newExpanded.concat(expanded, {
                section: section,
                ...item,
            })
        } else if (expandedItem && expandedItem.section !== section) {
            let filteredArray = expanded.filter(
                (i) => i.itemTypeId !== item.itemTypeId,
            )
            newExpanded = newExpanded.concat(filteredArray, {
                section: section,
                ...item,
            })
        } else if (expandedItem && expandedItem.section === section) {
            newExpanded = [...expanded]
            newExpanded.splice(expandedIndex, 1)
        }

        setExpanded(newExpanded)
    }

    const renderCollapsedContent = (item) => {
        const expandedItem = isExpanded(item)

        return (
            <>
                {expandedItem && (
                    <ItemTypeRequestItems
                        businessRequest={businessRequest}
                        requestId={id}
                        itemTypeId={item.itemTypeId}
                        currentTab={currentTabs[item.itemTypeId]}
                        fulfillmentStatus={
                            selectedFulfillmentStatuses[item.itemTypeId]
                        }
                    />
                )}
            </>
        )
    }

    const getSumOfItems = () =>
        requestItemTypes
            .map((d) => d.total)
            .reduce((a, b) => {
                return a + b
            }, 0)

    const handleTabChange = (item, selectedTab) => {
        if (!isExpanded(item)) {
            handleExpand(item)
        }
        const dictKey = item.itemTypeId

        setCurrentTabs({ ...currentTabs, [dictKey]: selectedTab.value })

        setSelectedFulfillmentStatuses({
            ...selectedFulfillmentStatuses,
            [dictKey]: null,
        })
    }

    const handeFulfillmentStatusChange = (
        item,
        selectedTab,
        fulfillmentStatus,
    ) => {
        if (!isExpanded(item)) {
            handleExpand(item)
        }
        const dictKey = item.itemTypeId

        setCurrentTabs({ ...currentTabs, [dictKey]: selectedTab.value })

        setSelectedFulfillmentStatuses({
            ...selectedFulfillmentStatuses,
            [dictKey]: fulfillmentStatus,
        })
    }

    return (
        <>
            {!!requestItemTypes?.length && (
                <Box className={classes.table} width="100%" key={id}>
                    <Box className="TableHead" width="100%">
                        <Box className={classes.tableHeaderRow}>
                            <Box
                                className={classes.tableHeaderCell}
                                width="100%"
                            >
                                {t('BusinessRequestItems')}{' '}
                                {requestItemTypes && <>({getSumOfItems()})</>}
                            </Box>
                        </Box>
                    </Box>
                    <Box className="TableBody" width="100%" overflow="hidden">
                        {isLoading ? (
                            <Box className="TableRow">
                                <Box className="TableCell">
                                    <Skeleton
                                        animation="wave"
                                        variant="rectangular"
                                        height={64}
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <>
                                {requestItemTypes.map((item, index) => (
                                    <React.Fragment key={item.itemTypeId}>
                                        <Box
                                            className={classes.tableRow}
                                            key={index}
                                            justifyContent="space-between"
                                        >
                                            <Box className={classes.firstCell}>
                                                {item.itemTypeFriendlyName}
                                            </Box>

                                            <Box className={classes.tabsCell}>
                                                <Tabs
                                                    variant="scrollable"
                                                    value={
                                                        currentTabs[
                                                            item.itemTypeId
                                                        ]
                                                            ? currentTabs[
                                                                  item
                                                                      .itemTypeId
                                                              ]
                                                            : false
                                                    }
                                                    disabled={!isExpanded(item)}
                                                    scrollButtons="auto"
                                                    sx={{
                                                        '.MuiTabs-scroller': {
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        },
                                                    }}
                                                >
                                                    {ITEM_STATUS_TABS.map(
                                                        (
                                                            itemStatusTab,
                                                            index,
                                                        ) => {
                                                            const count =
                                                                item[
                                                                    itemStatusTab
                                                                        .prop
                                                                ] || 0
                                                            if (
                                                                isNilOrEmpty(
                                                                    item[
                                                                        itemStatusTab
                                                                            .prop
                                                                    ],
                                                                )
                                                            ) {
                                                                return <></>
                                                            }
                                                            return (
                                                                <HorizontalTabs.Tab
                                                                    key={`${itemStatusTab.value}-${index}`}
                                                                    value={
                                                                        itemStatusTab.value
                                                                    }
                                                                    label={
                                                                        <Box
                                                                            display="flex"
                                                                            flexDirection="row"
                                                                        >
                                                                            <RoleCount
                                                                                label={
                                                                                    itemStatusTab.label
                                                                                }
                                                                                count={
                                                                                    count
                                                                                }
                                                                                color={getStatusColor(
                                                                                    itemStatusTab.prop,
                                                                                    '#000000',
                                                                                )}
                                                                                onClick={() => {
                                                                                    handleTabChange(
                                                                                        item,
                                                                                        itemStatusTab,
                                                                                    )
                                                                                }}
                                                                            />
                                                                            {count >
                                                                                0 &&
                                                                                itemStatusTab.showFullfilmentStatusDropdown && (
                                                                                    <FulfillmentStatusDropDown
                                                                                        key={
                                                                                            itemStatusTab.prop
                                                                                        }
                                                                                        requestId={
                                                                                            id
                                                                                        }
                                                                                        itemTypeId={
                                                                                            item.itemTypeId
                                                                                        }
                                                                                        itemStatus={
                                                                                            itemStatusTab.value
                                                                                        }
                                                                                        onChange={(
                                                                                            fulfillmentStatus,
                                                                                        ) => {
                                                                                            handeFulfillmentStatusChange(
                                                                                                item,
                                                                                                itemStatusTab,
                                                                                                fulfillmentStatus,
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                        </Box>
                                                                    }
                                                                />
                                                            )
                                                        },
                                                    )}
                                                </Tabs>
                                            </Box>

                                            <Box
                                                className={classes.tableCell}
                                                style={{
                                                    maxWidth: '260px',
                                                    minWidth: '120px',
                                                    paddingRight: '24px',
                                                    display: 'inline-table',
                                                    whiteSpace: 'nowrap',
                                                }}
                                                align="right"
                                            >
                                                <ExpandIcon
                                                    expanded={isExpanded(item)}
                                                    onClick={() => {
                                                        if (!isExpanded(item)) {
                                                            handleTabChange(
                                                                item,
                                                                ITEM_STATUS_TABS.find(
                                                                    (t) =>
                                                                        t.value ===
                                                                        'All',
                                                                ),
                                                            )
                                                        }
                                                        handleExpand(
                                                            item,
                                                            'details',
                                                        )
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box
                                                className={
                                                    Boolean(isExpanded(item))
                                                        ? classes.expansionCellActive
                                                        : classes.expansionCell
                                                }
                                            >
                                                <Collapse
                                                    in={Boolean(
                                                        isExpanded(item),
                                                    )}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    {renderCollapsedContent(
                                                        item,
                                                    )}
                                                </Collapse>
                                            </Box>
                                        </Box>
                                    </React.Fragment>
                                ))}
                            </>
                        )}
                    </Box>
                </Box>
            )}
        </>
    )
}

export default RequestItemsSummary

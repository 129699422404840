import React from 'react'
import PropTypes from 'prop-types'
import { Box, Drawer as MuiDrawer, styled } from '@mui/material'
import SideCloseButton from './SideCloseButton'
import { Spinner, Tooltip } from 'packages/eid-ui'
import WithCloseButton from '../WithCloseButton'
import { useTranslation } from 'react-i18next'

const LoadingContainer = styled('div')({
    position: 'absolute',
    top: '33%',
    left: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
})

const Header = styled('div')(({ position }) => ({
    position: position,
    zIndex: 20,
    top: 0,
    left: 0,
    width: '100%',
}))

const ScrollContainer = styled('div')(({ top }) => ({
    height: `calc(100% - ${top}px)`,

    overflow: 'auto',
    msOverflowY: 'auto',
    msOverflowX: 'auto',

    '@supports ( -moz-appearance:none )': {
        overflow: 'auto',
    },
    '@supports ( -webkit-appearance: none )': {
        overflow: 'auto overlay',
    },
    '::-webkit-scrollbar': {
        borderLeft: '1px solid #d8d8dd',
        backgroundImage: 'linear-gradient(to right, #efeff1 0%, #fcfcfd 100%)',
    },
    '::-webkit-scrollbar-thumb': {
        border: '6px solid transparent',
    },
    '::-webkit-scrollbar-thumb:hover': {
        height: '60px',
        borderRadius: '10px',
        border: '5px solid transparent',
        background: '#aab0b4',
        backgroundClip: 'padding-box',
    },
    '::-webkit-scrollbar-thumb:active': {
        height: '60px',
        borderRadius: '10px',
        border: '5px solid transparent',
        background: '#8b909a',
        backgroundClip: 'padding-box',
    },
}))

export default function Drawer({
    bgColor,
    sideButton,
    width,
    sideButtonWidth,
    top,
    children,
    loading,
    toggleDrawer,
    open,
    header,
    stickyHeader,
    closeOnOutsideClick,
    closeLabel,
    className,
    ...rest
}) {
    const { t } = useTranslation()

    return (
        <MuiDrawer
            {...rest}
            sx={{
                '& .MuiDrawer-paper': {
                    top: top,
                    width: width,
                    backgroundColor: sideButton ? '#edeff3' : bgColor,
                    height: `calc(100% - ${top}px)`,
                    borderTopLeftRadius: '16px',
                    borderBottomLeftRadius: '0px',
                    overflowY: 'hidden',
                },
            }}
            anchor="right"
            open={open}
            onClose={closeOnOutsideClick ? toggleDrawer : undefined}
            className={className}
            ModalProps={{
                BackdropComponent: (props) => (
                    <Tooltip
                        title={closeLabel || t('Close')}
                        followCursor
                        PopperProps={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 16],
                                    },
                                },
                            ],
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                backdropFilter: 'blur(1px)',
                            }}
                            {...props}
                            open={rest.open}
                            onClose={rest.onClose}
                        />
                    </Tooltip>
                ),
            }}
        >
            {sideButton ? (
                <ScrollContainer>
                    <Box marginRight={sideButton && sideButtonWidth}>
                        {loading ? (
                            <Box
                                width="100%"
                                height="98vh"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Spinner></Spinner>
                            </Box>
                        ) : (
                            <>{children}</>
                        )}
                    </Box>
                    <SideCloseButton
                        top={top}
                        toggleDrawer={toggleDrawer}
                        width={sideButtonWidth}
                        closeLabel={closeLabel}
                    />
                </ScrollContainer>
            ) : (
                <>
                    {loading ? (
                        <LoadingContainer>
                            <Spinner></Spinner>
                        </LoadingContainer>
                    ) : (
                        <>
                            {header && (
                                <Header
                                    position={
                                        stickyHeader ? 'sticky' : 'absolute'
                                    }
                                >
                                    <WithCloseButton
                                        iconPosition={{
                                            right: '18px',
                                            top: '50%',
                                        }}
                                        onClose={toggleDrawer}
                                    >
                                        {header}
                                    </WithCloseButton>
                                </Header>
                            )}

                            <ScrollContainer top={top}>
                                {children}
                            </ScrollContainer>
                        </>
                    )}
                </>
            )}
        </MuiDrawer>
    )
}

Drawer.propTypes = {
    top: PropTypes.number,
    bgColor: PropTypes.string,
    sideButton: PropTypes.bool,
    sideButtonWidth: PropTypes.string,
    width: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    header: PropTypes.node,
}

Drawer.defaultProps = {
    top: 10,
    bgColor: '#ffffff',
    width: '510px',
    sideButtonWidth: '100px',
    loading: false,
}

const listToTree = (
    list,
    config = {
        identifier: 'id',
        parentIdentifier: 'parentId',
        rootParentIdentifier: null,
    },
) => {
    const treeMap = {}
    const rootNodes = []
    try {
        const refinedList = list.map((item) => {
            return {
                id: item.id,
                parentId: item.parentId,
                friendlyName: item.friendlyName,
            }
        })
        // Step 1: Create a map of nodes using their IDs
        refinedList.forEach((node) => {
            treeMap[node[config.identifier]] = { ...node, children: [] }

            // If parentId is null or undefined, it's a root node
            if (node[config.parentIdentifier] === config.rootParentIdentifier) {
                rootNodes.push(treeMap[node[config.identifier]])
            }
        })

        // Step 2: Build the tree structure by linking child nodes to their parent nodes
        refinedList.forEach((node) => {
            if (node[config.parentIdentifier]) {
                const parentNode = treeMap[node[config.parentIdentifier]]
                if (parentNode) {
                    parentNode.children.push(treeMap[node[config.identifier]])
                }
            }
        })
    } catch (err) {}

    return rootNodes
}

export default listToTree

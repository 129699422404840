import { Fragment, useEffect, useState } from 'react'
import { Box, Table, TableCell, TableHead, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { Trans, useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { isNilOrEmpty } from 'packages/core'
import { Pagination, RiskIndicator } from 'packages/eid-ui'
import { useRisksFunctionalAccess } from 'hooks'
import { FUNCTIONAL_ACCESS_PAGE_SIZE } from 'utils'

const useStyles = makeStyles({
    header: {
        display: 'flex',
        alignItems: 'center',
        color: '#1b1f1f',
        fontSize: '16px',
        fontWeight: 'bold',
        paddingLeft: '24px',
        paddingBottom: '14px',
        paddingTop: '14px',
    },
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {},
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: '#307fc1 !important',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
    paginationSteppersContainer: {
        width: '100%',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

export interface FunctionalAccessItemProps {
    assigneeId: string
}

const FunctionalAccessItem: React.FC<FunctionalAccessItemProps> = ({
    assigneeId,
}) => {
    const classes = useStyles()
    let minimumRowsPerPage
    const [totalNumberOfPages, setTotalNumberOfPages] = useState<number>(0)
    const { t } = useTranslation()
    const [perPage, setPerPage] = useState(FUNCTIONAL_ACCESS_PAGE_SIZE)
    const [page, setPage] = useState(1)

    const handlePageChange = (value: any) => {
        setPage(value)
    }

    // Using isfecthging instead of isLoading as isLoaidng value in not getting changes after first time load
    const { latestData, isFetching } = useRisksFunctionalAccess(
        assigneeId,
        (page - 1) * perPage,
        perPage,
    )

    if(FUNCTIONAL_ACCESS_PAGE_SIZE < 10){
        minimumRowsPerPage = FUNCTIONAL_ACCESS_PAGE_SIZE
    }

    useEffect(() => {
        if (latestData?.totalCount) {
            setTotalNumberOfPages(Math.ceil(latestData.totalCount / perPage))
        }
    }, [latestData?.totalCount])

    const attributes = [
        {
            name: 'localFunctionFriendlyName',
            label: t('FunctionName'),
            style: {
                color: '#5d6870',
                minWidth: '120px',
            },
        },
        {
            name: 'functionType',
            label: t('FunctionType'),
            style: {
                minWidth: '120px',
            },
        },
        {
            name: 'description',
            label: t('Description'),
            style: {
                minWidth: '120px',
            },
        },
        {
            style: {
                width: '120px',
            },
            label: t('RiskLevel'),
            resolve: (item: any) => (
                <RiskIndicator
                    variant={item.riskLevel}
                    label={t(`${item.riskLevel}Risk`)}
                ></RiskIndicator>
            ),
        },
        {
            style: {
                width: '50px',
            },
            resolve: () => <></>,
        },
    ]

    const headings = attributes.map((a) => a.label)

    return (
        <Box>
            <Box className={classes.header}>{t('ItemsFunctionalAccess')}</Box>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRow}>
                        {headings.map((heading, index) => (
                            <TableCell
                                key={index}
                                className={clsx(
                                    classes.tableHeaderCell,
                                    classes.tableCell,
                                    classes.headGrey,
                                )}
                            >
                                {heading}
                            </TableCell>
                        ))}
                    </TableRow>
                    {isFetching ? (
                        <TableRow>
                            <TableCell colSpan={headings.length + 2}>
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                    height={64}
                                />
                            </TableCell>
                        </TableRow>
                    ) : latestData?.data?.length === 0 ? (
                        <TableRow className={classes.tableRow} hover={false}>
                            <TableCell
                                className={classes.tableCell}
                                colSpan={headings.length + 2}
                                style={{ textAlign: 'center' }}
                            >
                                <Trans i18nKey="NoDataFound" />
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {latestData?.data?.map((item: any) => (
                                <Fragment key={item.id}>
                                    <TableRow className={classes.tableRow}>
                                        {attributes.map((a, index) => (
                                            <TableCell
                                                key={`${item.id}${index}`}
                                                className={classes.tableCell}
                                                style={a.style}
                                            >
                                                {a.resolve
                                                    ? a.resolve(item)
                                                    : !isNilOrEmpty(
                                                          item[a.name],
                                                      )
                                                    ? item[a.name]
                                                    : '-'}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </Fragment>
                            ))}
                        </>
                    )}
                </TableHead>
            </Table>
            {!isFetching && (
                <Box className={classes.paginationSteppersContainer}>
                    <Pagination
                        count={totalNumberOfPages}
                        size="large"
                        page={page}
                        onChange={(_: any, value: any) =>
                            handlePageChange(value)
                        }
                        parentIndex={199}
                        showPageSelection={true}
                        onPageSelection={(val: any) => setPage(val)}
                        minimumRowsPerPage={minimumRowsPerPage}
                        showItemsPerPageSelection={true}
                        onItemsPerPageChange={(val: any) => {
                            setPage(1)
                            setPerPage(val)
                        }}
                        itemsPerPage={perPage}
                        showCountStat={true}
                        totalCount={latestData?.totalCount}
                    />
                </Box>
            )}
        </Box>
    )
}

export default FunctionalAccessItem

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputField from './InputField'
import { isNilOrEmpty } from 'packages/core'
import { ValidationMessage } from 'packages/eid-ui'
import {
    getDecisionCommentValidationMessage,
    DECISION_COMMENT_MAX_LENGTH,
} from 'utils'
import PredefinedComments from './PredefinedComments'

const useStyles = makeStyles({
    precontainer: {
        position:"relative",
       '& .MuiBox-root':{
       padding:'0 !important'
       }
    },
  
})
const DecisionComment = (props) => {
    const classes = useStyles({ })
    const { initialValue = '', onBlur, onChange = () => {}, isRejected } = props
    const { t } = useTranslation()
    const [comment, setComment] = useState(initialValue)
    const [selectedJustification, setSelectedJustification] = useState()

    const validationMessage = getDecisionCommentValidationMessage(
        comment,
        isRejected,
    )
    const isError = !isNilOrEmpty(validationMessage)
    return (
        <>
            <Box  className={classes.precontainer}>
                <PredefinedComments
                    selectedJustification={selectedJustification}
                    setSelectedJustification={setSelectedJustification}
                    setDecisionComment={(comment) => {
                        setComment(comment)
                        onChange(comment)
                    }}
                />
            </Box>
            {selectedJustification?.name === 'Other' && (
                <Box position="relative" marginTop="20px">
                    <InputField
                        value={comment}
                        multiline={true}
                        rows={2}
                        placeholder={t('AddADecisionComment')}
                        onChange={(e) => {
                            setComment(e.target.value)
                            onChange(e.target.value)
                        }}
                        onBlur={onBlur}
                        error={isError}
                    />

                    <Box position="absolute" right={2} fontSize="10px">
                        {comment?.length}/{DECISION_COMMENT_MAX_LENGTH}
                    </Box>
                </Box>
            )}
            {isError && <ValidationMessage message={validationMessage} />}
        </>
    )
}

export default DecisionComment

const useUrlGenerator = (useGetApplications) => {
    const { data } = useGetApplications()

    const getApp = (appName) => {
        return data.find(
            (a) => a.applicationName.toLowerCase() === appName.toLowerCase(),
        )
    }

    const getAppUrl = (appName, path = null) => {
        const app = getApp(appName)
        if (!app) return null

        const abosoluteUrl = ensureTrailingSlashRemoved(
            app.applicationAbsoluteUrl,
        )
        if (path) {
            return `${abosoluteUrl}${path}`
        }

        return abosoluteUrl
    }

    const ensureTrailingSlashRemoved = (url) => {
        const lastElement = url[url.length - 1]
        if (lastElement === '/') {
            return url.substring(0, url.length - 1)
        }
        return url
    }

    const getEidUrl = (path) => getAppUrl('EIDEmpowerIDWeb', path)

    const getAzAnalyticsUrl = (path) => getAppUrl('EIDAzureAnalyticsMS', path)

    const getItShopUrl = (path) => getAppUrl('EIDITShopMS', path)

    const getMyIdentityUrl = (path) => getAppUrl('EIDMyIdentity', path)

    const getMyTasksUrl = (path) => getAppUrl('EIDMyTasks', path)

    return {
        getApp,
        getAppUrl,
        getEidUrl,
        getAzAnalyticsUrl,
        getItShopUrl,
        getMyIdentityUrl,
        getMyTasksUrl,
    }
}

export default useUrlGenerator

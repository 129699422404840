import { User, UserManager } from 'oidc-client'
import axios from 'axios'
import { userLoggedIn } from './actions'
const jwt_decode = require('jwt-decode')

export const setOidcUser = (userManager: UserManager, user: User) => {
    userManager.storeUser(
        // @ts-ignore
        new User({
            ...user,
        }),
    )
}

export const refreshAccessToken = async (userManager: UserManager) => {
    const existingUser = await userManager.getUser()

    if (!existingUser) return

    const params = {
        client_id: userManager.settings.client_id,
        scope: userManager.settings.scope,
        grant_type: 'refresh_token',
        refresh_token: existingUser.refresh_token,
    }

    const data = Object.keys(params)
        // @ts-ignore
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&')

    try {
        let response = await axios.post('/v2/token', data, {
            baseURL: userManager.settings.authority,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            validateStatus: (status) => status >= 200 && status <= 299,
        })

        const decodedAccessToken = jwt_decode(response.data.access_token)
        const newExpiresAt = decodedAccessToken.exp | 0

        if (newExpiresAt <= existingUser.expires_at) {
            return
        }

        // authDispatch(
        //     userLoggedIn({
        //         accessToken: response.data.access_token,
        //         refreshToken: response.data.refresh_token,
        //         tokenExpiresAt: decodedAccessToken.exp,
        //     }),
        // )

        userManager.storeUser(
            // @ts-ignore
            new User({
                ...existingUser,
                access_token: response.data.access_token,
                refresh_token: response.data.refresh_token,
                expires_at: newExpiresAt,
            }),
        )
    } catch (err) {
        // Failed to refresh access token, navigate to session exprired page
        window.location.href = '/sessionExpired'
    }
}

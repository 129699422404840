import React from 'react'
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material'

const ListItemSkeleton = styled(Skeleton)({
    width: '100%',
    margin: '8px',
    height: 6,
})

const Container = styled('div')({
    width: '100%',
    height: '100%',
    display: ' flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
})

const Loader = () => {
    return (
        <Container>
            <ListItemSkeleton animation="wave" variant="rect" />

            <ListItemSkeleton animation="wave" variant="rect" />

            <ListItemSkeleton animation="wave" variant="rect" />
        </Container>
    )
}

export default Loader

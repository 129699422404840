import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const TruncatedText = ({ value, maxCharsToShow }) => {
    const [showMore, setShowMore] = useState(false)
    const { t } = useTranslation()

    return (
        <>
            <span>
                {showMore || value.length <= maxCharsToShow
                    ? value
                    : `${value.substring(0, maxCharsToShow)}...`}
            </span>
            {value.length > maxCharsToShow && (
                <span
                    style={{ color: 'blue', cursor: 'pointer' }}
                    onClick={() => setShowMore(!showMore)}
                >
                    {showMore ? ` - ${t('ShowLess')}` : ` + ${t('ShowMore')} `}
                </span>
            )}
        </>
    )
}

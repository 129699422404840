import { DateTimePicker } from '@mui/x-date-pickers'
import { useState } from 'react'
import { TextField, useTheme } from '@mui/material'
import moment from 'moment'

const MUIDateTimePicker = ({
    handleChange,
    value,
    minDate = '',
    maxDate = '',
    disabled = false,
    showWarningColor,
    label = '',
    fieldProps,
    customComponents,
    customComponentsProps,
    dateTimeFormat = 'L LT',
}) => {
    const [open, setOpen] = useState(false)
    const theme = useTheme()

    return (
        <DateTimePicker
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={value}
            onChange={(e) => {
                handleChange(e)
            }}
            minDateTime={minDate}
            maxDateTime={maxDate}
            disabled={disabled}
            timeSteps={{ minutes: 1 }}
            slots={{
                field: TextField,
                ...customComponents,
            }}
            slotProps={{
                field: {
                    onClick: () => {
                        setOpen((open) => !open)
                    }, // Trigger picker on click
                    label: label,
                    clearable: true,
                    type: 'text',
                    value: value
                        ? moment.utc(value).local().format(dateTimeFormat)
                        : '',
                    InputLabelProps: {
                        shrink: true,
                    },
                    inputProps: {
                        style: {
                            color: showWarningColor
                                ? theme?.palette?.warning?.main
                                : 'black',
                        },
                    },
                    ...fieldProps,
                },
                openPickerButton: {
                    onClick: (e) => {
                        e.preventDefault()
                    },
                    sx: { display: 'none' },
                },
                actionBar: {
                    actions: ['clear', 'today'],
                },
                ...customComponentsProps,
            }}
        />
    )
}

export default MUIDateTimePicker

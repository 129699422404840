import WorkflowCommentsList from 'components/CommentsList/WorkflowCommentsList'
import { useWorkflowItemComments, useWorkflowAddComment } from 'hooks'
import CommentsSectionContainer from 'components/CommentsSectionContainer'

const commentsContainerProps = {
    style: {
        maxHeight: '400px',
        overflow: 'auto',
        paddingRight: '20px',
        marginRight: '-15px',
        paddingBottom: '36px',
    },
    width: 'auto',
}

const WorkflowComments = ({ businessProcessId, workflowTaskId }) => {
    const {
        data: comments,
        isLoading: loadingComments,
    } = useWorkflowItemComments(businessProcessId)

    const [addComment, { isLoading: isSubmitting }] = useWorkflowAddComment(
        businessProcessId,
    )

    return (
        <CommentsSectionContainer comments={comments}>
            <WorkflowCommentsList
                page="WorkflowItems"
                requestId={businessProcessId}
                commentsContainerProps={commentsContainerProps}
                list={comments}
                loading={loadingComments}
                isSubmitting={isSubmitting}
                handleAddComment={(value) =>
                    addComment({
                        workflowTaskId: workflowTaskId,
                        comment: value,
                    })
                }
            />
        </CommentsSectionContainer>
    )
}

export default WorkflowComments

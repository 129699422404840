import { Box, styled } from '@mui/material'

const IconLabel = styled(Box)({
    fontSize: '14px',
    fontWeight: 'bold',
    wordBreak:'break-word',

    width: '120px',

    marginLeft: '10px',
})

export default IconLabel

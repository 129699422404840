import { FC, Fragment, useEffect, useState } from 'react'
import { CheckboxList } from 'packages/eid-ui'
import { Box, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IMitigatingControl } from 'types'
import { useTranslation } from 'react-i18next'
import { useMitigatingControls } from 'hooks'
import { Skeleton } from '@mui/material';

const useStyles = makeStyles(
    createStyles({
        root: {
            paddingTop: '12px',
            paddingBottom: '12px',
        },
        title: {
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: 1.1,
            color: '#9b9b9b',
            textTransform: 'uppercase',
        },
        listItemsContainer: {
            marginLeft: '3px',
        },
    }),
)

export interface MitigationControlListProps {
    globalRiskId: string
    onChange: (selected: IMitigatingControl[]) => void
}

const MitigationControlList: FC<MitigationControlListProps> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const { data, isLoading } = useMitigatingControls(props.globalRiskId, true)

    const [selectedItems, setSelectedItems] = useState([])

    const handleChange = (item: any) => {
        const currentIndex = selectedItems.findIndex(
            (i: any) => i.id === item.id,
        )
        const newChecked: any = [...selectedItems]

        if (currentIndex === -1) {
            newChecked.push(item)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setSelectedItems(newChecked)
    }

    useEffect(() => {
        props.onChange(selectedItems)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems])

    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>
                {t('SelectMitigationControl')}{' '}
                <Box component={'span'} color={'#D0021b'}>
                    *
                </Box>
            </Typography>

            <Box className={classes.listItemsContainer}>
                {isLoading || !data ? (
                    <Skeleton height={40} />
                ) : data.length === 0 ? (
                    <Fragment>{t('NoDataFound')}</Fragment>
                ) : (
                    <CheckboxList
                        dataList={data}
                        checked={selectedItems}
                        handleToggle={handleChange}
                    />
                )}
            </Box>
        </Box>
    )
}

export default MitigationControlList

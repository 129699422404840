import { useMutation, useQuery } from 'react-query'
import { useAxios } from 'packages/core'
import { refreshComments } from './utils'
import { useAuthState } from 'packages/core'

export const useAddComment = (page, requestId, itemId) => {
    const callApi = useAxios()
    const [{ tokenExpiresAt }] = useAuthState()
    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/comments/create',
                data,
            }),
        {
            onSuccess: () =>
                refreshComments(page, tokenExpiresAt, requestId, itemId),
        },
    )
}

export const useReplyComment = (page, requestId, itemId) => {
    const callApi = useAxios()
    const [{ tokenExpiresAt }] = useAuthState()
    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/comments/reply',
                data,
            }),
        {
            onSuccess: () =>
                refreshComments(page, tokenExpiresAt, requestId, itemId),
        },
    )
}

export const useEditComment = (page, requestId, itemId) => {
    const callApi = useAxios()
    const [{ tokenExpiresAt }] = useAuthState()
    return useMutation(
        (data) =>
            callApi({
                method: 'PUT',
                url: '/api/Comments/edit',
                data: data,
            }),
        {
            onSuccess: () =>
                refreshComments(page, tokenExpiresAt, requestId, itemId),
        },
    )
}

export const useDeleteComment = (page, requestId, itemId) => {
    const callApi = useAxios()
    const [{ tokenExpiresAt }] = useAuthState()
    return useMutation(
        (id) =>
            callApi({
                method: 'DELETE',
                url: `/api/Comments/delete/${id}`,
            }),
        {
            onSuccess: () => {
                refreshComments(page, tokenExpiresAt, requestId, itemId)
            },
        },
    )
}

export const usePredefinedComment = () => {
    const callApi = useAxios()
    return useQuery(
        'PREDEFINED_COMMENTS',
        () =>
            callApi({
                method: 'GET',
                url: 'api/comments/PredefinedApproval',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

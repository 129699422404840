import { Button as MuiButton } from '@mui/material'
import ApproveButton from './ApproveButton'
import ClaimButton from './ClaimButton'
import UnclaimButton from './UnclaimButton'
import DelegateButton from './DelegateButton'
import RevokeButton from './RevokeButton'
import SendButton from './SendButton'
import SearchButton from './SearchButton'
import FiltersButton from './FiltersButton'
import ExpandButton from './ExpandButton'
import ApplyFiltersButton from './ApplyFiltersButton'
import SortButton from './SortButton'
import ViewDetailsButton from './ViewDetailsButton'
import BackButton from './BackButton'
import RoundedButton from './RoundedButton'
import DecisionButton from './DecisionButton'
import SubmitButton from './SubmitButton'
import { Loader } from 'packages/eid-ui'
import DoNothingButton from './DoNothingButton'
import TimeConstraintButton from './TimeConstraintButton'
import EditTimeConstraintButton from './EditTimeConstraintButton'

const styles = {
    small: {
        padding: '3.25px 20px',
        minHeight: '35px',
    },
    medium: {
        padding: '5.25px 15px',
        minHeight: '39px',
    },
    large: {
        padding: '9.25px 30px',
        minHeight: '47px',
    },
}

const Button = (props) => {
    const {
        size,
        children,
        style,
        disabledStyles,
        loading,
        loaderColor,
        startIcon,
        endIcon,
        onClick,
        ...rest
    } = props
    const variantStyles = styles[size]
    return (
        <MuiButton
            startIcon={!loading && startIcon}
            endIcon={!loading && endIcon}
            size={size}
            sx={{
                textTransform: 'capitalize',
                fontSize: '16px',
                border: '1px solid transparent',
                borderRadius: '5px',
                ...variantStyles,
                '& .MuiButton-startIcon': {
                    marginRight: size === 'large' ? '14px' : '10px',
                },
                '&.Mui-disabled': {
                    ...disabledStyles,
                },
                ...style,
            }}
            onClick={!loading ? onClick : undefined}
            {...rest}
        >
            {!loading ? (
                children
            ) : (
                <Loader
                    color={loaderColor ? loaderColor : '#ffffff'}
                    size={0.6}
                    height="0.5inh"
                />
            )}
        </MuiButton>
    )
}

Button.Approve = ApproveButton
Button.DoNothing = DoNothingButton
Button.Claim = ClaimButton
Button.Unclaim = UnclaimButton
Button.Delegate = DelegateButton
Button.Revoke = RevokeButton
Button.Send = SendButton
Button.Search = SearchButton
Button.Filters = FiltersButton
Button.Expand = ExpandButton
Button.Sort = SortButton
Button.ApplyFilters = ApplyFiltersButton
Button.ViewDetails = ViewDetailsButton
Button.BackButton = BackButton
Button.Rounded = RoundedButton
Button.Decision = DecisionButton
Button.Submit = SubmitButton
Button.TimeConstraint = TimeConstraintButton
Button.EditTimeConstraint = EditTimeConstraintButton

export default Button

import { forwardRef } from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { isNilOrEmpty } from 'packages/core'
import classNames from 'classnames'

export interface IAttributeValueProps {
    className?: string
    children?: any
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            fontSize: '1.4rem',
            lineHeight: 1.33,
            color: theme.palette.common.black,
            width: '100%',
            display: 'block',
            wordBreak: 'break-word',
            wordWrap: 'break-word',
            paddingTop: '0.5rem',
            letterSpacing: '0.048rem',
        },
    }),
)

export const AttributeValue = forwardRef<any, IAttributeValueProps>(
    (props, ref) => {
        const classes = useStyles()

        return (
            <div
                className={classNames(classes.root, props.className)}
                ref={ref}
            >
                {isNilOrEmpty(props.children) ? '-' : props.children}
            </div>
        )
    },
)

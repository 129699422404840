import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { resetAuthState } from '../actions'
import { useAuthState } from '../context'
import useUserManager from '../useUserManager'

function LoginCallback({ render }) {
    const [, dispatch] = useAuthState()
    const userManager = useUserManager()

    useEffect(() => {
        dispatch(resetAuthState())
        userManager.signoutRedirectCallback()
    }, [dispatch])

    return render()
}

LoginCallback.propTypes = {
    render: PropTypes.func.isRequired,
}

export default LoginCallback

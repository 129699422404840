import React, { useState } from 'react'
import { Box, MenuItem as MuiMenuItem, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'
import { Dropdown, Tooltip } from 'packages/eid-ui'
import Button from '../Button'
import { Icon } from 'packages/eid-icons'

const MenuItem = styled(MuiMenuItem)(({ number }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px',
    color: '#919193',
    border: '1px solid transparent',
    minHeight: '40px',
    padding: number ? '10px 4px' : '10px 16px',
    '& svg': {
        width: '16px',
        height: '16px',
    },
    '& div': {
        fontSize: '13px',
        marginTop: '2px',
        marginRight: '10px',
        color: '#919193',
        '& span': {
            fontWeight: 'bold',
            color: '#3b454d',
        },
    },
}))

const nameBasedDecisions = ['Certify', 'Convert to JIT']

const decisionColors = {
    Continue: {
        primary: '#1eb77b',
        secondary: '#e1fee6',
    },
    End: {
        primary: '#d0021b',
        secondary: '#f9dada',
    },
    DoNothing: {
        primary: '#000000',
    },
    Clear: {
        primary: '#000000',
    },
    Certify: {
        primary: '#9230c5',
        secondary: '#f0def9',
    },
    'Convert to JIT': {
        primary: '#ff7e00',
        secondary: '#f8eadc',
    },
}

const decisionIcons = {
    Continue: 'Approve',
    End: 'Close',
    DoNothing: 'Delete',
    Clear: 'Delete',
    Certify: 'Clipboard',
    'Convert to JIT': 'Defer',
}

const useStyles = makeStyles(() => ({
    Continue: {
        color: decisionColors.Continue.primary,
        '&:hover': {
            backgroundColor: `${decisionColors.Continue.secondary} !important`,
            border: `1px solid ${decisionColors.Continue.primary} !important`,
        },
    },
    End: {
        color: decisionColors.End.primary,
        '&:hover': {
            backgroundColor: `${decisionColors.End.secondary} !important`,
            border: `1px solid  ${decisionColors.End.primary} !important`,
        },
    },
    DoNothing: {
        color: decisionColors.DoNothing.primary,
        '&:hover': {
            border: `1px solid  ${decisionColors.DoNothing.primary} !important`,
        },
    },
    Clear: {
        color: decisionColors.DoNothing.primary,
        '&:hover': {
            border: `1px solid  ${decisionColors.DoNothing.primary} !important`,
        },
    },
    Certify: {
        color: decisionColors.Certify.primary,
        '&:hover': {
            backgroundColor: `${decisionColors.Certify.secondary} !important`,
            border: `1px solid ${decisionColors.Certify.primary} !important`,
        },
    },
    ConverttoJIT: {
        color: decisionColors['Convert to JIT'].primary,
        '&:hover': {
            backgroundColor: `${decisionColors['Convert to JIT'].secondary} !important`,
            border: `1px solid  ${decisionColors['Convert to JIT'].primary} !important`,
        },
    },
}))

const styles = {
    rootMenu: {
        paddingRight: '0px',
        marginTop: '10px',
        width: '170px',
    },
    list: {
        padding: '0px 6px',
    },
}

const getDecisionLabel = (decision) =>
    decision.decisionFriendlyName ?? decision.decisionName

const DecisionMenuItem = ({
    decision,
    onChange,
    numberOfDecisions,
    nameBasedAction,
}) => {
    const classes = useStyles()

    const decisionKeyName = nameBasedAction
        ? decision.decisionName
        : decision.decisionAction

    return (
        <Tooltip
            title={getDecisionLabel(decision)}
            placement="bottom"
            enterDelay={400}
            enterNextDelay={400}
        >
            <MenuItem
                onClick={onChange}
                className={classes[decisionKeyName.replaceAll(' ', '')]}
                number={numberOfDecisions}
            >
                <label style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    minWidth: '35px',
                    maxWidth: '90px',
                }}>


                    {getDecisionLabel(decision)}
                </label>
                {numberOfDecisions && (
                    <div>
                        <span >{decision.count}</span>/{numberOfDecisions}
                    </div>
                )}
                {decisionIcons[decisionKeyName] && (
                    <Icon
                        name={decisionIcons[decisionKeyName]}
                        color={decisionColors[decisionKeyName]?.primary}
                    />
                )}
            </MenuItem>
        </Tooltip>
    )
}

const DecisionMenu = ({
    value,
    options,
    onChange,
    isClearable = true,
    numberOfDecisions = null,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    const selectedValue = options.find((o) => o.decisionId === value)

    const getVariant = (value, selectedValue) => {
        if (value === 'DecideAll') return value
        else if (selectedValue) {
            if (nameBasedDecisions.includes(selectedValue.decisionName)) {
                return selectedValue.decisionName
            } else return selectedValue.decisionAction
        } else return 'default'
    }

    return (
        <Box>
            <Tooltip
                title={
                    selectedValue
                        ? getDecisionLabel(selectedValue)
                        : value === 'DecideAll'
                        ? t('DecideAll')
                        : t('Decision')
                }
                placement="bottom"
                enterDelay={400}
                enterNextDelay={400}
            >
                <Button.Decision
                    endIcon
                    variant={getVariant(value, selectedValue)}
                    onClick={(e) => {
                        setOpen((prev) => !prev)
                        setAnchorEl(e.currentTarget)
                    }}
                >
                    <label style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',
                        minWidth: '35px',
                        maxWidth: '90px',
                    }}>
                        {selectedValue
                            ? getDecisionLabel(selectedValue)
                            : value === 'DecideAll'
                                ? t('DecideAll')
                                : t('Decision')}
                    </label>
                </Button.Decision>
            </Tooltip>
            <Dropdown
                open={open}
                anchorEl={anchorEl}
                width="160px"
                rootStyles={styles.rootMenu}
                listStyles={styles.list}
                handleClose={handleClose}
                handleListKeyDown={handleListKeyDown}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {options.map((decision, index) => {
                    return (
                        <DecisionMenuItem
                            key={`${decision.decisionId}_${index}`}
                            decision={decision}
                            numberOfDecisions={numberOfDecisions}
                            nameBasedAction={nameBasedDecisions.includes(
                                decision.decisionName,
                            )}
                            onChange={() => {
                                setOpen(false)
                                onChange(decision)
                            }}
                        />
                    )
                })}
                {isClearable && (
                    <Tooltip
                        title={t('Clear')}
                        placement="bottom"
                        enterDelay={400}
                        enterNextDelay={400}
                    >
                        <MenuItem
                            onClick={() => {
                                setOpen(false)
                                onChange(null)
                            }}
                            className={classes['Clear']}
                            number={numberOfDecisions}
                        >
                            <label style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                                minWidth: '35px',
                                maxWidth: '90px',
                            }}>
                                {t('Clear')}
                            </label>
                            <Icon
                                name={decisionIcons['Clear']}
                                color={decisionColors['Clear'].primary}
                            />
                        </MenuItem>
                    </Tooltip>
                )}
            </Dropdown>
        </Box>
    )
}

DecisionMenu.DecisionMenuItem = DecisionMenuItem

export default DecisionMenu

import { useTranslation } from 'react-i18next'
import { usePageContext } from 'pageContext'
import { Card } from 'packages/eid-ui'
import React, { FC, Fragment } from 'react'
import { Box, FormControlLabel, Radio } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from 'packages/eid-icons'
import { useRequestItemFulfillmentStatuses } from 'hooks'
import { Skeleton } from '@mui/material';

const useFormControlStyles = makeStyles(() => ({
    formLabelMargin: {
        marginRight: '30px',
        padding: '10px',
    },
    radio: {
        padding: '0px 12px 0px 0px',
    },
    checked: {
        '&, & + $label': {
            color: '#000000',
        },
    },
    formControlLabel: {
        padding: '6px',
    },
    label: {
        fontSize: '14px',
        color: '#000000',
    },
}))

const StyledRadio: FC<any> = (props) => {
    const { checked, label, onChange, value } = props
    const classes = useFormControlStyles()
    return (
        <FormControlLabel
            classes={{
                root: classes.formControlLabel,
                label: classes.label,
            }}
            checked={checked}
            value={value}
            label={label}
            labelPlacement="end"
            control={
                <Radio
                    icon={<Icon name="Radio" />}
                    classes={{
                        root: classes.radio,
                        checked: classes.checked,
                    }}
                    checkedIcon={<Icon name="RadioFilled" />}
                />
            }
            onChange={onChange}
        />
    )
}

const FulfillmentStatusesFilter = () => {
    const { t } = useTranslation()
    const { data, isLoading } = useRequestItemFulfillmentStatuses()
    const [state, dispatch]: any = usePageContext()

    const onHandleChange = (e: any) => {
        const value = e.target.value
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'fulfillmentStatus',
                value: value,
            },
        })
    }

    return (
        <Fragment>
            {isLoading ? (
                <Box paddingTop={'16px'} marginX={'24px'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Box paddingTop={'16px'}>
                    {data.map((d: any) => (
                        <Box key={d} marginX={'24px'}>
                            <StyledRadio
                                label={t(`${d}`)}
                                checked={state.fulfillmentStatus === d}
                                value={d}
                                onChange={onHandleChange}
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </Fragment>
    )
}

export const FulfillmentStatusFilter = () => {
    const { t } = useTranslation()

    return (
        <Card cardTitle={t('FilterByFulfillmentStatus')} collapsible>
            <FulfillmentStatusesFilter />
        </Card>
    )
}

import React, { useState } from 'react'
import { Box, Collapse, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ExpandIconPlusMinus } from './ExpandIcon'
import { RiskIndicator } from 'packages/eid-ui'
import RiskFunctions from './RiskFunctions'
import { useTranslation } from 'react-i18next'
import { useRiskFunctionsWithRiskID } from 'hooks'
import { Skeleton } from '@mui/material';
const useStyles = makeStyles(
    createStyles({
        riskfunctionacc: {
            borderRadius: '8px',
            marginTop: '16px',
            border: '1px solid #307fc1',
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.12)',
            overflow: 'hidden',
            fontFamily: 'Rubik',
            '& span': {
                fontSize: '14px',
                fontWeight: '500',
                color: '#fff',
                display: 'block',
                fontFamily: 'Rubik',
            },
        },
        riskcollapse: {
            display: 'flex',
            '& h4': {
                fontSize: '12px',
                fontWeight: 500,
                color: '#9b9b9b',
                textTransform: 'uppercase',
                fontFamily: 'Rubik',
            },
            '& p': {
                fontSize: '12px !important',
                fontWeight: 400,
                color: '#000  !important',
                marginTop: '0',
                marginBottom: '0',
                fontFamily: 'Rubik',
            },
        },
        riskfunctionheader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: '#307fc1',
            padding: '8px  16px',
            height: '56px',
        },
        riskcollapseright: {
            maxWidth: '200px',
            '& button': {
                background: '#d0021b',
                padding: '4px 8px 4px 0',
                '& span': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontFamily: 'Rubik',
                },
                '& p': {
                    fontSize: '12px !important',
                    fontWeight: '600',
                    color: '#000 !important',
                    fontFamily: 'Rubik',
                },
            },
        },
        riskcollapseleft: {
            maxWidth: '180px',
            width: '100%',
        },
        riskcollapseinnersite: {},
        riskcollapseinnerbody: {
            padding: '20px 16px',
            borderBottom: '1px solid #d8d8d8',
        },
        riskIndicator: {
            backgroundColor: '#fdf1f3',
            opacity: 1,
            fontWeight: 'bold',
        },
    }),
)
const RiskViolationLeftAndRight = ({
    requestId,
    risk,
    riskFunction,
    isSOD,
    requestType,
    itemId,
}: any) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [expanded, setExpended] = useState(false)
    const businessRequestRiskAssigneeList =
        riskFunction?.businessRequestRiskAssigneeList
    const membershipRiskAssigneeList =
        riskFunction?.membershipRiskAssigneeList || []

    const { data: functionData, isLoading } = useRiskFunctionsWithRiskID(
        expanded ? riskFunction.riskFunctionId : '',
    )

    const bindMembershipRisk = () => {
        return membershipRiskAssigneeList.map((item: any, index: number) => (
            <RiskFunctions
                key={`membership-risk-functions-${index}`}
                itemId={itemId}
                requestType={requestType}
                isSOD={isSOD}
                isMembership={true}
                requestId={requestId}
                riskFunction={item}
            />
        ))
    }

    const bindBusinessRisk = () => {
        return businessRequestRiskAssigneeList.map(
            (item: any, index: number) => (
                <RiskFunctions
                    itemId={itemId}
                    requestType={requestType}
                    key={`membership-risk-${index}`}
                    isSOD={isSOD}
                    isMembership={false}
                    requestId={requestId}
                    riskFunction={item}
                />
            ),
        )
    }

    return <>
        <Box className={classes.riskfunctionacc}>
            <Box className={classes.riskfunctionheader}>
                <span>{riskFunction.riskFunctionFriendlyName}</span>
                <ExpandIconPlusMinus
                    expanded={expanded}
                    onClick={() => {
                        setExpended(!expanded)
                    }}
                />
            </Box>
            {expanded && (
                <Collapse in={expanded}>
                    <Box className={classes.riskcollapseinnersite}>
                        {isLoading && (
                            <Box className={classes.riskcollapseinnerbody}>
                                <Box width={'100%'}>
                                    <Box my={2}>
                                        <Skeleton
                                            animation="wave"
                                            variant="rectangular"
                                            height={24}
                                        />
                                    </Box>
                                    <Box my={2}>
                                        <Skeleton
                                            animation="wave"
                                            variant="rectangular"
                                            height={24}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {!functionData && !isLoading ? (
                            <Box my={1}>{t('NoDataFound')}</Box>
                        ) : functionData ? (
                            <Box className={classes.riskcollapseinnerbody}>
                                <Box
                                    className={classes.riskcollapse}
                                    alignItems="center"
                                >
                                    <Box
                                        className={classes.riskcollapseleft}
                                    >
                                        <Typography
                                            variant="h4"
                                            component="h4"
                                        >
                                            {t('CriticalityLevel')}
                                        </Typography>
                                    </Box>
                                    <Box
                                        className={
                                            classes.riskcollapseright
                                        }
                                    >
                                        <RiskIndicator
                                            variant={risk.riskLevel}
                                            label={t(
                                                `${functionData.riskLevel}Risk`,
                                            )}
                                        />
                                    </Box>
                                </Box>
                                {functionData.descritpion && (
                                    <Box
                                        className={classes.riskcollapse}
                                        marginTop="14px"
                                    >
                                        <Box
                                            className={
                                                classes.riskcollapseleft
                                            }
                                        >
                                            <Typography
                                                variant="h4"
                                                component="h4"
                                            >
                                                {t(
                                                    'FunctionDescription',
                                                )}
                                            </Typography>
                                        </Box>
                                        <Box
                                            className={
                                                classes.riskcollapseright
                                            }
                                        >
                                            <p>
                                                {functionData.descritpion}
                                            </p>
                                        </Box>
                                    </Box>
                                )}
                                <Box
                                    className={classes.riskcollapse}
                                    marginTop="14px"
                                >
                                    <Box
                                        className={classes.riskcollapseleft}
                                    >
                                        <Typography
                                            variant="h4"
                                            component="h4"
                                        >
                                            {t('FunctionType')}
                                        </Typography>
                                    </Box>
                                    <Box
                                        className={
                                            classes.riskcollapseright
                                        }
                                    >
                                        <p>{functionData.functionType}</p>
                                    </Box>
                                </Box>
                                <Box
                                    className={classes.riskcollapse}
                                    marginTop="14px"
                                >
                                    <Box
                                        className={classes.riskcollapseleft}
                                    >
                                        <Typography
                                            variant="h4"
                                            component="h4"
                                        >
                                            {t('GlobalFunction')}
                                        </Typography>
                                    </Box>
                                    <Box
                                        className={
                                            classes.riskcollapseright
                                        }
                                    >
                                        <p>
                                            {
                                                functionData.globalFunctionFriendlyName
                                            }
                                        </p>
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                </Collapse>
            )}
            {/* Risk Functions List Start */}
            {membershipRiskAssigneeList?.length
                ? bindMembershipRisk()
                : null}
            {businessRequestRiskAssigneeList?.length
                ? bindBusinessRisk()
                : null}
            {/* Risk Functions List End */}
        </Box>
    </>;
}

export default RiskViolationLeftAndRight

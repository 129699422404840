import { FC, forwardRef } from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

export interface IAttributeProps {
    className?: string
    orientation?: 'horizontal' | 'vertical'
}

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            padding: '0.224rem 0 0.8rem 0',

            // If an AttributeWrapper is the first-child in it's parent, and it also has a sibling that is also an AttributeWrapper, add this styling
            '&:first-child:has(+ &)': {
                paddingTop: '0.8rem',
            },
        },
        vertical: {
            flexDirection: 'column',
        },
        horizontal: {
            flexDirection: 'row',
        },
    }),
)

export const Attribute: FC<IAttributeProps> = forwardRef((props, ref: any) => {
    const classes = useStyles()

    return (
        <div
            className={classNames(classes.root, props.className, {
                [classes.vertical]: props.orientation === 'vertical',
                [classes.horizontal]: props.orientation === 'horizontal',
            })}
            ref={ref}
        >
            {props.children}
        </div>
    )
})

Attribute.defaultProps = {
    orientation: 'vertical',
}

export * from './AttributeLabel'
export * from './AttributeValue'

import React, { FC, Fragment } from 'react'
import { Box, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'
import { IItemWithRisks } from 'types'
import moment from 'moment'
import { Risk } from './Risk'
import { mobileScreenWidth } from 'utils'
import classNames from 'classnames'

export const useStyles = makeStyles(
    createStyles({
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',

            borderRadius: '5px',
            '& >div': {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        },
        header: {
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            paddingTop: '16px',
            paddingBottom: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#307fc1',
            [`@media (max-width:${mobileScreenWidth})`]: {
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            },
        },
        titleSection: {
            display: 'flex',
            flexDirection: 'column',
        },
        headerTitle: {
            color: '#ffffff',
            fontSize: '18px',
            fontWeight: 'bold',
        },
        headerSubTitle: {
            color: 'yellow',
            fontSize: '14px',
            fontWeight: 'bold',
            [`@media (max-width:${mobileScreenWidth})`]: {
                lineHeight: 1.7,
            },
        },
        datesSection: {
            display: 'flex',
            alignItems: 'center',
            [`@media (max-width:${mobileScreenWidth})`]: {
                marginTop: '12px',
                marginLeft: '-8px',
            },
        },
        date: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 8px',

            '& .Label': {
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#ffffff',
                opacity: 0.5,
                textTransform: 'uppercase',
            },
            '& .Value': {
                fontSize: '14px',
                color: '#ffffff',
            },
        },
        contentClass: {
            backgroundColor: '#ffffff',
        },
        riskContainer: {
            paddingTop: '24px',
        },
        sectionDivider: {
            height: '16px',
            padding: '0 0 15px',
            backgroundImage:
                'linear-gradient(to bottom, #efeff1, rgba(0, 0, 0, 0))',
        },
        removePadding: {
            marginLeft: '-16px',
            marginRight: '-16px',
        },
        comments: {
            margin: '0px -16px',
        },
        itemDivider: {
            borderImageSource:
                'linear-gradient(to right, rgba(208, 2, 27, 0), #d0021b 8%, #d00238 91%, rgba(208, 2, 69, 0))',
            borderImageSlice: 1,
            borderBottom: '1px solid',
            opacity: '0.25',
        },
    }),
)

export const ItemWithRisks: FC<IItemWithRisks> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    let mainItem: any

    if (props.inverted) {
        mainItem = props.right
    } else {
        mainItem = props.left
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Box className={classes.titleSection}>
                    <Typography className={classes.headerTitle}>
                        {mainItem.riskFunctionAssignee}
                    </Typography>
                    <Typography className={classes.headerSubTitle}>
                        {mainItem.itemTypeActionFriendlyName}
                    </Typography>
                </Box>

                <Box className={classes.datesSection}>
                    {mainItem.startDateUtc && (
                        <Box className={classes.date}>
                            <Typography className="Label">
                                {t('StartDate')}
                            </Typography>
                            <Typography className="Value">
                                {moment
                                    .utc(mainItem.startDateUtc)
                                    .format('LLL')}
                            </Typography>
                        </Box>
                    )}

                    {mainItem.endDateUtc && (
                        <Box className={classes.date}>
                            <Typography className="Label">
                                {t('EndDate')}
                            </Typography>
                            <Typography className="Value">
                                {moment.utc(mainItem.endDateUtc).format('LLL')}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>

            <Box className={classes.contentClass}>
                {props.risks &&
                    props.risks.map((risk, index) => (
                        <Fragment key={risk.id}>
                            {index !== 0 && (
                                <Box
                                    className={classNames(
                                        classes.sectionDivider,
                                        classes.removePadding,
                                    )}
                                />
                            )}
                            <Box className={classes.riskContainer}>
                                <Risk
                                    key={risk.id}
                                    risk={risk}
                                    requestId={props.requestId}
                                    itemId={props.id}
                                    showFunctionsGranted
                                />
                            </Box>
                        </Fragment>
                    ))}
            </Box>

            <Box className={classes.itemDivider} />
        </Box>
    )
}

import React from 'react'
import Button from './index'
import { Icon } from 'packages/eid-icons'

const styles = {
    normal: {
        minWidth: '99px',
        backgroundColor: '#307fc1',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#307fc1',
        },
        '&:active': {
            backgroundColor: '#307fc1',
        },
    },

    withOutLable: {
        minWidth: '52px',
        backgroundColor: '#307fc1',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#307fc1',
        },
        '&:active': {
            backgroundColor: '#307fc1',
        },
        '& .MuiButton-startIcon': {
            marginRight: '0px !important',
        },
    },
    disabled: {
        backgroundColor: '#aab0b4 !important',
        color: '#ffffff !important',
    },
}

const SendButton = ({ withOutLable, ...rest }) => {
    return (
        <Button
            size="medium"
            style={withOutLable ? styles.withOutLable : styles.normal}
            disabledStyles={styles.disabled}
            startIcon={<Icon name="Send" color="#ffffff" />}
            {...rest}
        />
    )
}

export default SendButton

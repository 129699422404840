import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { resetAuthState } from '../actions'
import { useAuthState } from '../context'
import useUserManager from '../useUserManager'

function Logout({ render }) {
    const [, dispatch] = useAuthState()
    const userManager = useUserManager()

    useEffect(() => {
        sessionStorage.removeItem('MasterPassword')
        dispatch(resetAuthState())
        userManager.signoutRedirect()
    }, [dispatch])

    return render()
}

Logout.propTypes = {
    render: PropTypes.func.isRequired,
}

export default Logout

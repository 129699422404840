import React, { useState, useRef } from 'react'
import { useFulfillmentSummary } from 'hooks'
import { useTranslation } from 'react-i18next'
import { MenuItem, Typography } from '@mui/material'
import { Dropdown, Loader } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'

export const FulfillmentStatusDropDown = ({
    requestId,
    itemTypeId,
    itemStatus,
    onChange,
}) => {
    const { t } = useTranslation()
    const anchorRef = useRef(null)
    const [open, setOpen] = useState(false)

    const { data, isLoading } = useFulfillmentSummary(
        requestId,
        itemTypeId,
        itemStatus,
        open,
    )

    const handleDropDownClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    return (
        <>
            <Dropdown
                open={open}
                anchorEl={anchorRef.current}
                handleClose={handleDropDownClose}
                showCone={false}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {isLoading ? (
                    <Loader height={40} />
                ) : data && data.length > 0 ? (
                    data.map((d) => (
                        <MenuItem
                            key={d.id}
                            onClick={() => {
                                setOpen(false)
                                onChange(d.fulfillmentStatus)
                            }}
                        >
                            {`${d.fulfillmentStatusName} ( ${d.totalItems} )`}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem>
                        <Typography>{t('NoDataFound')}</Typography>
                    </MenuItem>
                )}
            </Dropdown>
            <span ref={anchorRef} style={{ width: '16px', height: '16px' }}>
                <Icon
                    name="Arrow"
                    color={open ? '#307fc1' : '#aab0b4'}
                    direction={open ? 'up' : 'down'}
                    onClick={() => setOpen(!open)}
                />
            </span>
        </>
    )
}

import React from 'react'
import PropTypes from 'prop-types'

const If = ({ condition, children }) => {
    const renderChildren = () => {
        return typeof children === 'function' ? (
            <>{children()}</>
        ) : (
            <>{children}</>
        )
    }
    const checkCondition = () => {
        return typeof condition === 'function' ? condition() : condition
    }

    return checkCondition() ? renderChildren() : <></>
}

If.propTypes = {
    condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

export default If

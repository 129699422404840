import { FC } from 'react'
import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

export interface IAttributeLabelProps {
    className?: string
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            fontSize: '1.2rem',
            lineHeight: 1.33,
            width: '21.2rem',
            minWidth: '21.2rem',
            color: theme.palette.grey[500],
            textTransform: 'uppercase',
            display: 'block',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            paddingTop: '0.6rem',
            letterSpacing: '0.048rem',
            fontWeight: 'bold !important',
        },
    }),
)

export const AttributeLabel: FC<IAttributeLabelProps> = (props) => {
    const classes = useStyles()

    return (
        <Box className={classNames(classes.root, props.className)}>
            {props.children}
        </Box>
    )
}

import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Spinner from '../Spinner'

const useStyles = makeStyles({
    link: (props) => ({
        '&:hover': {
            cursor: props.disabled ? 'default' : 'pointer',
        },
    }),
    root: (props) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: props.borderRadius,
        width: props.width,
        height: props.height,
        backgroundColor: props.bgColor,
        border: props.border,
        opacity: props.disabled && 0.7,
        '&:hover': {
            backgroundColor: props.hoverColor,
        },
        ...props.style,
    }),
    label: (props) => ({
        color: props.disabled ? '#b4b4b4' : props.fontColor,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
    }),
})

const ButtonV2 = ({
    fontSize,
    fontColor,
    fontWeight,
    label,
    width,
    height,
    borderRadius,
    bgColor,
    border,
    icon,
    onClick,
    hoverColor,
    loading,
    loadingLabel,
    spinnerColor,
    disabled,
    style,
}) => {
    const classes = useStyles({
        width,
        height,
        borderRadius,
        bgColor,
        border,
        fontSize,
        fontColor,
        fontWeight,
        hoverColor,
        disabled,
        style,
    })
    return (
        <Link
            component="button"
            className={classes.link}
            underline="none"
            onClick={onClick}
        >
            <Box className={classes.root}>
                {loading && (
                    <>
                        <Spinner
                            size={0.8}
                            color={spinnerColor ? spinnerColor : '#fff'}
                        />

                        {loadingLabel && (
                            <Typography
                                className={classes.label}
                                style={{
                                    color: spinnerColor,
                                    paddingLeft: '6px',
                                }}
                            >
                                {loadingLabel}
                            </Typography>
                        )}
                    </>
                )}

                {icon}

                {!loading && (
                    <Typography className={classes.label}>{label}</Typography>
                )}
            </Box>
        </Link>
    )
}

ButtonV2.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    borderRadius: PropTypes.string,
    bgColor: PropTypes.string,
    border: PropTypes.string,
    fontSize: PropTypes.string,
    fontColor: PropTypes.string,
    fontWeight: PropTypes.string,
    hoverColor: PropTypes.string,
    onClick: PropTypes.func,
    //icon: PropTypes.node,
    // list: PropTypes.array.isRequired,
}

ButtonV2.defaultProps = {
    width: '100px',
    height: '48px',
    borderRadius: '16.5px',
    bgColor: '#ffffff',
    border: 'none',
    fontSize: '12px',
    fontColor: '#307fc1;',
    fontWeight: 'normal',
}
export default ButtonV2

import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { isNilOrEmpty } from 'packages/core'
import MUIDateTimePicker from 'packages/eid-ui/DateTimePickers/MUIDateTimePicker/MUIDateTimePicker'

const ValidationMessageContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    fontSize: '12px',
    color: theme.palette.error.main,
    marginRight: '12px',
    wordBreak: 'break-word',
}))

export const EndDateTimePicker = ({
    selectedEndDate,
    setSelectedEndDate,
    minEndDate,
    maxEndDate,
    hasInvalidDates = false,
    validationMessage = '',
    showWarningColor = false,
}) => {
    const { t } = useTranslation()

    return (
        <>
            <MUIDateTimePicker
                minDate={minEndDate ? minEndDate : moment(new Date())}
                maxDate={maxEndDate}
                value={selectedEndDate ? moment(selectedEndDate) : null}
                handleChange={(e) => {
                    if (isNilOrEmpty(e)) {
                        if (maxEndDate) {
                            setSelectedEndDate(maxEndDate.utc().format())
                        } else setSelectedEndDate(moment().utc().format())
                    } else {
                        const date = moment(e)
                        const dateToSet = date.clone().utc().format()
                        setSelectedEndDate(dateToSet)
                    }
                }}
                label={t('EndDateTime')}
                showWarningColor={showWarningColor}
            />
            {hasInvalidDates && validationMessage && (
                <ValidationMessageContainer>
                    {validationMessage}
                </ValidationMessageContainer>
            )}
        </>
    )
}

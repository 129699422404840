import { Box, Checkbox, FormControlLabel } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiskIndicator, RiskLevel } from 'packages/eid-ui'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles(
    createStyles({
        root: {
            padding: '20px 20px 0 20px',
        },
        noRisk: {
            fontSize: '11px !important',
        },
    }),
)

export interface IRiskLevelFilterProps {
    value: (string | null)[]
    onChange: (values: (string | null)[]) => void
}

const riskLevels = [
    {
        label: 'VeryCriticalRisk',
        value: 'VeryCritical',
    },
    {
        label: 'CriticalRisk',
        value: 'Critical',
    },
    {
        label: 'HighRisk',
        value: 'High',
    },
    {
        label: 'MediumRisk',
        value: 'Medium',
    },
    {
        label: 'LowRisk',
        value: 'Low',
    },
]

export const RiskLevelFilter: FC<IRiskLevelFilterProps> = (props) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const [selectedItems, setSelectedItems] = useState<(string | null)[]>(
        props.value,
    )

    useEffect(() => {
        if (props.value !== selectedItems) {
            setSelectedItems(props.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value])

    useEffect(() => {
        if (props.value !== selectedItems) {
            props.onChange(selectedItems)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems])

    return (
        <Box className={classes.root}>
            {riskLevels.map((rl) => {
                const currentIndex = selectedItems.indexOf(rl.value)
                return (
                    <Box key={rl.value}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={currentIndex !== -1}
                                    icon={<Icon name="CheckBox" />}
                                    checkedIcon={<Icon name="CheckedBox" />}
                                    onChange={() => {
                                        const newChecked = [...selectedItems]
                                        if (currentIndex === -1) {
                                            newChecked.push(rl.value)
                                        } else {
                                            newChecked.splice(currentIndex, 1)
                                        }

                                        setSelectedItems(newChecked)
                                    }}
                                />
                            }
                            label={
                                <RiskIndicator
                                    variant={rl.value as RiskLevel}
                                    label={t(rl.label)}
                                    renderAsText
                                    forFitler={true}
                                />
                            }
                        />
                    </Box>
                )
            })}
            <FormControlLabel
                control={
                    <Checkbox
                        checked={selectedItems.includes(null)}
                        icon={<Icon name="CheckBox" />}
                        checkedIcon={<Icon name="CheckedBox" />}
                        onChange={() => {
                            const newChecked = [...selectedItems]
                            const nullCurrentIndex = selectedItems.indexOf(null)
                            if (nullCurrentIndex === -1) {
                                newChecked.push(null)
                            } else {
                                newChecked.splice(nullCurrentIndex, 1)
                            }
                            setSelectedItems(newChecked)
                        }}
                    />
                }
                label={
                    <Box className={classes.noRisk}>{t('NoRisk')}</Box>
                }
            />
        </Box>
    )
}

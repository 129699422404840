import makeStyles from '@mui/styles/makeStyles'
import { Box, List } from '@mui/material'

import { Spinner } from 'packages/eid-ui'
import CheckBoxListItem from './CheckBoxListItem'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
}))

export default function CheckboxListWithInfoIcon(props) {
    const {
        itemContainerHeight,
        handleToggle,
        checked,
        loading,
        dataList,
        highlightText,
        fallbackMessage,
        highlightSelected = false,
        showInfoTooltip = false,
    } = props

    const classes = useStyles()
    return loading ? (
        <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            padding="2rem"
            marginLeft="-1rem"
        >
            <Spinner />
        </Box>
    ) : (
        dataList && (
            <List className={classes.root}>
                {dataList.length === 0 && <>{fallbackMessage}</>}
                {dataList.map((item, index) => {
                    const labelId = `checkbox-list-label-${item.friendlyName}`

                    return (
                        <CheckBoxListItem
                            key={`checkbox-list-label-${index}`}
                            highlightText={highlightText}
                            highlightSelected={highlightSelected}
                            labelId={labelId}
                            itemContainerHeight={itemContainerHeight}
                            handleToggle={handleToggle}
                            checked={checked}
                            item={item}
                            showInfoTooltip={showInfoTooltip}
                        />
                    )
                })}
            </List>
        )
    )
}

import React from 'react'
import TreeV3 from '.'

const TreeV3ChildNode = React.memo(
    ({
        item,
        handleSelect,
        selectedItemId,
        isExpanded,
        highlightText,
        disableAssignableCheck,
        label,
        expandIcon,
        collapseIcon,
        radioSelection,
        loading,
        fontStyles,
        treeItemStyles,
        imageStyles,
        radioButtonStyles,
    }) => {
        return (
            <TreeV3
                data={item}
                handleSelect={handleSelect}
                selectedItemId={selectedItemId}
                disableAssignableCheck={disableAssignableCheck}
                isExpanded={isExpanded}
                highlightText={highlightText}
                label={label}
                expandIcon={expandIcon}
                collapseIcon={collapseIcon}
                radioSelection={radioSelection}
                loading={loading}
                fontStyles={fontStyles}
                treeItemStyles={treeItemStyles}
                imageStyles={imageStyles}
                radioButtonStyles={radioButtonStyles}
            />
        )
    },
)

export default TreeV3ChildNode

import { useAuthState } from 'packages/core'

const PagesAccess = () => {
    const [
        {
            access: { pages },
        },
    ] = useAuthState()

    return pages
}
export default PagesAccess

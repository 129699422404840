import React, { useState, useRef } from 'react'
import { Box, Popover, Typography, styled } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import FieldTypeValuesAssignmentInfo from './FieldTypeValuesAssignmentInfo'
import { useTranslation } from 'react-i18next'

const StyledBox = styled(Box)(({ withBorder, showFieldTypes, showLabel }) => ({
    position: 'relative',
    '& .MuiPopover-root': {
        background: '#0000004a',
    },
    width: '3.2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 .8rem',
    borderRadius: '.4rem',
    cursor: 'pointer',
    ...(withBorder
        ? {
              border: 'solid 1px #9230c5',
              height: '3.2rem',
          }
        : {
              color: '#9230c5',
              gap: '.8rem',
              justifyContent: 'flex-end',
              background: '#fff !important',
              '& p': {
                  fontSize: '1.3rem',
              },
          }),
    ...(showFieldTypes &&
        !showLabel && {
            background: '#9230c5',
            '& svg': {
                color: '#fff',
            },
        }),
}))

const FieldTypeValuesAssignmentPopup = ({
    withBorder = true,
    showLabel = false,
    showFieldTypes = false,
    fieldTypeValueJson,
}) => {
    const { t } = useTranslation()
    const [showFiedlTypes, setShowFieldTypes] = useState(false)
    const anchorEl = useRef(null)
    return (
        <Box
            sx={{
                position: 'relative',
                '& .MuiPopover-root': {
                    background: '#0000004a',
                },
            }}
        >
            <StyledBox
                withBorder={withBorder}
                showFieldTypes={showFieldTypes}
                showLabel={showLabel}
                ref={anchorEl}
                onClick={(e) => setShowFieldTypes(true)}
            >
                <Icon name={showFiedlTypes && !showLabel ? 'Close' : 'Eye'} />
                {showLabel && <Typography>{t('Preview')}</Typography>}
            </StyledBox>
            <Popover
                disablePortal={true}
                open={showFiedlTypes}
                anchorEl={anchorEl.current}
                onClose={() => setShowFieldTypes(false)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                sx={(theme) => ({
                    paper: {
                        borderRadius: '.4rem',
                        boxShadow:
                            'inset 0 0 0 1px #fbfbfd, 0 0 0 4px rgba(48, 127, 193, 0.05), 0 4px 16px 4px rgba(0, 0, 0, 0.05), 0 8px 32px 4px rgba(0, 0, 0, 0.05)',
                        backgroundColor: theme?.palette?.background?.paper,
                        marginLeft: '-1rem',
                    },
                })}
            >
                <Box
                    sx={
                        showLabel
                            ? {
                                  width: '72rem !important',
                                  padding: '0 2rem',
                                  '& .MuiBox-root': {
                                      maxWidth: '100% !important',
                                  },
                              }
                            : {
                                  width: '72rem !important',
                                  padding: '0 2rem',
                              }
                    }
                >
                    <FieldTypeValuesAssignmentInfo
                        fieldTypeValueJson={fieldTypeValueJson}
                        showExpanded={true}
                        infoPopup={true}
                    />
                </Box>
            </Popover>
        </Box>
    )
}

export default FieldTypeValuesAssignmentPopup

import { Box, styled, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isNilOrEmpty } from 'packages/core'
import { Tooltip } from 'packages/eid-ui'
import { smallScreenWidth } from 'utils'
import { TruncatedText } from './TruncatedText'

const tooltipDelay = 1000

export const useStyles = makeStyles((theme) => ({
    profileContainer: {
        position: 'relative',
        width: '100%',
        paddingRight: '0px',

        [`@media (min-width:${smallScreenWidth})`]: {
            width: '40%',
            paddingRight: '36px',
        },
    },
    detailsContainer: {
        paddingLeft: '36px',
        width: '60%',
        [`@media (max-width:${smallScreenWidth})`]: {
            width: '100%',
            paddingLeft: '0px',
        },
    },
    progressAttribute: {
        display: 'flex',
        alignItems: 'center',
        [`@media (max-width:${smallScreenWidth})`]: {
            alignItems: 'flex-start',
        },
        paddingLeft: '10px',
        paddingBottom: '15px',
    },

    progressIndicatorContainer: {
        flexBasis: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& >div:first-child': {
            paddingRight: '10px',
        },
        [`@media (max-width:${smallScreenWidth})`]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& >div:first-child': {
                paddingBottom: '20px',
            },
        },
    },

    person: {
        width: 'calc(100% - 150px)',
        maxWidth: '375px',
    },

    accessDatesLabel: {
        fontSize: '1rem !important',
        color: '#b4b4b4 !important',
        fontWeight: '500 !important',
    },

    accessDatesValues: {
        fontSize: '1.6rem !important',
        color: '#5d6870 !important',
    },

    dateWarning: {
        color: `${theme?.palette?.warning?.main} !important`,
    },

    warningLabel: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
        color: theme?.palette?.warning?.main,
        margin: '0 0.5rem',
    },

    warningMessage: {
        fontSize: '1.4rem',
    },
}))

export const AttributeLabel = styled(Box)({
    minWidth: '120px',
    maxWidth: '120px',
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#b4b4b4',
    marginLeft: '13px',
    marginRight: '13px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    overflow: 'auto',
})

export const StyledTypography = styled(Typography)({
    fontSize: '14px',
    color: '#000000',
})

export const AttributeValue = ({
    value,
    maxCharsToShow = Infinity,
    ...rest
}) => {
    const textToShow =
        maxCharsToShow < Infinity && value?.length > maxCharsToShow ? (
            <TruncatedText value={value} maxCharsToShow={maxCharsToShow} />
        ) : (
            value
        )

    return (
        <Tooltip
            title={isNilOrEmpty(value) ? '' : value}
            placement="bottom-start"
            enterDelay={tooltipDelay}
            enterNextDelay={tooltipDelay}
        >
            <StyledTypography {...rest}>
                {isNilOrEmpty(value) ? '-' : textToShow}
            </StyledTypography>
        </Tooltip>
    )
}

export const styles = {
    attributeValue: {
        fontSize: '14px',
        color: '#000000',
    },
    nextToAvatar: {
        width: '30%',
    },
    roleReceiverValue: {
        marginLeft: '13px',
        width: '74%',
        fontSize: '20px',
        fontWeight: '600',
        color: '#282828',
    },
    locationValue: {
        color: '#307fc1',
    },
    breakWord: {
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        overflow: 'auto',
    },
    divider: {
        marginBottom: 30,
    },
}

export const VerticalAlignedLabel = styled(AttributeLabel)({
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    overflow: 'auto',
})

export const PaperContainer = styled(Box)({
    bgcolor: '#ffffff',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
})

export const Container = styled(Box)({
    padding: '30px',
    display: 'flex',
    width: '100%',
    overflow: 'hidden',

    [`@media (max-width:${smallScreenWidth})`]: {
        flexDirection: 'column',
        padding: '20px 12px',
    },
})

export const AttributeContainer = styled(Box)({
    display: 'flex',
    margin: '10px 0px',
})
export const AttributeDateContainer = styled(Box)({
    display: 'flex',
    margin: '25px 20px',
})

export const getLocationString = (city, country) => {
    let location = ''
    if (city) {
        location += city
    }
    if (country) {
        location = location ? `${location}, ${country}` : country
    }
    return location
}

// NOTE: Use this for large width labels
export const AttributeLabelLarge = styled(Box)({
    minWidth: '170px',
    maxWidth: '170px',
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#b4b4b4',
    marginLeft: '13px',
    marginRight: '13px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    overflow: 'auto',
})

import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Select } from 'packages/eid-ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FieldItemWrapper from '../Components/FieldItemWrapper'
import RequiredFieldErrorMessage from '../Components/FieldItemWrapper/RequiredFieldErrorMessage'
import classNames from 'classnames'
import { getFieldTypeRequiredErrorMessage } from 'packages/core'
import InfoMessageForWrongJson from '../Components/InfoMessageForWrongJson'

const useStyles = makeStyles({
    dropdownList: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        '& .MuiFormControl-root': {
            marginTop: '0 !important',
        },
        '& .MuiSelect-select': {
            boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
            padding: '9px 10px !important',
            height: '20px',
            borderRadius: '4px !important',
            borderColor: '#ebebed !important',
            '& span': {
                fontSize: '14px',
            },
        },
        '& p': {
            minWidth: '360px',
        },
        '& input': {
            minWidth: '360px',
        },
        '& .MuiInputBase-formControl': {
            marginTop: '0 !important',
            minWidth: '360px',
        },
        '& svg': {
            width: '24px !important',
        },
    },
    errorField: {
        '& .MuiSelect-select ': {
            outline: '1px solid #d0021b',
        },
    },
    disabledField: {
        pointerEvents: 'none',
        '& .MuiSelect-select ': {
            opacity: '.7',
            cursor: 'not-allowed',
        },
    },
})

const SingleSelectDropdownList = ({
    fieldType,
    isFormValidated,
    valueType,
    isApiSupportedSource,
    useDataHook,
    handleUpdate,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [sourceValues, setSourceValues] = useState([])
    const [showFieldValues, setShowFieldValues] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [sourceUpdated, setSourceUpdated] = useState(false)

    useEffect(() => {
        if (fieldType?.SourceValues && sourceUpdated) {
            setShowFieldValues(fieldType?.SourceValues?.length ? true : false)
            setSourceValues(fieldType?.SourceValues || [])
        }
    }, [fieldType?.SourceValues, sourceUpdated])

    useEffect(() => {
        if (isFormValidated && !fieldType?.[valueType]) {
            setErrorMessage(getFieldTypeRequiredErrorMessage(fieldType))
        } else {
            setErrorMessage('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated, fieldType?.[valueType]])

    const { data: sourceData, isFetching } = useDataHook(fieldType)

    useEffect(() => {
        if (isApiSupportedSource) {
            if (!isFetching) {
                updateSourceValues()
                setSourceUpdated(true)
            }
        } else {
            setShowFieldValues(fieldType?.SourceValues?.length ? true : false)
            setSourceValues(fieldType?.SourceValues || [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isApiSupportedSource, isFetching])

    const updateSourceValues = () => {
        const valueToUpdate = {
            ...fieldType,
            SourceValues: sourceData?.data || [],
        }
        handleUpdate(valueToUpdate)
    }

    const getOptionLabel = (option) => option?.name || option?.value || ''

    const handleSelection = (selected) => {
        const valueToUpdate = {
            ...fieldType,
            [valueType]: selected
                ? { ValueName: selected.id, FriendlyName: selected.name }
                : null,
        }
        handleUpdate(valueToUpdate)
    }

    return (
        <FieldItemWrapper
            title={fieldType?.Name}
            isRequired={fieldType?.IsRequired}
            fieldTypeScope={fieldType?.FieldTypeScope}
        >
            <Box
                className={classNames(classes.dropdownList, {
                    [classes.errorField]: Boolean(errorMessage),
                    [classes.disabledField]: fieldType?.IsReadOnly,
                })}
            >
                {showFieldValues ? (
                    <Select
                        value={
                            fieldType?.[valueType]
                                ? {
                                      id: fieldType?.[valueType]?.ValueName,
                                      name: fieldType?.[valueType]
                                          ?.FriendlyName,
                                      value: fieldType?.[valueType]?.ValueName,
                                  }
                                : null
                        }
                        options={
                            sourceValues?.length
                                ? sourceValues.map((item) => {
                                      return {
                                          id: item.ValueName,
                                          name: item.FriendlyName,
                                          value: item.ValueName,
                                          description: item.Description,
                                      }
                                  })
                                : []
                        }
                        getOptionLabel={getOptionLabel}
                        onChange={(e) => handleSelection(e)}
                        placeholder={t('Common_SelectFieldValue')}
                        isLoading={isFetching}
                        disabled={fieldType?.IsReadOnly}
                        showInfoTooltip={true}
                    />
                ) : (
                    <InfoMessageForWrongJson />
                )}
            </Box>
            <Box>
                {Boolean(errorMessage) && (
                    <RequiredFieldErrorMessage errorMsgKey={errorMessage} />
                )}
            </Box>
        </FieldItemWrapper>
    )
}

export default SingleSelectDropdownList

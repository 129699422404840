import React from 'react'
import { InView } from 'react-intersection-observer'
import { useInfiniteQuery } from 'react-query'
import useAxios from './useAxios'

const useApiGetWithInfiniteScroll = (
    url,
    queryString,
    enabled = true,
    defaultTake = 16,
) => {
    const callApi = useAxios()

    const {
        data,
        isLoading,
        isIdle,
        isFetchingMore,
        fetchMore,
        canFetchMore,
        error,
        refetch,
    } = useInfiniteQuery(
        [url, queryString],
        async (url, queryString, page = 1) => {
            const skip = (page - 1) * defaultTake

            const skipTakeQueryParam = `skip=${skip}&take=${defaultTake}`
            const fullUrl =
                url +
                (queryString
                    ? `?${skipTakeQueryParam}&${queryString}`
                    : `?${skipTakeQueryParam}`)

            return await callApi({
                url: fullUrl,
            })
        },
        {
            getFetchMore: (lastPage) => {
                if (lastPage.totalCount > lastPage.skip + lastPage.take) {
                    const nextPage = lastPage.skip / defaultTake + 2
                    return nextPage
                } else {
                    return 0
                }
            },
            enabled,
        },
    )

    let list = data ? [] : undefined
    if (data) {
        for (let i = 0; i < data.length; i++) {
            list = list.concat(data[i].data)
        }
    }

    const infiniteScrollObervableDiv = (
        <InView
            as="div"
            threshold={1}
            onChange={(inView) => {
                if (inView && !isFetchingMore && canFetchMore) {
                    fetchMore()
                }
            }}
        />
    )

    return {
        totalCount: data ? data[data.length - 1].totalCount : undefined,
        loading: isLoading || isIdle,
        list,
        rest: data ? data[data.length - 1] : undefined,
        error,
        loadingMore: isFetchingMore,
        infiniteScrollObervableDiv,
        revalidate: refetch,
    }
}

export default useApiGetWithInfiniteScroll

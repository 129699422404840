import React from 'react'
import {
    Tooltip as MuiTooltip,
    TooltipProps as MuiTooltipProps,
} from '@mui/material'

export interface StyleProps {
    fontColor?: string
    color?: string
    tooltipStyles: {}
}

export interface TooltipProps extends MuiTooltipProps {
    fontColor?: string
    color?: string
    arrow?: boolean
    tooltipStyles?: {}
}

const Tooltip: React.FC<TooltipProps> = ({
    fontColor = '#ffffff',
    color = '#000000',
    disableInteractive = true,
    arrow = true,
    tooltipStyles = {},
    ...rest
}) => {
    return (
        <MuiTooltip
            slotProps={{
                tooltip: {
                    sx: {
                        backgroundColor: color,
                        color: fontColor,
                        fontSize: '12px',
                        borderRadius: '3px',
                        padding: '3px 8px',
                        ...tooltipStyles,
                    },
                },
                arrow: {
                    sx: {
                        color,
                        fontSize: 5.5,
                    },
                },
            }}
            PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
            arrow={arrow}
            disableInteractive={disableInteractive}
            {...rest}
        />
    )
}

export default Tooltip

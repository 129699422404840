import { useState } from 'react'
import { ApproversList } from 'components'
import { useRequestItemApprovers } from 'hooks'

const take = 9

export const ItemApprovers = ({ requestId, totalCount, itemId }) => {
    const [searchTerm, setSearchTerm] = useState('')

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(take)

    const handlePageChange = (value) => {
        setPage(value)
    }

    const handlePageSelection = (value) => {
        setPage(value)
    }

    const handleItemsPerPageChange = ( value) => {
        setPage(1)
        setPerPage(value)
    }

    const { latestData } = useRequestItemApprovers(
        requestId,
        itemId,
        (page - 1) * perPage,
        perPage,
        searchTerm,
    )

    const isLoading = !Boolean(latestData)

    const data = latestData ? latestData.data : undefined

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / perPage)
        : 0

    return (
        <ApproversList
            totalCount={totalCount}
            visibleCount={latestData && latestData.totalCount}
            isLoading={isLoading}
            data={data}
            page={page}
            handlePageChange={handlePageChange}
            numberOfPages={numberOfPages}
            searchTerm={searchTerm}
            handleSearch={setSearchTerm}
            perPage={perPage}
            handlePageSelection={handlePageSelection}
            handleItemsPerPageChange={handleItemsPerPageChange}
        />
    )
}

import { FC, ReactNode } from 'react'
import { makeStyles, styled } from '@mui/styles'
import { MenuItem, Select as MuiSelect, FormControl, Box } from '@mui/material'
import { Icon } from '../../eid-icons'
import { Input } from './Input'
import { Skeleton } from '@mui/lab'
import { isNilOrEmpty } from 'packages/core'
import InfoTooltip from '../ApplicationFieldTypes/Components/InfoTooltip'

const StyledMenuItem = styled(MenuItem)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#919193',
    fontSize: '1.6rem',
    borderRadius: '0.4rem',
    padding: '0.5rem 1.2rem',
    wordBreak: 'break-word',
    '&:hover': {
        backgroundColor: '#f5f6f8 !important',
    },
})

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiSelect-iconOpen': {
            transform: 'rotate(180deg) !important',
        },
    },
    iconOpen: {
        transform: 'rotate(180deg) !important',
    },
    formControl: {
        width: '100%',
        position: 'relative',
        margin: 0,
    },
    placeholder: {
        position: 'absolute !important' as any,
        left: '1.6rem',
        color: '#b4b4b4',
        fontSize: '1.4rem',
        top: '1.2rem',
        zIndex: 1,
        pointerEvents: 'none',
        width: 'fit-content !important' as any,
        background: 'transparent !important' as any,
    },
    label: {
        color: '#b4b4b4',
        fontSize: '1.2rem',
        paddingLeft: '0.8rem',
        marginBottom: '-0.4rem',
    },
}))

const useMenuStyles = makeStyles(() => ({
    paper: {
        marginTop: '0.4rem',
        padding: '0px 0.6rem',
        maxHeight: '20rem',
        border: '0.1rem solid #d4d4d8',
        boxShadow: '0 0.4rem 0.6rem 0 rgb(0 0 0 / 7%)',
        backgroundColor: '#ffffff',
        paddingLeft: '0.6rem',
        borderRadius: '0.4rem',
    },

    root: {
        '& .Mui-selected': {
            color: '#000000 !important',
            backgroundColor: '#f5f6f8 !important',
        },
    },
}))

export interface SelectProps extends React.ComponentProps<typeof MuiSelect> {
    value?: any
    options?: any
    isLoading?: boolean
    onChange?: any
    getOptionLabel?: any
    clearIcon?: any
    label?: any
    nullableText?: any
    children?: ReactNode
    onOpen?: () => void
    onClose?: () => void
    placeholder?: string
    disabled?: boolean
    showInfoTooltip?: boolean
}

export const Select: FC<SelectProps> = (props) => {
    const {
        options,
        getOptionLabel,
        value,
        onChange,
        onOpen,
        onClose,
        label,
        placeholder,
        isLoading,
        disabled,
        nullableText,
        showInfoTooltip,
    } = props
    const classes = useStyles()

    const menuClasses = useMenuStyles()

    const menuProps: any = {
        PopoverClasses: menuClasses,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    }
    const isSelected = (option: any) => option.id === value?.id

    return (
        <>
            {label && <Box className={classes.label}>{label}</Box>}

            <FormControl
                variant="standard"
                disabled={disabled}
                className={classes.formControl}
            >
                {isNilOrEmpty(value) && (
                    <Box className={classes.placeholder}>{placeholder}</Box>
                )}

                <MuiSelect
                    variant="standard"
                    value={value}
                    renderValue={(value: any) => (
                        <span>{getOptionLabel(value)}</span>
                    )}
                    input={<Input />}
                    MenuProps={{
                        ...menuProps,
                    }}
                    IconComponent={(props) => (
                        <Icon
                            name="Arrow"
                            className={`material-icons ${props.className}`}
                            style={{
                                right: '12px',
                                width: '20px',
                                top: 'auto',
                            }}
                            color="#919193"
                            {...props}
                        />
                    )}
                    onChange={(e: any) => {
                        onChange(e.target.value)
                    }}
                    onOpen={onOpen}
                    onClose={onClose}
                >
                    {!isLoading && nullableText && (
                        <StyledMenuItem
                            value={''}
                            key={`nullable-option-${nullableText}`}
                        >
                            {nullableText}
                        </StyledMenuItem>
                    )}
                    {!isLoading &&
                        options.map((o: any) => (
                            <StyledMenuItem
                                value={o}
                                key={o.id}
                                data-closeEvent="prevent"
                                selected={isSelected(o)}
                            >
                                <Box sx={{ display: 'flex' }}>
                                    {getOptionLabel(o)}
                                    {showInfoTooltip && (
                                        <InfoTooltip
                                            friendlyName={getOptionLabel(o)}
                                            description={o.description}
                                            style={{ mt: '0.2rem' }}
                                        />
                                    )}
                                </Box>

                                {isSelected(o) && (
                                    <Icon
                                        name="Check"
                                        width="10px"
                                        height="7px"
                                        color="#7d7c7c"
                                        style={{ marginLeft: '1.2rem' }}
                                    />
                                )}
                            </StyledMenuItem>
                        ))}

                    {isLoading &&
                        [0, 1, 2].map((i) => (
                            <StyledMenuItem key={i}>
                                <Skeleton height={20} width="100%" />
                            </StyledMenuItem>
                        ))}
                </MuiSelect>
            </FormControl>
        </>
    )
}

import { styled } from "@mui/material";
import PropTypes from "prop-types";
import { ReactComponent as ArrowSvg } from "./arrow.svg";

const orientationAngle = {
  up: "180",
  right: "270",
  down: "0",
  left: "90",
};

const ArrowSmall = styled(ArrowSvg)(({ theme, color, direction }) => ({
  transform: `rotate(${orientationAngle[direction]}deg)`,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

ArrowSmall.propTypes = {
  direction: PropTypes.oneOf(["left", "right", "up", "down"]),
  fill: PropTypes.string,
};

ArrowSmall.defaultProps = {
  color: "#01AE8F",
  direction: "down",
  fill: "none",
};

export default ArrowSmall;

export const supportedSubjectTypes = [
    'ApplicationRole',
    'AzureLicense',
    'AzureRole',
    'BusinessRole',
    'ManagementRole',
    'Person',
    'Mailbox',
]

export const itShopSupportedResourceTypes = [
    'ApplicationRole',
    'AzureLicense',
    'AzureRole',
    'BusinessRole',
    'ManagementRole',
]

import { ReactComponent as PhoneRound } from './icon.svg'
import PropTypes from 'prop-types'
export default PhoneRound

PhoneRound.defaultProps = {
    color: '#01745F',
}

PhoneRound.propTypes = {
    color: PropTypes.string,
}

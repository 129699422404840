import { Box, styled } from '@mui/material'
import { Fragment, useState } from 'react'
import ApproverPersonCardItem from './ApproverPersonCardItem'
import { Trans, useTranslation } from 'react-i18next'
import { useWorkflowApprovers } from 'hooks'
import { Spinner } from 'packages/eid-ui'
import { Search } from 'components'
import { usePageContext } from 'pageContext'

const TaskApproversContainer = styled(Box)({
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '30px',
    display: 'flex',
})

const TaskApprovers = ({ id }) => {
    const defaultTake = 6
    const { t } = useTranslation()
    const [searchTerm, setSearchTerm] = useState('')
    const [, dispatch] = usePageContext()
    const { list, loading, pagination } = useWorkflowApprovers(
        id,
        searchTerm,
        defaultTake,
    )

    const SearchComponent = (
        <Box paddingBottom="10px">
            <Search
                outlined
                placeholder={t('Search')}
                value={searchTerm}
                handleClear={() => {
                    setSearchTerm('')
                }}
                handleSearch={(value) => {
                    if (searchTerm !== value) {
                        setSearchTerm(value)
                        dispatch({
                            type: 'SET_SUBPAGE',
                            payload: 1,
                        })
                    }
                }}
            />
        </Box>
    )

    if (loading) {
        return (
            <TaskApproversContainer display="flex" flexDirection="column">
                {SearchComponent}
                <Spinner style={{ alignSelf: 'center' }} />
            </TaskApproversContainer>
        )
    }

    return (
        <TaskApproversContainer
            padding="0px 0px 0px 15px"
            display="flex"
            flexDirection="column"
        >
            {SearchComponent}
            {list && list.length > 0 ? (
                <Fragment>
                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                        {list.map((item) => (
                            <ApproverPersonCardItem person={item} />
                        ))}
                    </Box>
                    <Box style={{ margin: '17px auto 0px auto' }}>
                        {pagination}
                    </Box>
                </Fragment>
            ) : (
                <div style={{ paddingTop: '5px', textAlign: 'center' }}>
                    <Trans i18nKey="NoDataFound" />
                </div>
            )}
        </TaskApproversContainer>
    )
}

export default TaskApprovers

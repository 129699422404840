import React from 'react'
import HeaderSearch from '../HeaderSearch'
import { Toolbar, Box } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const mobileStyles = {
    img: {
        width: 22,
        height: 22,
        objectFit: 'contain',
    },
    menuAnchor: {
        position: 'relative',
        top: '50px',
        left: '-170px',
    },
}

const MobileHeader = withStyles(mobileStyles)(
    ({
        appMobileLogo,
        mobileArrowLeft,
        rightSideContent,
        genericSearch,
        searchStyleData,
        searchKey,
        searchPlaceholder,
        handleSearch,
        searchComponent,
        showBack,
    }) => {
        return (
            <Toolbar style={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                    flex="1"
                    alignItems="flex-end"
                    display="flex"
                    width="100%"
                    padding="0 5px"
                >
                    <Box flex="1">{appMobileLogo}</Box>
                    <Box display="flex" flex={1} justifyContent="flex-end">
                        {rightSideContent.map((item, key) => (
                            <Box container="span" key={key}>
                                {item}
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box flex="1" alignItems="center" display="flex" width="100%">
                    {showBack && <Box flex="15%">{mobileArrowLeft}</Box>}
                    <Box flex="85%">
                        {genericSearch ? (
                            <HeaderSearch
                                styleData={searchStyleData}
                                value={searchKey}
                                handleSearch={handleSearch}
                                placeholder={searchPlaceholder}
                            />
                        ) : (
                            searchComponent
                        )}
                    </Box>
                </Box>
            </Toolbar>
        )
    },
)

export default MobileHeader

const attributes = [
    {
        showInAll: true,
        placement: 'TagRow',
        component: {
            componentName: 'StatusIndicator',
            props: {
                size: 'small',
            },
            name: 'status',
            styles: {
                minWidth: '98px',
            },
        },
    },

    {
        isHeader: true,
        showInAll: true,
        name: 'name',
        label: 'Task',
        sortBy: 'name',
        sortable: true,
        tableStyles: {
            headerCellStyles: {
                width: '40%',
                minWidth: '200px',
            },
            styles: {
                position: 'relative',
                overflow: 'visible',
                color: '#01ae8f',
                fontWeight: 500,
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
        },
        link: false,
    },
    {
        showInAll: true,
        name: 'id',
        label: 'RequestID',
        sorBy: 'id',
        sortable: true,

        tableStyles: {
            headerCellStyles: {
                width: '10%',
                minWidth: '10%',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
        },
    },

    {
        showInAll: true,
        name: 'requestWorkflowFriendlyName',
        label: 'WorkflowDisplayName',
        sortBy: 'requestWorkflowFriendlyName',
        sortable: true,
        tableStyles: {
            headerCellStyles: {
                width: '15%',
            },
            styles: {
                fontSize: '13px',
            },
        },
    },
    {
        showInMyTasks: true,
        showInAllWorkflows: true,
        name: 'initiatorPersonFriendlyName',
        label: 'RequestedBy',
        hideValue: true,
        sortable: true,
        sortBy: 'InitiatorPersonFriendlyName',
        avatar: true,
        imgProp: 'initiatorPersonImageThumbUrl',
        tableStyles: {
            headerCellStyles: {
                maxWidth: '150px',
            },
            styles: {
                maxWidth: '150px',
            },
            textStyles: {
                color: '#282828',
                fontSize: '13px',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
            },
        },
    },
    {
        showInAllWorkflows: true,
        name: 'requestorPersonFriendlyName',
        label: 'RequestedFor',
        hideValue: true,
        sortable: true,
        sortBy: 'requestorPersonFriendlyName',
        avatar: true,
        imgProp: 'requestorPersonImageThumbUrl',
        tableStyles: {
            headerCellStyles: {
                maxWidth: '150px',
            },
            styles: {
                maxWidth: '150px',
            },
            textStyles: {
                color: '#282828',
                fontSize: '13px',
                whiteSpace: 'normal',
            },
        },
    },
    {
        showInAll: true,
        name: 'startDate',
        hideValue: true,
        label: 'ElapsedTime',
        sortable: true,
        sortBy: 'startDate',
        tableStyles: {
            headerCellStyles: {
                width: '12%',
            },
        },
        component: { componentName: 'ElapsedTime' },
    },

    {
        showInAll: true,
        name: 'startDate',
        hideValue: true,
        label: 'Started',
        sortable: true,
        sortBy: 'startDate',
        tableStyles: {
            headerCellStyles: {
                width: '12%',
            },
        },
        component: { componentName: 'CellDateTime' },
    },

    {
        showInAll: true,
        name: 'expirationDate',
        hideValue: true,
        label: 'Expires',
        sortable: true,
        sortBy: 'expirationDate',
        tableStyles: {
            headerCellStyles: {
                width: '12%',
            },
        },
        component: { componentName: 'CellDateTime' },
    },
]

export default attributes

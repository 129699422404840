import React from 'react'
import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Drawer, Spinner } from 'packages/eid-ui'
import { useIsSmallScreen } from 'packages/core'
import { useWorkflowItem, useApplicationSettings } from 'hooks'
import StickyContainer from 'components/StickyContainer'
import WorkflowItemDetails from './Details'
import { Button, TextFormatter } from 'components'

const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 48px 0px 32px',
    overflow: 'hidden',
    color: '#ffffff',
    fontSize: '22px',
})

const DetailsDrawer = ({ id, onClose }) => {
    const { data: workflowItem, refetch, isLoading } = useWorkflowItem(id)

    const { data: appConfig } = useApplicationSettings()

    const isSmallScreen = useIsSmallScreen()
    const { t } = useTranslation()

    if (isSmallScreen) {
        return (
            <Box>
                <StickyContainer
                    color="#ffffff"
                    styles={{
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Box paddingY="10px" width="100%" maxWidth="260px">
                            <Button.BackButton onClick={() => onClose()}>
                                {t('Back')}
                            </Button.BackButton>
                        </Box>

                        <HeaderContainer
                            height="80px"
                            textAlign="start"
                            bgcolor={appConfig.style.colors.primary?.header}
                            style={{
                                backgroundImage:
                                    appConfig.style.colors.primary?.gradient,
                            }}
                        >
                            <TextFormatter
                                value={
                                    workflowItem?.description ??
                                    workflowItem?.name
                                }
                                maxCharacters={75}
                            />
                        </HeaderContainer>
                    </Box>
                </StickyContainer>

                {workflowItem && (
                    <WorkflowItemDetails
                        data={workflowItem}
                        refetch={refetch}
                    />
                )}
            </Box>
        )
    } else {
        return (
            <Drawer
                width="76%"
                open={true}
                toggleDrawer={() => onClose()}
                stickyHeader
                header={
                    <HeaderContainer
                        height="95px"
                        bgcolor={appConfig.style.colors.primary?.header}
                        style={{
                            backgroundImage:
                                appConfig.style.colors.primary?.gradient,
                        }}
                    >
                        <TextFormatter
                            value={workflowItem?.name}
                            maxCharacters={385}
                        />
                    </HeaderContainer>
                }
                closeOnOutsideClick
            >
                {isLoading && (
                    <Box display="flex" justifyContent="center" padding="30px">
                        <Spinner />
                    </Box>
                )}
                {workflowItem && (
                    <WorkflowItemDetails
                        data={workflowItem}
                        refetch={refetch}
                    />
                )}
            </Drawer>
        )
    }
}

export default DetailsDrawer

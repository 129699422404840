import { Box, FormLabel, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'
import Autocomplete from '@mui/material/Autocomplete'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles({
    dynamicSelectBox: {
        '& label': {
            fontSize: '12px',
            color: 'rgb(180, 180, 180)',
            textTransform: 'uppercase',
        },
    },
    dynamicSelect: {
        '& .MuiFormControl-fullWidth': {
            marginTop: '5px !important',
        },
        '& .MuiOutlinedInput-root': {
            width: '100%',
            boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
            padding: '2px 10px 2px 20px',
            borderColor: ' #d8d8dd',
            borderRadius: '5px',
            '& .MuiSelect-select:focus': {
                backgroundColor: 'white',
            },
            '&:before': {
                content: 'none !important',
            },
        },
        '& .MuiChip-root': {
            background: '#f1f1f1',
            border: '1px solid #ccc',
        },
    },
    dynamicSelectSearch: {
        position: 'absolute',
        top: '16px',
        left: '8px',
    },
})

const MultiSelectDropdown = ({
    item,
    t,
    bindRequiredColor,
    ns,
    bindValue,
    handleDDChange,
}) => {
    const classes = useStyles()
    const handleChange = (e, values) => {
        const evt = {
            target: {
                name: item.fieldName,
                value: values?.length ? prepareSelectedValues(values) : null,
            },
        }
        handleDDChange(evt)
    }

    const prepareSelectedValues = (values) => {
        const selectValue = values.map((item) => {
            return item.value
        })
        return selectValue.toString()
    }

    const bindOptions = () => {
        const values = bindValue(item.fieldName)
        if (!values) {
            return item.options
        } else {
            const nonSelectedValues = item.options.filter((option) => {
                const isValueExist = values
                    .split(',')
                    .find((p) => p === option.value)
                if (!isValueExist) {
                    return option
                }
            })

            return nonSelectedValues
        }
    }

    return (
        <Box marginTop="10px" className={classes.dynamicSelectBox}>
            <FormLabel
                component="label"
                className={classNames(classes.customFormLabel, {
                    [classes.requiredLabel]: bindRequiredColor(item.fieldName),
                })}
            >
                {(item.localeKey
                    ? t(`${ns}:MsCommonAnonymous_${item.localeKey}`)
                    : item.fieldName) + (item.required ? ' *' : '')}
            </FormLabel>
            <Box className={classes.dynamicSelectBox}>
                <Box className={classes.dynamicSelectSearch}>
                    <Icon name="Search" />
                </Box>

                <Autocomplete
                    multiple
                    className={classes.dynamicSelect}
                    id="multiple-limit-tags"
                    name={item.fieldName}
                    options={bindOptions()}
                    getOptionLabel={(option) =>
                        t(`${ns}:MsCommonAnonymous_${option.localeKey}`)
                    }
                    onChange={handleChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder={t('Common_Search')}
                        />
                    )}
                />
            </Box>
        </Box>
    )
}

export default MultiSelectDropdown

import { ISubjectType } from '../ISubjectType'
import { Filter, Details } from './Components'

class PersonSubjectType implements ISubjectType {
    name = 'Person'

    filterComponent = Filter

    detailComponent = Details
}

export default PersonSubjectType

import { styled, Box, Typography, InputBase } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import { NativeDatePicker } from 'packages/eid-ui/DateTimePickers'
import { isNilOrEmpty } from 'packages/core'

const iconContainer = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
}

const clearContainer = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
}
const Container = styled(Box)({
    height: '40px',
    width: '100%',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 15px',
    position: 'relative',
})

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        borderRadius: '5px',
        marginRight: '12px',
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        position: 'relative',
        boxShadow: 'none',
        border: 'none',
        fontSize: 16,
        color: '#5d6870',
    },

    focused: {
        backgroundColor: theme.palette.common.white,
    },
}))(InputBase)

const DateTimeFilter = ({
    label,
    showLabelWithin,
    value,
    placeholder,
    onChange,
    minDate,
    disablePast,
    disabled,
    handleClear,
}) => {
    const InputComponent = () => {
        return (
            <Container className="Input-Container">
                <Box>
                    {label && (
                        <Typography
                            style={{
                                color: '#b4b4b4',
                                fontSize: '10px',
                                textTransform: 'uppercase',
                                minWidth: '60px',
                                marginRight: '12px',
                            }}
                        >
                            {label}
                        </Typography>
                    )}
                </Box>

                <NativeDatePicker
                    handleChange={(e) => {
                        if (isNilOrEmpty(e)) {
                            handleClear()
                        } else {
                            const date = moment(e)
                            onChange(date)
                        }
                    }}
                    value={value?.local().format('YYYY-MM-DD')}
                    minDate={
                        minDate
                            ? minDate.format('YYYY-MM-DD')
                            : disablePast
                            ? moment(new Date()).format('YYYY-MM-DD')
                            : ''
                    }
                    disabled={disabled}
                >
                    <StyledInput
                        value={
                            value
                                ? moment(value)
                                      .clone()
                                      .local()
                                      .format('D MMMM YYYY')
                                : ''
                        }
                        placeholder={placeholder}
                    />
                </NativeDatePicker>

                {!isNilOrEmpty(value) && (
                    <Box
                        style={clearContainer}
                        height="40px !important"
                        width="40px"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleClear()
                        }}
                        padding="0 12px"
                    >
                        <Icon name="Close" color="#959598" />
                    </Box>
                )}

                <Box style={iconContainer} paddingRight="15px">
                    <Icon name="Date" color="#D8D8DD" />
                </Box>
            </Container>
        )
    }

    const InputWithLabel = () => {
        return (
            <Box>
                <Typography
                    style={{
                        color: '#b4b4b4',
                        fontSize: '10px',
                        textTransform: 'uppercase',
                        minWidth: '60px',
                        padding: '10px 10px',
                    }}
                >
                    {label}
                </Typography>
                <Container
                    padding="0px 5px !important"
                    boxShadow="none !important"
                    className="Input-Container"
                >
                    <Box
                        style={iconContainer}
                        padding="0 5px"
                        marginRight="12px"
                    >
                        <Icon name="Calendar" color="#959598" />
                    </Box>
                    <NativeDatePicker
                        handleChange={(e) => {
                            if (isNilOrEmpty(e)) {
                                handleClear()
                            } else {
                                const date = moment(e)
                                onChange(date)
                            }
                        }}
                        value={value?.local().format('YYYY-MM-DD')}
                        minDate={
                            minDate
                                ? minDate.format('YYYY-MM-DD')
                                : disablePast
                                ? moment(new Date()).format('YYYY-MM-DD')
                                : ''
                        }
                        disabled={disabled}
                    >
                        <StyledInput
                            value={
                                value
                                    ? moment(value)
                                          .clone()
                                          .local()
                                          .format('D MMMM YYYY')
                                    : ''
                            }
                            placeholder={placeholder}
                        />
                    </NativeDatePicker>

                    {!isNilOrEmpty(value) && (
                        <Box
                            style={clearContainer}
                            padding="0 12px"
                            onClick={(e) => {
                                e.stopPropagation()
                                handleClear()
                            }}
                        >
                            <Icon name="Close" color="#959598" />
                        </Box>
                    )}
                </Container>
            </Box>
        )
    }

    return <>{showLabelWithin ? <InputComponent /> : <InputWithLabel />}</>
}

export default DateTimeFilter

import React, { useState } from 'react'
import {
    useAddAdditionalApprovers,
    useAssign,
    useClaim,
    useCreatePermanentStepDelegation,
    useCurrentPerson,
    useUnclaim,
} from 'hooks'
import DelegateForms from '../DelegateForms'
import { Dropdown, IconButton, Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { Box, MenuItem as MuiMenuItem, styled, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import { defaultDataProtectedSubcomponent } from 'utils'

const assignThisTaskOption = {
    title: 'AssignThisTask',
    name: 'AssignTask',
    helpText: 'AssignTaskHelpText',
}
const unassignThisTaskOption = {
    title: 'UnassignThisTask',
    name: 'UnassignTask',
    helpText: 'UnassignTaskHelpText',
}

const reassignThisTaskOption = {
    title: 'ReassignThisTask',
    name: 'AssignTask',
    helpText: 'ReassignTaskHelpText',
}

const addAdditionalApproverOption = {
    title: 'AddAdditionalApprovers',
    name: 'Approvers',
    helpText: 'AddAdditionalApproversHelpText',
}

const alwaysDelegateCurrentStepOption = {
    title: 'AlwaysDelegateThisStep',
    name: 'AssignCurrentStep',
    helpText: 'AlwaysDelegateThisStepHelpText',
}

const MenuItem = styled(MuiMenuItem)({
    position: 'relative',
    borderRadius: '5px',
    color: '#919193',
    whiteSpace: 'normal !important',
    '&:hover': {
        color: '#000000 !important',
    },
    '& p': {
        paddingRight: '30px',
    },

    '& svg': {
        position: 'absolute',
        right: 16,
        transform: 'translate(50%, -50%)',
        height: '16px !important',
        width: '16px !important',
        color: '#919193',
        '&:hover': {
            color: '#307fc1',
        },
    },

    '& .Tooltip-icon-container': {
        position: 'absolute',
        right: 20,
        transform: 'translate(50%, -50%)',
        width: '16px !important',
    },
})

const styles = {
    rootMenu: {
        paddingRight: '0px',
        marginTop: '10px',
        width: '270px',
    },
    list: {
        padding: '0px 6px',
    },
}

const useStyles = makeStyles({
    iconButton: ({ isClickable }) => ({
        borderRadius: '5px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',

        display: 'flex',
        marginLeft: '4px',
        marginRight: '4px',
        margin: 'auto',
        paddingLeft: '0px',
        paddingRight: '0px',
        minWidth: '60px',
        backgroundColor: 'inherit',
        '&:hover': {
            backgroundColor: isClickable ? '#efeff1 !important' : 'initial',
        },
    }),
})

const DelegateMenuIconButton = ({
    page,
    requestId,
    requestItemId,
    isApprover,
    currentApprovalStepId,
    assignedByPersonId,
    assignedByPersonFriendlyName,
    claimedApproverId,
    claimedApproverFriendlyName,
    allowAddApprover,
    allowAssignApprover,
    protectSubcomponent = defaultDataProtectedSubcomponent,
}) => {
    const { t } = useTranslation()
    const [modalState, setModalState] = useState('')

    const { data: currentPerson } = useCurrentPerson()

    const [claim, { isLoading: isClaiming }] = useClaim(
        page,
        requestId,
        requestItemId,
    )
    const [unclaim] = useUnclaim(page, requestId, requestItemId)

    const [assign, { isLoading: isAssigning }] = useAssign(
        page,
        requestId,
        requestItemId,
    )
    const [
        addAdditionalApprovers,
        { isLoading: isAddingAdditionalApprovers },
    ] = useAddAdditionalApprovers(
        page,
        requestId,
        requestItemId,
        currentApprovalStepId,
    )
    const [
        createPermanentStepDelegation,
        { isLoading: isCreatingPermanentStepDelegation },
    ] = useCreatePermanentStepDelegation(page, requestId, requestItemId)

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    const onChange = (option) => setModalState(option.name)

    const isAssigned = !isNilOrEmpty(assignedByPersonId)

    const isAssignedToMe = claimedApproverId === currentPerson.id
    const assignedTo = claimedApproverFriendlyName

    const {
        hasAccessToAdminAssignTaskDelegateControl,
        hasAccessToAssignTaskDelegateControl,
        hasAccessToAdminAddAdditionalApproversDelegateControl,
        hasAccessToAddAdditionalApproversDelegateControl,
        hasAccessToPermanentlyDelegateStepControl,
    } = useSubcomponents()

    const optionsToRender = []

    if (allowAssignApprover) {
        if (hasAccessToAdminAssignTaskDelegateControl) {
            if (isAssigned) {
                optionsToRender.push({
                    ...unassignThisTaskOption,
                    protectSubcomponent: hasAccessToAdminAssignTaskDelegateControl,
                })
                optionsToRender.push({
                    ...reassignThisTaskOption,
                    protectSubcomponent: hasAccessToAdminAssignTaskDelegateControl,
                })
            } else {
                optionsToRender.push({
                    ...assignThisTaskOption,
                    protectSubcomponent: hasAccessToAdminAssignTaskDelegateControl,
                })
            }
        } else {
            if (isApprover && hasAccessToAssignTaskDelegateControl) {
                if (isAssigned) {
                    optionsToRender.push({
                        ...unassignThisTaskOption,
                        protectSubcomponent: hasAccessToAssignTaskDelegateControl,
                    })
                    optionsToRender.push({
                        ...reassignThisTaskOption,
                        protectSubcomponent: hasAccessToAssignTaskDelegateControl,
                    })
                } else {
                    optionsToRender.push({
                        ...assignThisTaskOption,
                        protectSubcomponent: hasAccessToAssignTaskDelegateControl,
                    })
                }
            }
        }
    }

    if (allowAddApprover) {
        if (hasAccessToAdminAddAdditionalApproversDelegateControl) {
            optionsToRender.push({
                ...addAdditionalApproverOption,
                protectSubcomponent: hasAccessToAdminAddAdditionalApproversDelegateControl,
            })
        } else {
            if (
                isApprover &&
                hasAccessToAddAdditionalApproversDelegateControl
            ) {
                optionsToRender.push({
                    ...addAdditionalApproverOption,
                    protectSubcomponent: hasAccessToAddAdditionalApproversDelegateControl,
                })
            }
        }
    }

    if (
        allowAssignApprover &&
        isApprover &&
        hasAccessToPermanentlyDelegateStepControl
    ) {
        optionsToRender.push({
            ...alwaysDelegateCurrentStepOption,
            protectSubcomponent: hasAccessToPermanentlyDelegateStepControl,
        })
    }

    const assignedToDisplayName = isAssignedToMe ? t('You') : assignedTo
    const assignedByMessage = `${t(
        'AssignedBy',
    )}: ${assignedByPersonFriendlyName} ${t(
        'To',
    )} ${assignedToDisplayName}`

    const isClickable = optionsToRender.length > 0

    const classes = useStyles({ isClickable })

    return <>
        <Box data-protectedsubcomponent={protectSubcomponent}>
            {isAssigned && assignedTo ? (
                <Box position="relative">
                    <Tooltip arrow={false} title={assignedByMessage}>
                        <Box>
                            <IconButton
                                className={classes.iconButton}
                                onClick={(e) => {
                                    if (!isClickable) return
                                    setOpen((prev) => !prev)
                                    setAnchorEl(e.currentTarget)
                                }}
                                size="large">
                                <Icon
                                    name={isClickable ? 'Delegate' : 'Info'}
                                    color="#307fc1"
                                    width={isClickable ? 26 : 20}
                                    height={isClickable ? 26 : 20}
                                />
                            </IconButton>
                        </Box>
                    </Tooltip>
                </Box>
            ) : (
                isClickable && (
                    <IconButton
                        className={classes.iconButton}
                        onClick={(e) => {
                            setOpen((prev) => !prev)
                            setAnchorEl(e.currentTarget)
                        }}
                        size="large">
                        <Icon
                            name="Delegate"
                            color="#307fc1"
                            width="26"
                            height="26"
                        />
                    </IconButton>
                )
            )}

            {isClickable && (
                <Dropdown
                    open={open}
                    anchorEl={anchorEl}
                    rootStyles={styles.rootMenu}
                    listStyles={styles.list}
                    handleClose={handleClose}
                    handleListKeyDown={handleListKeyDown}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {optionsToRender.map((option) => (
                        <MenuItem
                            key={option.name}
                            onClick={() => {
                                if (option.name === 'UnassignTask') {
                                    unclaim(currentApprovalStepId).then(
                                        () => {
                                            setOpen(false)
                                        },
                                    )
                                } else {
                                    setOpen(false)
                                    onChange(option)
                                }
                            }}
                            data-protectedsubcomponent={
                                option.protectSubcomponent
                            }
                        >
                            <Typography>{t(option.title)}</Typography>
                            <Tooltip title={t(option.helpText)}>
                                <span className="Tooltip-icon-container">
                                    <Icon
                                        name="Info"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        </MenuItem>
                    ))}
                </Dropdown>
            )}
        </Box>

        <DelegateForms
            isAddingAdditionalApprovers={isAddingAdditionalApprovers}
            addAdditionalApprovers={addAdditionalApprovers}
            isCreatingPermanentStepDelegation={
                isCreatingPermanentStepDelegation
            }
            createPermanentStepDelegation={createPermanentStepDelegation}
            isAssigning={isAssigning}
            assign={assign}
            isClaiming={isClaiming}
            claim={claim}
            setModalState={setModalState}
            modalState={modalState}
            currentApprovalStepId={currentApprovalStepId}
        />
    </>;
}
export default DelegateMenuIconButton

import React, { forwardRef, Fragment } from 'react'
import { Box, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ItemTypeCount } from 'components'
import { Divider } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { DateTime } from './utils'
import moment from 'moment'
import { IRequestWithApprovalSteps } from 'types'
import { getFullImageUrl } from 'utils'
import RequestedByPersonDetails from './RequestedByPersonDetails'

const useContainerStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            marginBottom: '3rem',
            paddingLeft: '0.9rem',
            '& *': {
                zIndex: 2,
            },
        },
    }),
)

const styles = {
    preApprovedStatus: {
        color: '#01ae8f',
        fontSize: '14px',
    },
    divider: {
        margin: '0px 10px',
        color: '#d8d8dd',
        width: '16px',
        height: '1px',
    },
    timeDivider: {
        backgroundColor: '#5d6870',
        width: '1.6rem',
        height: '0.1rem',
    },
}

const RequestedByNode = forwardRef<
    HTMLDivElement,
    {
        id: string
        request: IRequestWithApprovalSteps
    }
>((props, ref) => {
    const { request, ...rest } = props
    const { t } = useTranslation()
    const classes = useContainerStyles()

    return (
        <div className={classes.root} ref={ref}>
            <Box>
                <Box
                    display="flex"
                    alignItems="flex-start"
                    position="relative"
                    marginBottom="-2.5rem"
                >
                    <RequestedByPersonDetails
                        avatarId={props.id}
                        name={request.initiator.friendlyName}
                        imageUrl={getFullImageUrl(
                            request.initiator.imageThumbUrl,
                        )}
                        requestedBy={t('RequestedBy')}
                        moreInfo={
                            request.preApproved && (
                                <Fragment>
                                    <Divider vertical style={styles.divider} />
                                    <Typography
                                        style={styles.preApprovedStatus}
                                    >
                                        {t('PreApproved')}
                                    </Typography>
                                </Fragment>
                            )
                        }
                        {...rest}
                    />
                    {request.createdDateUtc && (
                        <Box
                            display="flex"
                            paddingTop="2px"
                            marginLeft="1.6rem"
                            alignItems="center"
                            marginTop="0.75rem"
                            marginBottom="0.75rem"
                        >
                            <Icon
                                height="1.6rem"
                                width="1.6rem"
                                name="Clock"
                                color="#5d6870"
                            />

                            <DateTime noWrap>
                                {moment
                                    .utc(request.createdDateUtc)
                                    .local()
                                    .format('ll')}
                            </DateTime>
                            <Divider vertical style={styles.timeDivider} />
                            <DateTime noWrap>
                                {moment
                                    .utc(request.createdDateUtc)
                                    .local()
                                    .format('LTS')}
                            </DateTime>
                        </Box>
                    )}
                </Box>

                <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    paddingLeft="5rem"
                    width="100%"
                >
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        width="34rem"
                        marginLeft="-1rem"
                    >
                        <ItemTypeCount
                            requestId={request.id}
                            itemTypeCount={request.itemTypeCounts}
                        />
                    </Box>
                </Box>
            </Box>
        </div>
    )
})

export { RequestedByNode }

import config from 'config'
import { FIELD_TYPE_COMPONENTS_TYPES, isNilOrEmpty, isWhitespace } from 'packages/core'
import i18n from 'i18next'
import moment from 'moment'

const statusColors = {
    approved: '#1db57a',
    rejected: '#d0021b',
    open: '#d66a00',
    fulfilled: '#1db57a',
    canceled: '#5d6870',
    completed: '#307fc1',
    error: '#d14e4e',
    notReady: '#5b6871',
    autoClosed: '5d6870',
}

export const isDate = (date: any) => {
    if (!date) {
        return false
    }
    return moment(date, moment.ISO_8601, true).isValid()
}

export const isDateInPast = (date: any) => {
    if (isDate(date)) {
        const currentDate = moment(new Date())
        return currentDate.isAfter(moment.utc(date))
    }
    return false
}

const getNumberOrDefault = (number: any, defaultValue: number) => {
    if (isNaN(number)) return defaultValue
    return number
}
export const getStatusColor = (status: any, defaultColor = '#ffffff') => {
    return Object.keys(statusColors).includes(status)
        ? //@ts-ignore
        statusColors[status]
        : defaultColor
}

export const getFullImageUrl = (imagePath: any) =>
    !isNilOrEmpty(imagePath) ? `${config.BASE_EID_URL}${imagePath}` : undefined

export const mobileScreenWidth = '767px'
export const tabletScreenWidth = '768px'
export const smallScreenWidth = '1022px'
export const headerHeight = 70
export const defaultDataProtectedSubcomponent = 'NotProtected'

export const COMMENT_MAX_LENGTH = 1000

export const getDecisionCommentValidationMessage = (
    comment: any,
    isRejected = false,
) => {
    if (REJECTION_COMMENTS_MIN_LENGTH > 0 && !comment && isRejected) {
        return i18n.t('CommentRequired')
    }
    if (DECISION_COMMENT_MIN_LENGTH > 0 && !comment) {
        return i18n.t('CommentRequired')
    }
    if (isWhitespace(comment)) {
        return i18n.t('WhiteSpaceNotAllowed')
    }
    if (comment?.length > DECISION_COMMENT_MAX_LENGTH) {
        return i18n.t('DecisionCommentMaxLength', {
            length: DECISION_COMMENT_MAX_LENGTH,
        })
    }
    if (comment?.length < DECISION_COMMENT_MIN_LENGTH) {
        return i18n.t('DecisionCommentMinLength', {
            length: DECISION_COMMENT_MIN_LENGTH,
        })
    }
    if (isRejected && comment?.length < REJECTION_COMMENTS_MIN_LENGTH) {
        return i18n.t('RejectionCommentMinLength', {
            length: REJECTION_COMMENTS_MIN_LENGTH,
        })
    }

    return ''
}
export const DECISION_COMMENT_MAX_LENGTH = getNumberOrDefault(
    config.DECISION_COMMENT_MAX_LENGTH,
    500,
)

export const DECISION_COMMENT_MIN_LENGTH = getNumberOrDefault(
    config.DECISION_COMMENT_MIN_LENGTH,
    0,
)

export const REJECTION_COMMENTS_MIN_LENGTH = getNumberOrDefault(
    config.REJECTION_COMMENTS_MIN_LENGTH,
    0,
)

export const isWholeNumber = (number: any) => {
    const result = number - Math.floor(number) !== 0

    if (result) return false
    else return true
}

export const roundOffNumber: (number: any, upto?: any) => number = (
    number,
    upto = 2,
) => {
    if (isWholeNumber(number)) return parseInt(number)

    const pow = Math.pow(10, upto)
    return Math.round(number * pow) / pow
}

export const FUNCTIONAL_ACCESS_PAGE_SIZE = 5

const ANONYMOUS_PERSON_GUID = [
    '9B66D0FA-61A2-4E66-B9CC-A7804BC810CD',
    'EC2883FD-AAAA-47C2-A65E-EA7335690B32',
    '3AF825A6-5CF1-4412-A45B-7BB802FD5C9F',
]

export const checkIfAnonymousUserGuid = (personGuid: any) => {
    if (!personGuid) {
        return ''
    }
    return ANONYMOUS_PERSON_GUID.includes(personGuid.toUpperCase())
        ? ''
        : personGuid
}

export const RESOURCE_TYPES = {
    GROUP: 'Group',
    SETGROUP: 'SetGroup',
    PERSON: 'Person',
}

export const getCombinedFields = (dataObject: any) => {
    let updatedAppRights = []
    try {
        const appRights = dataObject?.Rights || []
        if (appRights?.length) {
            //To check if App Role
            appRights.forEach((element: any) => {
                const fieldTypes = element?.FieldType || []
                const modifiedField = {
                    displayName: element?.DisplayName,
                    isModified: fieldTypes.find((x: any) => x.isModified)
                        ? true
                        : false,
                    fieldTypes: fieldTypes,
                }
                if (fieldTypes?.length) {
                    updatedAppRights.push(modifiedField)
                }
            })
        } else {
            // Else is for app Right
            const appRightsFields = dataObject?.FieldType || []
            const modifiedField = {
                displayName: dataObject?.DisplayName,
                isModified: appRightsFields.find((x: any) => x.isModified)
                    ? true
                    : false,
                fieldTypes: appRightsFields,
            }
            if (appRightsFields?.length) {
                updatedAppRights.push(modifiedField)
            }
        }
    } catch (err) { }
    return updatedAppRights
}

export const getFieldTypeWithValue = (field: any) => {
    try {
        const valueType =
            field?.AzFieldTypeSelectionRuleTypeID === 3
                ? 'AssignedValues'
                : 'AssignedValue'
        const value = field[valueType]
        if (field.isModified) {
            return field
        }
        if (
            field?.SelectionRuleType === 'Range' &&
            (field?.SelectionRule === FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_RANGE ||
                field?.SelectionRule === FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE)
        ) {
            if (field.AssignedValueFrom || field.AssignedValueTo) {
                return field
            }
        } else if (typeof value === 'string') {
            if (value) {
                return field
            }
        } else if (Array.isArray(value)) {
            if (value?.length) {
                return field
            }
        } else {
            if (value?.ValueName) {
                return field
            } else if (value) {
                return field
            }
        }
    } catch (err) { }
    return null
}

export const isValidJson = (fieldTypeJson: any) => {
    let isValidJson = false
    try {
        const apiDataObject = JSON.parse(fieldTypeJson)
        const parsedObject = Array.isArray(apiDataObject)
            ? apiDataObject[0]
            : apiDataObject
        if (Object.keys(parsedObject).length > 0) {
            isValidJson = true
        }
        // Check to find if all fieldtypes contains value
        if (isValidJson) {
            const combinedFieldTypes = getCombinedFields(parsedObject)
            if (!combinedFieldTypes?.length) {
                return false
            }
            let isValidJsonWithFieldTypes = false
            combinedFieldTypes.forEach((item) => {
                item?.fieldTypes?.forEach((field: any) => {
                    const validField = getFieldTypeWithValue(field)
                    if (validField) {
                        isValidJsonWithFieldTypes = true
                    }
                })
            })
            return isValidJson && isValidJsonWithFieldTypes
        }
        // TODO: Uncomment below when we need to show the changed fieldtype only
        // if (isValidJson) {
        //     const combinedFieldTypes = getCombinedFields(parsedObject)
        //     const isModifed = combinedFieldTypes.find((x) => x.isModified)
        //     return isValidJson && isModifed
        // }
    } catch (err) { }
    return isValidJson
}

export const updateJsonMappingWithApiFormat = (data: any, isApiFormat: any) => {
    try {
        if (data?.Rights?.length) {
            const newData = data?.Rights?.map((right: any) => {
                let fieldType = []
                if (right?.FieldType?.length) {
                    fieldType = right.FieldType.map((field: any) => {
                        const valueType =
                            field?.AzFieldTypeSelectionRuleTypeID === 3
                                ? 'AssignedValues'
                                : 'AssignedValue'
                        let fieldValue = field[valueType]
                        if (field?.AzFieldTypeSelectionRuleTypeID !== 3) {
                            if (isApiFormat && fieldValue) {
                                fieldValue = [fieldValue]
                            } else if (!isApiFormat && fieldValue?.length) {
                                fieldValue = fieldValue?.[0]
                            }
                        }
                        return {
                            ...field,
                            [valueType]: fieldValue,
                            parentRightId: right.AzLocalRightID,
                        }
                    })
                }
                return { ...right, FieldType: fieldType }
            })
            return { ...data, Rights: newData }
        } else {
            if (data?.FieldType?.length) {
                const fieldTypes = data.FieldType.map((field: any) => {
                    const valueType =
                        field?.AzFieldTypeSelectionRuleTypeID === 3
                            ? 'AssignedValues'
                            : 'AssignedValue'
                    let fieldValue = field[valueType]
                    if (field?.AzFieldTypeSelectionRuleTypeID !== 3) {
                        if (isApiFormat && fieldValue) {
                            fieldValue = [fieldValue]
                        } else if (!isApiFormat && fieldValue?.length) {
                            fieldValue = fieldValue?.[0]
                        }
                    }
                    return {
                        ...field,
                        [valueType]: fieldValue,
                        parentRightId: data.AzLocalRightID,
                    }
                })
                return { ...data, FieldType: fieldTypes }
            }
            return data
        }
    } catch (err) { }
    return data
}

import { FC, memo } from 'react'
import { Box, Collapse } from '@mui/material'
import { IApprovalStep } from 'types'
import { RiskViolationLeftRight } from '../V2/RiskViolationLeftRight'

export type RiskNodeProps = {
    id?: string
    requestId: string
    itemId?: string
    step: IApprovalStep
    showMore: boolean
}

const getViolation = (risk: any) => {
    const violation: any = {
        extendedRiskDescription: risk.riskDescription,
        riskType: risk.riskTypeID,

        riskDetails: {
            riskReason: risk.riskFunctionSource,
            requestId: risk.riskBusinessRequestID,
            requestNumber: risk.riskRequestNumber,
            riskFunctionAssignee: risk.riskFunctionAssignee,
        },
    }
    if (violation.riskType === 'SegregationOfDuties') {
        violation.segregatedRiskDetails = {
            riskReason: risk.riskSegregatedFunctionSource,
            requestId: risk.segregatedRiskBusinessRequestID,
            requestNumber: risk.segregatedRiskRequestNumber,
            riskFunctionAssignee: risk.riskSegregatedFunctionAssignee,
        }
    }
    return violation
}

const RiskNode: FC<RiskNodeProps> = memo((props) => {
    return (
        <Collapse timeout={10} in={props.showMore}>
            {props.step.risk &&
                props.step.risk.length > 0 &&
                props.step.risk.map((item: any, index: number) => (
                    <Box marginY={'7px'}>
                        <RiskViolationLeftRight
                            showFunctions={false}
                            requestId={props.requestId}
                            itemId={props.itemId}
                            page={'Requests'}
                            violation={getViolation(item)}
                            riskNodeID={`risk_${props.id}_${index}`}
                        />
                    </Box>
                ))}
        </Collapse>
    )
})

export { RiskNode }

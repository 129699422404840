import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { InputBase } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SearchIcon } from '../../../eid-icons'
import { useDebounce } from '../../../core'

const useStyles = makeStyles(() => {
    return {
        searchGeneric: (props) => ({
            width: '100%',
            height: '4rem',
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            borderRadius: 3,
            margin: 'auto',
            border: `1px solid ${props.borderColor}`,
            backgroundColor: props.bgColor,
        }),
        searchIcon: {
            width: '1.8rem',
            height: '1.8rem',
            marginLeft: '1.3rem',
            marginTop: '0.2rem',

            '& > svg': {
                width: '100%',
                height: '100%',
            },
        },
        input: (props) => ({
            marginLeft: '0.8rem',
            fontSize: '1.5rem',
            flex: 1,
            color: `${props.color} !important`,
            '&:hover, &:focus': {
                color: `${props.hoverColor} !important`,
            },
        }),
    }
})

function Search({ handleSearch, styleData, placeholder, value }) {
    const classes = useStyles(styleData)
    const [searchKey, setSearchKey] = useState(value)

    useEffect(() => {
        if (value !== searchKey) {
            setSearchKey(value)
        }
    }, [value])

    const handleChange = (event) => {
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const debouncedValue = useDebounce(searchKey)
    useEffect(() => {
        handleSearch(debouncedValue)
    }, [debouncedValue])

    return (
        <div className={classes.searchGeneric}>
            <div className={classes.searchIcon}>
                <SearchIcon color={styleData.iconColor} />
            </div>
            <InputBase
                fullWidth={true}
                value={searchKey}
                onChange={handleChange}
                classes={{ input: classes.input }}
                placeholder={placeholder ?? 'Search'}
            />
        </div>
    )
}

Search.propTypes = {
    handleSearch: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
}

Search.defaultProps = {
    handleSearch: () => {},
    value: '',
}

export default Search

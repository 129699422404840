import { Box, Divider } from '@mui/material'
import { OverviewSectionSkeleton, Person, ProgressIndicator } from 'components'
import moment from 'moment'
import { StatusIndicator } from 'packages/eid-ui'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequestAccessRecipient, usePerson } from 'hooks'
import {
    Container,
    AttributeContainer,
    AttributeLabel,
    AttributeValue,
    styles,
    VerticalAlignedLabel,
    useStyles,
    PaperContainer,
} from 'components/DetailsComponents'
import { getFullImageUrl, roundOffNumber } from 'utils'
import { useRegistry } from 'core/useRegistry'
import FunctionalAccessRequest from 'components/FunctionalAccessRequest'
import useSubcomponents from 'useSubcomponents'

const statusStyles = {
    color: '#9b9b9b',
    maxWidth: '270px',
}

const BusinessRequestFulfillmentStatusIndicator = ({ attributeName, item }) => {
    const { t } = useTranslation()
    const status = item[attributeName]

    return (
        <Fragment>
            {status && (
                <StatusIndicator
                    label={`${t('Fulfillment')} ${t(
                        `${status}`,
                    )}`}
                    size="small"
                    style={{
                        minWidth: '160px',
                        height: '16px',
                    }}
                />
            )}
        </Fragment>
    )
}

const Overview = ({ data }) => {
    const registry = useRegistry()
    const { t } = useTranslation()

    const { hasAccessToFunctionalAccessControl } = useSubcomponents()

    const classes = useStyles()

    const { data: accessRecipient } = useRequestAccessRecipient(
        data.id,
        data.targetPersonId,
    )

    const { data: initiatorPerson } = usePerson(data?.initiatorPersonId)

    const requestStatus = data?.preApproved ? 'PreApproved' : data?.status

    if (!data || !accessRecipient) {
        return (
            <Container>
                <OverviewSectionSkeleton />
            </Container>
        )
    }

    const subjectTypeInstance = registry.getSubjectType(
        accessRecipient.subjectType,
    )

    const SubjectDetailsComponent = subjectTypeInstance.detailComponent

    return (
        <PaperContainer>
            <Container>
                <Box className={classes.profileContainer}>
                    <SubjectDetailsComponent
                        accessRecipient={accessRecipient}
                    />
                </Box>

                <Divider
                    orientation="vertical"
                    flexItem
                    style={styles.divider}
                />

                <Box className={classes.detailsContainer}>
                    {data?.currentApprovalStepName && (
                        <AttributeContainer>
                            <AttributeLabel>{t('Step')}</AttributeLabel>
                            <AttributeValue
                                style={{
                                    fontWeight: 700,
                                }}
                                value={data?.currentApprovalStepName}
                            />
                        </AttributeContainer>
                    )}
                    <VerticalAlignedLabel
                        style={{
                            color: '#5d6870',
                            fontWeight: '600',
                            maxWidth: '50%',
                            marginBottom: '-10px',
                        }}
                    >
                        {t('PercentageCompleted', {
                            value: `${roundOffNumber(
                                data?.percentageCompleted * 100,
                            )} %`,
                        })}
                    </VerticalAlignedLabel>

                    <AttributeContainer className={classes.progressAttribute}>
                        <Box className={classes.progressIndicatorContainer}>
                            <Box>
                                <ProgressIndicator
                                    percentageCompleted={
                                        data?.percentageCompleted * 100
                                    }
                                    width={'275'}
                                />
                            </Box>
                            <Box display="flex">
                                <StatusIndicator
                                    size="large"
                                    variant={requestStatus}
                                    label={t(`${requestStatus}`)}
                                    style={{
                                        minWidth: 'fit-content',
                                    }}
                                />
                            </Box>
                        </Box>
                    </AttributeContainer>

                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('Requestor')}
                        </VerticalAlignedLabel>

                        <Box classes={{ root: classes.person }}>
                            <Person.Details
                                imgSrc={getFullImageUrl(
                                    initiatorPerson?.imageThumbUrl,
                                )}
                                size="large"
                                name={
                                    initiatorPerson
                                        ? initiatorPerson.friendlyName
                                        : data?.initiatorPersonFriendlyName
                                }
                                status={initiatorPerson?.login}
                                statusStyles={statusStyles}
                                showTooltip={true}
                            />
                        </Box>
                    </AttributeContainer>

                    <AttributeContainer>
                        <AttributeLabel>
                            {t('RequestID')}
                        </AttributeLabel>
                        <AttributeValue value={data?.number} />
                    </AttributeContainer>
                    {data?.showFulFillmentStatus && (
                        <AttributeContainer>
                            <AttributeLabel>
                                {t('Fulfillment')}
                            </AttributeLabel>
                            <BusinessRequestFulfillmentStatusIndicator
                                item={data}
                                attributeName={
                                    'businessRequestFulfillmentStatusName'
                                }
                            />
                        </AttributeContainer>
                    )}
                    <AttributeContainer>
                        <AttributeLabel>
                            {t('CreatedOn')}
                        </AttributeLabel>
                        <AttributeValue
                            value={
                                !data
                                    ? ''
                                    : moment
                                          .utc(data.createdDateUtc)
                                          .local()
                                          .format('LLL')
                            }
                        />
                    </AttributeContainer>

                    {data?.dueDateUtc && (
                        <AttributeContainer>
                            <AttributeLabel>
                                {t('DueDate')}
                            </AttributeLabel>
                            <AttributeValue
                                value={
                                    !data || !data.dueDateUtc
                                        ? ''
                                        : moment
                                              .utc(data.dueDateUtc)
                                              .local()
                                              .format('LLL')
                                }
                            />
                        </AttributeContainer>
                    )}

                    <AttributeContainer>
                        <AttributeLabel>
                            {t('LastModifiedOn')}
                        </AttributeLabel>

                        <AttributeValue
                            value={
                                !data
                                    ? ''
                                    : moment
                                          .utc(data.modifiedDateUtc)
                                          .local()
                                          .format('LLL')
                            }
                        />
                    </AttributeContainer>

                    <AttributeContainer>
                        <AttributeLabel>
                            {t('ExpirationDate')}
                        </AttributeLabel>

                        <AttributeValue
                            value={
                                !data
                                    ? ''
                                    : moment
                                          .utc(data.expirationDateUtc)
                                          .local()
                                          .format('LLL')
                            }
                        />
                    </AttributeContainer>

                    <AttributeContainer>
                        <AttributeLabel>
                            {t('InactivityExpirationDate')}
                        </AttributeLabel>

                        <AttributeValue
                            value={
                                !data
                                    ? ''
                                    : moment
                                          .utc(data.inactivityExpirationDateUtc)
                                          .local()
                                          .format('LLL')
                            }
                        />
                    </AttributeContainer>
                </Box>
            </Container>
            {hasAccessToFunctionalAccessControl && (
                <div
                    data-protectedsubcomponent={
                        hasAccessToFunctionalAccessControl
                    }
                >
                    <FunctionalAccessRequest assigneeId={data.subjectId} />
                </div>
            )}
        </PaperContainer>
    )
}

export default Overview

const attributes = [
    {
        isHeader: true,
        name: 'description',
        sortable: true,
        sortBy: 'Description',
        label: 'Name',
        headerCellStyles: {
            width: '25%',
            minWidth: '180px',
        },
        styles: {
            color: '#9230c5',
            fontSize: '16px',
            fontWeight: 'bold',
            marginTop: '6px',
            marginLeft: '10px',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        link: false,
    },
    {
        name: 'number',
        sortable: true,
        sortBy: 'Number',
        label: 'BusinessRequestNumber',

        headerCellStyles: {
            minWidth: '105px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
            marginTop: '6px',
        },
    },
    {
        name: 'status',
        hideValue: true,
        label: 'Status',
        sortable: true,
        sortBy: 'Status',

        headerCellStyles: {
            width: '15%',
            minWidth: '15%',
        },
        styles: {
            padding: '20px 0px',
        },
        component: {
            componentName: 'StatusIndicator',
            name: 'status',
            props: {
                size: 'medium',
            },
        },
    },
    {
        name: 'initiatorPersonFriendlyName',
        label: 'RequestedBy',
        hideValue: true,
        sortable: true,
        sortBy: 'InitiatorPersonFriendlyName',
        avatar: true,
        imgProp: 'initiatorPersonThumbnailUrl',
        headerCellStyles: {
            maxWidth: '150px',
        },
        styles: {
            maxWidth: '150px',
        },
        textStyles: {
            color: '#282828',
            fontSize: '13px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
        },
    },
    {
        name: 'targetPersonFriendlyName',
        label: 'RequestedFor',
        sortBy: 'SubjectFriendlyName',
        hideValue: true,
        avatar: false,
        sortable: true,
        headerCellStyles: {
            maxWidth: '150px',
        },
        styles: {
            maxWidth: '150px',
        },
        component: {
            componentName: 'SubjectType',
            props: {
                size: 'small',
                style: {
                    color: '#282828',
                    fontSize: '13px',
                },
            },
        },
    },
    {
        name: 'typeFriendlyName',
        label: 'Type',
        sortBy: 'typeFriendlyName',
        avatar: false,
        sortable: true,
        headerCellStyles: {
            maxWidth: '150px',
        },
        styles: {
            maxWidth: '150px',
            marginTop: '6px',
            fontSize: '13px',
            whiteSpace: 'normal',
            color: '#282828',
        },
    },
    {
        name: 'createdDateUtc',
        hideValue: true,
        label: 'CreatedDate',
        sortable: true,
        sortBy: 'CreatedDateUtc',

        headerCellStyles: {
            width: '15%',
            minWidth: '15%',
        },
        component: { componentName: 'CellDateTime' },
    },
    {
        name: 'Risk',
        hideValue: true,
        label: 'RiskLevel',
        sortable: true,
        sortBy: 'RiskLevel',
        headerCellStyles: {
            width: '10%',
            maxWidth: '105px',
        },
        styles: {
            maxWidth: '105px',
        },
        component: { componentName: 'RiskIndicator', name: 'riskLevel' },
    },
]

export default attributes

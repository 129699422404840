import React from 'react'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))

const RightSection = ({ children }) => {
    const classes = useStyles()

    return <div className={classes.root}>{children}</div>
}

export default RightSection

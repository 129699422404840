import React from 'react'
import { EidAutocomplete } from 'packages/eid-controls'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Icon, CheckIfIconExists } from 'packages/eid-icons'
import { isNilOrEmpty } from 'packages/core'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import {
    AttributeContainer,
    AttributeValue,
    styles,
    VerticalAlignedLabel,
} from 'components/DetailsComponents'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'label',
    labelProp: 'friendlyName',
})

const queryParams = {
    take: 10,
}

const getOptionLabel = (option: any) => option.friendlyName ?? ''

export const Filter = ({ subjectType }: any) => {
    const [state, dispatch]: any = usePageContext()
    const value = state.subject

    const onClear = () =>
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'subject',
                value: null,
            },
        })

    const onChange = (payload: any) => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'subject',
                value: payload,
            },
        })
    }

    return (
        <Box padding="0px 20px 30px 20px">
            <EidAutocomplete.New
                url={`/api/subjectTypes/${subjectType?.id}/subjects`}
                placeholder={subjectType?.friendlyName}
                getOptionLabel={getOptionLabel}
                optionComponent={OptionComponent}
                value={value ? value : null}
                onChange={(_: any, value: any) => {
                    const valueToDispatch = value ? value : null
                    onChange(valueToDispatch)
                }}
                clearIcon={
                    !isNilOrEmpty(value) && (
                        <Icon name="Close" color="#959598" onClick={onClear} />
                    )
                }
                queryParams={queryParams}
                filterOptions={undefined}
            />
        </Box>
    )
}

const useStyles = makeStyles({
    avatarContainer: {
        position: 'absolute',
        right: '15px',
    },
    avatar: {
        width: '64px',
        height: '64px',
    },
    viewProfileAnchor: {
        textDecoration: 'none',
        color: 'rgb(48, 127, 193)',
        '&:hover,&:active,&:visted': {
            color: 'rgb(48, 127, 193)',
        },
    },
    infoBox: {
        '& div': {
            '& div': {
                minWidth: '140px',
                maxWidth: '140px',
                marginRight: '10px',
            },
        },
    },
})

export const Details = ({ accessRecipient }: any) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const isSubjectGroup = accessRecipient?.subjectType === 'ApplicationRole'

    return (
        <>
            <Box
                className={classes.avatarContainer}
                marginBottom="24px !important"
            >
                <Icon
                    className={classes.avatar}
                    name={
                        CheckIfIconExists(accessRecipient?.subjectType)
                            ? accessRecipient?.subjectType
                            : 'Unknown'
                    }
                    color="#848992"
                />
            </Box>
            <Box className={classes.infoBox}>
                <AttributeContainer
                    style={{ display: 'block', marginBottom: '20px' }}
                >
                    <VerticalAlignedLabel>
                        {t('AccessRecipient')}
                    </VerticalAlignedLabel>

                    <AttributeValue
                        value={
                            isSubjectGroup
                                ? accessRecipient?.empowerID_FriendlyName || '-'
                                : accessRecipient?.friendlyName || '-'
                        }
                        style={styles.roleReceiverValue}
                    />
                </AttributeContainer>

                {isSubjectGroup && (
                    <>
                        <AttributeContainer>
                            <VerticalAlignedLabel>
                                {t('Description')}
                            </VerticalAlignedLabel>
                        </AttributeContainer>

                        <AttributeValue
                            value={accessRecipient?.empowerID_Description}
                            maxCharsToShow={250}
                            marginLeft={'1.3rem'}
                        />
                    </>
                )}

                <AttributeContainer>
                    <VerticalAlignedLabel>{t('Name')}</VerticalAlignedLabel>

                    <AttributeValue value={accessRecipient?.name} noWrap />
                </AttributeContainer>
                {accessRecipient?.email && (
                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('Email')}
                        </VerticalAlignedLabel>

                        <AttributeValue value={accessRecipient?.email} noWrap />
                    </AttributeContainer>
                )}
                {accessRecipient?.groupType && (
                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('GroupType')}
                        </VerticalAlignedLabel>

                        <AttributeValue
                            value={accessRecipient?.groupType}
                            noWrap
                        />
                    </AttributeContainer>
                )}
                {accessRecipient?.highLevelClassification && (
                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('HighLevelClassification')}
                        </VerticalAlignedLabel>

                        <AttributeValue
                            value={accessRecipient?.highLevelClassification}
                            noWrap
                        />
                    </AttributeContainer>
                )}
                {accessRecipient?.logon && (
                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('AccountViewLogonName')}
                        </VerticalAlignedLabel>

                        <AttributeValue value={accessRecipient?.logon} noWrap />
                    </AttributeContainer>
                )}
                {accessRecipient?.accountStore && (
                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('AccountStore')}
                        </VerticalAlignedLabel>

                        <AttributeValue
                            value={accessRecipient?.accountStore}
                            noWrap
                        />
                    </AttributeContainer>
                )}
                {accessRecipient?.accountType && (
                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('AccountType')}
                        </VerticalAlignedLabel>

                        <AttributeValue
                            value={accessRecipient?.accountType}
                            noWrap
                        />
                    </AttributeContainer>
                )}
                {accessRecipient?.roleType_1 && (
                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('RoleType')}
                        </VerticalAlignedLabel>

                        <AttributeValue
                            value={accessRecipient?.roleType_1}
                            noWrap
                        />
                    </AttributeContainer>
                )}
                {accessRecipient?.roleType_2 && (
                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('RoleType')}
                        </VerticalAlignedLabel>

                        <AttributeValue
                            value={accessRecipient?.roleType_2}
                            noWrap
                        />
                    </AttributeContainer>
                )}
                {accessRecipient?.riskType && (
                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('RiskType')}
                        </VerticalAlignedLabel>

                        <AttributeValue
                            value={t(`${accessRecipient?.riskType}`)}
                            noWrap
                        />
                    </AttributeContainer>
                )}
                {accessRecipient?.riskLevel && (
                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('RiskLevel')}
                        </VerticalAlignedLabel>

                        <AttributeValue
                            value={t(`${accessRecipient?.riskLevel}Risk`)}
                            noWrap
                        />
                    </AttributeContainer>
                )}
                {accessRecipient?.employeeId && (
                    <AttributeContainer>
                        <VerticalAlignedLabel>
                            {t('EmployeeId')}
                        </VerticalAlignedLabel>

                        <AttributeValue
                            value={accessRecipient?.employeeId}
                            noWrap
                        />
                    </AttributeContainer>
                )}
                {accessRecipient.additionalProperties &&
                    Object.keys(accessRecipient.additionalProperties).map(
                        (k) => (
                            <AttributeContainer key={k}>
                                <VerticalAlignedLabel>
                                    {t(`${k}`)}
                                </VerticalAlignedLabel>

                                <AttributeValue
                                    value={accessRecipient.additionalProperties[
                                        k
                                    ]?.toString()}
                                />
                            </AttributeContainer>
                        ),
                    )}
            </Box>
        </>
    )
}

import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FieldItemWrapper from '../Components/FieldItemWrapper'
import RequiredFieldErrorMessage from '../Components/FieldItemWrapper/RequiredFieldErrorMessage'
import classNames from 'classnames'
import {
    FIELD_TYPE_COMPONENTS_TYPES,
    getFieldTypeRequiredErrorMessage,
} from 'packages/core'
import { TextField } from 'packages/eid-ui'

const useStyles = makeStyles((theme) => ({
    fieldListWraper: {
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',
        '& label': {
            minWidth: '3.3rem',
        },
        '& input': {
            maxWidth: '7rem',
            color: '#3b454d',
        },
        '& .MuiFormControl-root': {
            marginTop: '0 !important',
            borderRadius: '0.4rem',
            boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        },
    },
    fieldList: {
        display: 'flex',
        '& li': {
            width: 'auto',
            background: '#fbfbfd !important',
            color: '#767676',
            fontWeight: 'normal',
            fontSize: '1.4rem',
            paddingLeft: '0',
        },
        '& .Mui-selected': {
            color: `${theme?.palette?.common?.black} !important`,
        },
    },
    matchingLabel: {
        color: '#9b9b9b',
        fontWeight: 'normal',
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        marginTop: '1.2rem',
    },
    fieldListContainer: {
        marginBottom: '3rem',
    },
    disabledStyle: {
        pointerEvents: 'none',
        opacity: '.6',
        cursor: 'not-allowed',
    },
    errorMessageTo: {
        paddingLeft: '16rem !important',
    },
}))

const FreeTextRange = ({ fieldType, isFormValidated, handleUpdate }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [errorMessageFrom, setErrorMessageFrom] = useState('')
    const [errorMessageTo, setErrorMessageTo] = useState('')

    useEffect(() => {
        if (
            isFormValidated &&
            (!fieldType?.AssignedValueFrom || !fieldType?.AssignedValueTo)
        ) {
            if (!fieldType?.AssignedValueFrom) {
                setErrorMessageFrom(getFieldTypeRequiredErrorMessage(fieldType))
                setErrorMessageTo('')
            } else {
                setErrorMessageTo(getFieldTypeRequiredErrorMessage(fieldType))
                setErrorMessageFrom('')
            }
        } else {
            setErrorMessageFrom('')
            setErrorMessageTo('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isFormValidated,
        fieldType?.AssignedValueTo,
        fieldType?.AssignedValueFrom,
    ])

    const handleChange = (value, key) => {
        try {
            const valueToUpdate = {
                ...fieldType,
                [key]: value,
            }
            handleUpdate(valueToUpdate)
        } catch (err) {}
    }

    const bindErrorMessage = () => {
        return errorMessageFrom || errorMessageTo || ''
    }

    return (
        <Box className={classes.fieldListContainer}>
            <FieldItemWrapper
                title={fieldType?.Name}
                isRequired={fieldType?.IsRequired}
                fieldTypeScope={fieldType?.FieldTypeScope}
            >
                <Box
                    className={classNames(classes.fieldListWraper, {
                        [classes.disabledStyle]:
                            fieldType?.IsReadOnly ||
                            fieldType?.SelectionRule ===
                                FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE,
                    })}
                >
                    <Box>
                        <TextField
                            margin={'none'}
                            onChange={(e) =>
                                handleChange(
                                    e.target.value,
                                    'AssignedValueFrom',
                                )
                            }
                            value={fieldType?.AssignedValueFrom || ''}
                            label={t('Common_From')}
                            error={Boolean(errorMessageFrom)}
                            disabled={
                                fieldType?.IsReadOnly ||
                                fieldType?.SelectionRule ===
                                    FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE
                            }
                        />
                    </Box>
                    <Box>
                        <TextField
                            margin={'none'}
                            onChange={(e) =>
                                handleChange(e.target.value, 'AssignedValueTo')
                            }
                            value={fieldType?.AssignedValueTo || ''}
                            label={t('Common_To')}
                            error={Boolean(errorMessageTo)}
                            disabled={
                                fieldType?.IsReadOnly ||
                                fieldType?.SelectionRule ===
                                    FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE
                            }
                        />
                    </Box>
                </Box>

                {(Boolean(errorMessageFrom) || Boolean(errorMessageTo)) && (
                    <RequiredFieldErrorMessage
                        errorMsgKey={bindErrorMessage()}
                        parentClass={errorMessageTo && classes.errorMessageTo}
                    />
                )}
            </FieldItemWrapper>
        </Box>
    )
}

export default FreeTextRange

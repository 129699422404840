import DrawerActionButton from './DrawerActionButton'
import { Box, Typography, useTheme } from '@mui/material'
import { useHistory } from 'react-router'
import { Icon } from 'packages/eid-icons'

const BackButton = ({ label, previousRoute }) => {
    const history = useHistory()
    const theme = useTheme()
    if (label) {
        return (
            <DrawerActionButton
                width="auto"
                fontColor={theme.palette.primary.main}
                border={`solid 1px ${theme.palette.primary.main}`}
                bgColor="transparent"
                height="40px"
                paddingRight="20px"
                onClick={() => {
                    if (previousRoute) {
                        history.push(previousRoute)
                    } else {
                        history.goBack()
                    }

                }}
            >
                <Box display="flex" alignItems="center" paddingRight="10px">
                    <Icon
                        name="Arrow"
                        direction="left"
                        style={{
                            cursor: 'pointer',
                        }}
                        color={theme.palette.primary.main}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography style={{ color: theme.palette.primary.main }}>
                        {label}
                    </Typography>
                </Box>
            </DrawerActionButton>
        )
    }
    return (
        <Box
            width="70px"
            display="flex"
            justifyContent="flex-end"
            marginRight="10px"
        >
            <Icon
                name="BackButton"
                style={{
                    cursor: 'pointer',
                }}
                fill={theme.palette.primary.main}
                onClick={() => {
                    if (previousRoute) {
                        history.push(previousRoute)
                    } else {
                        history.goBack()
                    }
                }}
            />
        </Box>
    )
}

export default BackButton

import React from 'react'
import { useSnackbar } from 'notistack'
import NotificationMessage from './NotificationMessage'
import StatusInfoMessage from './StatusInfoMessage'

const useNotification = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const showNotification = (type, message) => {
        const notificationContent = (key, message) => (
            <NotificationMessage
                type={type}
                onClose={() => closeSnackbar(key)}
                message={message}
            />
        )
        enqueueSnackbar(message, {
            content: notificationContent,
        })
    }

    const showMessages = (messagesData, title) => {
        const messagesContent = (key) => (
            <StatusInfoMessage
                messages={messagesData}
                title={title}
                handleClose={() => closeSnackbar(key)}
            />
        )
        enqueueSnackbar('', {
            content: messagesContent,
            persist: true,
            anchorOrigin: {
                vertical: 'center',
                horizontal: 'center',
            },
        })
    }

    const showSuccessMessage = (message) => showNotification('success', message)
    const showWarningMessage = (message) => showNotification('warning', message)
    const showInformationMessage = (message) =>
        showNotification('information', message)
    const showMessagesInPopup = (messagesData, title) =>
        showMessages(messagesData, title)

    return {
        showSuccessMessage,
        showWarningMessage,
        showInformationMessage,
        showMessagesInPopup,
    }
}

export default useNotification

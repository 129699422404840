import { useTranslation } from 'react-i18next'
import { AttributeValue, AttributeLabel } from 'components/DetailsComponents'
import { Box, styled, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Divider, Spinner } from 'packages/eid-ui'
import { useRequestPendingItemAdditionalProperties } from 'hooks'
import { useAppState } from 'appContext'
import { Loader } from 'components'
import moment from 'moment'
import { isDate, isDateInPast } from 'utils'
import classNames from 'classnames'
import { useEffect } from 'react'

const useStyles = makeStyles((theme) =>
    createStyles({
        dateWarning: {
            color: `${theme?.palette?.warning?.main} !important`,
        },
    }),
)

export const AttributeContainer = styled(Box)({
    margin: '12px 0px',
    width: '33%',
})

export const StyledAttributeValue = styled(AttributeValue)({
    marginLeft: '13px',
    marginRight: '13px',
})

export const StyledAttributeLabel = styled(AttributeLabel)({
    maxWidth: 'none',
})

export const StyledTypography = styled(Typography)({
    fontSize: '16px',
    color: '#3b454d',
    textTransform: 'uppercase',
    marginLeft: '13px',
    fontWeight: 'bold',
})

const PendingItemsDetails = ({
    itemId,
    additionalResourceName,
    handleShowDateWarning,
}) => {
    const { t } = useTranslation()
    const [{ devFeaturesEnabled }] = useAppState()
    const classes = useStyles()

    const { data, isLoading } = useRequestPendingItemAdditionalProperties(
        itemId,
    )

    let endDateValue = data?.otherProperties?.['EndDate']

    useEffect(() => {
        if (endDateValue && isDateInPast(endDateValue)) {
            handleShowDateWarning(true)
        }
    }, [data])

    return (
        <>
            {isLoading ? (
                <Box display="flex" justifyContent="center">
                    <Loader />
                </Box>
            ) : (
                <>
                    {data &&
                    (Object.keys(data?.additionalProperties).length > 0 ||
                        Object.keys(data?.otherProperties).length > 0 ||
                        additionalResourceName) ? (
                        <Box display="flex" flexWrap="wrap">
                            {data?.additionalProperties &&
                                Object.keys(data?.additionalProperties).length >
                                    0 &&
                                Object.keys(data?.additionalProperties).map(
                                    (k) => {
                                        const value = data?.additionalProperties[
                                            k
                                        ]?.toString()

                                        return (
                                            <AttributeContainer>
                                                <StyledAttributeLabel>
                                                    {t(`${k}`)}
                                                </StyledAttributeLabel>

                                                <StyledAttributeValue
                                                    value={
                                                        isDate(value)
                                                            ? moment
                                                                  .utc(value)
                                                                  .local()
                                                                  .format('LLL')
                                                            : value
                                                    }
                                                    noWrap
                                                    className={classNames({
                                                        [classes.dateWarning]:
                                                            (k ===
                                                                'StartDate' ||
                                                                k ===
                                                                    'EndDate') &&
                                                            isDateInPast(
                                                                endDateValue,
                                                            ),
                                                    })}
                                                />
                                            </AttributeContainer>
                                        )
                                    },
                                )}
                            {data?.otherProperties &&
                                Object.keys(data?.otherProperties).length > 0 &&
                                Object.keys(data?.otherProperties).map((k) => {
                                    const value = data?.otherProperties[
                                        k
                                    ]?.toString()

                                    return (
                                        <AttributeContainer>
                                            <StyledAttributeLabel>
                                                {t(`${k}`)}
                                            </StyledAttributeLabel>

                                            <StyledAttributeValue
                                                value={
                                                    isDate(value)
                                                        ? moment
                                                              .utc(value)
                                                              .local()
                                                              .format('LLL')
                                                        : value
                                                }
                                                noWrap
                                                className={classNames({
                                                    [classes.dateWarning]:
                                                        (k === 'StartDate' ||
                                                            k === 'EndDate') &&
                                                        isDateInPast(
                                                            endDateValue,
                                                        ),
                                                })}
                                            />
                                        </AttributeContainer>
                                    )
                                })}
                            {additionalResourceName && (
                                <AttributeContainer>
                                    <StyledAttributeLabel>
                                        {t(`TargetAdditionalResource`)}
                                    </StyledAttributeLabel>

                                    <StyledAttributeValue
                                        value={additionalResourceName}
                                        noWrap
                                    />
                                </AttributeContainer>
                            )}
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent="center">
                            {t('NoDataFound')}
                        </Box>
                    )}

                    {
                        /* TODO : Remove devFeaturesEnabled once recertification details are fetched from backend */
                        devFeaturesEnabled && (
                            <>
                                <Box marginTop="10px">
                                    <StyledTypography>
                                        {t('RecertificationDetails')}
                                    </StyledTypography>
                                </Box>

                                <Box marginTop="10px">
                                    <Divider color="#d8d8d8" height="1.5px" />
                                </Box>

                                <Box display="flex" marginTop="10px">
                                    <AttributeContainer>
                                        <StyledAttributeLabel>
                                            {t('PolicyType')}
                                        </StyledAttributeLabel>
                                        <StyledAttributeValue
                                            value={'Person Access Summary'} //TODO: Replace dummy value when data available
                                            noWrap
                                        />
                                    </AttributeContainer>

                                    <AttributeContainer>
                                        <StyledAttributeLabel>
                                            {t('RecertificationDataType')}
                                        </StyledAttributeLabel>
                                        <StyledAttributeValue
                                            value={'Group Membership'} //TODO : Replace dummy value when data available
                                            noWrap
                                        />
                                    </AttributeContainer>
                                </Box>
                            </>
                        )
                    }
                </>
            )}
        </>
    )
}

export default PendingItemsDetails

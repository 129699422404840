import { styled, Box } from '@mui/material'

const PaperComponent = styled(Box)({
    marginTop: '7px',
    '& > ul': {
        maxHeight: '30vh',
    },
})

export default PaperComponent

import React from 'react'
import { Icon, EidLogoIcon } from 'packages/eid-icons'
import { Header } from 'packages/eid-ui'
import { styled, Typography, Box, useTheme } from '@mui/material'
import LanguageMenu from './LanguageMenu'
import AccountDropdown from './AccountDropdown'
import { useEnvironment } from 'hooks'
import { useAppState } from 'appContext'
import appConfig from 'config'

const styles = {
    environmentText: {
        fontSize: '8px',
        padding: '0px 1.5px',
        maxWidth: '58px',
    },
    dummyText: {
        fontSize: '8px',
        padding: '0px 1.5px',
        maxWidth: '58px',
        opacity: 0,
    },
}

const StyledChip = styled('div')({
    position: 'absolute',
    top: '11px',
    left: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff6100 !important',
    color: '#ffffff',
    height: '13px',
    maxWidth: '58px',
    minWidth: '24px',
    transform: 'skew(-20deg)',
    borderRadius: '2px',
})

const TitleContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width:1023px)': {
        display: 'none',
    },
})

const ResponsiveContainer = styled(Box)({
    '@media (max-width:1023px)': {
        //display: 'none',
    },
})

const AppDesktopHeader = ({ config }) => {
    const { data: env } = useEnvironment()
    const theme = useTheme()
    const [{ devFeaturesEnabled }] = useAppState()

    const envName = env?.environmentName
    return (
        <Header
            bgColor={config.bgColor}
            height={config.desktop?.height}
            borderBottom={
                config?.bottomColor
                    ? { borderBottom: `2px solid ${config.bottomColor}` }
                    : {}
            }
        >
            <Header.IconLink
                logo
                href={
                    config.eidLogo?.url
                        ? config.eidLogo?.url
                        : `${appConfig.APP_SUBPATH}/`
                }
                rightDivider={config.showEidLogo}
            >
                {config.showEidLogo && (
                    <>
                        {config.eidLogo?.type ? (
                            <>
                                {config.eidLogo?.type === 'icon' ? (
                                    <Icon
                                        style={config.eidLogo?.style}
                                        name={config.eidLogo?.value}
                                        color={theme?.palette?.primary?.main}
                                    />
                                ) : (
                                    <img
                                        style={config.eidLogo?.style}
                                        src={config.eidLogo?.value}
                                        alt={config.eidLogo?.name}
                                    />
                                )}
                            </>
                        ) : (
                            <EidLogoIcon />
                        )}
                    </>
                )}
            </Header.IconLink>

            <ResponsiveContainer
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Header.IconLink logo href={`${appConfig.APP_SUBPATH}/`}>
                    {config.appLogo.type === 'icon' ? (
                        <Icon
                            style={config.appLogo.style}
                            name={config.appLogo.value}
                            color={theme.palette.primary.main}
                        />
                    ) : (
                        <img
                            style={config.appLogo.style}
                            src={config.appLogo.value}
                            alt={config.appLogo.name}
                        />
                    )}
                </Header.IconLink>
            </ResponsiveContainer>

            <TitleContainer>
                {config.appTitle.type === 'text' ? (
                    <Header.Title
                        style={{
                            ...config.appTitle.style,
                            color: theme.palette.primary.main,
                        }}
                    >
                        {config.appTitle.value}
                    </Header.Title>
                ) : (
                    <Header.IconLink logo href={`${appConfig.APP_SUBPATH}/`}>
                        <img
                            style={config.appTitle.style}
                            src={config.appTitle.value}
                            alt={config.appTitle.name}
                        />
                    </Header.IconLink>
                )}
            </TitleContainer>

            {config.showEnvironment && envName && (
                <Box position="relative" paddingX="8px" title={envName}>
                    <Box component="span" opacity={0}>
                        <Typography noWrap style={styles.dummyText}>
                            {envName}
                        </Typography>
                    </Box>

                    <StyledChip>
                        <Typography noWrap style={styles.environmentText}>
                            {envName}
                        </Typography>
                    </StyledChip>
                </Box>
            )}
            {devFeaturesEnabled && (
                <Box position="relative">
                    <StyledChip>
                        <Typography
                            noWrap
                            style={{
                                fontSize: '8px',
                                padding: '0px 1.5px',
                            }}
                        >
                            - WIP
                        </Typography>
                    </StyledChip>
                </Box>
            )}

            <Header.RightSection>
                <Header.Item style={{ minWidth: '32px', padding: '0px 6px' }}>
                    <LanguageMenu
                        anchorPosition={{
                            right: '-32px',
                            top: '54px',
                        }}
                    />
                </Header.Item>
                <Header.Item
                    style={{ minWidth: '32px', padding: '0px 34px 0px 16px' }}
                >
                    <AccountDropdown />
                </Header.Item>
            </Header.RightSection>
        </Header>
    )
}
export default AppDesktopHeader

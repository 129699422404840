import { RegistryNamespace } from './RegistryNamespace'
import { IRegistrable } from './IRegistrable'

/**
 * Contains all instances registered in the system. Instances
 * are separated out in different namespaces for better organization.
 */
export class RegistryBase {
    /**
     * Namespaces and registered instances.
     */
    readonly registry: Record<string, RegistryNamespace> = {}

    /**
     * Registers a new namespace in registry object.
     * @param name Unique name of the namespace.
     */
    registerNamespace(name: string) {
        this.registry[name] = {}
    }

    /**
     * Registers a particular registrable instance within a namespace in registry.
     * @param namespace Unique namespace within registry.
     * @param instance Instance to register.
     */
    register<T extends IRegistrable>(namespace: string, instance: T) {
        const name = instance.name
        this.registry[namespace][name] = instance
    }

    /**
     * Returns a full namespace from the registry.
     * @param namespace Namespace to return.
     */
    getAll<T extends IRegistrable>(namespace: string): RegistryNamespace {
        return this.registry[namespace]
    }

    /**
     * Namespaces are registered as objects. But this method is a convenient method
     * to return a full namespace from the registry as an array.
     * @param namespace Namespace to return.
     */
    getAllAsArray<T extends IRegistrable>(namespace: string) {
        const namespaceEntries = this.registry[namespace]
        return Object.keys(namespaceEntries).map((e) => namespaceEntries[e])
    }

    /**
     * Returns a registered type from the specified namespace.
     * @param namespace Namespace to get the type from.
     * @param name Name of the type.
     * @returns The registered type.
     */
    get<T extends IRegistrable>(namespace: string, name: string): T {
        return this.registry[namespace][name]
    }
}

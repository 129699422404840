import React from 'react'
import { Box } from '@mui/material'
import IconContainer from './IconContainer'
import IconLabel from './IconLabel'
import { Icon } from 'packages/eid-icons'

const PendingExternalAction = ({ label }) => {
    return (
        <Box display="flex" alignItems="center">
            <IconContainer bgcolor="#ff9300">
                <Icon
                    name="ClockRequest"
                    color="#ffffff"
                />
            </IconContainer>
            <IconLabel style={{ color: '#ff9300' }}>{label}</IconLabel>
        </Box>
    )
}

export default PendingExternalAction

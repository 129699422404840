import { Typography, styled } from '@mui/material'
import { CloseIcon } from 'packages/eid-icons'
import { IconButton } from 'packages/eid-ui'
import { Icon } from '../../../eid-icons'

const ItemContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    margin: '6px 0px',
})
const ContentHeader = ({ currentApp, currentSection, onClick, style }) => {
    return <>
        <IconButton
            onClick={() => onClick()}
            style={{
                margin: '10px 0px 40px 15px',
                width: '12px',
                padding: 0,
            }}
            size="large">
            <CloseIcon color="#919193" />
        </IconButton>

        <div style={{ paddingLeft: '15px' }}>
            <ItemContainer>
                <Icon
                    name={currentApp}
                    color={style.color}
                    style={{ marginRight: '12px', minWidth: '24px' }}
                />

                <Typography
                    style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: style.color,
                        display: 'block',
                        wordBreak: 'break-word',
                    }}
                >
                    {currentApp}
                </Typography>
            </ItemContainer>

            <ItemContainer>
                <Typography
                    style={{
                        fontSize: '11px',
                        fontWeight: 'bold',
                        color: style.color,
                    }}
                >
                    {currentSection}
                </Typography>
            </ItemContainer>
        </div>
    </>;
}

export default ContentHeader

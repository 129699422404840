import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useAuthState } from '../context'
import jwtDecode from 'jwt-decode'
import { resetAuthState, userLoggedIn } from '../actions'
import useUserManager from '../useUserManager'

const requireAuth = (ChildComponent, loader) => (props) => {
    const [{ isAuth, extractAuthData }, dispatch] = useAuthState()
    const userManager = useUserManager()

    const { location } = props

    useEffect(() => {
        if (isAuth === null) {
            userManager.getUser().then((user) => {
                if (user && !user.expired) {
                    const decodedAccessToken = jwtDecode(user.access_token)

                    extractAuthData(user.access_token, decodedAccessToken).then(
                        (userAuthData) => {
                            dispatch(
                                userLoggedIn({
                                    accessToken: user.access_token,
                                    idToken: user.id_token,
                                    ...userAuthData,
                                }),
                            )
                        },
                    )
                } else {
                    dispatch(resetAuthState())
                }
            })
        }
    }, [isAuth, dispatch])

    switch (isAuth) {
        case null:
            return loader ? loader : <></>
        case false:
            return (
                <Redirect
                    to={{ pathname: '/signin', state: { from: location } }}
                />
            )
        default:
            return <ChildComponent {...props} />
    }
}
export default requireAuth

import { styled } from '@mui/material'
import { ReactComponent as BusinessFunctionSvg } from './business-function.svg'

const BusinessFunction = styled(BusinessFunctionSvg)((props) => ({
    '& path': {
        fill: props.color,
    },
}))

BusinessFunction.defaultProps = {
    color: '#000000',
}

export default BusinessFunction

import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePageContext } from 'pageContext'
import { Card } from 'packages/eid-ui'
import { Box } from '@mui/material'
import { EidAutocomplete } from 'packages/eid-controls'
import { Icon } from 'packages/eid-icons'
import { isNilOrEmpty } from 'packages/core'

const SubjectTypeOptionComponent = EidAutocomplete.getOptionComponent({
    type: 'label',
    labelProp: 'friendlyName',
})

const queryParams = {
    take: 20,
}

const getOptionLabel = (option) => option.friendlyName

const AuditFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    const filter = (
        <Box padding="18px">
            <EidAutocomplete.New
                placeholder={t('SelectAudit')}
                url={'/api/businessRequests/audits'}
                queryParams={queryParams}
                getOptionLabel={getOptionLabel}
                dedupingInterval={60000}
                value={state.audit ? state.audit : null}
                optionComponent={SubjectTypeOptionComponent}
                clearIcon={
                    !isNilOrEmpty(state.audit) && (
                        <Icon
                            name="Close"
                            color="#959598"
                            onClick={() =>
                                dispatch({
                                    type: 'SET_PROP',
                                    payload: {
                                        key: 'audit',
                                        value: null,
                                    },
                                })
                            }
                        />
                    )
                }
                onChange={(_, value) => {
                    dispatch({
                        type: 'SET_PROP',
                        payload: {
                            key: 'audit',
                            value: value,
                        },
                    })
                }}
            />
        </Box>
    )

    return (
        <Card
            cardTitle={t('FilterByAudit')}
            color="#fff"
            collapsible
            expanded={Boolean(state.audit)}
        >
            {filter}
        </Card>
    )
}

export default AuditFilter

import React, { useEffect, useState } from 'react'
import { Box, Chip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Autocomplete as MuiAutocomplete } from '@mui/material'
import DefaultPaper from './Paper'
import DefaultListbox from './Listbox'
import DefaultLabel from './Label'
import DefaultOption from './Option'
import DefaultTag from './Tag'
import DefaultInputWrapper from './InputWrapper'
import DefaultEndAdornmentContainer from './EndAdornmentContainer'
import Spinner from '../Spinner'
import Checkbox from '../Checkbox'
import { isNilOrEmpty } from 'packages/core'
import InfoTooltip from '../ApplicationFieldTypes/Components/InfoTooltip'

const useStyles = makeStyles((theme) => ({
    root: ({ rootStyleProps }) => ({
        width: '100%',

        '& > div': {
            transition: 'border 0.15s ease-in-out 0s',
        },
        ...rootStyleProps,
    }),
    input: {
        height: '3.6rem',
    },
    focused: {
        '& > div': {
            borderColor: theme?.palette?.primary?.main,
        },
    },
    option: {
        width: 'max-content',
        minWidth: '100%',
    },
    popper: ({ popperStyleProps }) => ({
        ...popperStyleProps,
    }),
}))

const fullWidthStyles = { width: '100%' }

function Autocomplete(props) {
    const {
        label,
        loading,
        options,
        getOptionLabel,
        disabled,
        placeholder,
        labelComponent: Label,
        optionComponent: Option,
        tagComponent: Tag,
        inputWrapperComponent: InputWrapper,
        endAdornmentContainerComponent: EndAdornmentContainer,
        endAdornmentComponent: EndAdornment,
        clearIcon,
        value,
        rootStyleProps,
        multiselect,
        chipsConfig,
        inputStyleProps,
        popperStyleProps,
        getOptionSubLabel,
        showOptionSubLabel,
        inputWrapperStyles,
        showInfoTooltip = false,
        ...rest
    } = props
    const classes = useStyles({ rootStyleProps, popperStyleProps })
    const [localValue, setLocalValue] = useState([])
    useEffect(() => {
        if (multiselect && isNilOrEmpty(value)) {
            setLocalValue([])
        } else {
            setLocalValue(value)
        }
    }, [value])
    return (
        <MuiAutocomplete
            classes={classes}
            multiple={multiselect}
            loadingText=""
            noOptionsText=""
            disablePortal={false}
            loading={loading}
            options={options}
            getOptionLabel={getOptionLabel}
            disabled={disabled}
            disableCloseOnSelect={multiselect}
            filterOptions={(options) => options}
            value={localValue}
            renderOption={(props, option, optionState) => {
                const newProps = {
                    ...props,
                    key: option.id ? option.id : props.key,
                }
                return multiselect ? (
                    <li {...newProps}>
                        <Checkbox checked={optionState.selected} />
                        <div>
                            {getOptionLabel
                                ? getOptionLabel(option)
                                : option.friendlyName}
                        </div>
                        {showInfoTooltip && (
                            <InfoTooltip
                                friendlyName={getOptionLabel(option)}
                                description={
                                    option?.Description || option?.description
                                }
                            />
                        )}
                    </li>
                ) : (
                    <li {...newProps}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Option
                                option={option}
                                optionState={optionState}
                                getOptionLabel={getOptionLabel}
                                showOptionSubLabel={showOptionSubLabel}
                                getOptionSubLabel={getOptionSubLabel}
                            />
                            {showInfoTooltip && (
                                <InfoTooltip
                                    friendlyName={getOptionLabel(option)}
                                    description={
                                        option?.Description ||
                                        option?.description
                                    }
                                />
                            )}
                        </Box>
                    </li>
                )
            }}
            getOptionSelected={(option, value) =>
                getOptionLabel(option) === getOptionLabel(value)
            }
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return chipsConfig && chipsConfig.type === 'round' ? (
                        <Chip
                            label={getOptionLabel(option)}
                            size={chipsConfig.size || 'small'}
                            style={{ fontSize: '12px' }}
                            {...getTagProps({ index })}
                        />
                    ) : (
                        <Tag
                            label={getOptionLabel(option)}
                            {...getTagProps({ index })}
                        />
                    )
                })
            }
            renderInput={(params) => {
                return (
                    <>
                        {label && (
                            <Label {...params.InputLabelProps}>{label}</Label>
                        )}
                        <InputWrapper
                            ref={params.InputProps.ref}
                            style={{
                                paddingRight:
                                    (EndAdornment || clearIcon) && '55px',
                                ...inputWrapperStyles,
                            }}
                        >
                            {params.InputProps.startAdornment}
                            <input
                                {...params.inputProps}
                                placeholder={placeholder}
                                style={{
                                    ...fullWidthStyles,
                                    ...inputStyleProps,
                                }}
                            />
                            {loading && (
                                <EndAdornmentContainer>
                                    <Spinner size={0.7} color="#453fbb" />
                                </EndAdornmentContainer>
                            )}
                            {!loading && EndAdornment && (
                                <EndAdornmentContainer>
                                    <EndAdornment />
                                </EndAdornmentContainer>
                            )}
                            {!loading && clearIcon && (
                                <EndAdornmentContainer>
                                    {clearIcon}
                                </EndAdornmentContainer>
                            )}
                        </InputWrapper>
                    </>
                )
            }}
            {...rest}
        />
    )
}

Autocomplete.defaultProps = {
    PaperComponent: DefaultPaper,
    ListboxComponent: DefaultListbox,
    labelComponent: DefaultLabel,
    optionComponent: DefaultOption,
    tagComponent: DefaultTag,
    inputWrapperComponent: DefaultInputWrapper,
    endAdornmentContainerComponent: DefaultEndAdornmentContainer,
}

Autocomplete.PaperComponent = DefaultPaper
Autocomplete.ListboxComponent = DefaultListbox
Autocomplete.labelComponent = DefaultLabel
Autocomplete.optionComponent = DefaultOption
Autocomplete.tagComponent = DefaultTag
Autocomplete.inputWrapperComponent = DefaultInputWrapper
Autocomplete.endAdornmentContainerComponent = DefaultEndAdornmentContainer

export default Autocomplete

import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { Icon } from 'packages/eid-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    requiredFieldErrorMessage: {
        fontSize: '1.2rem',
        color: '#d0021b',
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.8rem',
        paddingLeft: '0rem',
        gap: '5px',
        '& svg': {
            width: '1.2rem',
            height: '1.2rem',
        },
    },
})

const RequiredFieldErrorMessage = ({ errorMsgKey, parentClass, sx }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <Box
            sx={sx}
            className={classNames(classes.requiredFieldErrorMessage, {
                [parentClass]: parentClass,
            })}
        >
            <Icon name="Warning" color="#d0021b" fontSize="1rem" />
            <Typography variant="label">{t(errorMsgKey)}</Typography>
        </Box>
    )
}

export default RequiredFieldErrorMessage

import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import withStyles from '@mui/styles/withStyles';
import { MobileMenuIcon } from '../../eid-icons'

const styles = () => ({
    mobileMenuButton: {
        position: 'fixed',
        top: '43vh',
        left: 0,
        cursor: 'pointer',
        zIndex: '25',
    },
})

const MobileMenuButton = withStyles(styles)(
    ({ classes, onClick, className }) => {
        return (
            <MobileMenuIcon
                color="#000000"
                onClick={onClick}
                className={clsx(classes.mobileMenuButton, className)}
            ></MobileMenuIcon>
        )
    },
)

MobileMenuButton.propTypes = {
    onClick: PropTypes.func,
}

export default MobileMenuButton

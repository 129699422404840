import React from 'react'
import { Box } from '@mui/material'
import IconContainer from './IconContainer'
import IconLabel from './IconLabel'

const CanceledIcon = (props) => {
    const { label, bgColor } = props
    return (
        <Box display="flex" alignItems="center">
            <IconContainer bgcolor={bgColor ? bgColor : '#5d6870'}>
                <Box
                    border={'none'}
                    color={'#ffffff'}
                    bgcolor={'#ffffff'}
                    height={'2px'}
                    width={'40%'}
                />
            </IconContainer>
            {label && (
                <IconLabel style={{ color: '#5d6870' }}>{label}</IconLabel>
            )}
        </Box>
    )
}

export default CanceledIcon

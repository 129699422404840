import React from 'react'
import PropTypes from 'prop-types'
import { Box, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as GroupIconSvg } from './Resources/group.svg'
import { ReactComponent as OvalIconSvg } from './Resources/oval.svg'
const useStyles = makeStyles({
    root: {
        padding: 0,
        margin: 0,
        paddingRight: '12px',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    formControlLabel: {
        marginLeft: '0px',
        marginRight: '20px',
        minHeight: '40px',
        fontSize: '14px',
        color: '#767676',
        '&:focus-within': {
            color: '#000000',
        },
    },
})

function StyledRadio(props) {
    const classes = useStyles()

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={
                <Box displat="flex" alignItems="center">
                    <GroupIconSvg></GroupIconSvg>
                </Box>
            }
            icon={<OvalIconSvg></OvalIconSvg>}
            {...props}
        />
    )
}

const RadioList = ({ props, handleChange, value, data }) => {
    const classes = useStyles()

    return data.options && (
        <FormControl variant="standard" component="fieldset">
            <FormLabel
                component="legend"
                style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#000000',
                }}
            >
                {data.heading}
            </FormLabel>
            <RadioGroup
                defaultValue="100"
                aria-label="100"
                name="customized-radios"
                value={value}
                onChange={handleChange}
                row
            >
                {data.options.map((option, index) => (
                    <FormControlLabel
                        key={option.id}
                        className={classes.formControlLabel}
                        value={option.id}
                        control={<StyledRadio />}
                        label={option.friendlyName}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}
RadioList.propTypes = {
    handleChange: PropTypes.func,
    value: PropTypes.string,
    data: PropTypes.object,
}

export default RadioList

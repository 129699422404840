import React, { useState } from 'react'
import { Icon } from 'packages/eid-icons'
import DropdownFilter from './DropdownFilter'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { SubPathLevel } from 'appConfigurations/index'

const DropdownMenu = ({ routeOptions, selectedParentRoute, onChange }) => {
    const { t } = useTranslation()
    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const [open, setOpen] = useState(false)
    const pathnames = pathname.split('/')
    const areaName = pathnames[1 + SubPathLevel]
    const view = pathnames[2 + SubPathLevel]

    const getAccessBasedView = (routeOptions, selectedView) => {
        let _view = `/forbidden`
        const filtered = routeOptions.children.filter((r) => {
            return (
                r.view.toLowerCase() === selectedView.toLowerCase() &&
                r.hasAccess
            )
        })
        if (filtered && filtered.length > 0) {
            _view = filtered[0].view
        } else {
            _view = getNextAccessibleRoute(
                routeOptions.children.indexOf(
                    (r) => r.view.toLowerCase() === selectedView.toLowerCase(),
                ),
                routeOptions.children,
            )
        }
        return _view
    }

    const getNextAccessibleRoute = (currentIndex, children) => {
        let index = currentIndex
        do {
            index = (index + 1) % children.length
        } while (!children[index].hasAccess || index === currentIndex)
        if (index === currentIndex) {
            return `/forbidden`
        } else {
            return children[index].view
        }
    }
    return (
        <>
            <DropdownFilter
                label={t(selectedParentRoute.title)}
                setOpen={setOpen}
                open={open}
                style={{
                    height: '60px',
                    minWidth: 'fit-content',
                }}
                anchorPosition={{ top: '60px' }}
            >
                {routeOptions.map((routeOption, index) => (
                    <DropdownFilter.Item
                        selected={routeOption.route === `/${areaName}`}
                        onClick={(e) => {
                            setOpen((prev) => !prev)

                            if (onChange) {
                                onChange(routeOption.name)
                                return
                            }

                            history.push(
                                `${routeOption.route}/${getAccessBasedView(
                                    routeOption,
                                    view,
                                )}`,
                            )
                        }}
                        key={index}
                    >
                        {routeOption.icon && (
                            <Icon name={routeOption.icon} color="#919193" />
                        )}
                        {t(routeOption.title)}
                    </DropdownFilter.Item>
                ))}
            </DropdownFilter>
        </>
    )
}

export { DropdownMenu }

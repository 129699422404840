import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import config from 'config'
import { refreshRequestOrRequestItem } from 'hooks/utils'
import { RunWorkflowButton } from './Button/RunWorkflowButton'
import { EidWorkflow } from '../packages/eid-ui'

export interface IWorkflowModalProps {
    page: 'Requests' | 'RequestItems'
    requestId: string
    itemId?: string
    currentApprovalStepId: string
    decisionWorkflowName: string
    maxWidth?: any
    fullWidth?: boolean
    fullScreen?: boolean
    iframeHeight?: string
}

export const WorkflowDecisionModal = (props: IWorkflowModalProps) => {
    const { t } = useTranslation()

    const workflowParameters = [
        `BusinessRequestID=${props.requestId}`,
        `ApprovalFlowStepID=${props.currentApprovalStepId}`,
    ]
    if (props.itemId) {
        workflowParameters.push(`BusinessRequestItemID=${props.itemId}`)
    }

    return (
        <EidWorkflow
            maxWidth={props?.maxWidth}
            fullWidth={props?.fullWidth}
            fullScreen={props?.fullScreen}
            iframeHeight={props?.iframeHeight}
            baseEidUrl={config.BASE_EID_URL}
            title={t('ApprovalWorkflowInProgress')}
            workflowName={props.decisionWorkflowName}
            workflowParams={workflowParameters}
            onComplete={() => {
                refreshRequestOrRequestItem(
                    props.page,
                    props.requestId,
                    props.itemId,
                )
            }}
        >
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <RunWorkflowButton>
                    {t('MakeDecision')}
                </RunWorkflowButton>
            </Box>
        </EidWorkflow>
    )
}

import { styled } from '@mui/material'
import { ReactComponent as SuggestedRolesSvg } from './suggested-roles.svg'

const SuggestedRoles = styled(SuggestedRolesSvg)((props) => ({
    '& path': {
        fill: props.color,
    },
}))

SuggestedRoles.defaultProps = {
    color: '#000000',
}

export default SuggestedRoles

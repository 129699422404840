import { Box, Typography, Chip } from '@mui/material'

export const TabLabel = ({
    label,
    count,
}: {
    label: string
    count: number | undefined
}) => {
    return (
        <Box
            component="span"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Typography>{label}</Typography>
            {count && count > 0 ? <Chip size="small" label={count} /> : <></>}
        </Box>
    )
}

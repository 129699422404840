import { Box, styled, useTheme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import { EidAutocomplete } from 'packages/eid-controls'
import { ArrowIcon } from 'packages/eid-icons'
import { WithCloseButton } from 'packages/eid-ui'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectAutocomplete from '../Components/AutoComplete/AutoComplete'
import FieldItemWrapper from '../Components/FieldItemWrapper'
import RequiredFieldErrorMessage from '../Components/FieldItemWrapper/RequiredFieldErrorMessage'
import classNames from 'classnames'
import { getFieldTypeRequiredErrorMessage } from 'packages/core'
import InfoMessageForWrongJson from '../Components/InfoMessageForWrongJson'

const useStyles = makeStyles({
    fieldListWraper: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        '& p': {
            minWidth: '322px',
            fontSize: '15px',
        },
        '& input': {
            minWidth: '30.3rem',
            height: '4rem',
        },
        '& .MuiFormControl-root': {
            marginTop: '0 !important',
        },
    },
    errorField: {
        outline: '1px solid #d0021b',
        borderRadius: '4px',
    },
    disabledStyle: {
        pointerEvents: 'none',
        opacity: '.6',
        cursor: 'not-allowed',
    },
})

const SelectedValueIndicator = styled(WithCloseButton)(({ theme }) => ({
    borderRadius: '4px',
    backgroundColor: '#535b62',
    padding: '10px 22px 10px 16px',
    color: theme?.palette?.common?.white,
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
}))

const EndAdornmentContainer = styled('div')({
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    top: '50%',
    right: '-2px',
    display: 'flex',
    cursor: 'pointer',
})

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'person',
    disableImage: true,
    labelProp: 'FriendlyName',
})

const SingleSelectAutocomplete = ({
    fieldType,
    isFormValidated,
    valueType,
    isApiSupportedSource,
    useDataHook,
    handleUpdate,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const [sourceValues, setSourceValues] = useState([])
    const [showFieldValues, setShowFieldValues] = useState(true)
    const { t } = useTranslation()
    const [errorMessage, setErrorMessage] = useState('')
    const [sourceUpdated, setSourceUpdated] = useState(false)

    useEffect(() => {
        if (fieldType?.SourceValues && sourceUpdated) {
            setShowFieldValues(fieldType?.SourceValues?.length ? true : false)
            setSourceValues(fieldType?.SourceValues || [])
        }
    }, [fieldType?.SourceValues, sourceUpdated])

    useEffect(() => {
        if (isFormValidated && !fieldType?.[valueType]) {
            setErrorMessage(getFieldTypeRequiredErrorMessage(fieldType))
        } else {
            setErrorMessage('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated, fieldType?.[valueType]])

    const { data: sourceData, isFetching } = useDataHook(fieldType)

    useEffect(() => {
        if (isApiSupportedSource) {
            if (!isFetching) {
                updateSourceValues()
                setSourceUpdated(true)
            }
        } else {
            setShowFieldValues(fieldType?.SourceValues?.length ? true : false)
            setSourceValues(fieldType?.SourceValues || [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isApiSupportedSource, isFetching])

    const getOptionLabel = (option) =>
        option ? option.FriendlyName || option.ValueName : ''

    const updateSourceValues = () => {
        const valueToUpdate = {
            ...fieldType,
            SourceValues: sourceData?.data || [],
        }
        handleUpdate(valueToUpdate)
    }

    const handleSelection = (selected) => {
        const valueToUpdate = {
            ...fieldType,
            [valueType]: selected,
        }
        handleUpdate(valueToUpdate)
    }

    return (
        <FieldItemWrapper
            title={fieldType?.Name}
            isRequired={fieldType?.IsRequired}
            fieldTypeScope={fieldType?.FieldTypeScope}
        >
            <Box
                className={classNames(classes.fieldListWraper, {
                    [classes.errorField]: Boolean(errorMessage),
                    [classes.disabledStyle]: fieldType?.IsReadOnly,
                })}
            >
                {isFetching ? (
                    <Fragment>
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                        <Skeleton height={40} />
                    </Fragment>
                ) : showFieldValues ? (
                    <Fragment>
                        {fieldType?.[valueType] ? (
                            <Box>
                                <SelectedValueIndicator
                                    onClose={() => {
                                        handleSelection(null)
                                    }}
                                >
                                    <Typography
                                        style={{
                                            wordBreak: 'break-all',
                                        }}
                                        variant="body1"
                                    >
                                        {fieldType?.[valueType]?.FriendlyName}
                                    </Typography>
                                </SelectedValueIndicator>
                            </Box>
                        ) : (
                            <SelectAutocomplete
                                placeholder={t('Select')}
                                optionComponent={OptionComponent}
                                value={fieldType?.[valueType]}
                                options={sourceValues}
                                getOptionLabel={getOptionLabel}
                                onChange={(_, value) => handleSelection(value)}
                                labelProps="FriendlyName"
                                endAdornmentComponent={() => (
                                    <ArrowIcon
                                        direction="down"
                                        color={theme?.palette?.grey?.[600]}
                                    />
                                )}
                                endAdornmentContainerComponent={
                                    EndAdornmentContainer
                                }
                                showInfoTooltip={true}
                            />
                        )}
                    </Fragment>
                ) : (
                    <InfoMessageForWrongJson />
                )}
            </Box>
            <Box>
                {Boolean(errorMessage) && (
                    <RequiredFieldErrorMessage errorMsgKey={errorMessage} />
                )}
            </Box>
        </FieldItemWrapper>
    )
}

export default SingleSelectAutocomplete

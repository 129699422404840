import React from 'react'
import { getDayOfMonth, getMonthDayYear } from './helper'

const weekdays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

const Month = ({ onChange, select, datesInMonth }) => {
    const getSelected = (date) =>
        getMonthDayYear(select) === getMonthDayYear(date)

    return (
        <div className={`month`}>
            {weekdays.map((i) => (
                <div key={'weekdays' + i} className={`day-heading`}>
                    <p>{i}</p>
                </div>
            ))}
            {datesInMonth.map((i, index) => {
                return (
                    <div
                        className={`day 
            ${i.currentMonth ? 'current-month' : ''}
            ${getSelected(i.date) ? 'selected' : ''}
            `}
                        onClick={() => {
                            onChange(i?.date)
                        }}
                        key={`
            ${i.currentMonth ? 'currentMonth' : ''}
            ${getDayOfMonth(i?.date) + index}`}
                    >
                        <p>{getDayOfMonth(i?.date)}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default Month

// {
//   "22-10-2022": [{start: "10:00", end : "10:30"}]
// }

import { styled } from '@mui/material'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowScrollSvg } from './arrow-scroll.svg'

const orientationAngle = {
    right: '0',
    up: '270',
    left: '180',
    down: '90',
}

const ArrowScroll = styled(ArrowScrollSvg)(({ theme, color, direction }) => ({
    transform: `rotate(${orientationAngle[direction]}deg)`,
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    color,
}))

ArrowScroll.propTypes = {
    color: PropTypes.string,
    direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
    fill: PropTypes.string,
}

ArrowScroll.defaultProps = {
    color: '#000000',
    direction: 'down',
    fill: 'none',
}

export default ArrowScroll

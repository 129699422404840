import { FC } from 'react'
import { Box } from '@mui/material'
import LoaderIcon from './Icons/LoaderIcon'
import { AvatarGroup } from './AvatarGroup'
import { RiskIcon } from './Icons/RiskIcon'
import TextFormatter from 'components/TextFormatter'
import { CanceledIcon } from './Icons'
import { useTranslation } from 'react-i18next'
import { SkippedStatusIcon } from './Icons/SkippedStatusIcon'
import { ApproverTitle } from './StepNode'

const styles = {
    name: {
        fontSize: '14px',
        color: '#5d6870',
        fontWeight: 'bold',
    },
    position: {
        textTransform: 'uppercase',
        color: '#9b9b9b',
        fontSize: '12px',
        fontWeight: 'bold',
    },
    status: {
        color: '#9230c5',
        fontSize: '14px',
    },
    divider: {
        margin: '0px 10px',
        color: '#d8d8dd',
        width: '16px',
        height: '1px',
    },
    dummyIdentifier: {
        width: '20px',
        position: 'absolute',
        top: '0px',
        left: '10px',
    },
}

const Approver: FC<any> = (props) => {
    const { t } = useTranslation()
    const {
        id,
        size = 'large',
        currentStep,
        showAllApprovers,
        setShowAllApprovers,
        stepId,
        status,
        hasRisk,
        riskViolator,
        policyName
    } = props

    const renderIcon = () => {
        if (hasRisk) {
            return <RiskIcon active={status === 'Open'} />
        } else if (status === 'Canceled') {
            return <CanceledIcon bgColor={'rgb(216, 216, 221)'} />
        } else if (status === 'Skipped') {
            return <SkippedStatusIcon bgColor={'rgb(216, 216, 221)'} />
        } else {
            return <LoaderIcon active={status === 'Open'} />
        }
    }

    return (
        <Box position="relative">
            <Box paddingY="7.5px" display="flex">
                {/*@ts-ignore*/}
                <Box minWidth={size} marginRight="15px" paddingY="4px">
                    <Box
                        id={id}
                        style={{
                            borderRadius: '50%',
                        }}
                    >
                        {renderIcon()}
                    </Box>
                </Box>
                <Box>
                    {status === 'Open' && stepId && (
                        <AvatarGroup
                            open={showAllApprovers}
                            setOpen={setShowAllApprovers}
                        />
                    )}
                    {riskViolator && (
                        <Box display={'flex'} marginTop={'5px'}>
                            <TextFormatter
                                style={{
                                    fontSize: '14px',
                                    color: '#ce2333',
                                    fontWeight: 'bold',
                                }}
                                value={t('RiskViolator') + ' : ' + riskViolator}
                                maxCharacters={55}
                            />
                        </Box>
                    )}
                    <Box sx={{
                        marginTop: '.4rem'
                    }}>
                        <Box sx={{
                            display: "flex",
                            gap: "1.2rem",
                            alignItems: 'center'
                        }}>
                            <ApproverTitle>
                                {`${t("ApprovalPolicy")}:`}
                            </ApproverTitle>
                            <TextFormatter
                                style={{
                                    ...styles.status,
                                    color: '#9230c5',
                                    whiteSpace: 'nowrap',
                                    width:'fit-content'
                                }}
                                value={policyName}
                                maxCharacters={70}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1.2rem",
                                alignItems: 'center'
                            }}>
                            <ApproverTitle>
                                {`${t("CurrentStep")}:`}
                            </ApproverTitle>
                            <TextFormatter
                                style={{
                                    fontSize: '1.4rem',
                                    whiteSpace: 'nowrap',
                                    width:'fit-content'
                                }}
                                value={currentStep}
                                maxCharacters={70}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Approver

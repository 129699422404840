import { useTranslation } from 'react-i18next'
import moment from 'moment'

const ElapsedTime = ({ startTime }) => {
    const { t } = useTranslation()

    if (!startTime) {
        return <>-</>
    }
    const currentTime = new Date().toISOString()

    const duration = moment.duration(
        moment.utc(currentTime).diff(moment.utc(startTime)),
    )

    const hours = duration.hours().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    })
    const minutes = duration.minutes().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    })
    const time = `${hours} ${t('Hours')} ${minutes} ${t(
        'Mins',
    )}`
    const daysLabel = duration.days() > 1 ? t('Days') : t('Day')
    const durationInDays = duration.days() + ' ' + daysLabel + ' ' + time

    return <>{duration.days() > 0 ? durationInDays : time}</>
}

export default ElapsedTime

import { Box, styled } from '@mui/material'
import AffectedResourceCardItem from './AffectedResourceCardItem'
import { Trans } from 'react-i18next'
import { useWorkflowAffectedResources } from 'hooks'
import { Spinner } from 'packages/eid-ui'

const AffectedResourcesContainer = styled(Box)({
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '30px',
})
const AffectedResources = ({ id }) => {
    const { list, loading, pagination } = useWorkflowAffectedResources(id)

    if (loading) {
        return (
            <AffectedResourcesContainer display="flex" justifyContent="center">
                <Spinner />
            </AffectedResourcesContainer>
        )
    }
    if (list && list.length > 0) {
        return (
            <AffectedResourcesContainer>
                {list.map((item) => (
                    <AffectedResourceCardItem resource={item} />
                ))}

                <Box style={{ margin: '17px auto 0px auto' }}>{pagination}</Box>
            </AffectedResourcesContainer>
        )
    }

    return (
        <AffectedResourcesContainer display="flex" justifyContent="center">
            <Trans i18nKey="NoDataFound" />
        </AffectedResourcesContainer>
    )
}
export default AffectedResources

const deepMergeObjects = (target, source, mutateTarget) => {
    if (typeof source === 'object') {
        // Returns a deep merge of source into target. Does not mutate target unless mutateTarget = true.
        target = mutateTarget ? target : JSON.parse(JSON.stringify(target)) // clones the target object
        for (const [key, val] of Object.entries(source)) {
            if (val !== null && typeof val === `object`) {
                if (target[key] === undefined) {
                    target[key] = {}
                }

                // Even where mutateTarget = false,
                // recursive calls only work on clones, so they can always safely mutate --- saves unnecessary cloning
                target[key] = deepMergeObjects(target[key], val, true)
            } else {
                target[key] = val
            }
        }
    }
    return target
}
export default deepMergeObjects

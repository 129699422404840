import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled, Box } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import { HorizontalTabs } from '../../eid-ui'
import { TagsIcon, FormsIcon } from '../../eid-icons'
import TagsFilter from './TagsFilter'

const getTabsCss = (colorCode) => {
    return {
        minWidth: '90px',
        color: '#777777',
        '& svg': {
            color: '#777777',
        },
        '&:hover': {
            color: colorCode.primary,
            '& svg': {
                color: colorCode.primary,
            },
        },
    }
}

const AdvancedSearchTabs = styled(({ colorCode, ...rest }) => (
    <HorizontalTabs {...rest} TabIndicatorProps={{ children: <span /> }} />
))(({ colorCode }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        '& > span': {
            maxWidth: 70,
            width: '100%',
            backgroundColor: colorCode.primary,
        },
    },
    '& .Mui-selected': {
        color: `${colorCode.primary} !important`,
        '& svg': {
            color: `${colorCode.primary} !important`,
        },
    },
}))

const FormsTab = styled(({ colorCode, ...rest }) => (
    <HorizontalTabs.Tab {...rest} />
))(({ colorCode }) => {
    return getTabsCss(colorCode)
})
const TagsTab = styled(({ colorCode, ...rest }) => (
    <HorizontalTabs.Tab {...rest} />
))(({ colorCode }) => {
    return getTabsCss(colorCode)
})

const AdvancedFilter = ({
    formsFilter,
    forms,
    tags,
    formsLabel,
    tagsLabel,
    loadTags,
    tabsContinerStyles,
    showTags,
    ...rest
}) => {
    const [currentTab, setCurrentTab] = React.useState(0)

    useEffect(() => {
        if (loadTags && currentTab === 1) loadTags()
    }, [currentTab])
    return (
        <Box>
            <Box
                display="flex"
                justifyContent="center"
                style={{
                    paddingTop: '18px',
                    height: '96px',
                    backgroundColor: '#fbfbfd',
                    borderBottom: 'solid 1px #efeff1',
                    borderTop: 'solid 1px #efeff1',
                    ...tabsContinerStyles,
                }}
            >
                <AdvancedSearchTabs
                    onChange={(_, index) => setCurrentTab(index)}
                    value={!tags ? 0 : currentTab}
                    colorCode={
                        currentTab === 0 || !tags
                            ? forms.colorCode
                            : tags?.colorCode
                    }
                >
                    <FormsTab
                        label={formsLabel}
                        icon={<FormsIcon />}
                        colorCode={forms.colorCode}
                    />
                    {showTags && tags && (
                        <TagsTab
                            label={tagsLabel}
                            icon={<TagsIcon />}
                            colorCode={tags.colorCode}
                        />
                    )}
                </AdvancedSearchTabs>
            </Box>
            <SwipeableViews
                index={!tags ? 0 : currentTab}
                onChangeIndex={(index) => setCurrentTab(index)}
            >
                {formsFilter}
                {tags && <TagsFilter {...tags} />}
            </SwipeableViews>
        </Box>
    )
}

AdvancedFilter.propTypes = {
    forms: PropTypes.shape({
        colorCode: PropTypes.object,
    }),
    tags: PropTypes.shape({
        colorCode: PropTypes.object,
        data: PropTypes.object,
        selected: PropTypes.array,
        onTagClick: PropTypes.func,
    }),
}

export default AdvancedFilter

import { Box } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import IconContainer from './IconContainer'
import IconLabel from './IconLabel'

const DeferredIcon = ({ label }) => {
    return (
        <Box display="flex" alignItems="center">
            <IconContainer bgcolor="#3b454d">
                <Icon name="Defer" color="#ffffff" />
            </IconContainer>
            <IconLabel style={{ color: '#3b454d' }}>{label}</IconLabel>
        </Box>
    )
}

export default DeferredIcon

import { styled, theme } from '@mui/material'

const InputWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    border: '0.1rem solid #ebebed',
    backgroundColor: '#fff',
    borderRadius: '0.4rem',
    padding: '0.1rem',
    display: 'flex',
    flexWrap: 'wrap',
    boxShadow: '0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.1)',
    transition: 'border 0.15s ease-in-out 0s',
    '&:hover': {
        borderColor: theme?.palette?.primary?.main,
    },
    '& input': {
        fontSize: '1.5rem',
        height: '3.6rem',
        lineHeight: 'normal',
        padding: '0.8rem',
        flexGrow: 1,
        border: 0,
        outline: 0,
        '&::placeholder': {
            color: '#b4b4b4',
        },

        '&:-ms-input-placeholder': {
            opacity: `${1} !important`,
            color: '#b4b4b4 !important',
        },
    },
}))

export default InputWrapper

import React from 'react'
import { Box } from '@mui/material'
import AddReplyComponent from './AddReplyComponent'
import {
    TextButtonContainer,
    SHOW_REPLIES_AND_INPUT_FIELD,
    SHOW_REPLIES,
} from './utils'
import { Icon } from 'packages/eid-icons'
import Reply from './Reply'
import { useTranslation } from 'react-i18next'

const Replies = ({
    replies,
    collapse,
    setCollapse,
    handleReply,
    handleEditComment,
    handleDeleteComment,
    currentUserId,
}) => {
    const { t } = useTranslation()

    return (
        <>
            <Box padding="0px 15px" marginX="-15px" marginTop="-13px">
                {replies &&
                    replies.map((r) =>
                        r.commentorPersonId === currentUserId ? (
                            <Reply
                                key={r.id}
                                handleEdit={handleEditComment}
                                handleDelete={handleDeleteComment}
                                {...r}
                            />
                        ) : (
                            <Reply key={r.id} {...r} />
                        ),
                    )}
            </Box>

            <Box
                borderTop={replies?.length > 0 ? '1px solid #ebebed' : 'none'}
                paddingX="15px"
                paddingTop={
                    collapse === SHOW_REPLIES_AND_INPUT_FIELD ? '15px' : '7px'
                }
                paddingBottom={
                    collapse === SHOW_REPLIES_AND_INPUT_FIELD ? '11px' : '8px'
                }
                marginX="-15px"
            >
                {collapse === SHOW_REPLIES_AND_INPUT_FIELD && (
                    <AddReplyComponent
                        handleAddReply={handleReply}
                        handleCancel={() => {
                            replies.length !== 0
                                ? setCollapse(SHOW_REPLIES)
                                : setCollapse(false)
                        }}
                    />
                )}

                {collapse === SHOW_REPLIES && (
                    <TextButtonContainer
                        onClick={() => {
                            setCollapse(SHOW_REPLIES_AND_INPUT_FIELD)
                        }}
                    >
                        <Icon
                            name="ReplyArrow"
                            style={{ marginRight: '8px' }}
                        />
                        {t('Reply')}
                    </TextButtonContainer>
                )}
            </Box>
        </>
    )
}

export default Replies

import React from 'react'
import { Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        margin: theme.spacing(1),
        textTransform: 'capitalize',
        fontSize: '14px',
        height: '31px !important',
        // backgroundColor: props.color
        //     ? `${props.color} !important`
        //     : '#307fc1 !important',
        color: '#ffffff !important',
        backgroundColor: '#307fc1 !important',

        '&:hover': {
            //backgroundColor: '#307fc1',
            //boxShadow: '0 5px 5px -2px rgba(48, 127, 193, 0.55)',
            //color: 'white',
            // '& path': {
            //     fill: 'red',
            // },
            // '& span': {
            //     color: 'red',
            // },
        },
        ...props.styles,
    }),
    icon: {
        marginLeft: 6,
    },
}))
const ViewDetailsButton = ({ color, styles, children, ...rest }) => {
    const classes = useStyles({ color, styles })
    return (
        <Fab variant="extended" size="small" className={classes.root} {...rest}>
            {children}
            <Icon
                name="ArrowSmall"
                color="#ffffff"
                fill="#ffffff"
                className={classes.icon}
                direction="right"
            ></Icon>
        </Fab>
    )
}

export default ViewDetailsButton

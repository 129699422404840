import React from 'react'
import {
    TableHead,
    TableSortLabel,
    TableRow,
    TableCell,
    Link,
    Box,
    styled,
    Table,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Tooltip, StatusIndicator, RiskIndicator } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import {
    TextFormatter,
    Person,
    ItemTypeCount,
    SubjectTypeAttribute,
} from 'components'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'packages/core'
import moment from 'moment'
import { getFullImageUrl } from 'utils'
import ItemTypeResourceSummary from 'components/ItemTypeResourceSummary'

const maxCharacters = 100

const colors = {
    primary: '#307fc1',
    grey: '#7d7c7c',
    black: '#282828',
}

const EmptyRow = styled(TableRow)({
    border: 'none !important',
})
const EmptyCell = styled(TableCell)({
    border: 'none !important',
    padding: '9px',
})

const useHeaderRowStyles = makeStyles({
    root: {
        backgroundColor: '#fbfbfd',

        height: '32px',
        borderRadius: '8px !important',

        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',

        '& >th:first-child': {
            borderBottomLeftRadius: '4px !important',
            borderTopLeftRadius: '4px !important',
        },
        '& >th:last-child': {
            borderBottomRightRadius: '4px !important',
            borderTopRightRadius: '4px !important',
        },

        '& .MuiTableCell-head': {
            padding: '10px ',
            fontSize: '13px',
            lineHeight: 1.33,
            color: colors.grey,
        },
    },
})

const useSortLabelStyles = makeStyles({
    root: {
        color: '#7d7c7c !important',
        '& svg': {
            color: `#D2D2D9 !important`,
            height: '14px',
            width: '14px',
        },
        '&:hover': {
            color: `${colors.primary} !important`,

            '& svg': {
                color: `${colors.primary} !important`,
            },
        },
    },

    active: {
        '& svg': {
            color: `${colors.primary} !important`,
        },
    },
    icon: {
        opacity: `${1} !important`,
    },
})

const useHeaderCellStyles = makeStyles({
    root: ({ style }) => ({
        border: 'none !important',
        padding: '16px 10px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
    }),
})

const useCellStyles = makeStyles({
    root: ({ style }) => ({
        border: 'none ',
        padding: '16px 0px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        color: colors.black,
        fontSize: '13px',
        lineHeight: 1.5,
        '& >a': {
            color: style && style.color ? style.color : colors.primary,
        },

        ...style,
    }),
})

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    disableInteractive: false,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

const CustomTableCell = ({ style, link, children, onClick, url, ...rest }) => {
    const cellClasses = useCellStyles({ style })

    return (
        <TableCell
            align="left"
            classes={cellClasses}
            onClick={onClick}
            {...rest}
        >
            {!link ? (
                children
            ) : (
                <Link
                    href={url}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    {children}
                </Link>
            )}
        </TableCell>
    )
}

export const RequestsTableHead = ({ attributes }) => {
    const { t } = useTranslation()
    const [{ sortBy, sortOrder }, dispatch] = usePageContext()

    const headerRowClasses = useHeaderRowStyles()
    const headerCellClasses = useHeaderCellStyles()
    const sortLabelClasses = useSortLabelStyles()

    const handleSort = (heading) => {
        const sortByName = heading.sortBy ? heading.sortBy : heading.name
        dispatch({
            type: 'SET_MULTIPLE_PROPS',
            payload: {
                sortBy: sortByName,
                sortOrder:
                    sortBy !== sortByName
                        ? 'desc'
                        : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                        ? 'desc'
                        : 'asc',
            },
        })
    }

    return (
        <TableHead>
            <>
                <TableRow classes={headerRowClasses}>
                    {attributes.map((heading, index) => {
                        const sortByName = heading.sortBy
                            ? heading.sortBy
                            : heading.name
                        return (
                            <TableCell
                                key={'pd-head-cell-' + index}
                                classes={headerCellClasses}
                                align="left"
                                style={heading.headerCellStyles}
                            >
                                {!heading.sortable ? (
                                    <span style={{ display: 'flex' }}>
                                        {t(heading.label)}
                                    </span>
                                ) : (
                                    <span style={{ display: 'flex' }}>
                                        <Tooltip
                                            title={
                                                sortBy === sortByName &&
                                                sortOrder === 'desc'
                                                    ? t('SortAscending')
                                                    : t('SortDescending')
                                            }
                                        >
                                            <TableSortLabel
                                                classes={sortLabelClasses}
                                                active={sortBy === sortByName}
                                                direction={
                                                    sortBy === sortByName
                                                        ? sortOrder
                                                        : 'asc'
                                                }
                                                onClick={() =>
                                                    handleSort(heading)
                                                }
                                            >
                                                {t(heading.label)}
                                            </TableSortLabel>
                                        </Tooltip>
                                    </span>
                                )}
                            </TableCell>
                        )
                    })}
                </TableRow>
                <EmptyRow>
                    <EmptyCell colSpan={attributes.length} />
                </EmptyRow>
            </>
        </TableHead>
    )
}

const useRowStyles = makeStyles({
    root: {
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        borderTopLeftRadius: '8px !important',
        borderTopRightRadius: '8px !important',
        minHeight: '40px',

        '& >td:first-child': {
            borderBottomLeftRadius: '0px !important',
            borderTopLeftRadius: '8px !important',
        },
        '& >td:last-child': {
            borderBottomRightRadius: '0px !important',
            borderTopRightRadius: '8px !important',
        },

        '&:hover': {
            '& .Details-button': {
                backgroundColor: colors.primary,
                color: '#ffffff',
                cursor: 'pointer',
            },
        },
    },
})

const DetailsButton = ({ styles, onClick }) => (
    <span style={styles}>
        <StyledLink
            className="Details-button"
            component="button"
            onClick={(e) => {
                onClick()
                e.stopPropagation()
            }}
        >
            +
        </StyledLink>
    </span>
)

const StyledLink = styled(Link)({
    borderRadius: '50%',
    fontWeight: 'normal',
    width: '20px',
    height: '20px',
    backgroundColor: '#ffffff',
    border: '1px solid #aab0b4',
    color: '#aab0b4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
        backgroundColor: colors.primary,
        color: '#ffffff',
        cursor: 'pointer',
    },

    '&:focus': {
        outline: 'none',
    },
})

const SecondRowCell = styled(TableCell)({
    padding: '10px 10px',
    overflowWrap: 'break-word',
    wordBreak: 'normal',
    overflow: 'hidden',
    color: colors.grey,
    borderRadius: '8px !important',
    borderImageSource:
        'linear-gradient(to right, rgba(120, 48, 197, 0), #7830c5 7%, #c530a9 91%, rgba(197, 48, 169, 0))',
    borderImageSlice: 1,
    //boxShadow: 'inset 0 0 10px -10px #000000',
})

const useSecondRowStyles = makeStyles({
    root: {
        backgroundColor: '#ffffff',

        minHeight: '40px',

        boxShadow: 'inset 0 6px 16px -16px rgba(0, 0, 0, 0.5)',
        '& >td:first-child': {
            borderBottomLeftRadius: '8px !important',

            borderTopLeftRadius: '0px !important',
        },
        '& >td:last-child': {
            borderBottomRightRadius: '8px !important',
            borderTopRightRadius: '0px !important',
        },

        '&:hover': {
            '& .Details-button': {
                backgroundColor: colors.primary,
                color: '#ffffff',
                cursor: 'pointer',
            },
        },
    },
    hoverableAttribute: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
})

const CellDateTime = ({ value }) => (
    <>{value ? moment.utc(value).local().format('LLL') : '-'}</>
)

const Attribute = styled('span')({
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    fontSize: '13px !important',
    '& >span': {
        margin: '0px 6px 0px 10px',
        fontWeight: 'bold',
    },
    '& >svg': {
        marginBottom: '1px',
    },
})

const StyledTable = styled(Table)({
    border: 'none !important',
    boxShadow: 'none !important',
    borderCollapse: 'separate',
})

export const RequestsTable = StyledTable

export const RequestRow = ({ attributes, item }) => {
    const { t } = useTranslation()
    const rowClasses = useRowStyles()
    const secondRowClasses = useSecondRowStyles()
    const history = useHistory()
    const query = useQuery()

    const renderButton = (a) => {
        switch (a.button.name) {
            case 'DetailsButton':
                return (
                    <DetailsButton
                        onClick={() => {}}
                        styles={a.button.styles}
                    />
                )

            default:
                return <></>
        }
    }

    const renderComponent = (a, item) => {
        switch (a.component.componentName) {
            case 'StatusIndicator':
                const itemStatus = item.preApproved
                    ? 'PreApproved'
                    : item.status
                return (
                    <StatusIndicator
                        className="Status-Indicator"
                        style={a.component.styles}
                        variant={itemStatus}
                        label={t(`${itemStatus}`)}
                        {...a.component.props}
                    />
                )
            case 'RiskIndicator':
                return (
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        {item[a.component.name] ? (
                            <RiskIndicator
                                variant={item[a.component.name]}
                                label={t(`${item[a.component.name]}Risk`)}
                                {...a.component.props}
                            />
                        ) : (
                            <>-</>
                        )}
                    </Box>
                )

            case 'CellDateTime':
                return <CellDateTime value={item[a.name]} />

            case 'SubjectType':
                const subjectType = item.subjectType
                return (
                    <SubjectTypeAttribute
                        subjectType={subjectType}
                        item={item}
                        personComponentProps={{ ...a.component.props }}
                    />
                )

            default:
                return <></>
        }
    }

    return (
        <React.Fragment>
            <TableRow classes={rowClasses}>
                {attributes.map((a, index) => {
                    return (
                        <CustomTableCell
                            key={item.id + `${index}`}
                            style={a.styles}
                            link={a.link}
                            url={a.url}
                            onClick={() => {
                                query.set('id', item.id)
                                history.push(
                                    `${
                                        history.location.pathname
                                    }?${query.toString()}`,
                                )
                            }}
                        >
                            <Box
                                display="inline-block"
                                alignItems="center"
                                paddingX="10px"
                                width={a.styles?.width}
                            >
                                {a.avatar && (
                                    <Person
                                        name={item[a.name]}
                                        imgSrc={getFullImageUrl(
                                            item[a.imgProp],
                                        )}
                                        tooltipProps={tooltipProps}
                                        style={a?.textStyles}
                                    />
                                )}

                                {!a.hideValue && (
                                    <TextFormatter
                                        value={item[a.name]}
                                        tooltipProps={tooltipProps}
                                        style={a.styles}
                                        maxCharacters={maxCharacters}
                                    ></TextFormatter>
                                )}

                                {a.button && renderButton(a)}

                                {a.component && renderComponent(a, item)}
                            </Box>
                        </CustomTableCell>
                    )
                })}
            </TableRow>
            {item.itemTypeCount && item.itemTypeResourceSummary && (
                <TableRow className={secondRowClasses.root}>
                    <SecondRowCell colSpan={attributes.length}>
                        <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box display="flex" justifyContent="flex-start">
                                <ItemTypeResourceSummary
                                    itemTypeCount={item.itemTypeCount}
                                    itemTypeResourceSummary={
                                        item.itemTypeResourceSummary
                                    }
                                />
                            </Box>

                            {item.currentApprovalStepName && (
                                <Attribute key={item.status}>
                                    <span>{t('CurrentStep')}</span>
                                    {item.currentApprovalStepName}
                                </Attribute>
                            )}
                        </Box>
                    </SecondRowCell>
                </TableRow>
            )}
            <EmptyRow>
                <EmptyCell colSpan={attributes.length} />
            </EmptyRow>
        </React.Fragment>
    )
}

import React from 'react'
import Button from './index'

const getStyles = (loading) => {
    return {
        minWidth: '112px',
        backgroundColor: loading ? '#307fc1' : '#fbfbfd',
        color: '#307fc1',
        border: '1px solid #307fc1',
        '&:hover': {
            boxShadow: '0 2px 8px 0 rgba(48, 127, 193, 0.33)',
            backgroundColor: '#307fc1',
            color: '#ffffff',
            '& svg': {
                color: '#ffffff',
            },
        },
        '&:active': {
            backgroundColor: '#307fc1',
            color: '#ffffff',
            '& svg': {
                color: '#ffffff',
            },
            boxShadow:
                '0 2px 8px 0 rgba(48, 127, 193, 0.33), inset 2px 2px 4px 0 rgba(0, 0, 0, 0.11)',
        },
    }
}

const styles = {
    disabled: {
        color: '#aab04 !important',
        border: 'solid 1px #aab0b4 !important',
    },
}

const UnclaimButton = ({ loading, disabled, ...rest }) => {
    return (
        <Button
            size="small"
            loading={loading}
            disabled={disabled}
            style={getStyles(loading)}
            disabledStyles={styles.disabled}
            {...rest}
        />
    )
}

export default UnclaimButton

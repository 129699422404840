import { styled, Typography } from '@mui/material'

const statusColors = {
    Approved: '#01ae8f',
    Completed: '#01ae8f',
    PreApproved: '#01ae8f',
    Rejected: '#d0021b',
    DoNothing: '',
    Open: '#d66a00',
    WaitingOn: '#d66a00',
    NotStarted: '#d8d8dd',
    NotReady: '#d8d8dd',
    Canceled: '#5d6870',
    Skipped: '#5d6870',
    Pending_PreRequest: '#ff9300',
}

export const getStatusColor = (status: any, defaultColor = '#d66a00') => {
    return Object.keys(statusColors).includes(status)
        ? //@ts-ignore
          statusColors[status]
        : defaultColor
}

export const DateTime = styled(Typography)({
    paddingLeft: '5px',
    color: '#5d6870',
    fontSize: '12px',
})

import React from 'react'
import PropTypes from 'prop-types'
import { Fab as MuiFab } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = {
    button: {
        '& svg:first-child': {
            marginRight: 10,
        },
    },
    loader: {
        marginRight: 10,
    },
}

const ExtendedFab = withStyles(styles)(
    ({ classes, loading, icon, size, children, ...rest }) => (
        <MuiFab
            className={!loading ? classes.button : undefined}
            variant="extended"
            size={size}
            disabled={loading}
            {...rest}
        >
            {loading ? <></> : icon}
            {children}
        </MuiFab>
    ),
)

ExtendedFab.propTypes = {
    ...MuiFab.propTypes,
    loading: PropTypes.bool.isRequired,
    icon: PropTypes.any,
}

ExtendedFab.defaultProps = {
    ...MuiFab.defaultProps,
    loading: false,
    size: 'medium',
}

export default ExtendedFab

import { styled } from "@mui/material";
import PropTypes from "prop-types";
import { ReactComponent as ArrowSvg } from "./arrow-long.svg";

const orientationAngle = {
  up: "0",
  right: "90",
  down: "180",
  left: "270",
};

const ArrowLong = styled(ArrowSvg)(({ theme, color, direction }) => ({
  transform: `rotate(${orientationAngle[direction]}deg)`,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

ArrowLong.propTypes = {
  color: PropTypes.string,
  direction: PropTypes.oneOf(["left", "right", "up", "down"]),
  fill: PropTypes.string,
};

ArrowLong.defaultProps = {
  color: "#000000",
  direction: "down",
  fill: "none",
};

export default ArrowLong;

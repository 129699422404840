import { Box, styled } from '@mui/material'
import { getStatusColor } from './'

const Line = styled(Box)({
    display: 'flex',

    height: '1px',
    width: '100%',

    backgroundColor: '#01ae8f',
})

const ArrowShape = styled(Box)({
    width: 0,
    height: 0,

    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderLeft: '10px solid #01ae8f',

    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
})

const ArrowIcon = (props) => {
    const { color, variant = 'Continue', ...rest } = props
    return (
        <Box display="flex" alignItems="center" width="100%" maxWidth='160px' {...rest}>
            <Line
                style={{
                    backgroundColor: getStatusColor(variant),
                }}
            />
            <ArrowShape style={{ borderLeftColor: getStatusColor(variant) }} />
        </Box>
    )
}

export default ArrowIcon

import {
    createTheme,
    CssBaseline,
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material';
import { SnackbarProvider } from 'notistack'
import { useAppState } from 'appContext'
import { defaultThemeObject } from 'defaultTheme'
import { deepMergeObjects } from 'packages/core'
import { useMemo } from 'react'

const withCustomTheme = (ChildComponent) => (props) => {
    const [{ appConfig }] = useAppState()
    const customTheme = deepMergeObjects(
        defaultThemeObject,
        appConfig?.style?.theme,
        false,
    )
    const theme = useMemo(() => createTheme(customTheme), [customTheme])

    return <>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider>
                    <ChildComponent {...props} />
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </>;
}

export default withCustomTheme

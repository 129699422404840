import { useAxios } from 'packages/core'
import { usePageContext } from 'pageContext'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
import usePaginatedDetailsApi from './usePaginatedDetailsApi'
import { useMutation, queryCache, useQuery } from 'react-query'
export const WORKFLOW_ITEMS_KEY = 'WORKFLOW_ITEMS_KEY'
export const WORKFLOWS_KEY = 'WORKFLOWS_KEY'

export const useWorkflowItem = (itemId: any) => {
    const callApi = useAxios()
    return useQuery(
        [WORKFLOW_ITEMS_KEY, 'GET_SINGLE', itemId],
        () =>
            callApi({
                url: `api/WorkflowTasks/${itemId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(itemId),
        },
    )
}

export const useMyRequestWorkflows = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        requestedBy,
        approver,
        approverType,
        status,
        wentForApproval,
        showOnlyWhereNoApprovers,
    } = state

    const name = state['advancedSearch.forms.name']
    const workflowDisplayName =
        state['advancedSearch.forms.workflowDisplayName']
    const requestId = state['advancedSearch.forms.id']

    const callApi = useAxios()

    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
    }
    if (endDate) {
        queryData.endDateUtc = endDate
    }
    if (requestedBy) {
        queryData.initiatorPersonId = requestedBy.id
    }
    if (approver) {
        if (approverType === 'past') {
            queryData.pastApproverPersonId = approver.id
        } else {
            queryData.approverPersonId = approver.id
        }
    }
    if (status && status.length > 0) {
        queryData.status = status.map((i: any) => i.name)
    }
    if (wentForApproval) {
        queryData.wentForApproval = true
    }
    if (showOnlyWhereNoApprovers) {
        queryData.showOnlyWhereNoApprovers = true
    }

    const advancedSearch = []
    if (name) {
        advancedSearch.push({
            name: 'name',
            type: 'string',
            value: name,
        })
    }
    if (workflowDisplayName) {
        advancedSearch.push({
            name: 'requestWorkflowFriendlyName',
            type: 'string',
            value: workflowDisplayName,
        })
    }
    if (requestId) {
        advancedSearch.push({
            name: 'id',
            type: 'int',
            value: requestId,
        })
    }

    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            WORKFLOWS_KEY,
            'LIST_MY_REQUESTS',
            searchTerm,
            sortBy,
            status,
            sortOrder,
            startDate,
            endDate,
            requestedBy,
            name,
            workflowDisplayName,
            requestId,
            approverType,
            approver,
            wentForApproval,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: '/api/workflowTasks/my',
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useMyTasksWorkflows = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        requestedBy,
        wentForApproval,
    } = state

    const name = state['advancedSearch.forms.name']
    const workflowDisplayName =
        state['advancedSearch.forms.workflowDisplayName']
    const requestId = state['advancedSearch.forms.id']

    const callApi = useAxios()

    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
    }
    if (endDate) {
        queryData.endDateUtc = endDate
    }
    if (requestedBy) {
        queryData.initiatorPersonId = requestedBy.id
    }

    const advancedSearch = []
    if (name) {
        advancedSearch.push({
            name: 'name',
            type: 'string',
            value: name,
        })
    }
    if (workflowDisplayName) {
        advancedSearch.push({
            name: 'requestWorkflowFriendlyName',
            type: 'string',
            value: workflowDisplayName,
        })
    }
    if (requestId) {
        advancedSearch.push({
            name: 'id',
            type: 'int',
            value: requestId,
        })
    }
    if (wentForApproval) {
        queryData.wentForApproval = true
    }

    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            WORKFLOWS_KEY,
            'LIST_MY_TASKS',
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            requestedBy,
            name,
            workflowDisplayName,
            requestId,
            wentForApproval,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: '/api/workflowTasks/todo',
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useAllWorkflows = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        requestedBy,
        approver,
        approverType,
        status,
        wentForApproval,
        showOnlyWhereNoApprovers,
    } = state

    const name = state['advancedSearch.forms.name']
    const requestName = state['advancedSearch.forms.requestName']
    const requestId = state['advancedSearch.forms.id']

    const callApi = useAxios()

    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
    }
    if (endDate) {
        queryData.endDateUtc = endDate
    }
    if (requestedBy) {
        queryData.initiatorPersonId = requestedBy.id
    }
    if (approver) {
        if (approverType === 'past') {
            queryData.pastApproverPersonId = approver.id
        } else {
            queryData.approverPersonId = approver.id
        }
    }
    if (status && status.length > 0) {
        queryData.status = status.map((i: any) => i.name)
    }
    if (wentForApproval) {
        queryData.wentForApproval = true
    }
    if (showOnlyWhereNoApprovers) {
        queryData.showOnlyWhereNoApprovers = true
    }

    const advancedSearch = []
    if (name) {
        advancedSearch.push({
            name: 'name',
            type: 'string',
            value: name,
        })
    }
    if (requestName) {
        advancedSearch.push({
            name: 'BusinessRequestName',
            type: 'string',
            value: requestName,
        })
    }
    if (requestId) {
        advancedSearch.push({
            name: 'id',
            type: 'int',
            value: requestId,
        })
    }

    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            WORKFLOWS_KEY,
            'LIST_ALL',
            searchTerm,
            sortBy,
            status,
            sortOrder,
            startDate,
            endDate,
            requestedBy,
            name,
            requestName,
            requestId,
            approver,
            approverType,
            wentForApproval,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: '/api/workflowTasks/all',
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useWorkflowItemAccessRecipient = (worflowId: any) => {
    const callApi = useAxios()
    return useQuery(
        [WORKFLOW_ITEMS_KEY, worflowId, 'ACCESS_RECIPIENT'],
        () =>
            callApi({
                url: `api/workflowTasks/subjectDetails/${worflowId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(worflowId),
        },
    )
}

export const useWorkflowItemComments = (businessProcessId: any) => {
    const callApi = useAxios()

    return useQuery(
        [WORKFLOW_ITEMS_KEY, businessProcessId, 'WORKFLOW_COMMENTS'],

        () =>
            callApi({
                url: `api/workflowTasks/comments/${businessProcessId}`,
                method: 'POST',
                data: { skip: 0, take: 10 },
            }).then((data) => data.data),
        {
            enabled: Boolean(businessProcessId),
        },
    )
}

export const useWorkflowAddComment = (businessProcessId: any) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/WorkflowTasks/comment/create',
                data,
            }),
        {
            onSuccess: () => refreshComments(businessProcessId),
        },
    )
}

export const refreshComments = (businessProcessId: string) => {
    queryCache.invalidateQueries(
        (q) =>
            q.queryKey.includes(WORKFLOW_ITEMS_KEY) &&
            q.queryKey.includes(businessProcessId) &&
            q.queryKey.includes('WORKFLOW_COMMENTS'),
    )
}

export const useWorkflowRelatedTasks = (businessProcessId: any) => {
    const callApi = useAxios()

    return useQuery(
        [WORKFLOW_ITEMS_KEY, businessProcessId, 'WORKFLOW_RELATED_TASKS'],

        () =>
            callApi({
                url: `api/workflowTasks/previousDecisions/${businessProcessId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(businessProcessId),
        },
    )
}

export const useWorkflowDecisionSummary = (id: any) => {
    const callApi = useAxios()

    return useQuery(
        [WORKFLOW_ITEMS_KEY, id, 'WORKFLOW_DECISIONS_SUMMARY'],

        () =>
            callApi({
                url: `api/workflowTasks/collaborationProcessDecisionsSummary/${id}`,
                method: 'POST',
                data: { skip: 0, take: 20 },
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useWorkflowAffectedResources = (
    businessProcessId: any,
    defaultTake = 6,
) => {
    const callApi = useAxios()

    return usePaginatedDetailsApi(
        [WORKFLOW_ITEMS_KEY, businessProcessId, 'WORKFLOW_AFFECTED_RESOURCES'],

        (skip: number, take: number) => {
            return callApi({
                url: `api/WorkflowTasks/affectedResources/${businessProcessId}`,
                method: 'POST',
                data: { skip, take },
            })
        },
        {
            enabled: Boolean(businessProcessId),
        },
        defaultTake,
    )
}

export const useWorkflowApprovers = (
    id: any,
    searchTerm: any,
    defaultTake: any = 6,
) => {
    const callApi = useAxios()

    return usePaginatedDetailsApi(
        [WORKFLOW_ITEMS_KEY, id, 'WORKFLOW_APPROVERS', searchTerm],

        (skip: number, take: number) => {
            return callApi({
                url: `api/workflowTasks/taskApprovers/${id}`,
                method: 'POST',
                data: {
                    searchTerm: searchTerm,
                    skip,
                    take,
                },
            })
        },
        {
            enabled: Boolean(id),
        },
        defaultTake,
    )
}

export const useWorkflowOperationsDecisions = (
    businessProcessId: any,
    defaultTake = 6,
) => {
    const callApi = useAxios()

    return usePaginatedDetailsApi(
        [WORKFLOW_ITEMS_KEY, businessProcessId, 'WORKFLOW_OPERATIONSDECISIONS'],

        (skip: number, take: number) => {
            return callApi({
                url: `api/workflowTasks/operationDecisions/${businessProcessId}`,
                method: 'POST',
                data: { skip, take },
            })
        },
        { enabled: Boolean(businessProcessId) },
        defaultTake,
    )
}

export const useHassAccessToDelegateWorkFlowTask = (
    personGuid: string,
    workFlowId: string,
) => {
    const callApi = useAxios()

    return useQuery(
        [WORKFLOW_ITEMS_KEY, personGuid, 'WORKFLOW_DELEGATE_ACCESS'],

        () =>
            callApi({
                url: `api/WorkflowTasks/hasAccessToAddDelegateOperation/${personGuid}/${workFlowId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(personGuid) && Boolean(workFlowId),
        },
    )
}

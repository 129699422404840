import Signin from './components/Signin'
import SigninCallback from './components/SigninCallback'
import Signout from './components/Signout'

const routes = [
    {
        path: '/signin',
        component: Signin,
    },
    {
        path: '/callback',
        component: SigninCallback,
    },
    {
        path: '/signout',
        component: Signout,
    },
]
export default routes

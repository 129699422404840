import { useAuthState, useAxios } from 'packages/core'
import { usePageContext } from 'pageContext'
import { useMutation, usePaginatedQuery, useQuery } from 'react-query'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
import {
    augmentRisksWithDecisions,
    groupByRisks,
    refreshRequestItem,
    transformApprovalStepsResponse,
} from './utils'
import { IItemRisk } from 'types'
import { useRequest } from './requestHooks'
import usePaginatedDetailsApi from './usePaginatedDetailsApi'

export const REQUEST_ITEMS_KEY = 'REQUEST_ITEMS_KEY'

export const useMyRequestsItems = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        requestedBy,
        approver,
        showOnlyWhereNoApprovers,
        showOnlyPreApproved,
        approverType,
        status,
        fulfillmentStatus,
        itemType,
        riskLevels,
        subjectType,
        subject,
        dateFilterType,
    } = state

    const friendlyName = state['advancedSearch.forms.friendlyName']
    const requestName = state['advancedSearch.forms.requestName']
    const requestNumber = state['advancedSearch.forms.requestNumber']

    const callApi = useAxios()

    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
        if (dateFilterType === 'StatusChanged') {
            queryData.dateFilterType = dateFilterType
        }
    }
    if (endDate) {
        queryData.endDateUtc = endDate
        if (dateFilterType === 'StatusChanged') {
            queryData.dateFilterType = dateFilterType
        }
    }
    if (requestedBy) {
        queryData.initiatorPersonId = requestedBy.id
    }
    if (approver) {
        if (approverType === 'past') {
            queryData.pastApproverPersonId = approver.id
        } else {
            queryData.approverPersonId = approver.id
        }
    }
    if (showOnlyWhereNoApprovers) {
        queryData.showOnlyWhereNoApprovers = true
    }
    if (showOnlyPreApproved) {
        queryData.showOnlyPreApproved = true
    }
    if (status && status.length > 0) {
        queryData.status = status.map((i: any) => i.id)
    }
    if (fulfillmentStatus) {
        queryData.fulfillmentStatus = fulfillmentStatus
    }
    if (itemType && itemType.length > 0) {
        queryData.itemType = itemType.map((i: any) => i.id)
    }
    if (riskLevels && riskLevels.length > 0) {
        queryData.riskLevels = riskLevels
    }
    if (subjectType) {
        queryData.subjectTypeId = subjectType.id
    }
    if (subject) {
        queryData.subjectId = subject.id
    }
    const advancedSearch = []
    if (friendlyName) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: friendlyName,
        })
    }
    if (requestName) {
        advancedSearch.push({
            name: 'BusinessRequestName',
            type: 'string',
            value: requestName,
        })
    }
    if (requestNumber) {
        advancedSearch.push({
            name: 'BusinessRequestNumber',
            type: 'int',
            value: requestNumber,
        })
    }

    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            REQUEST_ITEMS_KEY,
            'LIST_MY_REQUESTS',
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            requestedBy,
            approver,
            showOnlyWhereNoApprovers,
            showOnlyPreApproved,
            approverType,
            status,
            fulfillmentStatus,
            itemType,
            riskLevels,
            friendlyName,
            requestName,
            requestNumber,
            subjectType,
            subject,
            (startDate || endDate) && dateFilterType,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: '/api/businessRequestItems/my',
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useMyRequestItemsTasks = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        requestedBy,
        itemType,
        riskLevels,
        subjectType,
        subject,
        fulfillmentStatus,
        dateFilterType,
    } = state

    const friendlyName = state['advancedSearch.forms.friendlyName']
    const requestName = state['advancedSearch.forms.requestName']
    const requestNumber = state['advancedSearch.forms.requestNumber']

    const callApi = useAxios()

    const queryData: any = {}
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
        if (dateFilterType === 'StatusChanged') {
            queryData.dateFilterType = dateFilterType
        }
    }
    if (endDate) {
        queryData.endDateUtc = endDate
        if (dateFilterType === 'StatusChanged') {
            queryData.dateFilterType = dateFilterType
        }
    }
    if (requestedBy) {
        queryData.initiatorPersonId = requestedBy.id
    }
    if (fulfillmentStatus) {
        queryData.fulfillmentStatus = fulfillmentStatus
    }
    if (itemType && itemType.length > 0) {
        queryData.itemType = itemType.map((i: any) => i.id)
    }
    if (riskLevels && riskLevels.length > 0) {
        queryData.riskLevels = riskLevels
    }
    if (subjectType) {
        queryData.subjectTypeId = subjectType.id
    }
    if (subject) {
        queryData.subjectId = subject.id
    }
    const advancedSearch = []
    if (friendlyName) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: friendlyName,
        })
    }
    if (requestName) {
        advancedSearch.push({
            name: 'BusinessRequestName',
            type: 'string',
            value: requestName,
        })
    }
    if (requestNumber) {
        advancedSearch.push({
            name: 'BusinessRequestNumber',
            type: 'int',
            value: requestNumber,
        })
    }

    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            REQUEST_ITEMS_KEY,
            'LIST_MY_TASKS',
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            requestedBy,
            fulfillmentStatus,
            itemType,
            riskLevels,
            friendlyName,
            requestName,
            requestNumber,
            subjectType,
            subject,
            (startDate || endDate) && dateFilterType,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: '/api/businessRequestItems/todo',
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useAllRequestsItems = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        sortBy,
        sortOrder,
        startDate,
        endDate,
        approver,
        showOnlyWhereNoApprovers,
        showOnlyPreApproved,
        approverType,
        requestedBy,
        status,
        fulfillmentStatus,
        itemType,
        riskLevels,
        subjectType,
        subject,
        dateFilterType,
    } = state

    const friendlyName = state['advancedSearch.forms.friendlyName']
    const requestName = state['advancedSearch.forms.requestName']
    const requestNumber = state['advancedSearch.forms.requestNumber']

    const callApi = useAxios()

    const queryData: any = {}
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
        if (dateFilterType === 'StatusChanged') {
            queryData.dateFilterType = dateFilterType
        }
    }
    if (endDate) {
        queryData.endDateUtc = endDate
        if (dateFilterType === 'StatusChanged') {
            queryData.dateFilterType = dateFilterType
        }
    }
    if (requestedBy) {
        queryData.initiatorPersonId = requestedBy.id
    }
    if (approver) {
        if (approverType === 'past') {
            queryData.pastApproverPersonId = approver.id
        } else {
            queryData.approverPersonId = approver.id
        }
    }
    if (showOnlyWhereNoApprovers) {
        queryData.showOnlyWhereNoApprovers = true
    }
    if (showOnlyPreApproved) {
        queryData.showOnlyPreApproved = true
    }
    if (status && status.length > 0) {
        queryData.status = status.map((i: any) => i.id)
    }
    if (fulfillmentStatus) {
        queryData.fulfillmentStatus = fulfillmentStatus
    }
    if (itemType.length > 0) {
        queryData.itemType = itemType.map((i: any) => i.id)
    }
    if (riskLevels && riskLevels.length > 0) {
        queryData.riskLevels = riskLevels
    }
    if (subjectType) {
        queryData.subjectTypeId = subjectType.id
    }
    if (subject) {
        queryData.subjectId = subject.id
    }
    const advancedSearch = []
    if (friendlyName) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: friendlyName,
        })
    }
    if (requestName) {
        advancedSearch.push({
            name: 'BusinessRequestName',
            type: 'string',
            value: requestName,
        })
    }
    if (requestNumber) {
        advancedSearch.push({
            name: 'BusinessRequestNumber',
            type: 'int',
            value: requestNumber,
        })
    }

    queryData.advancedSearch = advancedSearch

    return useApiWithInfiniteScroll(
        [
            REQUEST_ITEMS_KEY,
            'LIST_ALL',
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            approver,
            showOnlyWhereNoApprovers,
            showOnlyPreApproved,
            approverType,
            requestedBy,
            status,
            fulfillmentStatus,
            itemType,
            riskLevels,
            friendlyName,
            requestName,
            requestNumber,
            subjectType,
            subject,
            (startDate || endDate) && dateFilterType,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: 'api/businessRequestItems/all',
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useRequestItem = (itemId: any) => {
    const callApi = useAxios()
    return useQuery(
        [REQUEST_ITEMS_KEY, itemId],
        () =>
            callApi({
                url: `api/businessRequestItems/${itemId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(itemId),
        },
    )
}

export const useRequestItemAccessRecipient = (itemId: any, requestId: any) => {
    const callApi = useAxios()
    return useQuery(
        [REQUEST_ITEMS_KEY, itemId, requestId, 'ACCESS_RECIPIENT'],
        () =>
            callApi({
                url: `api/businessRequests/subjectDetails/${requestId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(itemId) && Boolean(requestId),
        },
    )
}

export const useRequestItemStepApprovers = (
    requestItemId: any,
    stepId: any,
    skip: any,
    take: any,
    searchTerm: any,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            REQUEST_ITEMS_KEY,
            'STEP_APPROVERS',
            requestItemId,
            stepId,
            skip,
            take,
            searchTerm,
        ],
        () =>
            callApi({
                url: `/api/BusinessRequestItems/approvers/${requestItemId}/${stepId}`,
                method: 'POST',
                data: {
                    searchTerm: searchTerm,
                    skip: skip,
                    take: take,
                },
            }).then((data) => data),
        {
            enabled: Boolean(requestItemId) && Boolean(stepId),
        },
    )
}

export const useRequestItemStepApproversPaginated = (
    requestItemId: any,
    stepId: any,
    searchTerm: any,
    defaultTake: 6,
) => {
    const callApi = useAxios()

    return usePaginatedDetailsApi(
        [
            REQUEST_ITEMS_KEY,
            'STEP_APPROVERS_PAGINATED',
            requestItemId,
            stepId,
            searchTerm,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/BusinessRequestItems/approvers/${requestItemId}/${stepId}`,
                method: 'POST',
                data: {
                    searchTerm: searchTerm,
                    skip: skip,
                    take: take,
                },
            })
        },
        {
            enabled: Boolean(requestItemId) && Boolean(stepId),
        },
        defaultTake,
    )
}

export const useRequestItemApprovalDecisions = (
    requestItemId: any,
    isApprovable: any,
) => {
    const callApi = useAxios()
    return useQuery(
        [REQUEST_ITEMS_KEY, 'APPROVAL_DECISIONS', requestItemId],
        () =>
            callApi({
                url: `api/businessRequestItems/approvalDecisions/${requestItemId}`,
                method: 'GET',
            }).then((data) => data.data),
        {
            enabled: Boolean(requestItemId) && isApprovable,
        },
    )
}

export const useRequestItemApprovalSteps = (requestId: any, itemId: any) => {
    const callApi = useAxios()

    const { data: request } = useRequest(requestId)
    const { data: item } = useRequestItem(itemId)

    const url = `api/businessRequests/approvalSteps/${requestId}?itemId=${itemId}`
    return useQuery(
        [REQUEST_ITEMS_KEY, itemId, 'APPROVAL_STEPS'],
        () =>
            callApi({
                url,
                method: 'GET',
            }).then((data) =>
                transformApprovalStepsResponse(request, data.data, item),
            ),
        {
            enabled: Boolean(request) && Boolean(item),
        },
    )
}

export const useSubmitRequestItemDecision = (
    requestId: any,
    requestItemId: any,
) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `/api/businessRequestItems/submitDecisions/${requestId}`,
                data,
            }),
        {
            onSettled: () => refreshRequestItem(requestItemId),
        },
    )
}

export const useRequestItemComments = (requestId: any, itemId: any) => {
    const callApi = useAxios()
    const [{ tokenExpiresAt }]: any = useAuthState()

    return useQuery(
        [REQUEST_ITEMS_KEY, itemId, 'REQUEST_ITEM_COMMENTS', tokenExpiresAt],

        () =>
            callApi({
                url: `api/comments/all/${requestId}/${itemId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId) && Boolean(itemId),
        },
    )
}

export const useRequestItemDelegationsHistory = (
    requestId: any,
    itemId: any,
) => {
    const callApi = useAxios()

    return useQuery(
        [REQUEST_ITEMS_KEY, requestId, itemId, 'DELEGATIONS_HISTORY'],
        () =>
            callApi({
                url: `api/delegations/manualDelegations/${requestId}/${itemId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(requestId) && Boolean(itemId),
        },
    )
}

export const useRequestItemRisks = (
    itemId: string,
    groupBy: undefined | 'Risks' = undefined,
) => {
    const callApi = useAxios()
    return useQuery<IItemRisk[]>(
        [REQUEST_ITEMS_KEY, itemId, 'RISKS', groupBy],
        () =>
            callApi({
                url: `api/businessRequestItems/risks/${itemId}`,
            }).then((data) => {
                switch (groupBy) {
                    case 'Risks':
                        return groupByRisks(data.data)
                    default:
                        return data.data
                }
            }),
        {
            enabled: Boolean(itemId),
        },
    )
}

export const useRequestItemEditFulfillmentDate = (itemId: string) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `/api/BusinessRequestItems/updateFulfilmentDate/${itemId}`,
                data: data,
            }),
        {
            onSuccess: () => {
                refreshRequestItem(itemId)
            },
        },
    )
}

const _useItemPendingRiskItems = (
    itemId: string,
    skip: number,
    take: number,
    searchKey?: string,
) => {
    const callApi = useAxios()
    const queryObj: any = {
        skip: skip,
        take: take,
    }
    if (searchKey) {
        queryObj.searchTerm = searchKey
    }

    return usePaginatedQuery(
        [
            REQUEST_ITEMS_KEY,
            itemId,
            'PENDING_RISK_ITEMS',
            skip,
            take,
            searchKey,
        ],
        () =>
            callApi({
                url: `/api/businessRequestItems/${itemId}/pendingRiskItems/byItemId`,
                method: 'POST',
                data: queryObj,
            }).then((data) => data.data),
        {
            enabled: Boolean(itemId),
        },
    )
}

export const useItemPendingRiskItems = (itemId: string) => {
    const { data: items, isLoading: isLoadingItems } = _useItemPendingRiskItems(
        itemId,
        0,
        50,
    )

    const { data: risks, isLoading: isLoadingRisks } =
        useRequestItemRisks(itemId)

    if (isLoadingItems || isLoadingRisks || !items || !risks) {
        return {
            data: undefined,
            totalCount: undefined,
            isLoading: true,
        }
    }

    const pendingRiskItems = groupByRisks(
        augmentRisksWithDecisions(items, risks),
    )

    return {
        data: pendingRiskItems,
        totalCount: pendingRiskItems.length,
        isLoading: false,
    }
}

export const useCancelRequestItem = (itemId: string) => {
    const callApi = useAxios()

    return useMutation(
        () =>
            callApi({
                method: 'POST',
                url: `/api/businessRequestItems/cancel/${itemId}`,
            }),
        {
            onSettled: () => refreshRequestItem(itemId),
        },
    )
}

export const useRefreshRequestItemRisks = (
    requestId: string,
    itemId: string,
) => {
    const callApi = useAxios()

    return useMutation(
        () =>
            callApi({
                method: 'POST',
                url: `/api/BusinessRequests/refreshRisks/${requestId}`,
            }),
        {
            onSettled: () => refreshRequestItem(itemId),
        },
    )
}

export const useItemMitigatingControlsItemView = (stepId: string) => {
    const callApi = useAxios()

    return useQuery(
        [REQUEST_ITEMS_KEY, stepId, 'ITEM_MITIGATING_CONTROLS'],
        () =>
            callApi({
                method: 'POST',
                url: `/api/risks/violationItemMitigatingControls/${stepId}`,
                data: {
                    skip: 0,
                    take: 15,
                },
            }).then((data) => data.data),
        {
            enabled: Boolean(stepId),
        },
    )
}

export const useRequestItemFulfillmentStatuses = () => {
    const callApi = useAxios()

    return useQuery(
        [REQUEST_ITEMS_KEY, 'FULFILLMENT_STATUSES'],
        () =>
            callApi({
                method: 'GET',
                url: '/api/businessRequestItems/fulfillmentStatuses',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

import React, { FC } from 'react'
import { useSubmitViolationItemDecision } from 'hooks'
import DoNothingButton from 'components/Button/DoNothingButton'
import { ReactComponent as DoNothingIcon } from 'components/Button/do-nothing.svg'
import { Tooltip } from 'packages/eid-ui'

export const DoNothing: FC<any> = (props) => {
    const { item, d } = props

    const [
        submitRiskDecision,
        { isLoading: isSubmitting },
    ] = useSubmitViolationItemDecision(
        'Requests',
        item.businessRequestId,
        item.id,
    )

    return (
        <Tooltip title={d.decisionFriendlyName}>
            <div>
                <DoNothingButton
                    size={'small'}
                    onClick={() => {
                        const revokeReq = {
                            stepId: item.currentApprovalStepId,
                            decisionId: d.decisionId,
                        }
                        submitRiskDecision(revokeReq)
                    }}
                    startIcon={<DoNothingIcon color="#ffffff" />}
                    loading={isSubmitting}
                />
            </div>
        </Tooltip>
    )
}

import { styled } from '@mui/material'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowSvg } from './arrow-right-rounded-borders.svg'

const orientationAngle = {
    up: '270',
    right: '0',
    down: '90',
    left: '180',
}

const Arrow = styled(ArrowSvg)(({ theme, color, direction }) => ({
    transform: `rotate(${orientationAngle[direction]}deg)`,
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

Arrow.propTypes = {
    color: PropTypes.string,
    direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
    fill: PropTypes.string,
}

Arrow.defaultProps = {
    color: '#000000',
    direction: 'down',
    fill: 'none',
}

export default Arrow

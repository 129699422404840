import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'

export const ACCESS_SUBCOMPONENTS_KEY = 'ACCESS_SUBCOMPONENTS_KEY'

export const useAccessSubcomponents = () => {
    const callApi = useAxios()
    return useQuery(
        ACCESS_SUBCOMPONENTS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/application/access/subcomponents',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

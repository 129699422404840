import { CommentsList } from 'components'
import {
    useAddComment,
    useRequestComments,
    useDeleteComment,
    useEditComment,
} from 'hooks'
import CommentsSectionContainer from 'components/CommentsSectionContainer'

const commentsContainerProps = {
    style: {
        maxHeight: '400px',
        overflow: 'auto',
        paddingRight: '20px',
        marginRight: '-15px',
        paddingBottom: '36px',
    },
    width: 'auto',
}

const RequestComments = ({ requestId, businessRequest }) => {
    const { data: comments, isLoading: loadingComments } = useRequestComments(
        requestId,
    )

    const [addComment, { isLoading: isSubmitting }] = useAddComment(
        'Requests',
        requestId,
    )

    const [deleteComment] = useDeleteComment('Requests', requestId)

    const [editComment] = useEditComment('Requests', requestId)

    return (
        <CommentsSectionContainer comments={comments}>
            <CommentsList
                page="Requests"
                commentsContainerProps={commentsContainerProps}
                requestId={requestId}
                list={comments}
                loading={loadingComments}
                isSubmitting={isSubmitting}
                handleAddComment={(value) =>
                    addComment({
                        businessRequestId: requestId,
                        value,
                    })
                }
                handleDeleteComment={(id) => {
                    deleteComment(id)
                }}
                handleEditComment={(id, value) => {
                    editComment({
                        id,
                        value,
                    })
                }}
            />
        </CommentsSectionContainer>
    )
}

export default RequestComments

import { styled } from '@mui/material'

const Label = styled('label')({
    padding: '0 0 4px 8px',
    lineHeight: 1.5,
    fontSize: '12px',
    display: 'block',
    color: ' #b4b4b4',
    textTransform: 'uppercase',
})

export default Label

import { FC, Fragment } from 'react'
import { ApproveRisk } from './ApproveRisk'
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { RejectRisk } from './RejectRisk'
import { useSubmitViolationItemDecision } from 'hooks'
import { DoNothing } from './DoNothing'

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
        },
        button: {
            margin: '0 4px',
            '& button': {
                minWidth: 'auto',
                maxWidth: '40px',
                padding: '3.25px 3px 3.25px 16px',
            },
        },
    }),
)

export const ViolatingItemDecision: FC<{ item: any }> = (props) => {
    const classes = useStyles()
    const { item } = props

    const [
        submitRiskDecision,
        { isLoading: isSubmitting },
    ] = useSubmitViolationItemDecision(
        'Requests',
        item.businessRequestId,
        item.id,
    )

    return (
        <Fragment>
            {item.decisions && (
                <Box className={classes.root}>
                    {item.decisions.map((d: any) => (
                        <Fragment key={d.decisionId}>
                            {d.decisionAction === 'Continue' ? (
                                <Box className={classes.button}>
                                    <ApproveRisk
                                        currentApprovalStepId={
                                            item.currentApprovalStepId
                                        }
                                        onSubmit={(value) => {
                                            const approveReq = {
                                                ...value,
                                                stepId:
                                                    item.currentApprovalStepId,
                                                decisionId: d.decisionId,
                                            }
                                            return submitRiskDecision(
                                                approveReq,
                                            )
                                        }}
                                        decisionFriendlyName={
                                            d.decisionFriendlyName
                                        }
                                        isLoading={isSubmitting}
                                        assignmentStartDate={
                                            item.requestDataAssignmentStartDate
                                        }
                                        assignmentEndDate={
                                            item.requestDataAssignmentEndDate
                                        }
                                        showTimeConstrainedControl={
                                            d.isTimeConstraintEditable
                                        }
                                        timeConstraintRequired={
                                            d.isTimeConstraintRequired
                                        }
                                        requestPolicyId={item.requestPolicyId}
                                    />
                                </Box>
                            ) : d.decisionAction === 'DoNothing' ? (
                                <Box className={classes.button}>
                                    <DoNothing d={d} item={item} />
                                </Box>
                            ) : (
                                <Box className={classes.button}>
                                    <RejectRisk d={d} item={item} />
                                </Box>
                            )}
                        </Fragment>
                    ))}
                </Box>
            )}
        </Fragment>
    )
}

import { useState } from 'react'
import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
    ItemDetails,
    WorkflowApprovalFormModal,
    WorkflowClaimControl,
} from 'components'
import Overview from './Overview'
import WorkflowComments from './WorkflowComments'
import WorkflowRelatedTasksListing from './WorkflowRelatedTasksListing'
import TaskApprovers from './TaskApprovers'
import AffectedResources from './AffectedResources'
import OperationsDecisions from './OperationDecisions'
import PreviousDecisions from './PreviousDecisions'
import { smallScreenWidth } from 'utils'
import { PageContextProvider } from 'pageContext'
import useSubcomponents from 'useSubcomponents'
import { WorkflowDelegateMenuButton } from 'components'
import { isNilOrEmpty } from 'packages/core'
import {
    useCurrentPerson,
    useHassAccessToDelegateWorkFlowTask,
    useWorkflowDecisionSummary,
    useWorkflowOperationsDecisions,
} from 'hooks'
import { usePageContext } from 'pageContext'

const TabsContainerBox = styled(Box)({
    backgroundColor: '#fbfbfd',
    borderBottom: '1px solid #d8dadd',

    paddingRight: '30px',
    display: 'flex',
    justifyContent: 'space-between',

    '& .Tabs-root': {
        display: 'flex',
    },

    [`@media (max-width:${smallScreenWidth})`]: {
        flexDirection: 'column-reverse',
        paddingTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',

        '& .Tabs-root': {
            marginLeft: '-20px',
        },
    },
})

const WorkflowItemDetails = ({ data }) => {
    const { t } = useTranslation()
    const { data: currentPerson } = useCurrentPerson()
    const [{ subPage }, dispatch] = usePageContext()
    const [activeTab, setActiveTab] = useState('overview')
    const {
        data: workflowDecisionSummary,
        isLoading: workflowLoading,
    } = useWorkflowDecisionSummary(data.businessProcessId)

    const {
        data: hasAccessToDelegateWorkFlow,
    } = useHassAccessToDelegateWorkFlowTask(
        currentPerson.id,
        data.requestWorkflowGUID,
    )

    const {
        list: operationsDecisionsList,
        pagination: operationsDecisionsPagination,
    } = useWorkflowOperationsDecisions(data.id)

    const {
        hasAccessToWorkflowClaimControl,
        hasAccessToWorkflowDelegateControl,
    } = useSubcomponents()

    const canSeeDelegateBtn = () => {
        return (
            hasAccessToWorkflowDelegateControl &&
            hasAccessToDelegateWorkFlow?.hasAccess
        )
    }

    const canSeeOperationsDecisions = () => {
        return operationsDecisionsList && operationsDecisionsList.length > 0
    }

    return (
        <>
            <TabsContainerBox>
                <Box marginLeft="-13px" className="Tabs-root">
                    <ItemDetails.Tabs
                        variant="scrollable"
                        scrollButtons
                        value={activeTab}
                        onChange={(_, value) => {
                            if (subPage > 1) {
                                dispatch({
                                    type: 'SET_SUBPAGE',
                                    payload: 1,
                                })
                            }
                            setActiveTab(value)
                        }}
                    >
                        <ItemDetails.Tabs.Tab
                            value="overview"
                            label={t('Overview')}
                        />
                        <ItemDetails.Tabs.Tab
                            value="taskApprovers"
                            label={t('TaskApprovers')}
                        />

                        <ItemDetails.Tabs.Tab
                            value="previousDecisions"
                            label={t('PreviousDecisions')}
                        />
                        {canSeeOperationsDecisions() && (
                            <ItemDetails.Tabs.Tab
                                value="decisions"
                                label={t('OperationsDecisions')}
                            />
                        )}
                        <ItemDetails.Tabs.Tab
                            value="affectedResources"
                            label={t('AffectedResources')}
                        />
                    </ItemDetails.Tabs>
                </Box>
                <Box display="flex" alignItems="center">
                    {hasAccessToWorkflowClaimControl &&
                        isNilOrEmpty(data.delegatePersonId) && (
                            <Box marginRight="8px" data-protectedsubcomponent={hasAccessToWorkflowClaimControl}>
                                <WorkflowClaimControl
                                    disabled={data.status !== 'Open'}
                                    isApprover={data.isPendingApprover}
                                    workflowTaskId={data.id}
                                    claimedByPersonId={data.claimedByPersonId}
                                    claimByPersonFriendlyName={
                                        data.claimByPersonFriendlyName
                                    }
                                />
                            </Box>
                        )}
                    {canSeeDelegateBtn() && (
                        <WorkflowDelegateMenuButton
                            disabled={data.status !== 'Open'}
                            workflowId={data.id}
                            requestWorkflowGUID={data.requestWorkflowGUID}
                            isApprover={data.isPendingApprover}
                            delegatePersonId={data.delegatePersonId}
                            delegatePersonFriendlyName={
                                data.delegatePersonFriendlyName
                            }
                            delegatorPersonFriendlyName={
                                data.delegatorPersonFriendlyName
                            }
                            protectSubcomponent={hasAccessToWorkflowDelegateControl}
                        />
                    )}
                </Box>
            </TabsContainerBox>

            {activeTab === 'overview' && <Overview data={data} />}

            {activeTab === 'decisions' && (
                <PageContextProvider key="CollaborationTasksDetailsOperationsDecisions">
                    <OperationsDecisions
                        list={operationsDecisionsList}
                        pagination={operationsDecisionsPagination}
                    />
                </PageContextProvider>
            )}

            {activeTab === 'previousDecisions' && (
                <PreviousDecisions businessProcessId={data.businessProcessId} />
            )}

            {activeTab === 'affectedResources' && (
                <AffectedResources id={data.id} />
            )}

            {activeTab === 'taskApprovers' && <TaskApprovers id={data.id} />}

            {data.showApprovalForm && data.approvalFormUrl && (
                <Box
                    display="flex"
                    alignItems="center"
                    marginLeft="30px"
                    marginTop="30px"
                >
                    <WorkflowApprovalFormModal
                        title={t('ApprovalForm')}
                        approvalFormUrl={data.approvalFormUrl}
                        maxWidth='lg'
                    />
                </Box>
            )}

            {workflowDecisionSummary && workflowDecisionSummary.length > 0 && (
                <WorkflowRelatedTasksListing
                    data={workflowDecisionSummary}
                    isLoading={workflowLoading}
                />
            )}

            <Box marginY="18px">
                <WorkflowComments
                    businessProcessId={data.businessProcessId}
                    workflowTaskId={data.id}
                />
            </Box>
        </>
    )
}

export default WorkflowItemDetails

import React, { useRef } from 'react'
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
    iconButton: ({ expanded }) => ({
        borderRadius: '5px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',

        display: 'flex',
        marginLeft: '4px',
        marginRight: '4px',
        paddingLeft: '0px',
        paddingRight: '0px',
        minWidth: '60px',
        backgroundColor: expanded ? '#efeff1' : 'inherit',
        '&:hover': {
            backgroundColor: '#efeff1 !important',
        },
    }),
    violationsIconContainer: {
        height: '24px',
        width: '24px',
        bgcolor: 'rgba(208, 2, 27, 0.07)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px #d0021b solid',
        borderRadius: '5px',
    },
    extraShade: ({ extraShadeHeight }) => ({
        position: 'absolute',
        backgroundColor: '#efeff1',
        marginLeft: '4px',
        marginRight: '4px',
        width: '60px',
        minHeight: `${extraShadeHeight}px`,
    }),
})

const ExpandButton = ({
    type,
    expanded,
    handleExpand,
    children,
    extraShadeHeight,
}) => {
    const classes = useStyles({ expanded, extraShadeHeight })
    const divRef = useRef(null)

    const renderIconOrChildren = () => {
        if (children) return children
        else if (type === 'Violations') {
            return (
                <Box className={classes.violationsIconContainer}>
                    <Icon name="AngledArrow" color="#d0021b" />
                </Box>
            )
        }

        return <Icon name={type} color="#307fc1" />
    }

    return <>
        <div ref={divRef}>
            <IconButton onClick={handleExpand} className={classes.iconButton} size="large">
                {renderIconOrChildren()}
            </IconButton>
        </div>
        {expanded && extraShadeHeight && (
            <Box
                className={classes.extraShade}
                style={{ top: divRef.current?.offsetHeight }}
            />
        )}
    </>;
}

ExpandButton.propTypes = {
    type: PropTypes.oneOf(['Comments', 'Approvers', 'Violations']),
}

export default ExpandButton

import { useWorkflowRelatedTasks } from 'hooks'
import { Box, styled } from '@mui/material'
import { Spinner } from 'packages/eid-ui'
import { Trans } from 'react-i18next'
import { Decision } from './Decision'

const PreviousDecisionsContainer = styled(Box)({
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '32px',
})

const PreviousDecisions = ({ businessProcessId }: any) => {
    const { data, isLoading } = useWorkflowRelatedTasks(businessProcessId)

    if (isLoading) {
        return (
            <PreviousDecisionsContainer display="flex" justifyContent="center">
                <Spinner />
            </PreviousDecisionsContainer>
        )
    }
    if (data && data.length > 0) {
        return (
            <PreviousDecisionsContainer>
                {data.map((decision: any, index: number) => {
                    return (
                        <Decision
                            key={decision.id + index}
                            personName={decision.executorPersonFriendlyName}
                            personTitle={decision.executorPersonTitle}
                            personImageUrl={
                                decision.executorPersonPersonImageThumbUrl
                            }
                            status={decision.status}
                            statusDescription={decision.statusDescription}
                            date={decision.startDate}
                            decisionName={decision.friendlyName}
                            showVerticalLine={index + 1 !== data.length}
                            approvalFormUrl={
                                decision.status === 'Open'
                                    ? decision.approvalFormUrl
                                    : ''
                            }
                        />
                    )
                })}
            </PreviousDecisionsContainer>
        )
    }
    return (
        <Box display="flex" justifyContent="center">
            <Trans i18nKey="NoDataFound" />
        </Box>
    )
}

export default PreviousDecisions

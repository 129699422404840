import React from 'react'
import PropTypes from 'prop-types'
import { styled, Box } from '@mui/material'
import { isNilOrEmpty } from 'packages/core'

const AttributeLabel = styled(Box)({
    fontSize: '12px',
    lineHeight: 1.33,
    color: '#9b9b9b',
    textTransform: 'uppercase',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    width: '25%',
})

const AttributeValue = styled(Box)({
    fontSize: '14px',
    lineHeight: 1.33,
    color: '#000000',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    width: '70%',
})

const Attribute = ({
    label,
    value,
    orientation,
    labelProps,
    valueProps,
    style,
    ...rest
}) => {
    return (
        <Box
            display={orientation === 'vertical' ? 'inherit' : 'flex'}
            paddingY="4px"
            alignItems="center"
            {...rest}
        >
            <AttributeLabel style={style} {...labelProps}>
                {label}
            </AttributeLabel>
            <AttributeValue
                margin={orientation === 'vertical' ? '12px 0px' : '0px 10px'}
                {...valueProps}
            >
                {isNilOrEmpty(value) ? '-' : value}
            </AttributeValue>
        </Box>
    )
}

Attribute.propTypes = {
    label: PropTypes.any,
    value: PropTypes.any,
    orientation: PropTypes.oneOf(['vertical', 'horizontal']),
}

Attribute.defaultProps = {
    orientation: 'horizontal',
}

export default Attribute

import { Avatar, Box, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

const StyledAvatar = styled(Avatar)({
    width: '16px',
    height: '16px',
    boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px',
})

const useStyles = makeStyles({
    root: {
        '& div:first-child': {
            marginLeft: '0px',
        },
    },
})

const TextButtonContainer = styled(Box)({
    color: '#307fc1',
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    zIndex: 10,
    '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    margin: '0px 8px',
})

export const AvatarGroup = ({ open = false, setOpen }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Box display="flex" alignItems="center" classes={classes}>
            <StyledAvatar />
            <StyledAvatar />
            <StyledAvatar />
            <StyledAvatar />
            <StyledAvatar />
            <TextButtonContainer onClick={setOpen} id="toggleShowApprovers">
                {!open ? t('ShowApprovers') : t('HideApprovers')}
            </TextButtonContainer>
        </Box>
    )
}

import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from '../eid-icons'
import { FC } from 'react'

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            margin: '12px 8px',
        },
        error: {
            display: 'flex',
            alignItems: 'center',
            margin: '0px 10px',
            fontSize: '13px',
            color: '#00000',
            lineHeight: 1.33,
        },
    }),
)

export const ValidationMessage: FC<{ message: string }> = (props) => {
    const classes = useStyles()
    return (
        <Box className={classes.root}>
            <Icon name="ErrorStatus" color="#d0021b" />
            <Box className={classes.error}>{props.message}</Box>
        </Box>
    )
}

import React from 'react'
import { styled } from '@mui/material'

const StyledContainer = styled('div')(({ color, styles }) => ({
    width: '100%',
    textAlign: 'center',
    backgroundColor: color ? color : '#eef0f4',
    position: 'sticky',
    top: '0px',
    zIndex: 200,
    display: 'flex',
    justifyContent: 'center',
    ...styles,
}))

const StickyContainer = ({ children, ...rest }) => {
    return <StyledContainer {...rest}>{children}</StyledContainer>
}

export default StickyContainer

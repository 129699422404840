import React from 'react'
import { CardHeader, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import HeaderBgImage from './header-bg.png'
import TextFormatter from 'components/TextFormatter'

const StyledImg = styled('img')({
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: -1,
    right: 0,
    top: 0,
})

const useStyles = makeStyles(() => ({
    root: (props) => ({
        width: '100%',
        position: 'relative',
        padding: '0px 20px',
        minHeight: '70px',
        ...props.style,
        ...props.styles,
    }),

    content: {
        width: '100%',
    },
    title: {
        fontSize: '18px',
        fontWeight: '600',
    },
}))

const maxCharacters = 100

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    disableInteractive: false,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

const headingStyles = {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: 1.33,
    color: '#fff',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    width: '100%',
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    maxWidth: '690px',
}

const ItemHeader = ({ title, styles }) => {
    const titleJsx = (
        <TextFormatter
            value={title}
            style={headingStyles}
            maxCharacters={maxCharacters}
            tooltipProps={tooltipProps}
        />
    )

    const classes = useStyles({ styles })

    return (
        <CardHeader
            classes={classes}
            title={
                <>
                    {titleJsx}

                    <StyledImg src={HeaderBgImage} alt="background" />
                </>
            }
        />
    )
}

export default ItemHeader

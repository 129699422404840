import { Box, styled } from '@mui/material'
import OperationsDecisionCardItem from './OperationsDecisionCardItem'

const OperationsDecisionsContainer = styled(Box)({
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '30px',
    display:"flex"

});
const OperationsDecisions = ({ list, pagination }) => {
        return (
            <>
                <OperationsDecisionsContainer
                    
                    flexDirection="row"
                    flexWrap="wrap"
                >
                    {list.map((item) => (
                        <OperationsDecisionCardItem decision={item} />
                    ))}
                    <Box style={{ margin: '17px auto 0px auto' }}>
                        {pagination}
                    </Box>
                </OperationsDecisionsContainer>
            </>
        )
}

export default OperationsDecisions
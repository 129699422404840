import config from 'config'

export function encodeQueryParams(queryData) {
    const encodedQueryData = []
    for (var d in queryData) {
        if (
            queryData[d] !== undefined &&
            queryData[d] !== null &&
            queryData[d] !== ''
        ) {
            encodedQueryData.push(
                encodeURIComponent(d) + '=' + encodeURIComponent(queryData[d]),
            )
        }
    }
    return encodedQueryData.join('&')
}

export function buildUrlWithQueryParams(url, queryData) {
    const queryString = encodeQueryParams(queryData)
    return url + (queryString ? `?${queryString}` : '')
}

export function formatDate(date, format) {
    switch (format) {
        case 'MM/dd/yyyy': {
            return (
                date.getMonth() +
                1 +
                '/' +
                date.getDate() +
                '/' +
                date.getFullYear()
            )
        }
        default:
            return date
    }
}

export function MIDPathToImage(cdnUrl, pathToImage) {
    return cdnUrl + pathToImage + '&timeStamp=' + Date.now()
}

export function getCookieByName(cname) {
    try {
        let name = cname + '='
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
    } catch (err) {}
    return ''
}

export function getWorkflowWaitTimeInSeconds() {
    const DEFAULT_WORKFLOW_WAIT_TIME = 5
    const workflowWaitTime = isNaN(config.WORKFLOW_WAIT_TIME)
        ? DEFAULT_WORKFLOW_WAIT_TIME
        : config.WORKFLOW_WAIT_TIME

    return workflowWaitTime
}

export const getFullPathFromUrl = () => {
    return window.location.pathname + window.location.search
}

import React from 'react'
import { Box } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import IconContainer from './IconContainer'
import IconLabel from './IconLabel'

const ApprovedIcon = ({ label, color, variant }) => {
    return (
        <Box display="flex" alignItems="center">
            <IconContainer bgcolor="#01ae8f">
                <Icon name="Approve" color="#ffffff" />
            </IconContainer>
            <IconLabel style={{ color: '#01ae8f' }}>{label}</IconLabel>
        </Box>
    )
}

export default ApprovedIcon

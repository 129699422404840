import React, { FC, useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { PillTabs } from 'components/PillTabs'
import { Tab } from 'components/PillTabs/Tab'
import { TabLabel } from 'components/PillTabs/TabLabel'
import { useTranslation } from 'react-i18next'

import { Search } from 'components'
import { mobileScreenWidth, smallScreenWidth } from 'utils'
import { useRisksFunctionalAccess } from 'hooks'
import { FUNCTIONAL_ACCESS_PAGE_SIZE } from 'utils'
import { Pagination } from 'packages/eid-ui'
import FunctionalAccessTable from 'components/FunctionalAccessRequest/FunctionalAccessTable'
import FunctionalAccessItemList from './FunctionalAccessList/FunctionalAccessList'

const TabsContainerBox = styled(Box)({
    backgroundColor: '#fbfbfd',
    borderBottom: '1px solid #d8dadd',
    justifyContent: 'space-between',
    overflow: 'auto',

    '& .Tabs-root': {
        display: 'flex',
    },

    [`@media (max-width:${smallScreenWidth})`]: {
        flexDirection: 'column-reverse',
        paddingTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',

        '& .Tabs-root': {
            marginLeft: '-20px',
        },
    },
})

const useStyles = makeStyles(
    createStyles({
        root: {
            marginTop: '1px',
        },
        tabsContainer: {
            display: 'flex',
            [`@media (max-width:${mobileScreenWidth}) `]: {
                display: 'block',
            },
        },
        tabsSearchContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: '#FAFAFC',
            borderBottom: 'solid 1px #d8d8dd',
            alignItems: 'flex-end',
            paddingBottom: '8px',
            '& > *': {
                marginRight: '26px',
                width: '309px',
                display: 'flex',
                alignItems: 'center',
                [`@media (max-width:${mobileScreenWidth}) `]: {
                    width: '100%',
                    marginLeft: '26px',
                    marginTop: '8px',
                },
            },
        },
        paginationSteppersContainer: {
            width: '100%',
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
)

export interface FunctionalAccessProps {
    assigneeId: any
    subjectId: any
}

const FunctionalAccess: FC<FunctionalAccessProps> = ({
    assigneeId,
    subjectId,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    let minimumRowsPerPage
    const [activeTab, setActiveTab] = useState('subjects')
    const [perPage, setPerPage] = useState(FUNCTIONAL_ACCESS_PAGE_SIZE)
    const [totalNumberOfPages, setTotalNumberOfPages] = useState<number>(0)

    const [searchTerm, setSearchTerm] = useState('')
    const [page, setPage] = useState(1)

    const handlePageChange = (value: any) => {
        setPage(value)
    }

    if(FUNCTIONAL_ACCESS_PAGE_SIZE < 10){
        minimumRowsPerPage = FUNCTIONAL_ACCESS_PAGE_SIZE
    }

    // Using isfecthging instead of isLoading as isLoaidng value in not getting changes after first time load
    const { latestData: assigneeFunctionalAccess, isFetching } =
        useRisksFunctionalAccess(
            assigneeId,
            (page - 1) * perPage,
            perPage,
            searchTerm,
        )

    const {
        latestData: subjectFunctionalAccess,
        isFetching: isFetchingFunctional,
    } = useRisksFunctionalAccess(
        subjectId,
        (page - 1) * perPage,
        perPage,
        searchTerm,
    )

    useEffect(() => {
        if (
            assigneeFunctionalAccess?.totalCount ||
            subjectFunctionalAccess?.totalCount
        ) {
            setTotalNumberOfPages(
                Math.ceil(
                    (activeTab === 'subjects'
                        ? subjectFunctionalAccess?.totalCount
                        : assigneeFunctionalAccess?.totalCount) / perPage,
                ),
            )
        }
    }, [
        assigneeFunctionalAccess?.totalCount,
        subjectFunctionalAccess?.totalCount,
        perPage,
        activeTab,
    ])

    return (
        <TabsContainerBox>
            <Box className={classes.root}>
                <Box className={classes.tabsContainer}>
                    <PillTabs
                        value={activeTab}
                        onChange={(_, newValue: string) => {
                            setPerPage(FUNCTIONAL_ACCESS_PAGE_SIZE)
                            setTotalNumberOfPages(0)
                            setPage(1)
                            setSearchTerm('')
                            setActiveTab(newValue)
                        }}
                    >
                        <Tab
                            label={
                                <TabLabel
                                    label={t('SubjectsFunctionalAccess')}
                                    count={subjectFunctionalAccess?.totalCount}
                                />
                            }
                            value={'subjects'}
                        />
                        <Tab
                            label={
                                <TabLabel
                                    label={t('ItemFunctionalAccess')}
                                    count={assigneeFunctionalAccess?.totalCount}
                                />
                            }
                            value={'functional'}
                        />
                    </PillTabs>
                    <Box className={classes.tabsSearchContainer}>
                        <Search
                            outlined
                            placeholder={t('Search')}
                            value={searchTerm}
                            handleClear={() => setSearchTerm('')}
                            handleSearch={(value: any) => {
                                setSearchTerm(value)
                            }}
                        />
                    </Box>
                </Box>
                <Box>
                    {activeTab === 'subjects' && (
                        <FunctionalAccessTable
                            latestData={subjectFunctionalAccess}
                            isFetching={isFetching}
                        />
                    )}
                    {activeTab === 'functional' && (
                        <FunctionalAccessItemList
                            latestData={assigneeFunctionalAccess}
                            isFetching={isFetchingFunctional}
                        />
                    )}
                    {!isFetching && (
                        <Box className={classes.paginationSteppersContainer}>
                            <Pagination
                                count={totalNumberOfPages}
                                size="large"
                                page={page}
                                onChange={(_: any, value: any) =>
                                    handlePageChange(value)
                                }
                                parentIndex={199}
                                showPageSelection={true}
                                onPageSelection={(val: any) => setPage(val)}
                                minimumRowsPerPage={minimumRowsPerPage}
                                showItemsPerPageSelection={true}
                                onItemsPerPageChange={(val: any) => {
                                    setPage(1)
                                    setPerPage(val)
                                }}
                                itemsPerPage={perPage}
                                showCountStat={true}
                                totalCount={
                                    activeTab === 'subjects'
                                        ? subjectFunctionalAccess?.totalCount
                                        : assigneeFunctionalAccess?.totalCount
                                }
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </TabsContainerBox>
    )
}

export default FunctionalAccess

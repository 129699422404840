import React, { useState, Fragment } from 'react'
import { Box, Grid, Typography, styled, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Divider, StatusIndicator, Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { ACTION_TYPES, FIELD_TYPE_COMPONENTS_TYPES } from 'packages/core'
import { FIELD_TYPES_SELECTION_RULE } from '../../../core'

const FieldTypeValuesAssignmentInfo = ({
    fieldTypeValueJson,
    showExpanded = false,
    infoPopup = false,
}) => {
    const parsedObject = Array.isArray(fieldTypeValueJson)
        ? fieldTypeValueJson[0]
        : fieldTypeValueJson
    const isSplitValues =
        parsedObject?.SplitBusinessRequestApprovalPerFieldTypeValue || false
    const { t } = useTranslation()
    const [showMore, setShowMore] = useState(isSplitValues || showExpanded)

    const bindFieldTypes = () => {
        const modifiedField = getCombinedFields(parsedObject)
        // TODO: Uncomment below when we need to show only modified values
        // modifiedField = modifiedField.filter((x) => x.isModified)

        return (
            <Fragment>
                <Grid
                    item
                    xs={12}
                    style={{
                        marginBottom: '1rem',
                    }}
                >
                    {modifiedField.map((field, index) => (
                        <DynamicFieldAdditionalLabelValue
                            item={field}
                            isLastItem={index === modifiedField.length - 1}
                            key={`field-type-${index}`}
                            isSplitValues={isSplitValues}
                            infoPopup={infoPopup}
                        />
                    ))}
                </Grid>
            </Fragment>
        )
    }
    return (
        <>
            <Divider />

            <Box
                sx={(theme) => ({
                    padding: `${!infoPopup ? '0 1.6rem' : '0'}`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '0.1rem solid #d8d8d8 !important',
                    width: '100% !important',
                    '& div': {
                        fontSize: '1.8rem',
                    },
                    ...(showExpanded
                        ? {}
                        : {
                              background: `linear-gradient(90deg, rgba(246,246,248,1) 0%, rgba(244,244,246,1) 0%, rgba(238,103,35,1) 0%, rgba(23,154,170,0.95) 0%, rgba(23,129,188,0.95) 100%),repeating-linear-gradient(${theme?.palette?.common?.white}, ${theme?.palette?.common?.white} 3px, rgba(0, 0, 0, 0.02) 3px, rgba(0, 0, 0, 1.02) 4px)`,
                              '& div': {
                                  fontSize: '1.4rem',
                                  color: `${theme?.palette?.common?.white} !important`,
                                  borderColor: `${theme?.palette?.common?.white} !important`,
                              },
                              '& span': {
                                  color: `${theme?.palette?.common?.white} !important`,
                              },
                          }),
                })}
            >
                <HorizontalOrVertical
                    padding={'1.6rem 0'}
                    label={t('Common_SelectedFieldTypeValues')}
                />
                {!showExpanded && (
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        fontSize="1.2rem"
                        fontWeight="bold"
                    >
                        <ExpandButton
                            component="span"
                            display="flex"
                            onClick={() => setShowMore(!showMore)}
                        >
                            {showMore ? t('Hide') : t('Show')}
                            <ExpandIcon>{showMore ? '-' : '+'}</ExpandIcon>
                        </ExpandButton>
                    </Box>
                )}
            </Box>
            {showMore && (
                <Box display="flex" marginTop="1.2rem">
                    <Grid container spacing={1}>
                        {bindFieldTypes()}
                    </Grid>
                </Box>
            )}
        </>
    )
}

const Attribute = ({ label, value, ...rest }) => {
    const theme = useTheme()

    return (
        <Box {...rest}>
            <Box
                color={theme?.palette?.grey?.[900]}
                fontSize="1.2rem"
                style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}
            >
                {label}
            </Box>
            <Box fontSize="1.4rem">{value}</Box>
        </Box>
    )
}

const DynamicFieldAdditionalLabelValue = ({
    item,
    isSplitValues,
    isLastItem,
    infoPopup,
    ...rest
}) => {
    const { t } = useTranslation()
    const fieldTypeswithValue = []
    item?.fieldTypes?.forEach((field) => {
        const validField = getFieldTypeWithValue(field)
        if (validField) {
            fieldTypeswithValue.push(validField)
        }
    })

    const bindArrayValues = (assignedValues) => {
        const addedValues = assignedValues.filter(
            (x) => x.ActionType === ACTION_TYPES.ADDED,
        )
        const deletedValues = assignedValues.filter(
            (x) => x.ActionType === ACTION_TYPES.DELETED,
        )

        const updatedValues = assignedValues.filter(
            (x) => x.ActionType === ACTION_TYPES.UPDATED,
        )

        const noChangeValues = assignedValues.filter(
            (x) => x.ActionType === ACTION_TYPES.NO_CHANGE || !x.ActionType,
        )

        const addedValuesLabels = addedValues
            .map((item) => item?.FriendlyName || item?.ValueName)
            .filter((x) => x)

        const removedValuesLabels = deletedValues
            .map((item) => item?.FriendlyName || item?.ValueName)
            .filter((x) => x)

        const updatedValuesLabels = updatedValues
            .map((item) => item?.FriendlyName || item?.ValueName)
            .filter((x) => x)

        const noChangeValuesLabels = noChangeValues
            .map((item) => item?.FriendlyName || item?.ValueName)
            .filter((x) => x)

        return (
            <MultiselectContainer>
                {bindValueName(
                    'Common_Added',
                    addedValuesLabels,
                    removedValuesLabels?.length ||
                        updatedValuesLabels?.length ||
                        noChangeValuesLabels?.length,
                )}
                {bindValueName(
                    'Common_Deleted',
                    removedValuesLabels,
                    addedValuesLabels?.length ||
                        updatedValuesLabels?.length ||
                        noChangeValuesLabels?.length,
                )}
                {bindValueName(
                    'Common_Updated',
                    updatedValuesLabels,
                    removedValuesLabels?.length ||
                        addedValuesLabels?.length ||
                        noChangeValuesLabels?.length,
                )}
                {bindValueName(
                    'Unchanged',
                    noChangeValuesLabels,
                    removedValuesLabels?.length ||
                        updatedValuesLabels?.length ||
                        addedValuesLabels?.length,
                )}
            </MultiselectContainer>
        )
    }

    const bindValueName = (labelKey, values, showLabel) => {
        if (values?.length) {
            return (
                <Box
                    display="flex"
                    style={{
                        gap: '1rem',
                    }}
                >
                    {showLabel ? <label>{`${t(labelKey)}: `}</label> : null}
                    <Typography> {values.join(', ')}</Typography>
                </Box>
            )
        }
        return null
    }

    const getFieldValueType = (field) => {
        try {
            return field.AzFieldTypeSelectionRuleTypeID ===
                FIELD_TYPES_SELECTION_RULE.MULTISELECT
                ? 'AssignedValues'
                : 'AssignedValue'
        } catch (err) {}
    }

    const isFieldModified = (field) => {
        return (
            field?.isModified ||
            field?.ActionType === ACTION_TYPES.FIXED ||
            false
        )
    }

    const getChangeLabel = (field) => {
        const valueType =
            field?.AzFieldTypeSelectionRuleTypeID ===
            FIELD_TYPES_SELECTION_RULE.MULTISELECT
                ? 'AssignedValues'
                : 'AssignedValue'
        const fieldValue = field[valueType]
        if (
            Array.isArray(fieldValue) &&
            fieldValue?.length &&
            !field?.AssignedAllValuesRange
        ) {
            const isAdded = fieldValue.find(
                (x) => x.ActionType === ACTION_TYPES.ADDED,
            )
            const isDeleted = fieldValue.find(
                (x) => x.ActionType === ACTION_TYPES.DELETED,
            )
            const isFixed = fieldValue.find(
                (x) => x.ActionType === ACTION_TYPES.FIXED,
            )
            const isUpdated = fieldValue.find(
                (x) => x.ActionType === ACTION_TYPES.UPDATED,
            )
            const isModified = (isAdded && isDeleted) || isUpdated
            if (isModified) {
                return 'Common_Updated'
            }
            return isAdded
                ? 'Common_Added'
                : isDeleted
                ? 'Common_Deleted'
                : isFixed
                ? 'Common_Fixed'
                : ''
        }
        return `Common_${field?.ActionType}`
    }

    const getChangeLabelVariant = (field) => {
        const valueType =
            field?.AzFieldTypeSelectionRuleTypeID ===
            FIELD_TYPES_SELECTION_RULE.MULTISELECT
                ? 'AssignedValues'
                : 'AssignedValue'
        const fieldValue = field[valueType]
        if (
            Array.isArray(fieldValue) &&
            fieldValue?.length &&
            !field?.AssignedAllValuesRange
        ) {
            const isAdded = fieldValue.find(
                (x) => x.ActionType === ACTION_TYPES.ADDED,
            )
            const isDeleted = fieldValue.find(
                (x) => x.ActionType === ACTION_TYPES.DELETED,
            )
            const isFixed = fieldValue.find(
                (x) => x.ActionType === ACTION_TYPES.FIXED,
            )
            const isUpdated = fieldValue.find(
                (x) => x.ActionType === ACTION_TYPES.UPDATED,
            )
            const isModified = (isAdded && isDeleted) || isUpdated
            if (isModified || isFixed) {
                return 'Open_PendingApproval'
            }
            return isAdded
                ? 'Approved'
                : isDeleted
                ? 'Rejected'
                : isFixed
                ? 'Fixed'
                : ''
        }

        return field?.ActionType === ACTION_TYPES.ADDED
            ? 'Approved'
            : field?.ActionType === ACTION_TYPES.UPDATED ||
              field?.ActionType === ACTION_TYPES.FIXED
            ? 'Open_PendingApproval'
            : 'Rejected'
    }

    if (!fieldTypeswithValue?.length) {
        return null
    }

    const bindRangeValue = (field) => {
        const fromVal =
            field?.ActionType === ACTION_TYPES.DELETED
                ? field?.oldValue?.oldFrom
                : field?.AssignedValueFrom
        const toValue =
            field?.ActionType === ACTION_TYPES.DELETED
                ? field?.oldValue?.oldTo
                : field?.AssignedValueTo
        return (
            <Fragment>
                <DataRange>
                    <Box color="#3b454d" fontSize="1.3rem">
                        <label>{`${t('Common_From')}:`}</label> {fromVal}
                    </Box>
                    <Box color="#3b454d" fontSize="1.3rem">
                        <label>{`${t('Common_To')}:`}</label> {toValue}
                    </Box>
                </DataRange>
            </Fragment>
        )
    }

    const bindFixedValue = (field) => {
        try {
            if (field?.ActionType === ACTION_TYPES.DELETED) {
                return field?.oldValue?.oldValue
            }
            return field?.[getFieldValueType(field)]?.ValueName
        } catch (err) {}
    }

    const bindFreeTextMultiValue = (assignedValue) => {
        if (!assignedValue?.length) {
            return null
        }

        const filteredValues = assignedValue.filter(
            (x) => x?.FriendlyName || x?.ValueName,
        )
        const addedValues = filteredValues.filter(
            (x) => x.ActionType === ACTION_TYPES.ADDED,
        )

        const deletedValues = filteredValues.filter(
            (x) => x.ActionType === ACTION_TYPES.DELETED,
        )

        const updatedValues = filteredValues.filter(
            (x) => x.ActionType === ACTION_TYPES.UPDATED,
        )

        const unChangedValues = filteredValues.filter(
            (x) => x.ActionType === ACTION_TYPES.NO_CHANGE || !x.ActionType,
        )

        const fixedValues = filteredValues.filter(
            (x) => x.ActionType === ACTION_TYPES.FIXED,
        )

        return (
            <Fragment>
                {bindFreeTextValueNames(
                    'Common_Added',
                    addedValues,
                    updatedValues?.length ||
                        deletedValues?.length ||
                        unChangedValues?.length,
                )}
                {bindFreeTextValueNames(
                    'Common_Updated',
                    updatedValues,
                    addedValues?.length ||
                        deletedValues?.length ||
                        unChangedValues?.length,
                )}
                {bindFreeTextValueNames(
                    'Common_Deleted',
                    deletedValues,
                    updatedValues?.length ||
                        addedValues?.length ||
                        unChangedValues?.length,
                )}
                {bindFreeTextValueNames(
                    'Unchanged',
                    unChangedValues,
                    updatedValues?.length ||
                        deletedValues?.length ||
                        addedValues?.length,
                )}
                {fixedValues?.length ? (
                    <Box>
                        {fixedValues.map((item, index) => (
                            <Fragment key={`free-text-field-fixed-${index}`}>
                                {bindKeyValuePair(item)}
                            </Fragment>
                        ))}
                    </Box>
                ) : null}
            </Fragment>
        )
    }

    const bindFreeTextValueNames = (labelKey, values, showLabel) => {
        if (values?.length) {
            return (
                <Box
                    sx={{
                        marginBottom: showLabel ? '.5rem' : '0',
                    }}
                >
                    {showLabel ? (
                        <Box
                            sx={{
                                '& label': {
                                    color: '#9b9b9b',
                                    textTransform: 'uppercase',
                                    fontWeight: '600',
                                    fontSize: '1.2rem',
                                },
                            }}
                        >
                            <label>{`${t(labelKey)}:`}</label>
                        </Box>
                    ) : null}
                    {values.map((item, index) => (
                        <Fragment key={`free-text-field-updated-${index}`}>
                            {bindKeyValuePair(item)}
                        </Fragment>
                    ))}
                </Box>
            )
        } else {
            return null
        }
    }

    const bindKeyValuePair = (item) => {
        return (
            <FreeTextMultiValue>
                <Box
                    color="#3b454d"
                    fontSize="1.3rem"
                    sx={{
                        display: 'flex',
                        maxWidth: '150px',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <label>{`${t('Common_Label')}:`}</label>{' '}
                    <Box>
                        <Tooltip title={item?.FriendlyName}>
                            <Box
                                sx={{
                                    maxWidth: '10rem !important',
                                    width: '100%',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {item?.FriendlyName}
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
                <Box
                    color="#3b454d"
                    fontSize="1.3rem"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <label>{`${t('Common_Value')}:`}</label>{' '}
                    <Box
                        sx={{
                            '& .MuiBox-root': {
                                width: 'fit-content !important',
                            },
                        }}
                    >
                        <Tooltip title={item?.ValueName}>
                            <Box
                                sx={{
                                    maxWidth: '10rem',
                                    width: '100%',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {item?.ValueName}
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </FreeTextMultiValue>
        )
    }
    const isRange = item?.SelectionRuleType === 'Range'
    return (
        <Box
            {...rest}
            sx={{
                width: '100% !important',
                display: 'flex',
                flexDirection: 'column',
                padding: `${!infoPopup ? '0 1.6rem' : '0'}`,
            }}
        >
            <ContainerBox>
                <Box
                    sx={{
                        display: 'flex',
                        borderBottom: '0.1rem solid #efeff1 !important',
                        alignItems: 'center',
                        paddingBottom: '0.5rem',
                    }}
                >
                    <span>
                        <label
                            style={{
                                fontSize: '1.4rem',
                                color: '#5d6870',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {`${t('Common_AppRight')}`}:
                        </label>
                    </span>
                    <FieldHead
                        sx={{
                            width: 'fit-content !important',
                        }}
                    >
                        {item?.displayName}
                    </FieldHead>
                </Box>

                <Icon name="dynamicForm" height="4rem" width="4rem" />
            </ContainerBox>

            <Box
                sx={{
                    display: 'flex',
                    borderBottom: '0.1rem solid #efeff1',
                    alignItems: 'center',
                    paddingBottom: '0.7rem',
                    paddingTop: '.7rem',
                    gap: '2rem',
                }}
            >
                <Typography
                    sx={{
                        width: '20rem',
                        color: '#919193',
                        fontSize: '1.2rem',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                    }}
                >
                    {t('Common_FieldType')}
                </Typography>
                <Typography
                    sx={{
                        color: '#919193',
                        fontSize: '1.2rem',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                    }}
                >
                    {t('Common_FieldTypeValues')}
                </Typography>
            </Box>
            {fieldTypeswithValue?.map((field, index) => (
                <Fragment key={`fieldtypes-${index}`}>
                    <RootWrapper>
                        <Tooltip
                            title={`${field?.Name} (${
                                field?.FieldTypeScope || ''
                            })`}
                        >
                            <FieldName className='fieldType-label'>
                                {`${field?.Name}`}
                                <FieldScopeName>{`(${
                                    field?.FieldTypeScope || '-'
                                })`}</FieldScopeName>
                            </FieldName>
                        </Tooltip>
                        <FieldData isRange={isRange}>
                            {/* Case range */}
                            {field?.SelectionRuleType === 'Range' ? (
                                <Fragment>
                                    {field?.SelectionRule ===
                                        FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE ||
                                    field?.SelectionRule ===
                                        FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_RANGE ? (
                                        bindRangeValue(field)
                                    ) : (
                                        <Fragment>
                                            <Box fontSize="1.3rem">
                                                {bindFixedValue(field)}
                                            </Box>
                                        </Fragment>
                                    )}
                                </Fragment>
                            ) : field?.SelectionRule ===
                                  FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUE ||
                              field?.SelectionRule ===
                                  FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_MULTI_VALUES ||
                              field?.SelectionRule ===
                                  FIELD_TYPE_COMPONENTS_TYPES.FIXED_LIST ||
                              field?.SelectionRule ===
                                  FIELD_TYPE_COMPONENTS_TYPES.PERSON_RELATIVE ? (
                                <Fragment>
                                    {bindFreeTextMultiValue(
                                        field?.[getFieldValueType(field)],
                                    )}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {typeof field?.[
                                        getFieldValueType(field)
                                    ] === 'string' ? (
                                        <Box fontSize="1.3rem">
                                            {field?.ActionType ===
                                            ACTION_TYPES.DELETED
                                                ? field?.oldValue?.oldValue
                                                : field?.[
                                                      getFieldValueType(field)
                                                  ]?.FriendlyName ||
                                                  field?.[
                                                      getFieldValueType(field)
                                                  ]?.ValueName}
                                        </Box>
                                    ) : Array.isArray(
                                          field?.[getFieldValueType(field)],
                                      ) || field?.AssignedAllValuesRange ? (
                                        <Fragment>
                                            {field?.AssignedAllValuesRange ? (
                                                <MultiselectContainer>
                                                    <Typography>
                                                        {t(
                                                            'Common_AllPossibleValuesSelected',
                                                        )}
                                                    </Typography>
                                                </MultiselectContainer>
                                            ) : (
                                                bindArrayValues(
                                                    field?.[
                                                        getFieldValueType(field)
                                                    ],
                                                )
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Box fontSize="1.3rem">
                                            {field?.ActionType ===
                                            ACTION_TYPES.DELETED
                                                ? field?.oldValue?.oldValue
                                                : field?.[
                                                      getFieldValueType(field)
                                                  ]?.FriendlyName ||
                                                  field?.[
                                                      getFieldValueType(field)
                                                  ]?.ValueName}
                                        </Box>
                                    )}
                                </Fragment>
                            )}
                        </FieldData>
                        {isFieldModified(field) && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flex: '1',
                                    height: 'fit-content',
                                }}
                            >
                                <StatusIndicator
                                    label={t(getChangeLabel(field))}
                                    size="small"
                                    variant={getChangeLabelVariant(field)}
                                />
                            </Box>
                        )}
                    </RootWrapper>
                    {!isLastItem && <Divider />}
                    {isLastItem && index !== item?.fieldTypes?.length - 1 && (
                        <Divider />
                    )}
                </Fragment>
            ))}
        </Box>
    )
}

const getCombinedFields = (dataObject) => {
    let updatedAppRights = []
    try {
        const appRights = dataObject?.Rights || []
        if (appRights?.length) {
            //To check if App Role
            appRights.forEach((element) => {
                const fieldTypes = element?.FieldType || []
                const modifiedField = {
                    displayName: element?.DisplayName,
                    isModified: fieldTypes.find((x) => x.isModified)
                        ? true
                        : false,
                    fieldTypes: fieldTypes,
                }
                if (fieldTypes?.length) {
                    updatedAppRights.push(modifiedField)
                }
            })
        } else {
            // Else is for app Right
            const appRightsFields = dataObject?.FieldType || []
            const modifiedField = {
                displayName: dataObject?.DisplayName,
                isModified: appRightsFields.find((x) => x.isModified)
                    ? true
                    : false,
                fieldTypes: appRightsFields,
            }
            if (appRightsFields?.length) {
                updatedAppRights.push(modifiedField)
            }
        }
    } catch (err) {}
    return updatedAppRights
}

const getFieldTypeWithValue = (field) => {
    try {
        const valueType =
            field?.AzFieldTypeSelectionRuleTypeID ===
            FIELD_TYPES_SELECTION_RULE.MULTISELECT
                ? 'AssignedValues'
                : 'AssignedValue'
        const value = field[valueType]
        if (field?.isModified) {
            return field
        }
        if (field?.AssignedAllValuesRange) {
            return field
        }
        if (
            field?.SelectionRuleType === 'Range' &&
            (field?.SelectionRule ===
                FIELD_TYPE_COMPONENTS_TYPES.FREE_TEXT_RANGE ||
                field?.SelectionRule ===
                    FIELD_TYPE_COMPONENTS_TYPES.FIXED_RANGE)
        ) {
            if (field.AssignedValueFrom || field.AssignedValueTo) {
                return field
            }
        } else if (typeof value === 'string') {
            if (value) {
                return field
            }
        } else if (Array.isArray(value)) {
            if (value?.length) {
                return field
            }
        } else {
            if (value?.ValueName) {
                return field
            } else if (value) {
                return field
            }
        }
    } catch (err) {}
    return null
}

export default FieldTypeValuesAssignmentInfo

const ContainerBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 !important',
    '& .MuiBox-root': {
        width: '100% !important',
    },
}))

const FieldHead = styled(Box)(() => ({
    fontSize: '1.4rem',
    color: '#000',
    marginLeft: '.8rem',
    fontWeight: 'bold',
    marginRight: '1.6rem',
    lineHeight: '3.4rem',
    maxWidth: '20rem',
    '& label': {
        fontSize: '1.6rem',
        fontWeight: '500',
    },
}))

const RootWrapper = styled(Box)(() => ({
    display: 'flex',
    gap: '2rem',
    padding: '1.2rem 0',
    position: 'relative',
    '& .MuiBox-root': {
        width: '20rem ',
    },
}))

const FieldName = styled(Box)(() => ({
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#9b9b9b',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
    height: 'fit-content',
    '&.fieldType-label':{
        maxWidth: '20rem !important',
    }
}))
const DataRange = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    '& label': {
        fontSize: '1.2rem',
        fontWeight: 'bold ',
        color: `${theme.palette.grey[500]} !important`,
        marginRight: '0.8rem',
        textTransform: 'uppercase',
    },
}))

const FreeTextMultiValue = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '2rem',
    width: '100%',
    marginBottom: '.6rem',
    '& label': {
        fontSize: '1rem',
        fontWeight: 'bold ',
        color: `${theme.palette.grey[500]} !important`,
        marginRight: '0.8rem',
        textTransform: 'uppercase',
    },
}))

const MultiselectContainer = styled(Box)(() => ({
    maxWidth: '35rem',
    '& label': {
        color: '#9e9e9e',
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        fontWeight: '600',
    },
    '& p': {
        color: '#3b454d',
        fontSize: '1.3rem',
    },
}))

const FieldScopeName = styled(Box)(() => ({
    fontSize: '1rem !important',
    marginLeft: '.4rem',
    textTransform: 'none',
    display: 'inline',
}))

const ExpandButton = styled(Box)(() => ({
    textTransform: 'uppercase',
    zIndex: 9999,
    marginTop: '0.2rem',
    color: '#307fc1',
    fontSize: '1.2rem',
    '&:hover': {
        cursor: 'pointer',
    },
}))
const ExpandIcon = styled(Box)(({ theme }) => ({
    width: '1.8rem',
    height: '1.8rem',
    marginLeft: '0.8rem',
    marginTop: '-0.2rem',
    borderRadius: '50%',
    border: `0.1rem solid ${theme?.palette?.primary?.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme?.palette?.primary?.main,
    lineHeight: '0',
    fontSize: '1.5rem',
}))
const HorizontalOrVertical = styled(Attribute)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1.6rem 0',
    '& div': {
        fontWeight: '600',
        color: theme?.palette?.common?.black,
    },

    '& > div:last-child': {
        marginLeft: '1.6rem',
    },

    '@media (max-width:660px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '& > div:last-child': {
            marginLeft: 0,
        },
    },
}))

const FieldData = styled(Box)(({ isRange }) => ({
    ...(isRange && {
        display: 'block',
    }),
    fontSize: '1.4rem',
    color: '#000000',
    fontWeight: 'normal',
    display: 'flex',
    flexWrap: 'wrap',
}))

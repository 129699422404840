import { useGetPagesAccess, useGetControlsAccess } from 'packages/core'

const useSubcomponents = () => {
    const pages = useGetPagesAccess().map((p) => p.name)
    const controls = useGetControlsAccess().map((c) => c.name)

    const hasAccessToControl = (controlName) => {
        return !controlName
            ? true
            : controls.includes(controlName)
            ? controlName
            : ''
    }

    const hasAccessToPage = (controlName) => {
        return pages.includes(controlName) ? controlName : ''
    }

    const hasAccessToRequestViewMyRequestsPage = hasAccessToPage(
        'MyTasks-RequestViewMyRequests-Page',
    )
    const hasAccessToItemViewMyRequestsPage = hasAccessToPage(
        'MyTasks-ItemViewMyRequests-Page',
    )

    const hasAccessToWorkflowsMyRequestsPage = hasAccessToPage(
        'MyTasks-WorkflowViewMyRequests-Page',
    )
    const hasAccessToWorkflowsMyTasksPage = hasAccessToPage(
        'MyTasks-WorkflowViewMyTasks-Page',
    )
    const hasAccessToWorkflowsAllRequestsPage = hasAccessToPage(
        'MyTasks-WorkflowViewAllRequests-Page',
    )

    const hasAccessToWorkflowClaimControl = hasAccessToControl(
        'MyTasks-WorkflowtDetailsClaim-Control',
    )

    const hasAccessToWorkflowDelegateControl = hasAccessToControl(
        'MyTasks-WorkflowDetailsDelegate-Control',
    )

    const hasAccessToRequestViewMyTasksPage = hasAccessToPage(
        'MyTasks-RequestViewMyTasks-Page',
    )
    const hasAccessToItemViewMyTasksPage = hasAccessToPage(
        'MyTasks-ItemViewMyTasks-Page',
    )

    const hasAccessToRequestViewAllRequestsPage = hasAccessToPage(
        'MyTasks-RequestViewAllRequests-Page',
    )
    const hasAccessToItemViewAllRequestsPage = hasAccessToPage(
        'MyTasks-ItemViewAllRequests-Page',
    )

    const trimFiltersBasedOnAccess = (filtersList) =>
        filtersList.filter((f) => hasAccessToControl(f.requireAccess))

    const hasAccessToRequestProcessStepsControl = hasAccessToControl(
        'MyTasks-RequestDetailsProcessSteps-Control',
    )
    const hasAccessToRequestCurrentStepApproversControl = hasAccessToControl(
        'MyTasks-RequestDetailsCurrentStepApprovers-Control',
    )
    const hasAccessToRequestClaimControl = hasAccessToControl(
        'MyTasks-RequestDetailsClaim-Control',
    )
    const hasAccessToRequestDelegateControl = hasAccessToControl(
        'MyTasks-RequestDetailsDelegate-Control',
    )

    const hasAccessToRequestItemProcessStepsControl = hasAccessToControl(
        'MyTasks-RequestItemDetailsProcessSteps-Control',
    )
    const hasAccessToRequestItemCurrentStepApproversControl =
        hasAccessToControl(
            'MyTasks-RequestItemDetailsCurrentStepApprovers-Control',
        )
    const hasAccessToRequestItemClaimControl = hasAccessToControl(
        'MyTasks-RequestItemDetailsClaim-Control',
    )
    const hasAccessToRequestItemDelegateControl = hasAccessToControl(
        'MyTasks-RequestItemDetailsDelegate-Control',
    )

    const hasAccessToAdminAssignTaskDelegateControl = hasAccessToControl(
        'MyTasks-AdminAssignTaskDelegate-Control',
    )
    const hasAccessToAssignTaskDelegateControl = hasAccessToControl(
        'MyTasks-AssignTaskDelegate-Control',
    )
    const hasAccessToAdminAddAdditionalApproversDelegateControl =
        hasAccessToControl(
            'MyTasks-AdminAddAdditionalApproversDelegate-Control',
        )
    const hasAccessToAddAdditionalApproversDelegateControl = hasAccessToControl(
        'MyTasks-AddAdditionalApproversDelegate-Control',
    )
    const hasAccessToPermanentlyDelegateStepControl = hasAccessToControl(
        'MyTasks-PermanentlyDelegateStepDelegate-Control',
    )
    const hasAccessToOutOfOfficeDelegateControl = hasAccessToControl(
        'MyTasks-OutOfOfficeDelegate-Control',
    )
    const hasAccessToDelegationsHistoryControl = hasAccessToControl(
        'MyTasks-ManualDelegations-Control',
    )
    const hasAccessToFunctionalAccessControl = hasAccessToControl(
        'MyTasks-FunctionalAccessTab-Control',
    )

    const hasAccessDeleteCommentControl = hasAccessToControl(
        'MyTasks-DeleteComment-Control',
    )

    const hasAccessEditCommentControl = hasAccessToControl(
        'MyTasks-EditComment-Control',
    )

    const hasAccessToAdminCancelRequestControl = hasAccessToControl(
        'MyTasks-AdminCancelRequest-Control',
    )
    const hasAccessToAdminCancelRequestItemControl = hasAccessToControl(
        'MyTasks-AdminCancelRequestItem-Control',
    )
    const hasAccessToCancelRequestControl = hasAccessToControl(
        'MyTasks-CancelRequest-Control',
    )
    const hasAccessToCancelRequestItemControl = hasAccessToControl(
        'MyTasks-CancelRequestItem-Control',
    )
    const hasAccessToRefreshViolationsControl = hasAccessToControl(
        'MyTasks-RefreshViolations-Control',
    )
    const hasAccessToRiskMitigationEndDateControl = hasAccessToControl(
        'MyTasks-RiskMitigationEndDate-Control',
    )
    const hasAccessToFulfillmentScheduleDateControl = hasAccessToControl(
        'MyTasks-FulfillmentScheduleDate-Control',
    )
    const hasAccessToEditFulfillmentScheduleDateControl = hasAccessToControl(
        'MyTasks-EditFulfillmentScheduleDate-Control',
    )

    const canSeeRiskViolationsByItems = hasAccessToControl(
        'MyTasks-RiskViolationsByItems-Control',
    )

    const canSeeRiskViolationsByRisks = hasAccessToControl(
        'MyTasks-RiskViolationsByRisks-Control',
    )
    const canSeeEIDProfileLink = hasAccessToControl(
        'MyTasks-PersonSubjectType-EIDLink-Control',
    )

    const canSeeToDoListTimeConstraint = hasAccessToControl(
        'MyTasks-ToDoItem-TimeConstraint-Control',
    )

    const canSeeRiskViolationsByFunctions = hasAccessToControl(
        'MyTasks-RiskViolationsByFunctions-Control',
    )

    return {
        hasAccessToRequestViewMyRequestsPage,
        hasAccessToItemViewMyRequestsPage,
        hasAccessToWorkflowsMyRequestsPage,

        hasAccessToRequestViewMyTasksPage,
        hasAccessToItemViewMyTasksPage,
        hasAccessToWorkflowsMyTasksPage,

        hasAccessToRequestViewAllRequestsPage,
        hasAccessToItemViewAllRequestsPage,
        hasAccessToWorkflowsAllRequestsPage,

        hasAccessToWorkflowDelegateControl,
        hasAccessToWorkflowClaimControl,

        trimFiltersBasedOnAccess,

        hasAccessToRequestProcessStepsControl,
        hasAccessToRequestCurrentStepApproversControl,
        hasAccessToRequestClaimControl,
        hasAccessToRequestDelegateControl,

        hasAccessToRequestItemProcessStepsControl,
        hasAccessToRequestItemCurrentStepApproversControl,
        hasAccessToRequestItemClaimControl,
        hasAccessToRequestItemDelegateControl,

        hasAccessToAdminAssignTaskDelegateControl,
        hasAccessToAssignTaskDelegateControl,
        hasAccessToAdminAddAdditionalApproversDelegateControl,
        hasAccessToAddAdditionalApproversDelegateControl,
        hasAccessToPermanentlyDelegateStepControl,
        hasAccessToOutOfOfficeDelegateControl,
        hasAccessToDelegationsHistoryControl,
        hasAccessToFunctionalAccessControl,

        hasAccessDeleteCommentControl,
        hasAccessEditCommentControl,

        hasAccessToAdminCancelRequestControl,
        hasAccessToAdminCancelRequestItemControl,
        hasAccessToCancelRequestControl,
        hasAccessToCancelRequestItemControl,

        hasAccessToRefreshViolationsControl,

        hasAccessToRiskMitigationEndDateControl,

        hasAccessToFulfillmentScheduleDateControl,
        hasAccessToEditFulfillmentScheduleDateControl,

        canSeeRiskViolationsByItems,
        canSeeRiskViolationsByRisks,
        canSeeEIDProfileLink,
        canSeeToDoListTimeConstraint,
        canSeeRiskViolationsByFunctions,
        hasAccessToControl,
    }
}

export default useSubcomponents

import React from 'react'
import Tabs from './Tabs'
import ActionButton from './ActionButton'

const ItemDetails = () => {
    return <></>
}

ItemDetails.Tabs = Tabs
ItemDetails.ActionButton = ActionButton

export default ItemDetails

import React from 'react'
import { AssignTaskForm, Modal } from 'components'
import { useCurrentPerson } from 'hooks'
import { useTranslation } from 'react-i18next'

const WorkflowDelegateForms = ({
    isAssigning,
    assign,
    isClaiming,
    claim,
    setModalState,
    modalState,
    workflowId,
    addApprover,
    addingApprover
}) => {
    const { data: currentPerson } = useCurrentPerson()
    const { t } = useTranslation()
    const showModal = modalState === 'AssignTask' || modalState === 'AddAdditionalApprover'
    const modalTitle = modalState === 'AssignTask' ? 'AssignThisTask' : 'AddAdditionalApprovers'
    const assignBtnLabel = modalState === 'AssignTask' ? 'AssignThisTask' : 'AddAdditionalApprover'
    return (
        <>
            {showModal  && (
                <Modal
                    open={showModal}
                    onClose={() => setModalState('')}
                    title={t(modalTitle)}
                >
                    <AssignTaskForm
                        onSave={(values) => {
                            if (values && values.person) {
                                const personId = values.person.id
                                const data = {
                                    personId,
                                    workflowTaskId: workflowId,
                                }
                                if(modalState === 'AssignTask'){
                                    if (personId === currentPerson.id) {
                                        claim().then(() => setModalState(''))
                                    } else {
                                        assign(data).then(() => setModalState(''))
                                    }
                                }else{
                                    addApprover(data).then(() => setModalState(''))
                                }
                            }
                        }}
                        isSaving={isAssigning || isClaiming || addingApprover}
                        onCancel={() => setModalState('')}
                        disableAdvancedSearch={true}
                        assignBtnLabel={assignBtnLabel}
                    />
                </Modal>
            )}
        </>
    )
}
export default WorkflowDelegateForms

import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FUNCTIONAL_ACCESS_PAGE_SIZE } from 'utils'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'packages/eid-ui'
import { useRisksFunctionalAccess } from 'hooks'
import { Search } from 'components'
import { smallScreenWidth } from 'utils'
import { CollapseableSectionContainer } from 'components'
import FunctionalAccessTable from './FunctionalAccessTable'

const useStyles = makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#282828',
        fontSize: '16px',
        fontWeight: 'bold',
        paddingLeft: '32px',
        height: '60px',
        textTransform: 'uppercase',
        '& .MuiChip-root': {
            height: '20px',
            color: '#ffffff',
            fontWeight: 'normal',
            fontSize: '14px',
            backgroundColor: '#8b909a',
            marginLeft: '8px',
        },
    },
    headGrey: {
        color: '#7d7c7c !important',
    },
    searchContainer: {
        width: '340px',
        maxWidth: '340px',
        float: 'right',
        marginBottom: '5px',
        [`@media (max-width:${smallScreenWidth})`]: {
            maxWidth: '340px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto',
        },
    },
    table: {
        backgroundColor: '#ffffff',
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: '#307fc1 !important',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
    paginationSteppersContainer: {
        width: '100%',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

export interface FunctionalAccessItemProps {
    assigneeId: string
}

const FunctionalAccessRequest: React.FC<FunctionalAccessItemProps> = ({
    assigneeId,
}) => {
    const classes = useStyles()
    let minimumRowsPerPage
    const [totalNumberOfPages, setTotalNumberOfPages] = useState<number>(0)
    const { t } = useTranslation()
    const [perPage, setPerPage] = useState(FUNCTIONAL_ACCESS_PAGE_SIZE)

    if(FUNCTIONAL_ACCESS_PAGE_SIZE < 10){
        minimumRowsPerPage = FUNCTIONAL_ACCESS_PAGE_SIZE
    }

    const [searchTerm, setSearchTerm] = useState('')
    const [page, setPage] = useState(1)

    const handlePageChange = (value: any) => {
        setPage(value)
    }
    const handleSearch = (val: string) => {
        setSearchTerm(val)
    }
    const handlePageSelection = (value: any) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value: any) => {
        setPage(1)
        setPerPage(value)
    }
    // Using isfecthging instead of isLoading as isLoaidng value in not getting changes after first time load
    const { latestData, isFetching } = useRisksFunctionalAccess(
        assigneeId,
        (page - 1) * perPage,
        perPage,
        searchTerm,
    )

    // Why cant we move API call in newly built Table Component? There are 3-4 instances in which this table is being used and will be used in future but the
    // problem is in every screen it has different search bars and we need to show/hide search bars on some conditions and also the count we need to show even before we open the tab and thats why we have to make API call
    // for each components separately. Moreover, the parent style in each component is also different.
    useEffect(() => {
        if (latestData?.totalCount) {
            setTotalNumberOfPages(Math.ceil(latestData.totalCount / perPage))
        }
    }, [latestData?.totalCount, perPage])

    const hideSearch =
        latestData && latestData.data?.length === 0 && searchTerm.length === 0

    return (
        <CollapseableSectionContainer
            label={t('SubjectsFunctionalAccess')}
            count={latestData?.totalCount}
        >
            <Box>
                {!hideSearch && (
                    <Box className={classes.searchContainer}>
                        <Search
                            outlined
                            placeholder={t('Search')}
                            value={searchTerm}
                            handleClear={(value: string) => {
                                handlePageChange(1)
                                handleSearch(value)
                            }}
                            handleSearch={(value: string) => {
                                if (searchTerm !== value) {
                                    handlePageChange(1)
                                    handleSearch(value)
                                }
                            }}
                        />
                    </Box>
                )}

                <FunctionalAccessTable
                    latestData={latestData}
                    isFetching={isFetching}
                />

                {!isFetching && (
                    <Box className={classes.paginationSteppersContainer}>
                        <Pagination
                            count={totalNumberOfPages}
                            size="large"
                            page={page}
                            onChange={(_: any, value: any) =>
                                handlePageChange(value)
                            }
                            parentIndex={199}
                            showPageSelection
                            onPageSelection={handlePageSelection}
                            minimumRowsPerPage={minimumRowsPerPage}
                            itemsPerPage={perPage}
                            onItemsPerPageChange={handleItemsPerPageChange}
                            showItemsPerPageSelection
                            showCountStat
                            totalCount={latestData?.totalCount}
                        />
                    </Box>
                )}
            </Box>
        </CollapseableSectionContainer>
    )
}

export default FunctionalAccessRequest

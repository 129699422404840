import { Box } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import IconContainer from './IconContainer'
import IconLabel from './IconLabel'

const EscalatedIcon = ({ label }) => {
    return (
        <Box display="flex" alignItems="center">
            <IconContainer bgcolor="#9230c5">
                <Icon name="Escalate" color="#ffffff" />
            </IconContainer>
            <IconLabel style={{ color: '#9230c5' }}>{label}</IconLabel>
        </Box>
    )
}

export default EscalatedIcon

import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as ArrowIcon } from './arrow.svg'
import { ReactComponent as VerticalArrowIcon } from './vertical-arrow.svg'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'

const smallScreenWidth = 959

const useStyles = makeStyles(
    createStyles({
        root: {
            position: 'relative',
            paddingTop: '4px',
            display: 'flex',
            alignItems: 'flex-start',

            [`@media (max-width:${smallScreenWidth}px)`]: {
                paddingLeft: '16px',
                flexDirection: 'column',
                marginTop: '8px',
            },
        },

        reasonArrowVertical: {
            position: 'absolute',
            left: 0,
            top: 15,

            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
        },

        reasonArrow: {
            padding: '0px 8px',
            [`@media (max-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
        },

        reasonLabel: {
            minWidth: '90px',
            color: '#5d6870',
            fontSize: '12px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            textAlign: 'right',
            paddingTop: '4px',
            [`@media (max-width:${smallScreenWidth}px)`]: {
                textAlign: 'left',
                minWidth: '50px',
            },
        },
        reasonValueContainer: {
            display: 'flex',
        },
        reasonTagContainer: {
            minWidth: '140px',
            paddingLeft: '16px',
        },
        reasonTag: {
            backgroundColor: '#ffffff',
            fontSize: '12px',
            fontWeight: 'bold',
            padding: '2px 8px',
            textTransform: 'uppercase',
            color: '#3b454d',
            border: '1px solid #aab0b4',
            borderRadius: '5px',
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
        },
        reasonValue: {
            display: 'flex',
        },
        reasonLabelAndTagBox: {
            display: 'flex',
            [`@media (max-width:${smallScreenWidth}px)`]: {
                marginBottom: '8px',
            },
        },
        textWrap: {
            wordBreak: 'break-word',
            wordWrap: 'break-word',
            overflow: 'auto',
        },
    }),
)

const RiskReason = ({ risk, requestId }: { risk: any; requestId: string }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    let left: any
    let right: any
    if (risk.riskDetailsInversed) {
        left = risk.segregatedRiskDetails
        right = risk.riskDetails
    } else {
        left = risk.riskDetails
        right = risk.segregatedRiskDetails
    }

    const renderRiskReason = () => {
        const isADifferentRequest = requestId !== right.requestId

        if (right.riskReason === 'BusinessRequest') {
            if (isADifferentRequest) {
                return (
                    <a
                        target={'_blank'}
                        rel="noreferrer"
                        href={`?id=${right.requestId}`}
                    >
                        {right.riskFunctionAssignee} (
                        {right.requestNumber
                            ? right.requestNumber
                            : 'Unknown Number'}
                        )
                    </a>
                )
            } else {
                return <Fragment>{right.riskFunctionAssignee}</Fragment>
            }
        }
        return <Fragment>{right.riskFunctionAssignee}</Fragment>
    }

    const renderRiskReasonLabel = () => {
        const isADifferentRequest = requestId !== left.requestId

        if (left.riskReason === 'RequestAccess') return 'RequestAccess'
        else if (left.riskReason === 'Membership') return 'Membership'
        else if (isADifferentRequest) return 'BusinessRequest'
        else return 'ThisRequest'
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.reasonArrowVertical}>
                <VerticalArrowIcon />
            </Box>

            <Box className={classes.reasonLabelAndTagBox}>
                <Box className={classes.reasonLabel}>{t('Source')}</Box>
                <Box className={classes.reasonTagContainer}>
                    <Box className={classes.reasonTag}>
                        {t(renderRiskReasonLabel())}
                    </Box>
                </Box>
            </Box>

            {risk.riskType === 'SegregationOfDuties' && right && (
                <Box className={classes.reasonValueContainer}>
                    <Box className={classes.reasonArrow}>
                        <ArrowIcon />
                    </Box>
                    <Box className={classes.reasonValue}>
                        <Box
                            component={'span'}
                            marginLeft={'8px'}
                            fontSize={'14px'}
                            className={classes.textWrap}
                        >
                            {renderRiskReason()}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default RiskReason

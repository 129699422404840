import { styled, Tab as MuiTab } from '@mui/material'

const Tab = styled(MuiTab)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 'fit-content',
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: '0.8rem',
    marginRight: '0.8rem',
    '&.Mui-selected': {
        fontWeight: 'bold',
        color: theme.palette.common.black,
    },
    '&:hover': {
        color: theme.palette.primary.main,
        opacity: 1,
    },
}))

export default Tab

import { Box } from '@mui/material'

const ProgressBar = (props) => {
    const { completed, height, label, showPercentage, showAnimation } = props

    const containerStyles = {
        height: height,
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '8px',
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        boxShadow: '0 2px 8px 0 rgba(46, 192, 124, 0.33)',
        backgroundImage: 'linear-gradient(103deg, #01ae8f, #30c17c)',
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: showAnimation ? 'width 1s ease-in-out' : 'none',
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
    }

    return (
        <Box>
            <Box style={containerStyles}>
                <Box style={fillerStyles}>
                    {showPercentage && (
                        <span style={labelStyles}>{`${Math.round(
                            completed,
                        )}%`}</span>
                    )}
                </Box>
            </Box>
            {label && label.length && (
                <Box
                    style={{
                        fontSize: '14px',
                        color: '#282828',
                        marginTop: '16px',
                        width: 'max-content',
                    }}
                >
                    {label}
                </Box>
            )}
        </Box>
    )
}

export default ProgressBar

import React from 'react'
import { Icon } from 'packages/eid-icons'
import IconContainer from './IconContainer'

const LoaderIcon = (props) => {
    const { active } = props
    return (
        <IconContainer bgcolor={active ? '#307fc1' : '#d8d8dd'}>
            <Icon name="HourGlassLoader" color="#ffffff" />
        </IconContainer>
    )
}

export default LoaderIcon

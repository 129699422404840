import { ChangeEvent } from 'react'
import { Tabs as MuiTabs } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

export type PillTabsProps = {
    children?: React.ReactNode
    value: string | number
    onChange: (event: ChangeEvent<{}>, newValue: string) => void
}

export const PillTabs = withStyles({
    root: {
        backgroundColor: '#fbfbfd',
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            backgroundColor: 'transparent',
            width: '100%',
        },
    },
    flexContainer: {
        borderBottom: 'solid 1px #d8d8dd',
        backgroundColor: '#fbfbfd !important',
        paddingTop: '30px',
        paddingLeft: '20px',
    },
})((props: PillTabsProps) => (
    <MuiTabs
        {...props}
        TabIndicatorProps={{ children: <span /> }}
        variant="scrollable"
    />
))

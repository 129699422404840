import { styled } from "@mui/material";
import PropTypes from "prop-types";
import { ReactComponent as CheckOutSvg } from "./check-out.svg";

const orientationAngle = {
  up: "270",
  right: "0",
  down: "90",
  left: "180",
};

const CheckOut = styled(CheckOutSvg)(({ theme, color, direction }) => ({
  transform: `rotate(${orientationAngle[direction]}deg)`,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

CheckOut.propTypes = {
  color: PropTypes.string,
  direction: PropTypes.oneOf(["left", "right", "up", "down"]),
  fill: PropTypes.string,
};

CheckOut.defaultProps = {
  color: "#000000",
  direction: "down",
  fill: "none",
};

export default CheckOut;

import React from 'react'
import Button from './index'
import { Icon } from 'packages/eid-icons'

const styles = {
    root: {
        minWidth: '100px',
        minHeight: '25px',
        border: '1px solid',
        padding: '1px 8px',
        fontSize: '12px',
        fontWeight: 'bold',
        textTransform: 'upper-case',
        '& span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    default: {
        backgroundColor: '#e7f4ff',
        border: '1px solid  #307fc1',
        color: '#307fc1',
        '& svg': {
            color: '#307fc1',
        },

        '&:hover': {
            backgroundColor: '#e7f4ff',
        },
    },
    DecideAll: {
        backgroundColor: '#307fc1',
        border: '1px solid  #307fc1',
        color: '#ffffff',
        '& svg': {
            color: '#ffffff',
        },

        '&:hover': {
            backgroundColor: '#307fc1',
        },
    },
    Continue: {
        backgroundColor: '#e1fee6 ',
        border: '1px solid #1eb77b',
        color: '#00bb75',
        fontWeight: 'bold',
        '& svg': {
            color: '#00bb75',
        },
        '&:hover': {
            backgroundColor: '#e1fee6 ',
        },
    },
    End: {
        backgroundColor: '#f9dada',
        border: '1px solid  #d0021b',
        color: '#d0021b',
        fontWeight: 'bold',
        '& svg': {
            color: '#d0021b',
        },
        '&:hover': {
            backgroundColor: '#f9dada',
        },
    },
    Certify: {
        backgroundColor: '#f0def9',
        border: '1px solid  #9230c5',
        color: '#9230c5',
        fontWeight: 'bold',
        '& svg': {
            color: '#9230c5',
        },
        '&:hover': {
            backgroundColor: '#f0def9',
        },
    },
    'Convert to JIT': {
        backgroundColor: '#f8eadc',
        border: '1px solid  #ff7e00',
        color: '#ff7e00',
        fontWeight: 'bold',
        '& svg': {
            color: '#ff7e00',
        },
        '&:hover': {
            backgroundColor: '#f8eadc',
        },
    },
    disabled: {
        backgroundColor: '#aab0b4 !important',
        color: '#ffffff !important',
    },
}

const DecisionButton = ({ endIcon, active, icon, variant, ...rest }) => {
    // let variantStyles = styles[variant] ? { ...styles[variant] } : {}
    return (
        <Button
            size="small"
            style={{ ...styles.root, ...styles[variant] }}
            disabledStyles={styles.disabled}
            startIcon={
                !endIcon &&
                (icon ? (
                    icon
                ) : (
                    <Icon
                        name="Arrow"
                        color="#ffffff"
                        width="14px"
                        height="14px"
                    />
                ))
            }
            endIcon={
                endIcon &&
                (icon ? (
                    icon
                ) : (
                    <Icon
                        name="Arrow"
                        color="#ffffff"
                        width="14px"
                        height="14px"
                    />
                ))
            }
            {...rest}
        />
    )
}

export default DecisionButton

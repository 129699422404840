import { Box, FormControl, InputBase, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import classNames from 'classnames'
import { Tooltip } from 'packages/eid-ui'
import React, { useState } from 'react'

const StyledInput = withStyles((theme) => ({
    root: {},
    input: {
        borderRadius: 4,
        position: 'relative',
        fontSize: 15,
        padding: '0 0 0 10px',
        '&:-ms-input-placeholder': {
            opacity: `${1} !important`,
            color: '#b4b4b4 !important',
        },
    },

    focused: {
        backgroundColor: theme?.palette?.common?.white,
    },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        padding: '12px',
        backgroundColor: `{${theme?.palette?.background?.paper}} !important`,
        border: 'solid 1px #ebebed',
        transition: 'border 0.15s ease-in-out 0s',

        '&:focus-within': {
            borderColor: theme?.palette?.primary?.main,
        },
    },
    margin: {},
    validationError: {
        '& input': {
            border: 'solid 1px red !important',
        },
        '& label': {
            color: 'red !important',
        },
    },
    errorField: {
        border: '1px solid #d0021b !important',
    },
    disabledField: {
        '& .Mui-disabled': {
            WebkitTextFillColor: '#5d6870',
        },
    },
}))

const InputWithInnerLabel = ({
    label,
    placeholder,
    value,
    handleChange,
    validationError = null,
    errorMessage = '',
    disabled = false,
    showTooltip = false,
    ...rest
}) => {
    const classes = useStyles()
    const [showErrors, setShowErrors] = useState(false)
    return (
        <FormControl
            className={classNames(classes.root, {
                [classes.validationError]: showErrors && validationError,
                [classes.errorField]: Boolean(errorMessage),
                [classes.disabledField]: disabled,
            })}
        >
            {label && (
                <InputLabel
                    style={{
                        textTransform: 'uppercase',
                        color: '#b4b4b4',
                        position: 'inherit',
                        fontSize: '12px',
                        transform: 'none',
                    }}
                >
                    {label}
                </InputLabel>
            )}
            <Tooltip title={showTooltip && value ? value : ''}>
                <StyledInput
                    value={value}
                    onChange={handleChange}
                    onBlur={() => {
                        if (!value) {
                            setShowErrors(true)
                        } else {
                            setShowErrors(false)
                        }
                    }}
                    disabled={disabled}
                    {...rest}
                />
            </Tooltip>
        </FormControl>
    )
}

export default InputWithInnerLabel

import React from 'react'
import { InputBaseProps, InputBase } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export interface StyleProps {
    fontColor?: string
    color?: string
    tooltipStyles: {}
}

const useStyles = makeStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(2),
        },
    },

    input: () => ({
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.6rem',
        padding: ' 1.1rem 1rem',
        position: 'relative',
        backgroundColor: theme?.palette?.background?.paper,
        border: '1px solid #ebebed',
        transition: theme?.transitions?.create(['border-color', 'box-shadow']),
        color: 'theme?.palette?.common?.black',

        margin: 'auto',
        borderRadius: '0.4rem',

        '&:hover': {
            borderColor: `${theme?.palette?.primary?.main} !important`,
        },
        '&:focus': {
            borderColor: 'theme?.palette?.primary?.main',
            boxShadow: '0 0.2rem 0.4rem 0 rgba(48, 127, 193, 0.15)',
            borderRadius: '0.4rem !important',
            backgroundColor: 'theme?.palette?.common?.white',
        },
    }),
}))

export interface InputProps extends InputBaseProps {}

export const Input: React.FC<InputProps> = ({ placeholder, ...rest }) => {
    const classes = useStyles()

    return <InputBase classes={classes} fullWidth {...rest} />
}

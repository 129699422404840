import React from 'react'
import { styled, Box } from '@mui/material'
import { StatusIndicator, Tooltip } from 'packages/eid-ui'

const TagContainer = styled(Box)(({ style }) => ({
    backgroundColor: '#eef0f4',
    border: 'solid 1px #307fc1',
    borderRadius: '5px',

    padding: '0px 16px',

    minWidth: '144px',
    width: 'fit-content',

    minHeight: '36px',

    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    fontSize: '14px',
    color: '#307fc1',
    textTransform: 'capitalize',

    ...style,
}))

const IconContainer = styled(Box)({
    marginRight: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})
const indicatorStyles = {
    backgroundColor: '#5d6870',
    position: 'absolute',
    top: '-10px',
    left: '-5px',
    zIndex: 1,
    display: 'block',
    maxWidth: '130px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
}

const Tag = ({ label, icon, children, value, rootStyles, tagStyles }) => {
    return (
        <Box position="relative">
            <span>
                <StatusIndicator
                    label={label}
                    size="small"
                    style={{ ...indicatorStyles, ...tagStyles }}
                />
            </span>
            <Tooltip title={value ? value : ''} disableInteractive={false}>
                <TagContainer style={rootStyles}>
                    <IconContainer>{icon}</IconContainer>
                    {children ? children : value}
                </TagContainer>
            </Tooltip>
        </Box>
    )
}

export default Tag

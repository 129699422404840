import { CSSProperties, FC, Fragment, memo } from 'react'
import { Box, Typography, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { getStatusColor } from './utils'
import IconContainer from './Icons/IconContainer'
import { Icon as EidIcon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { IItemWithApprovalSteps } from 'types'
import { Divider, Tooltip } from 'packages/eid-ui'
import { ArrowIcon } from './Icons'
import Icon from './Icons/Icon'
import { Status } from './Status'
import moment from 'moment'
import { RESOURCE_TYPES, isDateInPast } from 'utils'

const useStyles = makeStyles(
    createStyles({
        root: {
            marginBottom: '12px',
            marginTop: '12px',
            padding: '8px',
            position: 'relative',
            '& *': {
                zIndex: 2,
            },
        },
    }),
)

const getStyles: (status: any) => CSSProperties = (status: any) => ({
    fontWeight: 'normal',
    color:
        status === 'Completed' ||
        status === 'Approved' ||
        status === 'Rejected' ||
        status === 'Canceled' ||
        status === 'Error'
            ? getStatusColor(status)
            : '#aab0b4',
    fontSize: '12px',
})

const getRequestItemStatusAndStep = (item: IItemWithApprovalSteps) => {
    const firstPendingStep = item.approvalSteps.find(
        (i: any) => i.status === 'Open',
    )

    let status
    let steps = item.approvalSteps
    if (
        item.status === 'Completed' ||
        item.status === 'Approved' ||
        item.status === 'Rejected' ||
        item.status === 'Canceled' ||
        item.status === 'Error'
    ) {
        status = item.status
    } else if (firstPendingStep) {
        status = 'WaitingOn'
    } else if (steps.length > 0) {
        status = steps[0].status
    } else {
        status = item.status
    }

    const stepFriendlyName = firstPendingStep
        ? firstPendingStep.friendlyName
        : null

    return {
        status,
        stepFriendlyName,
    }
}

export type RequestItemNodeProps = {
    id: string
    item: IItemWithApprovalSteps
}

const leftMarginsMap = {
    0: '0',
    1: '56px',
    2: '112px',
}

const DateTimeContainer = styled(Box)({
    position: 'absolute',
    left: '-10px',
    bottom: '-32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
})

const DateTime = styled(Typography)({
    paddingLeft: '5px',
    color: '#5d6870',
    fontSize: '12px',
})

const RequestItemNode: FC<RequestItemNodeProps> = memo((props) => {
    const { id, children, item } = props

    const { t } = useTranslation()

    const itemStatusAndStep = getRequestItemStatusAndStep(item)

    const statusToUse = item.preApproved ? 'Approved' : itemStatusAndStep.status

    const showFulfillmentDate = Boolean(
        item?.processTimeUtc &&
            (statusToUse === 'Completed' || statusToUse === 'Approved'),
    )

    const showFulfillmentDelay = Boolean(
        !showFulfillmentDate && item?.status === 'Open' && item?.delayInHours,
    )

    const classes = useStyles()

    const tooltipStyles = {
        top: '5px',
        right: '70px',
    }

    return (
        <Fragment>
            <Box
                className={classes.root}
                marginLeft={leftMarginsMap[item.indentationLevel]}
            >
                <Box display="flex" alignItems="center">
                    <Box display="flex" width="100%">
                        <IconContainer id={id} style={styles.iconContainer}>
                            <EidIcon
                                name={
                                    item.resourceType === RESOURCE_TYPES.PERSON
                                        ? 'ViewProfile'
                                        : item.resourceType
                                }
                                style={styles.icon}
                            />
                        </IconContainer>

                        <Box paddingX="2rem" width="max-content">
                            <Attribute
                                isText={!item.itemTypeActionFriendlyName}
                                label={
                                    item.itemTypeActionFriendlyName ? (
                                        <Box>
                                            <Tooltip
                                                tooltipStyles={tooltipStyles}
                                                placement="top"
                                                arrow={true}
                                                title={item.friendlyName}
                                            >
                                                <Box
                                                    color={getStatusColor(
                                                        itemStatusAndStep.status,
                                                    )}
                                                    fontWeight={'bold'}
                                                >
                                                    {item.title
                                                        ? item.title
                                                        : item.resourceRequested}
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    ) : item.title ? (
                                        item.title
                                    ) : (
                                        item.resourceRequested
                                    )
                                }
                                status={statusToUse}
                            />
                            {/* NOTE: hiding it temporarly as per ticket: MT-1571 */}
                            {/* {item.globalApprovalSkipped && (
                            <Attribute
                                isText={false}
                                label={
                                    <Box color="rgb(1, 174, 143)">
                                        {t('GlobalApprovalSkipped')}
                                    </Box>
                                }
                            />
                        )} */}
                            <Attribute
                                isText={true}
                                label={
                                    item.approvalSteps?.length === 0
                                        ? item.itemTypeActionFriendlyName
                                        : item.preApproved
                                        ? t('PreApproved')
                                        : statusToUse === 'NotReady'
                                        ? undefined
                                        : t(`${statusToUse}`)
                                }
                                value={
                                    statusToUse === 'Completed' ||
                                    statusToUse === 'Approved' ||
                                    statusToUse === 'Rejected' ||
                                    statusToUse === 'Canceled' ||
                                    statusToUse === 'Error'
                                        ? null
                                        : itemStatusAndStep.stepFriendlyName
                                }
                                fontSize={'1.4rem'}
                                status={statusToUse}
                            />

                            {showFulfillmentDate && (
                                <>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Typography
                                            style={{
                                                color: '#5d6870',
                                                fontWeight: 'bold',
                                                fontSize: '1.2rem',
                                                textTransform: 'uppercase',
                                                marginRight: '0.8rem',
                                            }}
                                        >
                                            {`${t('FulfillmentDate')}:`}
                                        </Typography>
                                        <EidIcon
                                            height="1.6rem"
                                            width="1.6rem"
                                            name="Clock"
                                            color="#5d6870"
                                        />

                                        <DateTime noWrap>
                                            {moment
                                                .utc(item.processTimeUtc)
                                                .local()
                                                .format('ll')}
                                        </DateTime>
                                        <Divider
                                            vertical
                                            style={{
                                                backgroundColor: '#5d6870',
                                                width: '1.6rem',
                                                height: '0.1rem',
                                            }}
                                        />
                                        <DateTime noWrap>
                                            {moment
                                                .utc(item.processTimeUtc)
                                                .local()
                                                .format('LTS')}
                                        </DateTime>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Typography
                                            style={{
                                                color: '#5d6870',
                                                fontWeight: 'bold',
                                                fontSize: '1.2rem',
                                                textTransform: 'uppercase',
                                                marginRight: '0.8rem',
                                            }}
                                        >
                                            {`${t('FulfillmentStatus')}:`}
                                        </Typography>

                                        <DateTime noWrap>
                                            {item.processStatus}
                                        </DateTime>
                                    </Box>
                                </>
                            )}

                            {showFulfillmentDelay && (
                                <Box display={'flex'} alignItems={'center'}>
                                    <Typography
                                        style={{
                                            color: '#5d6870',
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem',
                                            textTransform: 'uppercase',
                                            marginRight: '0.8rem',
                                        }}
                                    >
                                        {`${t('FulfillmentDelayedBy')}:`}
                                    </Typography>
                                    <EidIcon
                                        height="1.6rem"
                                        width="1.6rem"
                                        name="Clock"
                                        color="#5d6870"
                                    />

                                    <DateTime noWrap>
                                        {`${item.delayInHours} ${t('Hours')}`}
                                    </DateTime>
                                </Box>
                            )}
                        </Box>
                    </Box>

                    {item.approvalSteps?.length === 0 && (
                        <>
                            <ArrowIcon
                                variant={item.status}
                                style={{ margin: '0px 20px' }}
                                maxWidth="none"
                            />

                            <Box position="relative">
                                <Icon
                                    icon={item.status}
                                    label={
                                        <Status
                                            status={item.status}
                                            autoApprove={false}
                                        />
                                    }
                                />

                                {item.approvedOnUtc && (
                                    <DateTimeContainer>
                                        <EidIcon
                                            height="16px"
                                            width="16px"
                                            name="Clock"
                                            color="#aab0b4"
                                        />

                                        <DateTime noWrap>
                                            {moment
                                                .utc(item.approvedOnUtc)
                                                .local()
                                                .format('LLL')}
                                        </DateTime>
                                    </DateTimeContainer>
                                )}
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
            {children}
        </Fragment>
    )
})

const styles = {
    iconContainer: {
        backgroundImage: 'linear-gradient(135deg, #afbcc6, #668397)',
        boxShadow: '0 0px 8px 0 #6e6e6f',
    },
    icon: {
        color: '#ffffff',
        width: '20px',
        height: '20px',
    },
}

const Attribute: FC<any> = memo((props) => {
    const { label, value, isText, status, bold, fontSize } = props
    return (
        <Box display="flex" alignItems="center">
            {isText ? (
                <Typography
                    style={{
                        color: getStatusColor(status),
                        fontWeight: bold ? 'bold' : 'normal',
                        fontSize: fontSize ? fontSize : '14px',
                    }}
                >
                    {label}
                    {value && ':'}
                </Typography>
            ) : (
                label
            )}
            <Typography
                style={{
                    color: getStatusColor(status),
                    fontWeight: bold ? 'bold' : 'normal',
                    paddingLeft: '4px',
                    fontSize: fontSize ? fontSize : '14px',
                }}
            >
                {value}
            </Typography>
        </Box>
    )
})

export { RequestItemNode }

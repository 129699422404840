import React, { useState } from 'react'
import { usePaginatedQuery } from 'react-query'
import { Pagination } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'

const usePaginatedDetailsApi = (
    queryKey,
    queryFn,
    queryConfig,
    defaultTake = 16,
) => {
    const [{ subPage }, dispatch] = usePageContext()
    const [perPage, setPerPage] = useState(defaultTake)
    let minimumRowsPerPage
    const handlePageChange = (_, value) => {
        dispatch({
            type: 'SET_SUBPAGE',
            payload: value,
        })
    }

    if(defaultTake < 10){
        minimumRowsPerPage = defaultTake
    }

    const handlePageSelection = (value) => {
        dispatch({
            type: 'SET_SUBPAGE',
            payload: value,
        })
    }

    const skip = (subPage - 1) * perPage
    const take = perPage

    const { latestData, error, refetch } = usePaginatedQuery(
        [...queryKey, skip, take],
        () => queryFn(skip, take),
        queryConfig,
    )

    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    const pagination = !isLoading && (
        <Pagination
            count={numberOfPages}
            size="large"
            page={subPage}
            onChange={handlePageChange}
            parentIndex={9}
            showPageSelection={true}
            minimumRowsPerPage={minimumRowsPerPage}
            onPageSelection={handlePageSelection}
            showItemsPerPageSelection={true}
            onItemsPerPageChange={(val) => {
                dispatch({
                    type: 'SET_SUBPAGE',
                    payload: 1,
                })
                setPerPage(val)
            }}
            itemsPerPage={perPage}
            showCountStat={true}
            totalCount={latestData?.totalCount}
        />
    )

    return {
        loading: isLoading,
        list: latestData ? latestData.data : undefined,
        totalCount: latestData ? latestData.totalCount : undefined,
        pagination,
        error,
        refetch,
    }
}

export default usePaginatedDetailsApi

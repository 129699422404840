import moment from "moment";
import React, { Fragment, useState } from "react";
import { getMonth, getYear, getYears } from "./helper";
import "./styleSheets/years.css";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Years = ({ select, onChange }) => {
  const [year, setYear] = useState(2022);
  const selectedYears = getYear(select);
  const selectedMonth = getMonth(select);
  const selectMonth = (date) => onChange(date);
  const onScroll = (e) => {
    e.target.scroll({ top: 0, behavior: 'smooth' })
  }


  return (
    <div className={`years`}>
      <div className={`years-container`}>
        {getYears().map((tempYear) => (
          <Fragment key={tempYear}>
            <div className={`year`} onClick={(e) => { setYear(tempYear); onScroll(e) }}>
              <p>{tempYear}</p>
            </div>
            <div
              className={`year-months ${year === tempYear ? "show-month" : ""}`}
            >
              {months.map((tempMonth) => (
                <div
                  onClick={() =>
                   { selectMonth(
                      moment(
                        `${(months.indexOf(tempMonth) + 1).toString()}-01-${tempYear.toString()}`,"MM-DD-yyyy"
                      ).toDate()
                    )
                   
                  }
                  }
                  key={tempMonth}
                  className={`year-month ${
                    year === selectedYears
                      ? months[selectedMonth] === tempMonth
                        ? "active-year-month"
                        : ""
                      : ""
                  }`}
                >
                  <p>{tempMonth}</p>
                  
                </div>
              ))}
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Years;

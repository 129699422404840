import React, { useState } from 'react'
import { Icon } from 'packages/eid-icons'
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TimeConstraintDialog from '../TimeConstraintDialog'
import { useAccessRequestPolicy } from 'hooks'

const useStyles = makeStyles({
    iconButton: ({ expanded }) => ({
        marginBottom: '0px',
        borderRadius: '5px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        display: 'flex',
        marginLeft: '4px',
        marginRight: '4px',
        margin: 'auto',
        paddingLeft: '0px',
        paddingRight: '0px',
        minWidth: '60px',
        paddingTop:'5px',
        paddingBottom:'5px',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor: expanded ? '#efeff1' : 'inherit',
        '&:hover': {
            backgroundColor: '#efeff1 !important',
        },
    }),
})

const TimeConstraintButton = (props) => {
    const {refetch} = props;
    const [expanded, handleExpand] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = useStyles({ expanded })

    const hasAccessToRequestPolicy = useAccessRequestPolicy(
        props.item.requestPolicyId
    )
    
    return (
        <>
            {hasAccessToRequestPolicy ? (
                <Box>
                    <TimeConstraintDialog
                        anchorEl={anchorEl}
                        expanded={expanded}
                        data={props.item}
                        id={props.id}
                        requestPolicyData={hasAccessToRequestPolicy}
                        handleExpand={(e) => handleExpand(e)}
                        refetch={refetch}
                    />
                    <Box
                        onClick={(e) => {
                            handleExpand(!expanded)
                            setAnchorEl(e.currentTarget)
                        }}
                    >
                        <iconButton className={classes.iconButton}>
                            <Icon
                                name={'TimeConstraint'}
                                color="#307fc1"
                               
                            />
                        </iconButton>
                    </Box>
                </Box>
            ) : (
                <></>
            )}
        </>
    )
}

export default TimeConstraintButton

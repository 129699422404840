import { Fragment } from 'react'
import { Table, TableCell, TableHead, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { Trans, useTranslation } from 'react-i18next'
import { Skeleton } from '@mui/material'
import { isNilOrEmpty } from 'packages/core'
import { RiskIndicator } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { RESOURCE_TYPES } from 'utils'

const useStyles = makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#282828',
        fontSize: '16px',
        fontWeight: 'bold',
        paddingLeft: '32px',
        height: '60px',
        textTransform: 'uppercase',
        '& .MuiChip-root': {
            height: '20px',
            color: '#ffffff',
            fontWeight: 'normal',
            fontSize: '14px',
            backgroundColor: '#8b909a',
            marginLeft: '8px',
        },
    },
    headGrey: {
        color: '#7d7c7c !important',
    },

    table: {
        backgroundColor: '#ffffff',
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '210px',
        minWidth: 'fit-content',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        overflowWrap: 'break-word',
        maxWidth: '210px',
        minWidth: 'fit-content',

        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: '#307fc1 !important',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
})

export interface FunctionalAccessTableProps {
    latestData: any
    isFetching: any
}

const FunctionalAccessTable: React.FC<FunctionalAccessTableProps> = ({
    latestData,
    isFetching,
}) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const attributes = [
        {
            style: {
                width: '120px',
            },
            label: t('RiskLevel'),
            resolve: (item: any) => (
                <RiskIndicator
                    variant={item.riskLevel}
                    label={t(`${item.riskLevel}Risk`)}
                />
            ),
        },
        {
            name: 'functionTypeFriendlyName',
            label: t('FunctionType'),
        },
        {
            name: 'localFunctionFriendlyName',
            label: t('LocalFunction'),
        },

        {
            name: 'assigneePreviewName',
            label: t('Assignee'),
        },
        {
            name: 'azAssignmentType',
            label: t('HowAssigned'),
        },
        {
            name: 'sourceAssigneePreviewName',
            label: t('AssignedTo'),
        },
        {
            name: 'sourceAssigneeType',
            label: t('Type'),
            resolve: (item: any) => (
                <Icon name={bindIconName(item?.assigneeTypeKeyIcon)} />
            ),
        },
        {
            name: 'globalFunctionFriendlyName',
            label: t('GlobalFunction'),
        },
    ]

    const bindIconName = (resourceType: any) => {
        if (
            resourceType === RESOURCE_TYPES.GROUP ||
            resourceType === RESOURCE_TYPES.SETGROUP
        ) {
            return 'ApplicationRole'
        }
        if (resourceType === RESOURCE_TYPES.PERSON) {
            return 'ViewProfile'
        }

        return resourceType
    }

    const headings = attributes.map((a) => a.label)

    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow className={classes.tableRow}>
                    {headings.map((heading, index) => (
                        <TableCell
                            key={index}
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                        >
                            {heading}
                        </TableCell>
                    ))}
                </TableRow>
                {isFetching ? (
                    <TableRow>
                        <TableCell colSpan={headings.length + 2}>
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                height={64}
                            />
                        </TableCell>
                    </TableRow>
                ) : latestData?.data?.length === 0 ? (
                    <TableRow className={classes.tableRow} hover={false}>
                        <TableCell
                            className={classes.tableCell}
                            colSpan={headings.length + 2}
                            style={{ textAlign: 'center' }}
                        >
                            <Trans i18nKey="NoDataFound" />
                        </TableCell>
                    </TableRow>
                ) : (
                    <>
                        {latestData?.data?.map((item: any) => (
                            <Fragment key={item.id}>
                                <TableRow className={classes.tableRow}>
                                    {attributes.map((a, index) => (
                                        <TableCell
                                            key={`${item.id}${index}`}
                                            className={classes.tableCell}
                                            style={a.style}
                                        >
                                            {a.resolve
                                                ? a.resolve(item)
                                                : !isNilOrEmpty(item[a.name])
                                                ? item[a.name]
                                                : '-'}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </Fragment>
                        ))}
                    </>
                )}
            </TableHead>
        </Table>
    )
}

export default FunctionalAccessTable

import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    requiredLabel: {
        fontSize: '1rem',
        color: '#d0021b',
    },
})

const RequiredLabel = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <Typography className={classes.requiredLabel} variant="label">{`${t(
            'Common_Required',
        )} *`}</Typography>
    )
}

export default RequiredLabel

import React, { useState, useEffect } from 'react'
import {
    useWorkflowDelegate,
    useWorkflowClaim,
    useCurrentPerson,
    useWorkflowUnDelegate,
    useCanRemoveDelegateOperation,
    useAddAdditionalApproversToWorkFlow,
} from 'hooks'
import WorkflowDelegateForms from './WorkflowDelegateForms'
import { Dropdown, Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import Button from '../Button'
import {
    Box,
    MenuItem as MuiMenuItem,
    styled,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isNilOrEmpty } from 'packages/core'
import TextFormatter from 'components/TextFormatter'
import useSubcomponents from 'useSubcomponents'
import { defaultDataProtectedSubcomponent } from 'utils'

const assignThisTaskOption = {
    title: 'AssignThisTask',
    name: 'AssignTask',
    helpText: 'AssignTaskHelpText',
}

const unassignThisTaskOption = {
    title: 'UnassignThisTask',
    name: 'UnassignTask',
    helpText: 'UnassignTaskHelpText',
}

const reassignThisTaskOption = {
    title: 'ReassignThisTask',
    name: 'AssignTask',
    helpText: 'ReassignTaskHelpText',
}

const addAdditionalApprovelToTask = {
    title: 'AddAdditionalApprovers',
    name: 'AddAdditionalApprover',
    helpText: 'AddAdditionalApproverHelpText',
}

const MenuItem = styled(MuiMenuItem)({
    position: 'relative',
    borderRadius: '5px',
    color: '#919193',
    whiteSpace: 'normal !important',
    '&:hover': {
        color: '#000000 !important',
    },
    '& p': {
        paddingRight: '30px',
    },

    '& svg': {
        position: 'absolute',
        right: 16,
        transform: 'translate(50%, -50%)',
        height: '16px !important',
        width: '16px !important',
        color: '#919193',
        '&:hover': {
            color: '#307fc1',
        },
    },

    '& .Tooltip-icon-container': {
        position: 'absolute',
        right: 20,
        transform: 'translate(50%, -50%)',
        width: '16px !important',
    },
})

const styles = {
    rootMenu: {
        paddingRight: '0px',
        marginTop: '10px',
        width: '270px',
    },
    list: {
        padding: '0px 6px',
    },
}

const AssignedToIndicator = styled(Box)({
    position: 'absolute',
    backgroundColor: '#5d6870',
    top: '-10px',
    left: '-5px',
    zIndex: '1',
    display: 'block',
    maxWidth: '130px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#ffffff',
    borderRadius: '6px',
    fontSize: '13px',
    padding: '0px 8px',
    textTransform: 'uppercase',
})

const AssignedByTo = ({ assignedBy, assignedTo, active }) => {
    const { t } = useTranslation()
    const textColor = active ? '#ffffff' : '#307fc1'

    return (
        <>
            <span style={{ color: textColor }}>
                <TextFormatter value={assignedBy} maxCharacters={10} noWrap />
            </span>
            <span
                style={{
                    textTransform: 'lowercase',
                    padding: '0px 5px',
                }}
            >
                {t('To')}
            </span>

            <span style={{ color: textColor }}>
                <TextFormatter value={assignedTo} maxCharacters={10} noWrap />
            </span>
        </>
    )
}

const WorkflowDelegateMenuButton = ({
    disabled,
    workflowId,
    isApprover,
    delegatePersonId,
    delegatePersonFriendlyName,
    delegatorPersonFriendlyName,
    requestWorkflowGUID,
    protectSubcomponent = defaultDataProtectedSubcomponent
}) => {
    const { t } = useTranslation()
    const [modalState, setModalState] = useState('')

    const { data: currentPerson } = useCurrentPerson()

    const [claim, { isLoading: isClaiming }] = useWorkflowClaim(workflowId)
    const [addApprover, { isLoading: addingApprover }] = useAddAdditionalApproversToWorkFlow(workflowId, requestWorkflowGUID)
    const [isTaskUnAssignable, setIsTaskUnAssignable] = useState(false)
    const [unDelegate] = useWorkflowUnDelegate(workflowId)

    const [delegate, { isLoading: isAssigning }] = useWorkflowDelegate(
        workflowId,
    )
    const [evaluateCanRemoveDelegate] = useCanRemoveDelegateOperation()

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    const onChange = (option) => setModalState(option.name)

    const isAssigned = !isNilOrEmpty(delegatePersonId)
    const isAssignedToMe = delegatePersonId === currentPerson.id
    const assignedTo = delegatePersonFriendlyName

    const {
        hasAccessToAdminAssignTaskDelegateControl,
        hasAccessToAssignTaskDelegateControl,
    } = useSubcomponents()

    useEffect(() => {
        async function canRemoveDelegation() {
            if (delegatePersonId) {
                var res = await evaluateCanRemoveDelegate(delegatePersonId)

                setIsTaskUnAssignable(res?.data.hasAccess || false)
            }
        }
        canRemoveDelegation()
    }, [delegatePersonId, evaluateCanRemoveDelegate])

    let optionsToRender = []

    if (hasAccessToAdminAssignTaskDelegateControl) {
        if (isAssigned) {
            if (isTaskUnAssignable) {
                optionsToRender.push({...unassignThisTaskOption, protectSubcomponent: hasAccessToAdminAssignTaskDelegateControl})
            }
            optionsToRender.push({...reassignThisTaskOption, protectSubcomponent: hasAccessToAdminAssignTaskDelegateControl})
        } else {
            optionsToRender.push({...assignThisTaskOption, protectSubcomponent: hasAccessToAdminAssignTaskDelegateControl})
        }
       optionsToRender.push({...addAdditionalApprovelToTask, protectSubcomponent: hasAccessToAdminAssignTaskDelegateControl})
    } else {
        if (isApprover && hasAccessToAssignTaskDelegateControl) {
            if (isAssigned) {
                optionsToRender.push({...unassignThisTaskOption, protectSubcomponent: hasAccessToAssignTaskDelegateControl})
                optionsToRender.push({...reassignThisTaskOption, protectSubcomponent: hasAccessToAssignTaskDelegateControl})
            } else {
                optionsToRender.push({...assignThisTaskOption, protectSubcomponent: hasAccessToAssignTaskDelegateControl})
            }
            optionsToRender.push({...addAdditionalApprovelToTask, protectSubcomponent: hasAccessToAssignTaskDelegateControl})
        }
    }

    if (disabled) {
        optionsToRender = []
    }

    const isClickable = optionsToRender.length > 0

    return (
        <>
            <Box data-protectedsubcomponent={protectSubcomponent}>
                {isAssigned && assignedTo ? (
                    <Box position="relative">
                        <AssignedToIndicator>
                            {t('AssignedBy')}
                        </AssignedToIndicator>
                        <Button.Delegate
                            deactivate={!isClickable}
                            icon={
                                <Icon
                                    name="Approve"
                                    width="16px"
                                    height="16px"
                                    color={open ? '#ffffff' : '#01ae8f'}
                                />
                            }
                            active={open}
                            onClick={(e) => {
                                if (!isClickable) return
                                setOpen((prev) => !prev)
                                setAnchorEl(e.currentTarget)
                            }}
                        >
                            <AssignedByTo
                                assignedBy={delegatorPersonFriendlyName}
                                assignedTo={
                                    isAssignedToMe
                                        ? t('You')
                                        : assignedTo
                                }
                                active={open}
                            />
                        </Button.Delegate>
                    </Box>
                ) : (
                    isClickable && (
                        <Button.Delegate
                            active={open}
                            onClick={(e) => {
                                setOpen((prev) => !prev)
                                setAnchorEl(e.currentTarget)
                            }}
                        >
                            {t('Delegate')}
                        </Button.Delegate>
                    )
                )}

                {optionsToRender.length > 0 && (
                    <Dropdown
                        open={open}
                        anchorEl={anchorEl}
                        rootStyles={styles.rootMenu}
                        listStyles={styles.list}
                        handleClose={handleClose}
                        handleListKeyDown={handleListKeyDown}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {optionsToRender.map((option) => (
                            <MenuItem
                                key={option.name}
                                onClick={() => {
                                    if (option.name === 'UnassignTask') {
                                        unDelegate(workflowId).then(() => {
                                            setOpen(false)
                                        })
                                    } else {
                                        setOpen(false)
                                        onChange(option)
                                    }
                                }}
                                data-protectedsubcomponent={option.protectSubcomponent}
                            >
                                <Typography>{t(option.title)}</Typography>
                                <Tooltip title={t(option.helpText)}>
                                    <span className="Tooltip-icon-container">
                                        <Icon
                                            name="Info"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            </MenuItem>
                        ))}
                    </Dropdown>
                )}
            </Box>

            <WorkflowDelegateForms
                isAssigning={isAssigning}
                assign={delegate}
                isClaiming={isClaiming}
                claim={claim}
                setModalState={setModalState}
                modalState={modalState}
                workflowId={workflowId}
                addApprover={addApprover}
                addingApprover={addingApprover}
            />
        </>
    )
}
export default WorkflowDelegateMenuButton

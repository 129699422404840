import React from 'react'
import TreeListItem from './TreeItem'

const TreeSubItem = React.memo(
    ({ node, labelProp, onClick, selected, idProp, searchTerm }) => {
        return (
            <TreeListItem
                node={node}
                labelProp={labelProp}
                onClick={onClick}
                selected={selected}
                idProp={idProp}
                searchTerm={searchTerm}
            />
        )
    },
)

export default TreeSubItem

import React from 'react'
import { Box, Typography } from '@mui/material'
import { Avatar, Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { isNilOrEmpty } from 'packages/core'
import { getFullImageUrl } from 'utils'

const tooltipDelay = 800
const Attribute = ({ value, ...rest }) =>
    isNilOrEmpty(value) ? (
        <span style={{ color: '#9b9b9b' }}>-</span>
    ) : (
        <Tooltip
            title={value ? value : ''}
            placement="bottom-start"
            enterDelay={tooltipDelay}
            enterNextDelay={tooltipDelay}
        >
            <Typography {...rest} noWrap>
                {value}
            </Typography>
        </Tooltip>
    )

const styles = {
    name: {
        fontSize: '16px',
        color: '#307fc1',
        fontWeight: 'bold',
        maxWidth: '200px',
    },
    position: {
        textTransform: 'uppercase',
        color: '#9b9b9b',
        fontSize: '12px',
        fontWeight: 'bold',
        maxWidth: '200px',
    },
    emailPhone: {
        maxWidth: '200px',
        fontSize: '14px',
        color: '#307fc1',
    },
    borderBox: {
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSlice: 1,
        height: '1px',
        width: '292px',
        opacity: 0.25,
    },
}

const PersonDetails = ({
    imgSrc,
    size = 'small',
    name,
    position,
    email,
    phoneNumber,
}) => (
    <Box position="relative">
        <Box marginY="7.5px" display="flex" alignItems="center">
            <Box minWidth="40px" marginRight="10px">
                <Avatar
                    style={{ boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px' }}
                    size={size}
                    src={getFullImageUrl(imgSrc)}
                />
            </Box>
            <Box>
                <Attribute style={styles.name} value={name}></Attribute>

                <Tooltip
                    title={position ? position : ''}
                    placement="bottom-start"
                    enterDelay={tooltipDelay}
                    enterNextDelay={tooltipDelay}
                >
                    <Typography style={styles.position} noWrap>
                        {position}
                    </Typography>
                </Tooltip>
            </Box>
        </Box>
        <Box marginY="7.5px" display="flex" alignItems="center">
            <Box
                minWidth="40px"
                marginRight="10px"
                display="flex"
                justifyContent="center"
            >
                <Icon name="EmailRound" color="#E45887" />
            </Box>
            <Box>
                <Attribute style={styles.emailPhone} value={email}></Attribute>
            </Box>
        </Box>
        <Box marginY="7.5px" display="flex" alignItems="center">
            <Box
                minWidth="40px"
                marginRight="10px"
                display="flex"
                justifyContent="center"
            >
                <Icon name="PhoneRound" color="#01745F" />
            </Box>
            <Box>
                <Attribute
                    style={styles.emailPhone}
                    value={phoneNumber}
                ></Attribute>
            </Box>
        </Box>
    </Box>
)

const PersonCard = ({ person }) => {
    return (
        <>
            <Box
                width="301px"
                height="141px"
                padding="20px"
                borderRadius="8px"
                boxShadow="0 4px 16px 0 rgba(0, 0, 0, 0.05)"
                bgcolor="#ffffff"
                display="flex"
            >
                <PersonDetails
                    imgSrc={person.imageThumbUrl}
                    name={person.friendlyName}
                    position={person.position}
                    size="large"
                    email={person.email}
                    phoneNumber={person.telephone}
                />
            </Box>
            <Box width="301px" display="flex" alignItems="center">
                <Box style={styles.borderBox} />
            </Box>
        </>
    )
}

export default PersonCard

import { UserManager, WebStorageStateStore } from 'oidc-client'
import { isEmpty, isNil } from 'ramda'
import config from 'config'
import { refreshAccessToken } from './refreshAccessToken'

const bindUserStore = () => {
    try {
        return new WebStorageStateStore({ store: window.sessionStorage })
    } catch (err) {
        return {}
    }
}

const defaultUserManagerConfig = {
    redirect_uri: `${window.location.origin}/callback`,
    silent_redirect_uri: `${window.location.origin}/renew.html`,
    post_logout_redirect_uri: `${window.location.origin}${window.config.APP_SUBPATH}`,
    response_type: 'code',
    scope: 'openid profile email',
    filterProtocolClaims: true,
    loadUserInfo: false,
    automaticSilentRenew: false,
    accessTokenExpiringNotificationTime: 60,
    userStore: bindUserStore(),
    monitorSession: true,
}
const authSettings = {
    authority: config.AUTHORITY_URL,
    client_id: config.CLIENT_ID,
    scope: config.SCOPE,
    response_type: config.RESPONSE_TYPE ?? 'code',
}

const userManager = new UserManager({
    ...defaultUserManagerConfig,
    ...authSettings,
})

const userSignedOutEventHandler = () => {
    window.location.replace(`${window.location.origin}/signOut`)
}

const accessTokenExpiringEventHandler = async () => {
    refreshAccessToken(userManager)
}

userManager.events.addUserSignedOut(userSignedOutEventHandler)
userManager.events.addAccessTokenExpiring(() => {
    accessTokenExpiringEventHandler()
})

const getUserManager = () => {
    if (isEmpty(authSettings.client_id) || isNil(authSettings.client_id)) {
        throw new Error(
            'Client ID (prop: "client_id") is not provided while setting up AuthContextProvider.',
        )
    }
    if (isEmpty(authSettings.authority) || isNil(authSettings.authority)) {
        throw new Error(
            'Authority (prop: "authority") is not provided while setting up AuthContextProvider.',
        )
    }
    return userManager
}
export default getUserManager

import React from 'react'
import { ArrowIcon } from 'packages/eid-icons'
import { EidAutocomplete } from 'packages/eid-controls'
import { useTranslation } from 'react-i18next'
import config from 'config'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'person',
    cdnUrl: config.BASE_EID_URL,
})

const queryParams = {
    take: 10,
}

const getOptionLabel = (option) => option.friendlyName

const advancedSearchColumns = [
    { name: 'FriendlyName', type: 'string' },
    { name: 'Login', type: 'string' },
    { name: 'Email', type: 'string' },
]

function PeopleSearch({
    value,
    url = '/api/people',
    disableAdvancedSearch = false,
    ...rest
}) {
    const { t } = useTranslation()
    return (
        <EidAutocomplete.New
            label={t('SomeoneElse')}
            placeholder={t('SelectPerson')}
            url={url}
            queryParams={queryParams}
            dedupingInterval={60000}
            value={value ? value : null}
            getOptionLabel={getOptionLabel}
            endAdornmentComponent={() => (
                <ArrowIcon direction="right" color="#453fbb" />
            )}
            optionComponent={OptionComponent}
            advancedSearchColumns={
                disableAdvancedSearch ? [] : advancedSearchColumns
            }
            {...rest}
        />
    )
}

export default PeopleSearch

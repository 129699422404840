import { ISubjectType } from '../ISubjectType'
import { Filter, Details } from './Components'

class GenericSubjectType implements ISubjectType {
    name = 'GenericSubjectType'

    filterComponent = Filter

    detailComponent = Details
}

export default GenericSubjectType

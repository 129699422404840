import React, { useReducer } from 'react'
import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PeopleSearch from '../Filters/PeopleSearch'
import Button from '../Button'
import { isNilOrEmpty } from 'packages/core'
import { mobileScreenWidth } from 'utils'
import { useCurrentPerson } from 'hooks'

const styles = {
    peopleSearch: {
        '& > div': {
            border: 'solid 1px #ebebed',
            '&:hover': {
                borderColor: '#307fc1',
            },
            padding: '1.5px !important',
            '& > div': {
                right: '0px !important',
                pointerEvents: 'none',
            },
            '& > input': {
                height: '35px',
                cursor: 'pointer',
                width: 'auto !important',
            },
        },
    },

    cancelButton: {
        backgroundColor: '#ffffff !important',
        color: '#307fc1',
        boxShadow: 'none',
        border: '1px solid #307fc1',
        arrowDirection: 'right',
    },
}

const ItemContainer = styled(Box)({
    padding: '15px 0px',
    [`@media (max-width:${mobileScreenWidth})`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
})

const reducers = (state, { type, payload }) => {
    switch (type) {
        case 'SET_PERSON':
            return { ...state, person: payload }

        case 'SET_DELEGATE_FOREVER':
            return { ...state, delegateForever: payload }

        case 'CLEAR_STATE':
            return {
                ...payload,
            }
        default:
            return state
    }
}

const AssignTaskForm = ({
    onSave,
    isSaving,
    onCancel,
    url = '/api/people/approvers',
    assignBtnLabel = 'AssignThisTask',
    ...rest
}) => {
    const [state, dispatch] = useReducer(reducers, {
        person: null,
    })

    const { t } = useTranslation()

    const { data: currentPerson } = useCurrentPerson()

    return (
        <>
            <ItemContainer>
                <PeopleSearch
                    endAdornmentComponent={() => <></>}
                    label={t('PersonName')}
                    placeholder={t('SelectPerson')}
                    onChange={(_, value) =>
                        dispatch({
                            type: 'SET_PERSON',
                            payload: value,
                        })
                    }
                    url={url}
                    rootStyleProps={styles.peopleSearch}
                    value={state.person}
                    excludeOptions={[currentPerson.id]}
                    debounceInterval={700}
                    {...rest}
                />
            </ItemContainer>

            <ItemContainer
                display="flex"
                marginLeft="-8px"
                flexDirection="row !important"
                flexWrap="wrap"
            >
                <Button.Rounded
                    disabled={isNilOrEmpty(state.person) || isSaving}
                    isLoading={isSaving}
                    onClick={() => {
                        onSave(state)
                    }}
                    styles={{ arrowDirection: 'right' }}
                >
                    {t(assignBtnLabel)}
                </Button.Rounded>

                <Button.Rounded onClick={onCancel} styles={styles.cancelButton}>
                    {t('Cancel')}
                </Button.Rounded>
            </ItemContainer>
        </>
    )
}

export default AssignTaskForm

import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { isNilOrEmpty } from 'packages/core'
import MUIDateTimePicker from 'packages/eid-ui/DateTimePickers/MUIDateTimePicker/MUIDateTimePicker'

export const StartDateTimePicker = ({
    selectedStartDate,
    setSelectedStartDate,
    showWarningColor = false,
}) => {
    const { t } = useTranslation()

    return (
        <MUIDateTimePicker
            minDate={moment(new Date())}
            value={selectedStartDate ? moment(selectedStartDate) : null}
            handleChange={(e) => {
                if (isNilOrEmpty(e)) {
                    setSelectedStartDate(moment().utc().format())
                } else {
                    const date = moment(e)
                    const dateToSet = date.clone().utc().format()
                    setSelectedStartDate(dateToSet)
                }
            }}
            label={t('StartDateTime')}
            showWarningColor={showWarningColor}
        />
    )
}

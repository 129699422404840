import { Tab as MuiTab } from '@mui/material'
import { styled } from '@mui/material'

const Tab = styled(MuiTab)(({ theme }) => ({
    backgroundColor: '#f7f8fa',
    minWidth: '125px',
    position: 'relative',
    marginLeft: '-15px',
    marginRight: 0,
    maxHeight: '50px !important',
    opacity: 1,
    display: 'flex',
    transform: 'perspective(5px) rotateX(2deg)',
    transformOrigin: 'bottom left',
    borderRadius: '10px 10px 0 0',
    color: '#4a4a4a',
    '& svg': {
        color: '#4a4a4a',
        marginRight: '5px',
        marginBottom: '0px !important',
    },
    '&:hover': {
        '& svg': {},
    },
    '& .MuiTab-wrapper': {
        display: 'flex',
        flexDirection: 'row',
    },
}))

export default Tab

import React from 'react'
import TreeListItem from './TreeItem'

const TreeList = React.memo(
    ({ data, onClick, labelProp, selected, idProp, searchTerm }) => {
        return (
            <>
                {data.map((node) => (
                    <TreeListItem
                        labelProp={labelProp}
                        selected={selected}
                        onClick={onClick}
                        key={node.id}
                        node={node}
                        idProp={idProp}
                        searchTerm={searchTerm}
                    />
                ))}
            </>
        )
    },
)

export default TreeList

import React from 'react'
import Layout from './CollaborationTasksLayout'
import {
    useMyRequestWorkflows,
    useMyTasksWorkflows,
    useAllWorkflows,
} from 'hooks'
import useSubcomponents from 'useSubcomponents'
import attributes from './attributes'

const allFilters = {
    myRequests: [
        {
            name: 'approver',
            requireAccess: 'MyTasks-MyRequestsApproverFilter-Control',
        },
        {
            name: 'businessProcessStatus',
            requireAccess:
                'MyTasks-MyRequestsWorkflowTasksStatusFilter-Control',
        },
        {
            name: 'wentForApproval',
            requireAccess: 'MyTasks-ShowWentForApprovalFilter-Control',
        },
        {
            name: 'workflowsAdvancedSearch',
            requireAccess:
                'MyTasks-MyRequestsWorkflowTasksViewAdvancedSearchFilter-Control',
        },
    ],
    myTasks: [
        {
            name: 'requestedBy',
            requireAccess: 'MyTasks-MyTasksRequestedByFilter-Control',
        },
        {
            name: 'wentForApproval',
            requireAccess: 'MyTasks-ShowWentForApprovalFilter-Control',
        },
        {
            name: 'workflowsAdvancedSearch',
            requireAccess:
                'MyTasks-MyTasksWorkflowTasksViewAdvancedSearchFilter-Control',
        },
    ],
    allRequests: [
        {
            name: 'requestedBy',
            requireAccess: 'MyTasks-AllRequestsRequestedByFilter-Control',
        },
        {
            name: 'approver',
            requireAccess: 'MyTasks-AllRequestsApproverFilter-Control',
        },
        {
            name: 'businessProcessStatus',
            requireAccess:
                'MyTasks-AllRequestsWorkflowTasksViewStatusFilter-Control',
        },
        {
            name: 'wentForApproval',
            requireAccess: 'MyTasks-ShowWentForApprovalFilter-Control',
        },
        {
            name: 'workflowsAdvancedSearch',
            requireAccess:
                'MyTasks-AllRequestsWorkflowTasksViewAdvancedSearchFilter-Control',
        },
    ],
}

const MyRequestsWorkflows = () => {
    const {
        list,
        totalCount,
        loading,
        loadingMore,
        pagination,
        refetch,
    } = useMyRequestWorkflows()

    const { trimFiltersBasedOnAccess } = useSubcomponents()

    const filters = trimFiltersBasedOnAccess(allFilters.myRequests).map(
        (f) => f,
    )

    return (
        <Layout
            totalCount={totalCount}
            list={list}
            isLoading={loading}
            isLoadingMore={loadingMore}
            pagination={pagination}
            filters={filters}
            refetch={refetch}
            attributes={attributes.filter(
                (a) => a.showInAll || a.showInMyRequests,
            )}
        />
    )
}

const MyTasksWorkflows = () => {
    const {
        list,
        totalCount,
        loading,
        loadingMore,
        pagination,
        refetch,
    } = useMyTasksWorkflows()

    const { trimFiltersBasedOnAccess } = useSubcomponents()

    const filters = trimFiltersBasedOnAccess(allFilters.myTasks).map(
        (f) => f,
    )

    return (
        <Layout
            totalCount={totalCount}
            list={list}
            isLoading={loading}
            isLoadingMore={loadingMore}
            pagination={pagination}
            filters={filters}
            refetch={refetch}
            attributes={attributes.filter(
                (a) => a.showInAll || a.showInMyTasks,
            )}
        />
    )
}

const AllWorkflows = () => {
    const {
        list,
        totalCount,
        loading,
        loadingMore,
        pagination,
        refetch,
    } = useAllWorkflows()

    const { trimFiltersBasedOnAccess } = useSubcomponents()

    const filters = trimFiltersBasedOnAccess(allFilters.allRequests).map(
        (f) => f,
    )

    return (
        <Layout
            totalCount={totalCount}
            list={list}
            isLoading={loading}
            isLoadingMore={loadingMore}
            pagination={pagination}
            filters={filters}
            refetch={refetch}
            attributes={attributes.filter(
                (a) => a.showInAll || a.showInAllWorkflows,
            )}
        />
    )
}

export { MyRequestsWorkflows, MyTasksWorkflows, AllWorkflows }

import { useAxios } from 'packages/core'
import { useMutation } from 'react-query'
import { refreshWorkflow } from './utils'
import { useNotification } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

export const useCanUnClaimWorkflow = () => {
    const callApi = useAxios()

    return useMutation((personId) =>
        callApi({
            method: 'GET',
            url: `/api/WorkflowTasks/hasAccessToUnClaimOperation/${personId}`,
        }),
    )
}

export const useCanRemoveDelegateOperation = () => {
    const callApi = useAxios()

    return useMutation((personId) =>
        callApi({
            method: 'GET',
            url: `/api/WorkflowTasks/hasAccessToRemoveDelegateOperation/${personId}`,
        }),
    )
}

export const useWorkflowClaim = (workflowTaskId) => {
    const callApi = useAxios()

    return useMutation(
        () =>
            callApi({
                method: 'POST',
                url: `/api/WorkflowTasks/claim/${workflowTaskId}`,
            }),
        {
            onSuccess: () => refreshWorkflow(workflowTaskId),
        },
    )
}

export const useWorkflowUnclaim = (workflowTaskId) => {
    const callApi = useAxios()
    const { t } = useTranslation()
    const { showWarningMessage } = useNotification()
    return useMutation(
        (id) =>
            callApi({
                method: 'POST',
                url: `/api/WorkflowTasks/unclaim/${id}`,
            }),
        {
            onSettled: () => refreshWorkflow(workflowTaskId),
            onError: () => showWarningMessage(t('Common_UnableToUnAssign')),
        },
    )
}

export const useWorkflowDelegate = (workflowTaskId) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `/api/WorkflowTasks/delegate/${data.workflowTaskId}/${data.personId}`,
            }),
        {
            onSettled: () => refreshWorkflow(workflowTaskId),
        },
    )
}
export const useWorkflowUnDelegate = (workflowTaskId) => {
    const callApi = useAxios()
    return useMutation(
        (id) =>
            callApi({
                method: 'POST',
                url: `/api/WorkflowTasks/undelegate/${id}`,
            }),
        {
            onSettled: () => refreshWorkflow(workflowTaskId),
        },
    )
}



export const useAddAdditionalApproversToWorkFlow = (workflowTaskId, requestWorkflowGUID) => {
    const callApi = useAxios()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `/api/WorkflowTasks/addApprover/${data.workflowTaskId}/${data.personId}/${requestWorkflowGUID}`,
            }),
        {
            onSettled: () => refreshWorkflow(workflowTaskId),
        },
    )
}

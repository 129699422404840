import React, { Fragment } from 'react'
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import TextFormatter from '../TextFormatter'
import classNames from 'classnames'

const StatusCountIndicator = ({ itemTypeCount, resource }) => {
    return (
        <Box px="4px" py="4px">
            {itemTypeCount
                .filter((i) => i.resourceType === resource)
                .map((item, index) => {
                    return (
                        <Box
                            key={index + item.resourceType}
                            my="4px"
                            fontSize="13px"
                            color={'white'}
                        >
                            <Box
                                component="span"
                                fontWeight="bold"
                                marginRight="8px"
                            >
                                {`${item.totalCount} -`}
                            </Box>
                            {item.itemTypeActionFriendlyName}
                        </Box>
                    )
                })}
        </Box>
    )
}

const useStyles = makeStyles({
    attribute: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '2px 10px 2px 10px',
        fontSize: '13px !important',
        color: '#5d6870 !important',
        '& > svg': {
            marginBottom: '1px',
        },
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '6px',
        fontWeight: 'bold',
    },
    iconContainerDefault: {
        '&:hover': {
            '& > div': {
                borderColor: '#cdcdd1',
            },
        },
    },
    totalCount: {
        fontWeight: 'bold',
        marginRight: '6px',
    },
    attributeOnHover: {
        cursor: 'pointer',
        '&:hover span': {
            color: '#000000',
        },
        '&:hover svg': {
            color: '#000000',
        },
    },
})

const ItemTypeResourceSummary = ({
    itemTypeCount,
    itemTypeResourceSummary,
}) => {
    const { t } = useTranslation()

    const classes = useStyles()
    const showToolTip = (resource) => {
        const x = itemTypeCount.find((i) => i.resourceType === resource)
        return Boolean(x?.itemTypeActionFriendlyName)
    }
    return (
        <Fragment>
            {itemTypeResourceSummary.map((resource, index) => (
                <Tooltip
                    key={resource.resourceType + index}
                    title={
                        showToolTip(resource.resourceType) ? (
                            <StatusCountIndicator
                                itemTypeCount={itemTypeCount}
                                resource={resource.resourceType}
                            />
                        ) : (
                            ''
                        )
                    }
                    enterDelay={800}
                >
                    <Box
                        key={resource.resourceType + index}
                        className={classNames(classes.attribute, {
                            [classes.attributeOnHover]:
                                resource.resourceType !== 'Unknown' &&
                                showToolTip(resource.resourceType),
                        })}
                    >
                        <Box
                            component={'span'}
                            className={classNames(classes.iconContainer, {
                                [classes.iconContainerDefault]:
                                    resource.resourceType === 'Unknown',
                            })}
                        >
                            {resource.resourceType !== 'Unknown' ? (
                                <Icon
                                    name={
                                        resource.resourceType === 'Person'
                                            ? 'ViewProfile'
                                            : resource.resourceType
                                    }
                                    color="#919193"
                                />
                            ) : (
                                <Box
                                    height="16px"
                                    width="16px"
                                    border="4px solid #d8d8dd"
                                    bgcolor="transparent"
                                    borderRadius="50%"
                                />
                            )}
                        </Box>

                        <Box component={'span'} className={classes.totalCount}>
                            {resource.count}
                        </Box>

                        {resource.resourceType !== 'Unknown' ? (
                            <span>{t(`${resource.resourceType}`)}</span>
                        ) : (
                            <Box component={'span'}>
                                <TextFormatter
                                    value={resource.categoryName}
                                    maxCharacters={40}
                                    style={{
                                        fontSize: '13px',
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Tooltip>
            ))}
        </Fragment>
    )
}

export default ItemTypeResourceSummary

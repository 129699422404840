import { FC } from 'react'
import { useSubmitViolationItemDecision } from 'hooks'
import DoNothingButton from 'components/Button/DoNothingButton'

export const DoNothing: FC<any> = (props) => {
    const { item, d } = props

    const [
        submitRiskDecision,
        { isLoading: isSubmitting },
    ] = useSubmitViolationItemDecision(
        'Requests',
        item.businessRequestId,
        item.id,
    )

    return (
        <DoNothingButton
            size={'small'}
            onClick={() => {
                const revokeReq = {
                    stepId: item.currentApprovalStepId,
                    decisionId: d.decisionId,
                }
                submitRiskDecision(revokeReq)
            }}
            loading={isSubmitting}
        >
            {d.decisionFriendlyName}
        </DoNothingButton>
    )
}

import React from 'react'
import { IconButton as MuiIconButton } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}))

const IconButton = (props) => <MuiIconButton classes={useStyles()} {...props} />

export default IconButton

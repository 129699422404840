import { Box, ListItem, styled, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Radio from '@mui/material/Radio'
import { Skeleton } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { Fragment, useEffect, useState } from 'react'
import FieldItemWrapper from '../Components/FieldItemWrapper'
import RequiredFieldErrorMessage from '../Components/FieldItemWrapper/RequiredFieldErrorMessage'
import classNames from 'classnames'
import { getFieldTypeRequiredErrorMessage } from 'packages/core'
import InfoMessageForWrongJson from '../Components/InfoMessageForWrongJson'
import InfoTooltip from '../Components/InfoTooltip'

export const StyledRadioIcon = styled(Radio)({
    padding: '0px 12px 0px 0px',
})

const useStyles = makeStyles((theme) => ({
    fieldList: {
        '& li': {
            width: 'auto',
            background: '#fbfbfd !important',
            color: '#767676',
            fontWeight: 'normal',
            fontSize: '14px',
            paddingLeft: '0',
        },
        '& .Mui-selected': {
            color: `${theme?.palette?.common?.black} !important`,
        },
    },
    disabledStyle: {
        pointerEvents: 'none',
        opacity: '.6',
        cursor: 'not-allowed',
    },
}))

const SingleSelectRadioButton = ({
    fieldType,
    AzLocalRightID,
    isFormValidated,
    valueType,
    isApiSupportedSource,
    useDataHook,
    handleUpdate,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const [sourceValues, setSourceValues] = useState([])
    const [showFieldValues, setShowFieldValues] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [sourceUpdated, setSourceUpdated] = useState(false)

    useEffect(() => {
        if (fieldType?.SourceValues && sourceUpdated) {
            setShowFieldValues(fieldType?.SourceValues?.length ? true : false)
            setSourceValues(fieldType?.SourceValues || [])
        }
    }, [fieldType?.SourceValues, sourceUpdated])

    useEffect(() => {
        if (isFormValidated && !fieldType?.[valueType]) {
            setErrorMessage(getFieldTypeRequiredErrorMessage(fieldType))
        } else {
            setErrorMessage('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormValidated, fieldType?.[valueType]])

    const { data: sourceData, isFetching } = useDataHook(fieldType)

    useEffect(() => {
        if (isApiSupportedSource) {
            if (!isFetching) {
                updateSourceValues()
                setSourceUpdated(true)
            }
        } else {
            setShowFieldValues(fieldType?.SourceValues?.length ? true : false)
            setSourceValues(fieldType?.SourceValues || [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isApiSupportedSource, isFetching])

    const updateSourceValues = () => {
        const valueToUpdate = {
            ...fieldType,
            SourceValues: sourceData?.data || [],
        }
        handleUpdate(valueToUpdate)
    }

    const handleChange = (selected) => {
        const valueToUpdate = {
            ...fieldType,
            [valueType]: {
                FriendlyName: selected?.FriendlyName,
                ValueName: selected?.ValueName,
            },
            parentRightId: AzLocalRightID,
        }
        handleUpdate(valueToUpdate)
    }

    return (
        <FieldItemWrapper
            title={fieldType?.Name}
            isRequired={fieldType?.IsRequired}
            fieldTypeScope={fieldType?.FieldTypeScope}
        >
            <Box
                className={classNames(classes.fieldList, {
                    [classes.disabledStyle]: fieldType?.IsReadOnly,
                })}
            >
                {/* Types List */}
                <Box display="flex" gap={'2.4rem'}>
                    {isFetching ? (
                        <Fragment>
                            <Skeleton height={40} />
                            <Skeleton height={40} />
                            <Skeleton height={40} />
                        </Fragment>
                    ) : showFieldValues ? (
                        <Fragment>
                            {sourceValues.map((item, index) => (
                                <ListItem
                                    selected={
                                        fieldType?.[valueType]?.ValueName ===
                                        item?.ValueName
                                    }
                                    key={`radio-list${index}`}
                                    onClick={() => handleChange(item)}
                                >
                                    <StyledRadioIcon
                                        icon={<Icon name="Radio" />}
                                        checkedIcon={
                                            <Icon
                                                name="RadioFilled"
                                                color={
                                                    theme?.palette?.primary
                                                        ?.main
                                                }
                                            />
                                        }
                                        checked={
                                            fieldType?.[valueType]
                                                ?.ValueName === item?.ValueName
                                        }
                                    />
                                    {item?.FriendlyName}
                                    <InfoTooltip
                                        friendlyName={item?.FriendlyName}
                                        description={item?.Description}
                                    />
                                </ListItem>
                            ))}
                        </Fragment>
                    ) : (
                        <InfoMessageForWrongJson />
                    )}
                </Box>
                {Boolean(errorMessage) && (
                    <RequiredFieldErrorMessage errorMsgKey={errorMessage} />
                )}
            </Box>
        </FieldItemWrapper>
    )
}

export default SingleSelectRadioButton

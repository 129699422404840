import moment from "moment/moment";
import React from "react";
import { getAMPM, getHour, getMin, inTimeRange } from "./helper";

const Times = ({ select, disabledSlots = [], onChange }) => {
  const Hour = getHour(select);
  const Min = getMin(select);
  const APPM = getAMPM(select);

  const isDisableHour = (hour) => { 
    let disabled = false;
    disabledSlots.length &&
      disabledSlots.map((i) => {
        let hourStart = i[0];
        let hourEnd = i[1];
        if (inTimeRange(hour, hourStart, hourEnd, "hours")) {
          disabled = true;
        }
        return i
      }); 
    return disabled;
  };

  const isDisableMin = (date) => {
    let disabled = false;
    disabledSlots.length &&
      disabledSlots.map((i) => {
        let hourStart = i[0];
        let hourEnd = i[1];
        if (inTimeRange(date, hourStart, hourEnd, "minutes")) {
          disabled = true;
        }
        return i
      });
    return disabled;
  };

  const createDate = (hours) => {
    const AP = moment(select).format("A");
    let tempHours = hours;
    const mins = moment(select).format("mm");
    const datea = moment(select).format("yyyy-MM-DD")
    const constorDate = `${datea} ${tempHours >= 10 ? tempHours.toString() : "0" + tempHours}:${mins}:00 ${AP}`
    return moment(constorDate, 'yyyy-MM-DD hh:mm:ss A');
  };
  const createDateUpdated = (min) => {
    const AP = moment(select).format("A");
    const date = moment(select).format("yyyy-MM-DD");
    const hours = moment(select).format("hh");
    const newDate = moment(`${date} ${hours}:${min}:00 ${AP}`, 'yyyy-MM-DD hh:mm:ss A');

    return newDate;
  };
  const onSelectHour = (hour) => { 
    onChange(createDate(hour).toDate());
  };

  const onSelectMin = (min) => {
    const date = moment(select).format("yyyy-MM-DD");
    const hours = moment(select).format("hh");
    const AP = moment(select).format("A");
    const newDate = moment(`${date} ${hours}:${min >= 10 ? min.toString() : "0" + min}:00 ${AP}`, 'yyyy-MM-DD hh:mm:ss A');
    onChange(newDate);
  };

  const onSelectAMPM = (AP) => {
    const date = moment(select).format("yyyy-MM-DD");
    const hours = moment(select).format("hh");
    const min = moment(select).format("mm");
    const newDate = moment(`${date} ${hours}:${min}:00 ${AP}`, 'yyyy-MM-DD hh:mm:ss A');
    onChange(newDate);
  };

  return (
    <div className={`time-slot`}>
      <div className={`hours`}>
        {[...Array(12).keys()].map((i, index) => {
          const checkHours = i + 1; 
          return (
            <div
              onClick={() =>
                !isDisableHour(createDate(checkHours)) &&
                onSelectHour(checkHours)
              }
              key={i + index}
              className={`hour 
              ${Hour === (checkHours).toString() ? "selected" : ""}
              ${isDisableHour(createDate(checkHours)) ? "disabled" : ""}
              `}
            >
              {(i + 1) >= 10 ? i + 1 : '0' + (i + 1)}
            </div>
          );
        })}
      </div>
      <div className={`minutes`}>
        {[...Array(60).keys()].map((i, index) => {
          return (
            <div
              onClick={() =>
                !isDisableMin(createDateUpdated(i)) && onSelectMin(i)
              }
              key={i + index}
              className={`minute 
            ${isDisableMin(createDateUpdated(i)) ? "disabled" : ""}
            ${Number(Min) === i ? "selected" : ""}
            `}
            >
              {i >= 10 ? i : '0' + i}
            </div>
          )
        }
        )}
      </div>
      <div className={`meridiems`}>
        <div
          onClick={() => onSelectAMPM("AM")}
          className={`meridiem ${APPM === "am" ? "selected" : ""}`}
        >
          AM
        </div>
        <div
          onClick={() => onSelectAMPM("PM")}
          className={`meridiem ${APPM === "pm" ? "selected" : ""}`}
        >
          PM
        </div>
      </div>
    </div>
  );
};

export default Times;

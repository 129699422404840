import React from 'react'
import { Fab, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from 'packages/eid-icons'
import { Spinner } from 'packages/eid-ui'

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        margin: theme.spacing(1),
        textTransform: 'capitalize',
        fontSize: '14px',
        height: '31px !important',
        color: '#ffffff !important',
        backgroundColor: '#307fc1',
        '&:hover': {
            backgroundColor: '#307fc1',
        },
        ...props.styles,
    }),

    icon: {
        marginLeft: 6,
    },
}))
const RoundedButton = ({ styles, isLoading, children, onClick, ...rest }) => {
    const classes = useStyles({ styles })
    return (
        <Fab
            variant="extended"
            size="small"
            classes={{ root: classes.root }}
            onClick={isLoading ? undefined : onClick}
            {...rest}
        >
            {isLoading ? (
                <Box
                    minWidth="72px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Spinner color="#ffffff" size={0.5} />
                </Box>
            ) : (
                <>
                    {children}
                    <Icon
                        name="ArrowSmall"
                        color={styles?.color ? styles.color : '#ffffff'}
                        fill={styles?.color ? styles.color : '#ffffff'}
                        direction={
                            styles?.arrowDirection
                                ? styles.arrowDirection
                                : 'down'
                        }
                        className={classes.icon}
                    />
                </>
            )}
        </Fab>
    )
}

export default RoundedButton

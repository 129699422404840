import { Theme, Tab as MuiTab } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { smallScreenWidth } from 'utils'

interface StyledTabProps {
    value: string | number
    label: any
}

export const Tab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            color: '#3b454d',
            backgroundColor: '#fbfbfd',
            fontWeight: 'bold',
            fontSize: '16px',
            maxWidth: '400px',

            marginRight: theme.spacing(1),
            marginBottom: '-1px',

            padding: '0px 20px',

            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',

            borderBottom: 'solid 1px #d8d8dd',

            transition: `all 0.5s ease-out`,

            '&:focus': {
                opacity: 1,
            },

            '& p': {
                fontWeight: 'bold',
                fontSize: '16px',
                wordBreak: 'break-word',
                wordWrap: 'break-word',
                overflow: 'auto',
                textAlign: 'left',
            },
            '& .MuiChip-root': {
                height: '20px',
                color: '#ffffff',
                fontWeight: 'normal',
                fontSize: '14px',
                backgroundColor: '#8b909a',
                marginLeft: '8px',
            },

            [`@media (max-width:${smallScreenWidth})`]: {
                maxWidth: '200px',
                fontSize: '12px',
                padding: '0px 12px',
            },
        },

        selected: {
            color: '#000000',

            border: 'solid 1px #d8d8dd',
            borderBottom: 'solid 1px transparent',

            boxShadow: '0 0 16px 0 rgba(0,0,0,0.11)',
            backgroundImage: 'linear-gradient(to bottom, #ffffff, #fbfbfd)',

            '& .MuiChip-root': {
                backgroundColor: '#307fc1',
            },
        },
    }),
)((props: StyledTabProps) => <MuiTab disableRipple {...props} />)

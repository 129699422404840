import React from 'react'
import { Avatar as MuiAvatar, Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getFullImageUrl } from 'utils'
import { Tooltip } from 'packages/eid-ui'
import { useRiskOwner } from 'hooks'
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(
    createStyles({
        root: {
            padding: '24px 31px',
        },
        riskgap: {
            marginTop: '16px',
        },
        risksectionavtar: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '15px',
            marginBottom: '10px',
            '& p': {
                fontSize: '12px',
                fontWeight: 500,
                color: '#fff',
                marginBottom: '0',
                marginLeft: '10px',
            },
        },
        riskowners: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            maxWidth: '150px',
            width: '100%',
        },
        riskownersName: {
            textOverflow: 'ellipsis',
            maxWidth: '100px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            marginBottom: '0px !important',
        },
        avatar: {
            width: '24px',
            height: '24px',
        },
        risksectionrightcontainer: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
    }),
)
function RiskOwners({ enableApi, localRiskId }: any) {
    const { t } = useTranslation()
    const classes = useStyles()
    const { data: owner, isLoading }: any = useRiskOwner(localRiskId, enableApi)

    return (
        <Box className={classes.riskgap}>
            <p>{t('RiskOwner')}</p>
            <Box className={classes.risksectionrightcontainer}>
                {isLoading ? (
                    <Box width={'100%'} my={2}>
                        <Skeleton animation="wave" variant="rectangular" height={24} />
                    </Box>
                ) : owner ? (
                    <Box
                        key={`approver-list-${owner.id}`}
                        className={classes.riskowners}
                    >
                        <Box className={classes.risksectionavtar}>
                            <MuiAvatar
                                src={getFullImageUrl(owner?.imageThumbUrl)}
                                className={classes.avatar}
                            />
                            <Tooltip title={owner?.friendlyName}>
                                <p className={classes.riskownersName}>
                                    {owner?.friendlyName}
                                </p>
                            </Tooltip>
                        </Box>
                    </Box>
                ) : (
                    <p>-</p>
                )}
            </Box>
        </Box>
    );
}

export default RiskOwners

import React from 'react'
import { Box, styled } from '@mui/material'
import AppDesktopHeader from './AppDesktopHeader'
import AppMobileHeader from './AppMobileHeader'
import { useApplicationSettings } from 'hooks'
import { mobileScreenWidth, tabletScreenWidth } from 'utils'

const Container = styled(Box)({
    [`@media (max-width:${mobileScreenWidth}) `]: {
        display: 'none',
    },
})

const MobileContainer = styled(Box)({
    [`@media (min-width:${tabletScreenWidth}) `]: {
        display: 'none',
    },
})

const AppHeader = () => {
    const { data: appConfig } = useApplicationSettings()

    const headerConfig = appConfig.style.header

    return (
        <>
            <MobileContainer>
                <AppMobileHeader config={headerConfig} />
            </MobileContainer>
            <Container>
                <AppDesktopHeader config={headerConfig} />
            </Container>
        </>
    )
}

export default AppHeader

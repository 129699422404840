import React, { useState } from 'react'
import EidCardList from './EidCardList'
import EidTable from './EidTable'
import { useViewIndicator } from 'packages/eid-ui'
import { useAppState } from 'appContext'
import { useTranslation } from 'react-i18next'

const useEidGrid = ({
    isLoading,
    data,
    isLoadingMore,
    noDataMessage = 'No data available',
    pagination,
    attributes,
    cardViewConfig,
    tableViewConfig,
    defaultView = 'table',
}) => {
    const [state, dispatch] = useAppState()

    const [currentView, setCurrentView] = useState(
        state && state.itemsView ? state.itemsView : defaultView,
    )
    const { t } = useTranslation()
    const { viewIndicator: viewSwitcher } = useViewIndicator({
        view: currentView,
        onCardViewClick: () => {
            setCurrentView('card')
            dispatch({
                type: 'SET_ITEMS_VIEW',
                payload: 'card',
            })
        },
        onListViewClick: () => {
            setCurrentView('table')
            dispatch({
                type: 'SET_ITEMS_VIEW',
                payload: 'table',
            })
        },
        cardViewLabel: t('Card_View_Label'),
        listViewLabel: t('List_View_Label'),
    })

    const viewToRender =
        currentView === 'card' ? (
            <EidCardList
                isLoading={isLoading}
                data={data}
                isLoadingMore={isLoadingMore}
                noDataMessage={noDataMessage}
                pagination={pagination}
                attributes={attributes}
                {...cardViewConfig}
            />
        ) : (
            <EidTable
                isLoading={isLoading}
                data={data}
                isLoadingMore={isLoadingMore}
                noDataMessage={noDataMessage}
                pagination={pagination}
                attributes={attributes}
                {...tableViewConfig}
            />
        )

    return { viewSwitcher, currentView, setCurrentView, viewToRender }
}

export { useEidGrid, EidCardList, EidTable }

import { ReactComponent as CloseRoundSvg } from './close-round.svg'
import { styled } from '@mui/material'

const CloseRoundIcon = styled(CloseRoundSvg)((props) => ({
    '& path': {
        fill: props.color,
    },
}))

CloseRoundIcon.defaultProps = {
    color: '#000000',
}

export default CloseRoundIcon

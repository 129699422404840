import React from 'react'
import Button from './index'
import { Icon } from 'packages/eid-icons'

const getStyles = (loading, active, deactivate) => {
    return {
        cursor: deactivate ? 'default' : 'pointer',
        minWidth: '112px',
        backgroundColor: deactivate
            ? '#fbfbfd'
            : loading || active
            ? '#9230c5'
            : '#fbfbfd',
        color: deactivate ? '#9230c5' : active ? '#ffffff' : '#9230c5',
        border: '1px solid #9230c5',
        '&:hover': !deactivate && {
            boxShadow: '0 2px 8px 0 rgba(146, 48, 197, 0.33)',
            backgroundColor: '#9230c5',
            color: '#ffffff',
            '& span': {
                color: '#ffffff !important',
            },
            '& svg': {
                color: '#ffffff',
            },
        },

        '&:active': !deactivate && {
            backgroundColor: '#9230c5',
            color: '#ffffff',
            '& svg': {
                color: '#ffffff',
            },
            boxShadow:
                '0 2px 8px 0 rgba(146, 48, 197, 0.33), inset 2px 2px 4px 0 rgba(0, 0, 0, 0.11)',
        },
    }
}

const disabledStyles = {
    color: '#aab04 !important',
    border: 'solid 1px #aab0b4 !important',
}

const DelegateButton = ({
    disabled,
    loading,
    active,
    deactivate,
    icon,
    ...rest
}) => {
    return (
        <Button
            size="small"
            loading={loading}
            disabled={disabled}
            style={getStyles(loading, active, deactivate)}
            disabledStyles={disabledStyles}
            startIcon={
                icon ? (
                    icon
                ) : (
                    <Icon
                        name="Delegate"
                        color={
                            disabled ? '#aab04' : active ? '#ffffff' : '#9230c5'
                        }
                    />
                )
            }
            {...rest}
        />
    )
}

export default DelegateButton

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getFullImageUrl } from 'utils'
import moment from 'moment'
import { Avatar } from 'packages/eid-ui'

const useStyles = makeStyles({
    maincontainer: {
        borderRadius: '8px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        border: 'solid 1px rgba(0, 0, 0, 0.11)',
        background: '#fff',
        overflow: 'hidden',
        marginBottom: '17px',
        width: '100%',
    },

    headertitle: {
        fontSize: '14px',
        fontWeight: 600,
        margin: '0px',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.11)',
        padding: '4px 20px ',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        height: '40px',
    },

    itemshow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 20px ',
    },
    leftshow: {
        width: '35%',
        marginRight: '10px',
    },
    rightshow: {
        width: '65%',
    },
    itemtitle: {
        fontSize: '12px',
        color: '#9b9b9b',
        margin: '0px',
        textTransform: 'uppercase',
        padding: '3px 0px',
        fontWeight: 'bold',
    },
    itemlabel: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#000',
        margin: '0px',
    },

    avtarsection: {
        display: 'flex',
        alignItems: 'center',
        ' & > div': {
            height: '20px',
            width: '20px',
        },
    },
    avtarname: {
        marginLeft: '5px',
        color: '#307fc1',
    },
})

export const AffectedResourceCardItem = ({ resource }: any) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <Box className={classes.maincontainer}>
            <Box className={classes.header}>
                <Box component="h2" className={classes.headertitle}>
                    {resource.resourceName ?? '-'}
                </Box>
            </Box>
            <Box className={classes.itemshow}>
                {resource.initiatorPersonName && (
                    <Box className={classes.leftshow}>
                        <Fragment>
                            <Box component="h2" className={classes.itemtitle}>
                                {t('Initiator')}
                            </Box>

                            <Box className={classes.avtarsection}>
                                <Avatar
                                    size="medium"
                                    src={getFullImageUrl(
                                        resource?.initiatorPersonThumbUrl,
                                    )}
                                />
                                <span className={classes.avtarname}>
                                    {resource.initiatorPersonName}
                                </span>
                            </Box>
                        </Fragment>
                    </Box>
                )}
                <Box className={classes.rightshow}>
                    <Box component="h2" className={classes.itemtitle}>
                        {t('Description')}
                    </Box>
                    <Box component="h2" className={classes.itemlabel}>
                        {resource.taskDescription ?? '-'}
                    </Box>
                </Box>
            </Box>
            <Box className={classes.itemshow}>
                <Box className={classes.leftshow}>
                    <Box component="h2" className={classes.itemtitle}>
                        {t('Operation')}
                    </Box>
                    <Box component="h2" className={classes.itemlabel}>
                        {resource.operationFriendlyName ?? '-'}
                    </Box>
                </Box>

                <Box className={classes.rightshow}>
                    <Box component="h2" className={classes.itemtitle}>
                        {t('Date')}
                    </Box>
                    <Box component="h2" className={classes.itemlabel}>
                        {moment.utc(resource.createdDate).local().format('LLL')}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AffectedResourceCardItem

import React from 'react'
import { Box, Button } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    createStyles({
        root: {
            cursor: 'pointer',
            minWidth: '112px',
            backgroundColor: '#307fc1',
            color: '#ffffff',
            border: '1px solid #307fc1',
            paddingLeft: '8px',
            paddingRight: '8px',
            textAlign: 'left',
            fontSize: '12px',
            '&:hover': {
                backgroundColor: '#307fc1',
                boxShadow: '0 2px 8px 0 rgb(48, 127, 193, 0.33)',
            },
            '&:active': {
                backgroundColor: '#307fc1',
                boxShadow:
                    '0 2px 8px 0 rgb(48, 127, 193, 0.33), inset 2px 2px 4px 0 rgba(0, 0, 0, 0.11)',
            },
        },
        triangle: {
            width: 0,
            height: 0,
            borderTop: '6px solid transparent',
            borderLeft: '12px solid #ffffff',
            borderBottom: '6px solid transparent',
        },
    }),
)

export const RunWorkflowButton = (props: any) => {
    const classes = useStyles()
    return (
        <Button
            size="small"
            className={classes.root}
            endIcon={<Box className={classes.triangle} />}
            {...props}
        />
    )
}

import { useTranslation } from 'react-i18next'
import { Box, Link, Divider } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { StatusIndicator } from 'packages/eid-ui'
import { Button, TextFormatter } from 'components'
import {
    useAllApplications,
    useRequestItemSummaryEditFulfillmentDate,
} from 'hooks'
import { getURLTarget, useUrlGenerator } from 'packages/core'
import { itShopSupportedResourceTypes } from 'components/SubjectTypeAttribute/supportedSubjectTypes'
import Attribute from './Attribute'
import useSubcomponents from 'useSubcomponents'
import { FulfillmentScheduleDateControl } from 'components/FulfillmentScheduleDateControl'
import { SubPathLevel } from 'appConfigurations'
import appConfig from 'config'
import moment from 'moment'
import { isDate, isValidJson } from 'utils'
import RequestDynamicFieldTypeValues from 'components/RequestDynamicFieldTypeValues'

const NS = 'public'
const styles = {
    name: {
        fontSize: '16px',
        color: '#307fc1',
        fontWeight: 'bold',
        maxWidth: '200px',
    },
    position: {
        textTransform: 'uppercase',
        color: '#9b9b9b',
        fontSize: '12px',
        fontWeight: 'bold',
        maxWidth: '200px',
    },
    approvalStep: {
        color: '#f94000',
        fontSize: '12px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    emailPhone: {
        maxWidth: '200px',
        fontSize: '14px',
        color: '#307fc1',
    },
    borderBox: {
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSlice: 1,
        height: '1px',
        width: '100%',
        opacity: 0.25,
    },
    indicatorContainer: {
        position: 'absolute',
        padding: '0 1.6rem',
        top: '-13px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    indicator: {
        padding: '4px 8px !important',
        maxWidth: '50%',
    },
    fulfillmentStatusIndicator: {
        padding: '4px 8px !important',
        backgroundColor: '#8b909a',
        marginRight: '2px',
    },

    container: {
        width: '100%',
        marginTop: '1.2rem',
        borderRadius: '8px',
        boxShadow: '0 -13px 16px 0 rgba(0, 0, 0, 0.05)',
        backgroundColor: '#ffffff',
        display: 'flex',
        position: 'relative',
        overflow: 'visible',
    },
    attributeContainer: {
        margin: '7.5px 0px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        paddingRight: '5px',
    },
    approvedbutton: {
        height: '48px',
        overflow: 'hidden',
    },
}

const ResourceTypeCard = ({
    businessRequest,
    item,
    itemTypeId,
    functionalAccessExpanded,
    onFunctionalAccessExpansion,
    approversExpanded,
    onApproversExpansion,
    commentsExpanded,
    onCommentsExpansion,
    violationsExpanded,
    onViolationsExpansion,
}) => {
    const { t } = useTranslation()
    const history = useHistory()

    const area = history.location.pathname.split('/')[1 + SubPathLevel]

    const { getItShopUrl } = useUrlGenerator(useAllApplications)

    const itemStatus = item.preApproved ? 'PreApproved' : item.status

    const getITShopSubjectTypePath = (item) => {
        if (!itShopSupportedResourceTypes.includes(item.subjectType))
            return undefined
        if (item.subjectType === 'AzureRole') {
            switch (item?.otherProperties?.AzureRoleType) {
                case 'Admin':
                    return 'AzureAdminRole'
                case 'Rbac':
                    return 'AzureRbacRole'
                default:
                    return undefined
            }
        } else return item.subjectType
    }

    const showCurrentApprovalStepName = item.currentApprovalStepName

    const { canSeeRiskViolationsByRisks } = useSubcomponents()

    const [editFulfillmentScheduleDate] =
        useRequestItemSummaryEditFulfillmentDate(
            item.id,
            item.businessRequestId,
            itemTypeId,
        )

    return (
        <>
            <Box style={styles.container}>
                <Box style={styles.indicatorContainer}>
                    {item.fulfillmentStatusId > 0 &&
                        item.fulfillmentStatusFriendlyName && (
                            <StatusIndicator
                                label={`${t('Fulfillment')} ${
                                    item.fulfillmentStatusFriendlyName
                                } `}
                                size="small"
                                style={styles.fulfillmentStatusIndicator}
                            />
                        )}

                    <StatusIndicator
                        variant={itemStatus}
                        label={t(`${itemStatus}`)}
                        size="small"
                        style={styles.indicator}
                    />
                </Box>
                <Box
                    overflow="auto"
                    // maxHeight={showCurrentApprovalStepName ? '436px' : '643px'}
                    width="100%"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        position="relative"
                        style={{
                            padding: '1.6rem',
                            minHeight: '40rem',
                        }}
                    >
                        {item.fulFillmentScheduleDateUtc && (
                            <FulfillmentScheduleDateControl
                                item={item}
                                onEdit={editFulfillmentScheduleDate}
                            />
                        )}

                        <Box marginY="7.5px" display="flex" alignItems="center">
                            <Attribute
                                style={styles.name}
                                value={item.itemTypeActionFriendlyName}
                                label={t('ActionType')}
                                orientation="vertical"
                            />
                        </Box>

                        {item.additionalProperties &&
                            Object.keys(item.additionalProperties).map((k) => {
                                const value =
                                    item.additionalProperties[k]?.toString()

                                return (
                                    <Box key={k}>
                                        <Attribute
                                            style={styles.name}
                                            value={
                                                isDate(value)
                                                    ? moment
                                                          .utc(value)
                                                          .local()
                                                          .format('LLL')
                                                    : value
                                            }
                                            label={t(`${k}`)}
                                            orientation="vertical"
                                        />
                                    </Box>
                                )
                            })}

                        {item.otherProperties &&
                            Object.keys(item.otherProperties).map((k) => {
                                const value =
                                    item.otherProperties[k]?.toString()
                                return (
                                    <Box key={k}>
                                        <Attribute
                                            style={styles.name}
                                            value={
                                                isDate(value)
                                                    ? moment
                                                          .utc(value)
                                                          .local()
                                                          .format('LLL')
                                                    : value
                                            }
                                            label={t(`${k}`)}
                                            orientation="vertical"
                                        />
                                    </Box>
                                )
                            })}

                        {item?.businessRequestItem_AdditionalFields?.length ? (
                            <>
                                <Divider orientation="horizontal" />
                                {item.businessRequestItem_AdditionalFields.map(
                                    (item) => {
                                        return (
                                            <Attribute
                                                style={styles.name}
                                                value={
                                                    item.AdditionalField_Value
                                                }
                                                label={
                                                    item.AdditionalField_LocaleKey
                                                        ? t(
                                                              `${NS}:MsCommonAnonymous_${item.AdditionalField_LocaleKey}`,
                                                          )
                                                        : item.AdditionalField_Name
                                                }
                                                orientation="vertical"
                                            />
                                        )
                                    },
                                )}
                            </>
                        ) : null}
                        <Box
                            height={
                                approversExpanded || commentsExpanded
                                    ? '20px'
                                    : '0px'
                            }
                            width="100%"
                        />
                    </Box>

                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        width={'100%'}
                        overflow="hidden"
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'flex-end'}
                            padding="0 1.6rem"
                            flexDirection={'column'}
                        >
                            <Box display={'flex'} justifyContent={'flex-end'}>
                                {getItShopUrl() &&
                                    item.subjectId &&
                                    getITShopSubjectTypePath(item) && (
                                        <Link
                                            href={getItShopUrl(
                                                `/${getITShopSubjectTypePath(
                                                    item,
                                                )}s?selected=${item.subjectId}`,
                                            )}
                                            target={getURLTarget()}
                                            style={{ marginRight: '10px' }}
                                        >
                                            {t('ViewResource')}
                                        </Link>
                                    )}
                                <Link
                                    href={`${appConfig.APP_SUBPATH}/${area}/businessRequestItems?id=${item.id}`}
                                    target={getURLTarget()}
                                >
                                    {t('ViewItem')}
                                </Link>
                            </Box>
                        </Box>
                        {/* FieldType values */}
                        {isValidJson(item?.fieldTypeValueJson) && (
                            <Box>
                                <RequestDynamicFieldTypeValues
                                    fieldTypeJson={item?.fieldTypeValueJson}
                                    cardView={true}
                                />
                            </Box>
                        )}
                        <Box display="flex" style={styles.approvedbutton}>
                            <Button.Expand
                                type="RiskFunction"
                                handleExpand={onFunctionalAccessExpansion}
                                expanded={functionalAccessExpanded}
                            />
                            {businessRequest.status !==
                                'Open_PendingApproval' &&
                                itemStatus !== 'Approved' &&
                                itemStatus !== 'Rejected' &&
                                itemStatus !== 'PreApproved' && (
                                    <Button.Expand
                                        type="Approvers"
                                        handleExpand={onApproversExpansion}
                                        expanded={approversExpanded}
                                    />
                                )}
                            <Button.Expand
                                type="Comments"
                                handleExpand={onCommentsExpansion}
                                expanded={commentsExpanded}
                            />

                            {item.riskCount > 0 &&
                                canSeeRiskViolationsByRisks && (
                                    <Box
                                        data-protectedsubcomponent={
                                            canSeeRiskViolationsByRisks
                                        }
                                    >
                                        <Button.Expand
                                            type={'Violations'}
                                            handleExpand={onViolationsExpansion}
                                            expanded={violationsExpanded}
                                        />
                                    </Box>
                                )}
                            {showCurrentApprovalStepName && (
                                <Box
                                    width={'100%'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    textAlign={'right'}
                                    overflow={'hidden'}
                                    maxHeight={'42px'}
                                    paddingRight="1.6rem"
                                >
                                    <TextFormatter
                                        style={styles.approvalStep}
                                        value={item.currentApprovalStepName}
                                        maxCharacters={42}
                                        showTooltip={'always'}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {!approversExpanded &&
                !commentsExpanded &&
                !violationsExpanded &&
                !functionalAccessExpanded && (
                    <Box display="flex" alignItems="center">
                        <Box style={styles.borderBox} />
                    </Box>
                )}
        </>
    )
}
export default ResourceTypeCard

import React, { useRef } from 'react'
import { Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import { isNilOrEmpty } from 'packages/core'
import { Avatar, Dropdown, Tooltip } from '../../eid-ui'
import { DefaultAvatarIcon } from '../../eid-icons'

const centerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const useStyles = makeStyles(() => ({
    dropdownAnchor: ({ anchorPosition }) => ({
        position: 'relative',
        top: '52px',
        left: '54px !important',
        ...anchorPosition,
    }),
}))

const useProfileNameStyles = makeStyles(() => ({
    root: {
        fontSize: '14px',
        color: '#919193',
        paddingLeft: '8px',
        maxWidth: '150px',
        overflow: 'hidden',
        '@media (max-width:1023px)': {
            display: 'none',
        },
    },
}))

const AccountDropdown = ({
    loading,
    friendlyName,
    imageUrl,
    anchorPosition,
    style,
    children,
    showCone = true,
    open,
    handleOpen: setOpen,
    disableImage = false,
}) => {
    const classes = useStyles({ anchorPosition })
    const profileNameClasses = useProfileNameStyles()

    const anchorRef = useRef(null)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    const avatarProps = {
        size: 'small',
        src: imageUrl,
    }

    const userAvatar = (
        <div style={{ ...centerStyles }}>
            {!disableImage && <Avatar {...avatarProps} />}
            {friendlyName && (
                <Typography noWrap classes={profileNameClasses}>
                    {friendlyName}
                </Typography>
            )}
        </div>
    )

    return <>
        <div style={{ position: 'relative', ...centerStyles, ...style }}>
            {loading ? (
                <Skeleton variant="circular" width={30} height={30} />
            ) : (
                <Tooltip title={friendlyName ? friendlyName : ''}>
                    <Link
                        style={{ ...centerStyles }}
                        underline="none"
                        component="button"
                        onClick={() => setOpen((prev) => !prev)}
                    >
                        <span
                            ref={anchorRef}
                            className={classes.dropdownAnchor}
                        />
                        {isNilOrEmpty(imageUrl) ? (
                            <DefaultAvatarIcon />
                        ) : (
                            userAvatar
                        )}
                    </Link>
                </Tooltip>
            )}
        </div>

        <Dropdown
            open={open}
            anchorEl={anchorRef.current}
            handleClose={handleClose}
            handleListKeyDown={handleListKeyDown}
            showCone={showCone}
            menuHeight="45vh"
        >
            {children}
        </Dropdown>
    </>;
}

export default AccountDropdown
